import Conversation from "@/utils/messaging/conversation/Conversation";
import NmsMessage from "@/utils/messaging/NmsMessage";

export function buildNotificationTitle(
	conversation: Conversation,
	inAppNotification: boolean
  ): string {
	// Conversation always has a name, only case it would not is for group chat with no subject
	return `${inAppNotification ? "" : "New message from "}${conversation.getName(true)}`;
  }
  
  export function buildNotificationBody(
	message: NmsMessage | undefined,
	fromRemote: string | undefined
  ): string | undefined {
	if (!message) {
	  return "";
	}
  
	const messageContentType = message["Content-Type"];
  
	let contentType =
	  typeof messageContentType === "string"
		? messageContentType
		: (messageContentType?.[0] ?? "");
  
	contentType = contentType.includes("application/vnd.gsma.rcs-ft-http+xml")
	  ? message.payloadParts[message.payloadParts.length - 1]?.contentType
	  : contentType;
  
	const resolveNonTextMessage = () => {
	  switch (true) {
		case contentType.includes("audio"):
		  return "You received an audio file";
		case contentType.includes("image"):
		  return "You received an image";
		case contentType.includes("location"):
		  return "You received a location";
		case contentType.includes("video"):
		  return "You received a video";
		case contentType.includes("vcf") || contentType.includes("vcard"):
		  return "You received a contact";
		default:
		  return "You received a file";
	  }
	};
  
	if (contentType.includes("text/plain")) {
	  return `${fromRemote ? `${fromRemote}: ` : ``}${message.payloadParts[0]?.textContent}`;
	} else {
	  return `${resolveNonTextMessage()}${fromRemote ? ` from ${fromRemote}` : ``}`;
	}
  }