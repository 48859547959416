import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";

function icon(searchQuery) {
  return css({
    color: colors.secondaryTextColor,
    width: "2em",
    height: "auto",
    visibility: searchQuery ? "visible" : "hidden",
    display: searchQuery ? "flex" : "none",
  });
}

const noSearchResults = css({
  width: "100%",
  height: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const primarySearchText = css({
  color: colors.primaryTextColor,
  textAlign: "center",
  fontSize: "1.125rem",
  margin: "0.5em 2em",
  wordBreak: "break-word",
});

function secondarySearchText(searchQuery) {
  return css({
    color: colors.secondaryTextColor,
    textAlign: "center",
    fontSize: "0.875rem",
    margin: "0.25em 1em",
    visibility: searchQuery ? "visible" : "hidden",
    display: searchQuery ? "flex" : "none",
  });
}

export default function NoResultFound({
  placeHolder,
  searchQuery,
  secondaryPlaceHolder,
}: {
  placeHolder: string;
  searchQuery: string;
  secondaryPlaceHolder?: string;
}) {
  return (
    <div css={noSearchResults}>
      <SearchIcon css={icon(searchQuery)} />
      <div css={primarySearchText}>
        {!searchQuery
          ? placeHolder /* "Enter a name or number to start a new conversation" */
          : `No Results found for "${searchQuery}"`}
      </div>
      <div css={primarySearchText}>
        {secondaryPlaceHolder
          ? secondaryPlaceHolder /* "Enter a name or number to start a new conversation" */
          : ""}
      </div>
      <div css={secondarySearchText(searchQuery)}>
        Check spelling or try another search
      </div>
    </div>
  );
}
