import { colors } from "@/styles/global.styles";
import { isMobile } from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const EventDetailsRoot = styled.div({
  backgroundAttachment: "fixed",
  position: "absolute",
  overflow: "auto",
});

export const EventDetailsBackButton = styled.button({
  display: "flex",
  alignItems: "center",
  position: "fixed",
  gap: "0.5em",
  backgroundColor: colors.primaryBackground,
  color: colors.primaryTextColor,
  padding: "1em",
  borderRadius: "50%",
  border: "none",
  zIndex: 999,
});

export const GetTheAppButton = styled.button({
  display: "flex",
  flexDirection: "row",
  borderRadius: "50px",
  padding: "1.5em 2.4em",
  columnGap: "0.8em",
  width: "fit-content",
  margin: "5vh auto 1vh",
  backgroundColor: colors.primaryBackground,
  color: colors.primaryTextColor,
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "1.1em",
  cursor: "pointer",
  alignItems: "center",
  border: "0px",
});

export const SuperAdminAccessButton = styled.button({
  display: "flex",
  flexDirection: "row",
  borderRadius: "50px",
  padding: "1em 2em",
  columnGap: "0.8em",
  width: "fit-content",
  margin: "auto auto 1vh",
  backgroundColor: colors.primaryBackground,
  color: "#49d9f6",
  fontSize: "0.7em",
  fontWeight: "bold",
  lineHeight: "1.1em",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  border: "0px",
});

export const EventDetailsMainContainerPosition = styled.div({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "110vh",
  paddingTop: "12.5vh",
});

export const EventDetailsContainer = styled.div({
  width: isMobile() ? "90%" : "50%",
  borderRadius: isMobile() ? "40px" : "80px",
  height: "fit-content",
  backgroundColor: "rgba(0, 0, 0, 0.85)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.5em",
  color: colors.primaryTextColor,
  padding: "1em",
  paddingTop: "5vh",
  paddingBottom: "3vh",
});

export const EventDetailsTitle = css({
  fontSize: isMobile() ? "2em" : "3em",
  textWrap: "wrap",
  width: "75%",
  textAlign: "center",
  fontWeight: 700,
  marginBottom: "0.67em",
});

export const EventDetailsActionRow = css({
  display: "flex",
  flexDirection: "row",
  gap: "1em",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexWrap: isMobile() ? "wrap-reverse" : "wrap",
});

export const EventDetailsDescriptionContainer = styled.div({
  maxWidth: isMobile() ? undefined : "40vw",
  backgroundColor: "black",
  borderRadius: "30px",
  color: colors.primaryTextColor,
  display: "flex",
  flexDirection: "column",
  padding: "2em",
  gap: "2vh",
  width: isMobile() ? undefined : "40em",
});

export const JoinButtonContainer = styled.div({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "1vh",
  maxWidth: "20em",
});

export const JoinButtonStyled = styled.button({
  width: "100%",
  color: colors.primaryTextColor,
  fontSize: "2em",
  display: "flex",
  borderRadius: "80px",
  padding: "1em",
  textTransform: "uppercase",
  fontWeight: "bold",
  position: "relative",
});

export const StreamButtonStyle = css({
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "space-between",
  gap: isMobile() ? "3vw" : "1vw",
  fontSize: isMobile() ? "0.75em" : "1em",
});

export const PulseStyle = css({
  position: "absolute",
  display: "flex",
  width: "2em",
  height: "2em",
  borderRadius: "50%",
  background: "white",
});

export const UpcomingEventStyle = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
  fontWeight: "bold",
  height: "10em",
  width: "10em",
  minWidth: isMobile() ? "9em" : "10em",
  minHeight: isMobile() ? "9em" : "10em",
  lineHeight: 1,
});

export const UpcomingEventContainer = styled.div({
  display: "flex",
  gap: "1vh",
  width: "50%",
  justifyContent: "center",
  alignItems: "center",
});
