import {
  DeleteDropDownContainer,
  DropDownButton,
} from "@/components/keyPad.style";
import { IconButton } from "@/components/shared/Button";
import Checkbox from "@/components/shared/Checkbox";
import ToolTip from "@/components/shared/Tooltip";
import Call from "@/utils/calls/Call";
import { atoms } from "@/utils/helpers/atoms";
import {
  convertDateToHumanReadable,
  convertDateToHumanReadableShort,
} from "@/utils/helpers/time";
import { useBlock } from "@/utils/hooks/useBlock";
import { useCall } from "@/utils/hooks/useCall";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useSetAtom } from "jotai";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import TranscriptIcon from "../../assets/transcript.svg?react";
import {
  callInfo,
  callTypeIconCss,
  iconFillCss,
  transcriptButtonCss,
} from "../../components/recentCallScreen/RecentCallCard.style";
import {
  deleteDropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "../../components/shared/Dropdown.style";
import {
  editListItemStyle,
  ListItem,
} from "../../components/shared/ListItem.style";
import { colors } from "../../styles/global.styles";

export default function RecentCallItem({
  call,
  isEditing,
  showCallTranscript,
  hasCallTranscript,
  callTranscript,
  onDeleteCall,
  setSelectedCall,
  contactBlocked,
  onContactBlocked,
  selectedCallLogsCheckboxes,
  onSelectedCallLog,
}: {
  call: Call;
  isEditing: boolean;
  showCallTranscript: () => void;
  hasCallTranscript: boolean;
  callTranscript?: string;
  onDeleteCall: (callId: string) => void;
  setSelectedCall: (call: Call) => void;
  contactBlocked: boolean;
  onContactBlocked: (blocked: boolean) => void;
  selectedCallLogsCheckboxes: string[];
  onSelectedCallLog: (callId: string, selected: boolean) => void;
} & {
  ref?: React.RefObject<HTMLLIElement>;
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { callWithAudio, callWithVideo, canAcceptOrMakeCall } = useCall();
  const block = useBlock(call.peer, contactBlocked, onContactBlocked);
  const setCallTranscriptText = useSetAtom(atoms.messaging.callTranscriptText);
  const setCallTranscriptRemoteSender = useSetAtom(
    atoms.messaging.callTranscriptRemoteSender
  );

  const closeDropdown = (e?: React.MouseEvent) => {
    setDropdownOpen(false);
  };
  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(dropdownRef, () => closeDropdown(), "mousedown");

  let callIcon: React.ReactNode;

  const handleMakeCall = async () => {
    if (!isEditing) {
      if (!canAcceptOrMakeCall) {
        return;
      }

      if (block.value === "Unblock") {
        block.show("calling");
        return;
      }

      if (call.callLogPayload.media! === "video") {
        callWithVideo(call.remote);
      } else {
        callWithAudio(call.remote);
      }
    }
  };

  if (call.callLogPayload.media! === "video") {
    callIcon = (
      <div
        css={callTypeIconCss(canAcceptOrMakeCall)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleMakeCall}
      >
        {isHovered && canAcceptOrMakeCall ? (
          <VideocamIcon css={iconFillCss} />
        ) : (
          <VideocamOutlinedIcon css={iconFillCss} />
        )}
      </div>
    );
  } else if (call.callLogPayload.media! === "audio") {
    callIcon = (
      <div
        css={callTypeIconCss(canAcceptOrMakeCall)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleMakeCall}
      >
        {isHovered && canAcceptOrMakeCall ? (
          <PhoneIcon css={iconFillCss} />
        ) : (
          <PhoneOutlinedIcon css={iconFillCss} />
        )}
      </div>
    );
  }

  const callDirection =
    call.callLogPayload.type! === "missed" ? (
      <CallMissedIcon css={{ color: colors.primaryAccentColor }} />
    ) : call.message.Direction! === "In" ? (
      <CallReceivedIcon css={{ color: colors.secondaryTextColor }} />
    ) : (
      <CallMadeIcon css={{ color: colors.secondaryTextColor }} />
    );

  const handleOpenDetails = (e: React.MouseEvent) => {
    if (e.ctrlKey) {
      console.log(call);
    }
    setSelectedCall(call);
  };

  const handleRightClick = (event: any) => {
    event.preventDefault();
    toggleDropdown();
    event.stopPropagation();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDeleteCall = (
    event: React.MouseEvent<HTMLButtonElement>,
    key: string
  ) => {
    event.stopPropagation(); // Stop event propagation to prevent it from bubbling up
    onDeleteCall(key);
    toggleDropdown();
  };

  const handleCheckboxChange = (callId: string, value: boolean) => {
    onSelectedCallLog(callId, !value);
  };

  return (
    <ListItem
      key={call.callId}
      onClick={(e) => {
        if (isEditing) {
          handleCheckboxChange(
            call.callId,
            selectedCallLogsCheckboxes.includes(call.callId)
          );
        }
      }}
      onClickCapture={
        import.meta.env.DEV
          ? (e) => {
              if (e.ctrlKey) {
                e.stopPropagation();
                e.preventDefault();
                console.log(call);
              }
            }
          : undefined
      }
      onContextMenu={handleRightClick}
      css={{ position: "relative" }}
    >
      {isEditing && (
        <div css={{ width: "10%" }}>
          <Checkbox
            checkboxId={call.callId}
            checked={selectedCallLogsCheckboxes.includes(call.callId)}
          />
        </div>
      )}
      <div
        css={[editListItemStyle, { width: "100%", gap: "2vw" }]}
        onClick={handleOpenDetails}
      >
        <div css={callTypeIconCss()}>
          {callIcon}

          <div css={{ width: "26px" }}>{callDirection}</div>
        </div>

        <div
          css={{
            flex: "1",
          }}
        >
          <div
            style={
              call.callLogPayload.type! === "missed"
                ? { color: colors.primaryAccentColor }
                : { color: colors.primaryTextColor }
            }
          >
            {call.peer.noNameReturnPhoneNumber()}
          </div>
          <div css={callInfo}>
            {
              call.peer
                .getAllPhoneNumbersWithTypes()
                .find(([phonenumber, _type]) => {
                  return isSamePhoneNumber(phonenumber, call.remote);
                })?.[1]
            }
          </div>
        </div>

        {hasCallTranscript && (
          <IconButton
            css={[
              transcriptButtonCss,
              {
                position: "absolute",
                right: "7vw",
                zIndex: 10,
              },
            ]}
            onClick={(event) => {
              event.stopPropagation();
              setCallTranscriptText(callTranscript!);
              setCallTranscriptRemoteSender(
                call.peer.noNameReturnPhoneNumber()!
              );
              showCallTranscript();
            }}
          >
            <TranscriptIcon />
          </IconButton>
        )}

        <MessageTime
          lastMessageTime={
            call.callLogPayload.start_call_timestamp
              ? new Date(call.callLogPayload.start_call_timestamp * 1000)
              : call.message.Date
          }
        />
      </div>
      {isDropdownOpen && (
        <DeleteDropDownContainer
          ref={dropdownRef}
          style={{
            margin: "0.5em 0",
            position: "absolute",
            top: "10%",
            right: "4%",
          }}
          css={[deleteDropdownContainer, headerDropdown]}
        >
          <DropDownButton
            css={[
              footerDropdownItem,
              {
                fontSize: "0.8em",
              },
            ]}
            onClick={(event) => handleDeleteCall(event, call.callId)}
          >
            <DeleteIcon css={{ fontSize: "1em" }} /> Delete
          </DropDownButton>
        </DeleteDropDownContainer>
      )}
      {block.modal}
    </ListItem>
  );
}

function MessageTime({ lastMessageTime }: { lastMessageTime: Date }) {
  return (
    <ToolTip
      tooltipContent={<>{convertDateToHumanReadable(lastMessageTime)}</>}
      color={colors.primaryTextColor}
      backgroundColor={colors.tertiaryTextColor}
      css={{
        color: colors.secondaryTextColor,
        fontSize: "0.75em",
        whiteSpace: "nowrap",
        zIndex: "initial",
      }}
    >
      {convertDateToHumanReadableShort(lastMessageTime)}
    </ToolTip>
  );
}
