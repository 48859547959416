import WeakConnectionLottie from "@/assets/lottie/WeakConnection.json";
import Lottie from "lottie-react";

const WeakConnectionAnimationLottie = () => {
  return (
    <Lottie
      css={{ height: "5vh" }}
      animationData={WeakConnectionLottie}
      loop={true}
    />
  );
};

export default WeakConnectionAnimationLottie;
