import signingOutAnimation from "@/assets/lottie/DotsLoading.json";
import { colors } from "@/styles/global.styles";
import { isEmbeddedMode } from "@/utils/hooks/useOdienceOrganization";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "lottie-react";
import { AnimatePresence } from "motion/react";
import { useState } from "react";
import PopupOverlay from "./PopupOverlay";

const SignOutPopup = ({
  toggleOverlay,
  logoutEvent,
}: {
  toggleOverlay: () => void;
  logoutEvent: () => Promise<void>;
}) => {
  const [isSigningOut, setIsSigningOut] = useState(false);
  const isOdience =
    isEmbeddedMode() || window.location.href.includes("/odience");

  const handleLogout = async () => {
    setIsSigningOut(true);
    try {
      await logoutEvent();
    } finally {
      setIsSigningOut(false);
      toggleOverlay();
    }
  };

  return (
    <AnimatePresence>
      <PopupOverlay>
        <div css={OverlayContainer}>
          {isSigningOut ? (
            <div css={SigningOutMessage}>
              <span>Signing Out</span>
              <Lottie
                id="loadingAnimation"
                css={{ width: "10em" }}
                animationData={signingOutAnimation}
                loop
              />
            </div>
          ) : (
            <>
              <div css={Header}>
                <h3>Sign Out</h3>
                <button css={CloseButton} onClick={toggleOverlay}>
                  <CloseIcon />
                </button>
              </div>
              <div css={Message}>
                You are about to sign out from this device. Once signed out, you
                will need to reactivate {isOdience ? "ODIENCE" : "VERSE"} on
                this device again for future use.
              </div>
              <div css={ButtonGroup}>
                <button css={Button("secondary")} onClick={toggleOverlay}>
                  Cancel
                </button>
                <button css={Button("primary")} onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
            </>
          )}
        </div>
      </PopupOverlay>
    </AnimatePresence>
  );
};

export default SignOutPopup;

const OverlayContainer = css({
  display: "flex",
  flexDirection: "column",
  background: colors.primaryBackground,
  borderRadius: "8px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  padding: "2rem",
  width: "90%",
  maxWidth: "500px",
});

const Header = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: "1.25rem",
  color: colors.primaryTextColor,
});

const Message = css({
  margin: "2vh 0",
  color: colors.primaryTextColor,
});

const ButtonGroup = css({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1vw",
});

const Button = (variant: "primary" | "secondary") =>
  css({
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    backgroundColor:
      variant === "primary"
        ? colors.primaryAccentColor
        : colors.secondaryBackground,
    color: colors.primaryTextColor,
    fontWeight: "bold",
    "&:hover": {
      opacity: 0.9,
    },
  });

const SigningOutMessage = css({
  fontSize: "1.25rem",
  color: colors.primaryTextColor,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "1em",
});

const CloseButton = css({
  border: "none",
  background: "transparent",
  cursor: "pointer",
});
