import { useEffect } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("config", "G-1X3PH92VG2", {
      page_path: location.pathname,
    });
  }, [location]);
};
