// import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const Letter = styled.button({
  cursor: "pointer",
  background: "none",
  color: colors.secondaryTextColor,
  transition: "color 0.25s ease",
  border: "none",
  "&:hover": {
    color: colors.primaryAccentColor,
  },
  "&:active": {
    color: `${colors.primaryAccentColor} !important`,
  },
});
