import { AnimateChangeInHeight } from "@/components/shared/AnimateChangeInHeight";
import { useSnapshotAcceptUndefined } from "@/utils";
import { getSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import { sanitizeMessage } from "@/utils/messaging/sanitize";
import linkifyHtml from "linkify-html";
import { motion } from "motion/react";
import { useMemo, useRef } from "react";
import Attachment from "../components/Attachment";
import Suggestions from "../components/Suggestions";
import { RepliedMessage } from "../messages/RepliedMessage";
import type { ChatMessageStatus, GeneralPurposeCardMessage } from "../typings";
import { MediaExtraInfo } from "../typings/moderatorChatbotInfo";
import {
  hasTextSelected,
  useCalculateAllowShrink,
  useCardState,
  useRoundBordersCss,
} from "../util/cardUtils";
import { RichCardStyleDeclarations } from "../util/cssStyleUtils";
import {
  cardContentCss,
  descriptionCss,
  mainContentCss,
  msgContentCss,
  titleCss,
} from "./style";

type GeneralPurposeCardProps = {
  message: GeneralPurposeCardMessage;
  stylesLoaded?: RichCardStyleDeclarations | null;
  uploadProgress?: number;
  messageStatus?: ChatMessageStatus;
  removeTopMargin: boolean;
  isLastMessage: boolean;
  roundBorderTop: boolean;
  roundBorderBottom: boolean;
  direction: "In" | "Out";
  mediaExtraInfo?: MediaExtraInfo;
  refId?: string;
  isReply?: boolean;
  showReplyMessage: boolean;
  messageId: string;
};

export default function GeneralPurposeCard({
  message,
  stylesLoaded,
  uploadProgress,
  messageStatus,
  removeTopMargin,
  isLastMessage,
  roundBorderTop,
  roundBorderBottom,
  direction,
  mediaExtraInfo,
  refId,
  isReply,
  showReplyMessage,
  messageId,
}: GeneralPurposeCardProps) {
  const {
    cardOrientation,
    imageAlignment,
    descriptionFontStyle = [],
    titleFontStyle = [],
  } = message.layout;

  const titleFontClasses = useMemo(() => titleFontStyle.join(" "), []);
  const descriptionFontClasses = useMemo(
    () => descriptionFontStyle.join(" "),
    []
  );

  const { media, title, description, suggestions } = message.content;

  const titleHTML = useMemo(
    () => title && linkifyHtml(sanitizeMessage(title), { target: "_blank" }),
    [title]
  );
  const descriptionHTML = useMemo(
    () =>
      description &&
      linkifyHtml(sanitizeMessage(description), { target: "_blank" }),
    [description]
  );

  const backgroundHideDelayRef = useRef(0.35);

  const { cardState, allowShrink, toggleShrink, shrink } = useCardState();

  const { attachmentRef, titleRef, descriptionRef, suggestionsRef } =
    useCalculateAllowShrink(allowShrink, shrink, false, cardOrientation);

  const cardContentRef = useRef<HTMLDivElement>(null!);

  const roundBorders = useRoundBordersCss(
    direction,
    roundBorderTop,
    roundBorderBottom
  );

  const renderMainContent = descriptionHTML || titleHTML || suggestions;

  const mediaOnlyMessage = !!media && !renderMainContent;

  const messageIsReply = !!refId;

  const repliedMessageProxy =
    showReplyMessage && !isReply && messageIsReply
      ? getSelectedConversation()?.getMessage(refId)
      : undefined;
  const repliedMessage = useSnapshotAcceptUndefined(repliedMessageProxy);

  return (
    <>
      {!isReply && repliedMessage && (
        <RepliedMessage
          repliedMessage={repliedMessage}
          incoming={direction === "In"}
        />
      )}
      <motion.div
        ref={cardContentRef}
        className={`rich-card-content ${cardOrientation} ${imageAlignment || ""}`}
        css={[cardContentCss, msgContentCss, roundBorders]}
        style={{
          ...stylesLoaded?.message,
          maxWidth: mediaOnlyMessage ? "15em" : undefined,
          marginTop: removeTopMargin ? "0" : undefined,
          // minHeight: cardConstants.shrinkMaxHeight,
          // maxHeight: cardConstants.shrinkMaxHeight,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (hasTextSelected()) return;
          toggleShrink();
        }}
      >
        <div>
          {media && (
            <Attachment
              attachment={media}
              ref={attachmentRef}
              container={cardContentRef}
              mediaOnlyMessage={mediaOnlyMessage}
              uploadProgress={uploadProgress}
              messageStatus={messageStatus}
              cardOrientation={cardOrientation}
              mediaExtraInfo={mediaExtraInfo}
              messageId={messageId}
            />
          )}
          {renderMainContent && (
            <section css={mainContentCss}>
              {titleHTML && (
                <AnimateChangeInHeight css={{ lineHeight: "0" }}>
                  <h3
                    ref={titleRef}
                    className={`title-odience-card ${titleFontClasses}`}
                    css={[titleCss.main, cardState.isShrunk && titleCss.shrink]}
                    style={stylesLoaded?.title}
                    dangerouslySetInnerHTML={{ __html: titleHTML }}
                  ></h3>
                </AnimateChangeInHeight>
              )}
              {descriptionHTML && (
                <AnimateChangeInHeight css={{ lineHeight: "0" }}>
                  <p
                    ref={descriptionRef}
                    className={descriptionFontClasses}
                    css={[
                      descriptionCss.main,
                      !titleHTML && descriptionCss.noTitle,
                      !suggestions && descriptionCss.noSuggestions,
                      cardState.isShrunk && descriptionCss.shrink,
                    ]}
                    style={stylesLoaded?.description}
                    dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                  ></p>
                </AnimateChangeInHeight>
              )}
              <Suggestions
                ref={suggestionsRef}
                suggestions={suggestions}
                style={stylesLoaded?.suggestions}
                canShrink={!!description && cardState.allowShrink}
                isShrunk={cardState.isShrunk}
                backgroundHideDelayRef={backgroundHideDelayRef}
              />
            </section>
          )}
        </div>
      </motion.div>
    </>
  );
}
