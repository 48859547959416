import UserContactInfoScreen from "@/pages/contactInfoScreen/UserContactInfoScreen";
import { createContact } from "@/utils/contacts";
import { contactState, resetContactState } from "@/utils/contacts/contactState";
import { useBlock } from "@/utils/hooks/useBlock";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import { cleanPhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import BlockIcon from "@mui/icons-material/Block";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { Box } from "../../pages/layout/Layout.style";
import { paths } from "../../routerPaths";
import { colors } from "../../styles/global.styles";
import WebGwContact, { NumberWithType } from "../../utils/helpers/WebGwContact";
import ContactActionsHeader from "../contacts/ContactActionsHeader";
import { avatar } from "../contacts/ContactInfo.style";
import { DropDownButton, DropDownContainer } from "../keyPad.style";
import { Avatar, AvatarBackground } from "../shared/Avatar";
import { IconButton, IconButtonBox } from "../shared/Button";
import ContactModal from "../shared/ContactModal";
import {
  dropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "../shared/Dropdown.style";
import SectionHeader from "../shared/SectionHeader";
import SlideOnTop from "../shared/SlideOnTop";
import CallLogItems from "./CallLogItems";

type CallLogDetailsProps = {
  closeCallLogDetails?: (e?: React.MouseEvent) => void;
  contact: WebGwContact;
  showCallTranscript: () => void;
  onDeleteCall: (callId: string) => void;
  contactBlocked: boolean;
  onContactBlocked: (blocked: boolean) => void;
};

export default function CallLogDetails({
  ref,
  closeCallLogDetails,
  contact,
  showCallTranscript,
  onDeleteCall,
  contactBlocked,
  onContactBlocked,
}: CallLogDetailsProps & {
  ref?: React.RefObject<HTMLDivElement | null>;
}) {
  const contacts = useContacts();
  contact = (contact && contacts?.find((c) => c.id === contact.id)) ?? contact;

  const navigate = useNavigate();
  const [rcsPhoneNumbers] = useState<undefined | NumberWithType[]>(undefined);
  const [contactInfoOpen, setContactInfoOpen] = useState(false);
  const [resetCounter, setResetCounter] = useState(0);

  const [dropdownOpened, setOpenDropdown] = useState(false);

  const closeDropdown = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setOpenDropdown(false);
  };

  const toggleDropdown = () => {
    setOpenDropdown(!dropdownOpened);
  };

  const openContactInfo = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setContactInfoOpen(true);
    setOpenDropdown(false);
  };

  const closeContactInfo = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setContactInfoOpen(false);
  };
  const handleSelectConversation = (phoneNumber: string | null) => {
    if (!phoneNumber) {
      return;
    }

    setSelectedConversationId(
      Conversation.getOrCreate({ phoneNumber, contactToLinkIfCreate: contact })
        .conversation.id
    );
    navigate(paths.messages);
  };

  const s1 = useRef<HTMLDivElement>(null!);
  const dropdownRef = useRef<HTMLDivElement>(null!);
  useOnClickOutside(dropdownRef, () => closeDropdown());

  const block = useBlock(contact, contactBlocked, onContactBlocked);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    resetContactState();
    setResetCounter((prev) => prev + 1);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await createContact(contactState);
    resetContactState();
  };

  const handleCancel = () => {
    resetContactState();
    setIsModalOpen(false);
  };

  if (!contact) return null;

  return (
    <Box
      ref={ref}
      css={{
        position: "relative",
        zIndex: "2",
        color: colors.primaryTextColor,
        padding: "3% 0",
      }}
    >
      <SectionHeader
        pageName={"Call History"}
        iconButton={closeCallLogDetails && <CloseRoundedIcon />}
        onClick={closeCallLogDetails}
        css={{
          color: colors.primaryTextColor,
        }}
      >
        <div
          css={{
            position: "relative",
            display: "flex",
            flexGrow: "1",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <IconButtonBox
            css={{
              backgroundColor: dropdownOpened
                ? colors.secondaryBackground
                : "transparent",
            }}
            ref={dropdownRef}
            onClick={toggleDropdown}
          >
            <MoreVertIcon />
            {dropdownOpened && (
              <DropDownContainer css={[dropdownContainer, headerDropdown]}>
                {contact.name ? (
                  <DropDownButton
                    css={footerDropdownItem}
                    onClick={openContactInfo}
                  >
                    <PersonIcon />
                    View Contact
                  </DropDownButton>
                ) : (
                  <DropDownButton css={footerDropdownItem} onClick={showModal}>
                    <PersonAddOutlinedIcon />
                    Add Contact
                  </DropDownButton>
                )}
                <DropDownButton
                  css={footerDropdownItem}
                  onClick={() => block.show()}
                >
                  <BlockIcon /> {block.value}
                </DropDownButton>
              </DropDownContainer>
            )}
          </IconButtonBox>
        </div>
      </SectionHeader>

      <ContactModal
        open={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        numberToCall={cleanPhoneNumber(contact.getMainPhoneNumber())}
        key={resetCounter}
      />

      <div css={{ padding: "2em 0 1em" }}>
        <AvatarBackground css={avatar}>
          <Avatar contact={contact} isGroupChat={false} photo={contact.photo} />
        </AvatarBackground>
      </div>

      <span css={{ fontSize: "1.5em", textAlign: "center" }}>
        {contact.noNameReturnPhoneNumber(undefined, true)}
      </span>
      <div css={{ paddingTop: "1.5em", width: "100%" }}>
        <section
          css={{
            color: colors.primaryAccentColor,
            background: colors.secondaryBackground,
            width: "100%",
            height: contactBlocked ? "6em" : "4em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5em",
            lineHeight: "1",
          }}
        >
          <ContactActionsHeader
            mainPhoneNumber={contact.getMainPhoneNumber()}
            rcsPhoneNumbers={rcsPhoneNumbers}
            onSelectConversation={handleSelectConversation}
            contactBlocked={contactBlocked}
            onContactBlocked={onContactBlocked}
          />
        </section>
      </div>

      <CallLogItems
        contact={contact}
        showCallTranscript={showCallTranscript}
        onDeleteCall={onDeleteCall}
      />

      <SlideOnTop ref={s1} open={contactInfoOpen} layer={1}>
        <UserContactInfoScreen
          webgwContact={contact}
          style={{ width: "100%", height: "100%" }}
          customHeaderChildren={
            <IconButton
              onClick={closeContactInfo}
              css={{ color: colors.primaryTextColor }}
            >
              <CloseRoundedIcon />
            </IconButton>
          }
        />
      </SlideOnTop>
      {block.modal}
    </Box>
  );
}
