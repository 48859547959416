import CopyIcon from "@/assets/CopyIcon";
import { colors } from "@/styles/global.styles";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { getLocalUser } from "@/utils/helpers/localstorage";
import { useToast } from "@/utils/helpers/toastManager";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "lottie-react";
import { motion } from "motion/react";
import { useRef, useState } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useOnClickOutside } from "usehooks-ts";
import inviteAnimation from "../../assets/lottie/invite.json";

interface InvitePopupProps {
  togglePopup: () => void;
  number?: string;
}

const InvitePopup = ({ togglePopup, number }: InvitePopupProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const invitePopupRef = useRef<HTMLDivElement>(null);
  const [showShare, setShowShare] = useState(false);
  const { showToast } = useToast();

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(invitePopupRef, () => {
    togglePopup();
    toggleShareOptions();
  });

  const toggleShareOptions = () => {
    setShowShare(!showShare);
  };

  const copyToClipboard = async () => {
    if (inputRef.current) {
      try {
        await navigator.clipboard.writeText(inputRef.current.value);
        showToast("Copied to clipboard");
      } catch (error) {
        console.error("Failed to copy text to clipboard: ", error);
      }
    }
  };

  const shareURL = window.location.origin;

  return (
    <motion.div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: "25vw",
        width: "100%",
        height: "100%",
        zIndex: 10,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        ref={invitePopupRef}
        css={{
          width: "25vw",
          height: "fit-content",
          backgroundColor: colors.secondaryBackground,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          padding: "2.5vw",
          minWidth: "300px",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <button
            css={{ background: "none", border: "none", color: "white" }}
            onClick={togglePopup}
          >
            <CloseIcon />
          </button>
        </div>
        <Lottie
          css={{ height: "10vh", minHeight: "150px", maxHeight: "225px" }}
          animationData={inviteAnimation}
          loop={true}
        />
        <div
          css={{ textAlign: "center", fontSize: "1rem", paddingBottom: "3vh" }}
        >
          The contact you're trying to reach is not on Verse.
        </div>

        <div
          css={{
            color: colors.secondaryAccentColor,
            textAlign: "center",
            fontSize: "1rem",
          }}
          onClick={() => copyToClipboard()}
        >
          Share The Link
        </div>

        <div
          css={{
            marginTop: "1.5vh",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "1vw",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "2vh",
          }}
        >
          <div
            style={{
              border: "2px solid #ccc",
              borderRadius: "8px",
              padding: "1vh",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                readOnly
                type="text"
                ref={inputRef}
                value="versemessages.com"
                style={{ marginRight: "0.25vw", border: "none" }}
              />
              <button
                onClick={copyToClipboard}
                style={{
                  borderLeft: "2px solid #ccc",
                  background: "none",
                  paddingLeft: "1rem",
                }}
              >
                <CopyIcon />
              </button>
            </div>
          </div>
        </div>

        <div css={{ paddingBottom: "2vh" }}>OR</div>

        <div
          css={{
            paddingBottom: "2vh",
            fontSize: "0.9rem",
            textAlign: "center",
          }}
        >
          <span css={{ color: "#12B891" }}>Send An Invite</span>
        </div>
        <button
          onClick={toggleShareOptions}
          css={{
            display: "flex",
            flexDirection: "row",
            height: "3em",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#12B891",
            width: "7em",
            borderRadius: "12px",
            gap: "1vh",
            flexWrap: "wrap",
          }}
        >
          Invite{" "}
          <img
            css={{ height: "1em" }}
            src="/odience/sharing/Share.svg"
            alt="Share"
          />
        </button>
        {showShare && (
          <div css={{ display: "flex", gap: "0.5vw", marginTop: "2vh" }}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={50} round={true} />
            </FacebookShareButton>
            <FacebookMessengerShareButton
              appId="426535209731814"
              url={shareURL}
            >
              <FacebookMessengerIcon size={50} round={true} />
            </FacebookMessengerShareButton>
            <TwitterShareButton url={shareURL}>
              <XIcon size={50} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton url={shareURL}>
              <WhatsappIcon size={50} round={true} />
            </WhatsappShareButton>
            <EmailShareButton
              subject={`You have been invited to join Verse Messages!`}
              url={`Hi, it's me (${formatPhoneNumber(getLocalUser()!, "E123")}), please log in to Verse Messages so we can call and chat. Thanks! ${shareURL}`}
            >
              <EmailIcon size={50} round={true} />
            </EmailShareButton>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default InvitePopup;
