import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const NameInput = styled.input({
  backgroundColor: colors.tertiaryBackground,
  borderRadius: "10px",
  height: "5.67vh",
  border: "none",
  width: "100%",
  color: colors.primaryTextColor,
  paddingLeft: "1rem",
});

export const SelectOptions = styled.select({
  backgroundColor: colors.tertiaryBackground,
  borderRadius: "10px",
  height: "5.67vh",
  width: "8.4375vw",
  border: "none",
  color: colors.primaryTextColor,
  paddingLeft: "1rem",
});

export const Root = styled.div({
  height: "75vh",
  overflowY: "scroll",
  padding: " 0 1em",
});

export const Space = styled.div({
  width: " 0.5208333333333334vw",
});

export const DividerLine = styled.div({
  width: "95%",
  borderBottom: "solid 1px",
  borderColor: colors.secondaryTextColor,
});

export const DividerSpace = styled.div({
  height: "1em",
  width: "100%",
});

export const SectionTitle = styled.div({
  color: colors.secondaryTextColor,
  textTransform: "uppercase",
  paddingTop: "1em",
});

export const AddButton = styled.button({
  background: "none",
  border: "none",
  color: colors.primaryTextColor,
});

export const DeleteButton = styled.button({
  background: "none",
  border: "none",
  color: colors.primaryTextColor,
});

export const InputField = styled.input({
  backgroundColor: colors.tertiaryBackground,
  borderRadius: "10px",
  height: "5.67vh",
  border: "none",
  width: "100%",
  color: colors.primaryTextColor,
  paddingLeft: "1rem",
});

export const InputRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "1rem",
  width: "95%",
  paddingBottom: "1em",
});

export const SectionHeader = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "95%",
  fontSize: "1rem",
  paddingTop: "1em",
});
