import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContacts } from "@/utils/hooks/useContacts";
import { useEffect, useState } from "react";
import { colors } from "../../styles/global.styles";
import WebGwContact, { NumberWithType } from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import HighlightedText from "../shared/HighlightedText";
import { ListItem } from "../shared/ListItem.style";
import { TextButton } from "../shared/TextButton";
import { logo, name } from "./ContactCard.style";

type BlockedContactCardProps = React.ComponentProps<"li"> & {
  contact: WebGwContact;
  toggleBlockModal: (contact: WebGwContact) => void;
};

const BlockedContactCard = ({
  contact: receivedContact,
  toggleBlockModal,
  ...liProps
}: BlockedContactCardProps) => {
  const contacts = useContacts();
  const contact =
    contacts?.find((pContact) => pContact.id === receivedContact?.id) ??
    receivedContact;
  const [phoneNumbers, setPhoneNumbers] = useState<
    undefined | NumberWithType[]
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [mainPhoneNumber, setMainPhoneNumber] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resolvedPhoneNumbers =
          await contact.getAllPhoneNumbersWithTypesAndCaps();
        setPhoneNumbers(resolvedPhoneNumbers);
        if (resolvedPhoneNumbers && resolvedPhoneNumbers.length > 0) {
          setMainPhoneNumber(resolvedPhoneNumbers[0][0]);
        } else {
          const mainNumber = contact.getMainPhoneNumber();
          if (mainNumber) {
            setMainPhoneNumber(mainNumber);
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching phone numbers:", error);
      }
    };
    setLoading(true);
    const fetchPromise = fetchData();
    fetchPromise.finally(() => setLoading(false));
  }, []);

  const contactName = contact.noNameReturnPhoneNumber(
    receivedContact.userInputNumber
  );
  const mainPhoneNumberFormatted = formatPhoneNumber(mainPhoneNumber, "E123");

  return (
    <>
      {loading ? (
        <ListItem {...liProps}>{/* Skeleton code */}</ListItem>
      ) : (
        phoneNumbers && (
          <ListItem {...liProps}>
            <Avatar contact={contact} />
            <div>
              <HighlightedText
                css={name}
                text={contactName}
                highlightIndex={undefined}
                searchQueryLength={0}
              />
              <HighlightedText
                css={[
                  name,
                  {
                    color: colors.secondaryTextColor,
                    marginTop: "0.25em",
                    fontSize: "0.8em",
                  },
                ]}
                text={mainPhoneNumberFormatted}
                highlightIndex={undefined}
                searchQueryLength={0}
              />
            </div>
            <div
              css={{
                width: "100%",
                height: "2em",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div css={logo}>
                <TextButton
                  active={true}
                  onClick={() => toggleBlockModal(contact)}
                >
                  Unblock
                </TextButton>
              </div>
            </div>
          </ListItem>
        )
      )}
    </>
  );
};

export default BlockedContactCard;
