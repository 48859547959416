import { odiencePathIndicatorKey } from "@/routerPaths";
import { useSearchParams } from "react-router-dom";
import expandmore from "../../../assets/provisioning/expand_more_black_24dp.svg";
import OptionalProvisionLogo from "../OptionalProvisionLogo";
import {
  Body,
  registerButton,
  step1Body,
  stepBody,
  stepHeader,
  text,
  textBody,
  textDark,
} from "../index.style";

type Props = {
  prev: (next: any) => void;
  next: (next: any) => void;
};

const StepTransferSession = ({ prev, next }: Props) => {
  const [params] = useSearchParams();
  const fromOdiencePreview = params.has(odiencePathIndicatorKey);

  return (
    <Body>
      <div
        id="step1Body"
        className="stepBody"
        css={[
          textBody,
          step1Body,
          stepBody,
          {
            maxWidth: "44em",
            padding: "3em 0",
          },
        ]}
      >
        <OptionalProvisionLogo />
        <h1 id="stepHeader" css={[text, stepHeader]}>
          {fromOdiencePreview ? "Odience" : "VERSE"} is currently being used in
          another browser
        </h1>

        <p css={[text, textDark]}>
          To use {fromOdiencePreview ? "Odience" : "Verse"} in this window,
          please press continue, this will log out the other active session.
        </p>

        <div
          className="actionContainer"
          css={{ display: "flex", flexDirection: "column" }}
        >
          <button
            type="submit"
            onClick={next}
            css={[
              registerButton,
              {
                fontSize: "1.2em",
                fontWeight: "bold",
                height: "55px",
                width: "100%",
              },
            ]}
          >
            Continue
          </button>
          <button
            type="submit"
            onClick={prev}
            css={{
              color: "#FFFFFF",
              marginTop: "1em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={expandmore}
              css={{
                filter:
                  "invert(100%) sepia(13%) saturate(359%) hue-rotate(300deg) brightness(115%) contrast(100%)",
                marginLeft: "-6px",
                position: "relative",
                transform: "rotate(90deg)",
              }}
            />
            Back to login
          </button>
        </div>
      </div>
    </Body>
  );
};

export default StepTransferSession;
