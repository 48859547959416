import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { saveUpdatedUserProfile } from "@/pages/profile/utils/ProfileScreenUtils";
import { paths } from "@/routerPaths";
import { routerUrl } from "@/utils";
import {
  getLocalDirectorToken,
  getLocalOdienceEvents,
  getLocalOdienceUser,
  getLocalUserFirstName,
  getLocalUserLastName,
  setLocalOdienceEvents,
} from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import {
  getEmail,
  getInvite,
  getInvitedEventId,
  getLastEventId,
  removeLastEventId,
  removeLastGroupId,
} from "@/utils/helpers/sessionStorage";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import io from "socket.io-client";
import { useUnmount } from "usehooks-ts";
import {
  OdienceEvent,
  OdienceUser,
  authenticateDirector,
  fetchEvents,
  fetchPublicEvents,
} from "./useDirectorAuthentication";
import { useOdienceOrganization } from "./useOdienceOrganization";

export function useOdienceEvents({
  odienceOnly,
  updateUserAvatar,
}: {
  odienceOnly: boolean;
  updateUserAvatar: (avatar: string) => void;
}) {
  const { organizationId, embeddedMode } = useOdienceOrganization();
  const { updateUserDisplayName, updateUserEmail } = useProfileModal();
  const odienceUser = getLocalOdienceUser();
  const authenticatedUser = odienceUser ? JSON.parse(odienceUser) : null;
  const displayName = authenticatedUser?.name;
  const firstName = getLocalUserFirstName();
  const lastName = getLocalUserLastName();
  const emailInvite = getEmail();
  const invitedId = getInvite();
  const invitedEventId = getInvitedEventId();

  const filterEvents = (events?: OdienceEvent[] | null) => {
    if (!events) {
      return [];
    }

    return events.filter(
      (event) =>
        event.label !== "ended" &&
        (!embeddedMode ||
          (embeddedMode &&
            (!organizationId || organizationId === event.organization_id)))
    );
  };

  const getAuthenticatedUser = async () => {
    return (await authenticateDirector()) as OdienceUser;
  };

  const {
    data: objEvents,
    isPending: eventsPending,
    refetch: refetchEvents,
    isFetching,
  } = useQuery({
    queryKey: ["odienceEvents"],
    queryFn: async () => {
      if (isProvisioned()) {
        const authenticatedUser = getAuthenticatedUser();
        if ((await authenticatedUser).avatar != null) {
          updateUserAvatar((await authenticatedUser).avatar!);
        }
      }

      const events = isProvisioned()
        ? await fetchEvents()
        : await fetchPublicEvents();

      setLocalOdienceEvents(events);
      return events;
    },
    initialData: () => getLocalOdienceEvents() || undefined,
    staleTime: 0,
    refetchOnMount: "always",
  });

  const handleUpdateUser = async (updatedEmail: string) => {
    await saveUpdatedUserProfile(
      authenticatedUser,
      firstName,
      lastName,
      displayName,
      updatedEmail,
      emailInvite,
      invitedId,
      invitedEventId || getLastEventId(),
      updateUserAvatar,
      updateUserDisplayName,
      updateUserEmail
    );
  };

  const socketRef = useRef<SocketIOClient.Socket | null>(null);

  const socketEvents = (socket) => {
    socket.current.on("EventsListUpdateAvailable", (updatedEvent) => {
      refetchEvents();
    });

    socket.current.on("EventUpdated", (updatedEvent) => {
      refetchEvents();
    });

    socket.current.on("EventRemoved", (removedEventId) => {
      refetchEvents();
    });

    socket.current.on("UserEmailVerified", async (data) => {
      const updatedEmail = data.email;
      await handleUpdateUser(updatedEmail);
    });
  };

  useEffect(() => {
    const setupSocket = async () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
        console.log("Existing socket disconnected");
      }

      if (isProvisioned()) {
        const url = routerUrl;
        const user = await getAuthenticatedUser();
        const userId = user.msisdn;

        socketRef.current = io(url, {
          transports: ["websocket"],
          query: { user_id: userId, token: getLocalDirectorToken() },
        });

        socketRef.current.on("Connected", () => {
          console.log("Connected to socket");
        });

        socketEvents(socketRef);

        socketRef.current.on("clientError", (error) => {
          console.error("Socket client error:", error);
        });
      }
    };

    setupSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log("Socket disconnected on cleanup");
        socketRef.current = null;
      }
    };
  }, [objEvents]);

  useUnmount(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      console.log("Socket disconnected on unmount");
      socketRef.current = null;
    }
  });

  return {
    objEvents: filterEvents(objEvents),
    eventsPending,
    embeddedMode,
    isFetching,
  };
}

export const generateEventListPath = (
  objEvent: OdienceEvent,
  embeddedMode: boolean
) => {
  removeLastGroupId();
  removeLastEventId();

  if (isProvisioned()) {
    // This logic is expected for now, hitting back in embedded mode from a private event should redirect to the list of events only from the organization event
    const organizationIdQuery =
      embeddedMode && objEvent && !objEvent.is_public
        ? `?organizationId=${objEvent.organization_id}`
        : "";

    return paths.odience + organizationIdQuery;
  } else {
    return paths.previewOdience;
  }
};
