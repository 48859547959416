import { colors, scrollbarWidth } from "@/styles/global.styles";
import {
  filterContacts,
  getLoadedContacts,
} from "@/utils/contacts/contactUtils";
import { atoms } from "@/utils/helpers/atoms";
import { contactNameRegexPattern } from "@/utils/helpers/Utils";
import { usePrevious } from "@/utils/hooks/usePrevious";
import { useAtom, useAtomValue } from "jotai";
import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { useBoolean } from "usehooks-ts";
import {
  DividerLetter,
  DividerLine,
} from "../../pages/contactListScreen/ContactListScreen.style";
import WebGwContact from "../../utils/helpers/WebGwContact";
import Checkbox from "../shared/Checkbox";
import {
  editListItemStyle,
  editListItemStyleDisabled,
  ListContainer,
} from "../shared/ListItem.style";
import NoResultFound from "../shared/NoResultFound";
import SvgGraphic from "../shared/SvgGraphic";
import AlphabeticalScrollBar, {
  DividerLetterRecord,
} from "./AlphabeticalScrollBar";
import ContactCard from "./ContactCard";

export type ContactSelectHandler = (searchQuery: string | WebGwContact) => void;

export default function ContactsList({
  searchQuery,
  contactSelectedHandler,
  ignoreContacts = [],
  isContactPicker,
  preSelectedContacts,
  onSelectContact,
  onUnSelectContact,
  fromConversationScreen = false,
  disableNoPhoneContacts = true,
}: {
  searchQuery: string;
  contactSelectedHandler?: ContactSelectHandler;
  ignoreContacts?: WebGwContact[];
  isContactPicker?: boolean;
  preSelectedContacts?: WebGwContact[];
  onSelectContact?: (contact: WebGwContact) => void;
  onUnSelectContact?: (contact: WebGwContact) => void;
  fromConversationScreen?: boolean;
  disableNoPhoneContacts?: boolean;
}) {
  const contacts = useAtomValue(atoms.contacts.filteredContacts);
  const [displayContact, setDisplayContact] = useAtom(
    atoms.contacts.displayContact
  );

  const contactsToDisplay = filterContacts(
    searchQuery,
    contacts,
    ignoreContacts
  );

  const onContactCardSelectHandler = (contact: WebGwContact | string) => {
    if (
      preSelectedContacts &&
      onUnSelectContact &&
      onSelectContact &&
      (contact as WebGwContact)
    ) {
      const contact2 = contact as WebGwContact;
      const wasSelected = preSelectedContacts.some(
        (current) => current.id === contact2.id
      );

      if (!wasSelected) {
        onSelectContact(contact2);
      } else {
        onUnSelectContact(contact2);
      }
    }

    contactSelectedHandler?.(contact);
  };

  const dividerLetterRefs = useRef<DividerLetterRecord>({});
  const [dividerLetterRefsState, setDividerLetterRefsState] =
    useState<DividerLetterRecord>({});
  useLayoutEffect(() => {
    if (Object.keys(dividerLetterRefs.current).length > 0) {
      setDividerLetterRefsState(dividerLetterRefs.current);
    }
  }, [contactsToDisplay]);

  return contactsToDisplay.length > 0 ? (
    <div
      css={{
        width: "100%",
        height: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <AlphabeticalScrollBarListContainer
        dividerLetterRefs={dividerLetterRefsState}
      >
        {contactsToDisplay.map(([contact, searchIndices], index) => {
          if (!contact.name) return null;

          const isHashSection =
            contactNameRegexPattern.test(contact.name[0]) ||
            contact.name === " ";

          const dividerLetter = isHashSection
            ? "#"
            : contact.name[0].toUpperCase();

          // if it is the first contact or the first letter of the previous contact is not the same as the current contact
          const lastContact = contactsToDisplay.at(index - 1)?.[0];
          const newDivision =
            index === 0 ||
            (!isHashSection &&
              lastContact?.name?.[0].toUpperCase() !== dividerLetter);

          const disabled =
            disableNoPhoneContacts && !contact.getMainPhoneNumber();

          return (
            <Fragment key={contact.id}>
              {newDivision && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "15px",
                  }}
                >
                  <DividerLetter
                    ref={(elem) => {
                      if (elem) dividerLetterRefs.current[dividerLetter] = elem;
                      else delete dividerLetterRefs.current[dividerLetter];
                    }}
                  >
                    {dividerLetter}
                  </DividerLetter>
                  <DividerLine />
                </div>
              )}
              <li
                css={[
                  editListItemStyle,
                  disabled && editListItemStyleDisabled,
                  {
                    backgroundColor:
                      displayContact === contact
                        ? colors.secondaryBackground
                        : "inherit",
                  },
                ]}
              >
                {isContactPicker && (
                  <Checkbox
                    disabled={disabled}
                    checked={
                      preSelectedContacts?.some(
                        (current) => current.id === contact.id
                      ) ?? false
                    }
                    onChange={() => {
                      if (!disabled) {
                        onContactCardSelectHandler(contact);
                      }
                    }}
                    css={{ marginRight: "1em" }}
                  />
                )}
                {contact.name && contact.id && (
                  <ContactCard
                    disabled={disabled}
                    contact={contact}
                    searchIndices={searchIndices}
                    searchQueryLength={searchQuery.length}
                    onClick={
                      !disabled
                        ? () => {
                            onContactCardSelectHandler(contact);
                            setDisplayContact(
                              getLoadedContacts()?.find(
                                (pContact) => pContact.id === contact?.id
                              ) ?? contact
                            );
                          }
                        : undefined
                    }
                  />
                )}
              </li>
            </Fragment>
          );
        })}
      </AlphabeticalScrollBarListContainer>
    </div>
  ) : !fromConversationScreen || contactsToDisplay.length < 1 ? (
    <NoResultFound
      placeHolder="Enter a contact name or number to start a new conversation"
      searchQuery={searchQuery}
    />
  ) : (
    <SvgGraphic src={"assets/Chat_Graphic.svg"} />
  );
}

function AlphabeticalScrollBarListContainer({
  children,
  dividerLetterRefs,
}: {
  children: React.ReactNode;
  dividerLetterRefs: DividerLetterRecord;
}) {
  const {
    value: showAlphabeticalScrollBar,
    setTrue: setShowAlphabeticalScrollBar,
    setFalse: setHideAlphabeticalScrollBar,
  } = useBoolean(false);
  const prevShowAlphabeticalScrollBar = usePrevious(showAlphabeticalScrollBar);

  return (
    <>
      <ListContainer
        style={{
          paddingRight: showAlphabeticalScrollBar ? "2.25em" : "0",
          transition: `padding-right ease ${prevShowAlphabeticalScrollBar ? "0.35s" : "0s"}`,
        }}
        onScrollBarHidden={setHideAlphabeticalScrollBar}
        onScrollBarShown={setShowAlphabeticalScrollBar}
      >
        {children}
      </ListContainer>
      <div
        css={{
          position: "absolute",
          right: `calc(${scrollbarWidth} + 1.25em)`,
          height: "110%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          transform: "translateY(0.75em)",
          transition: "opacity 0.35s ease",
        }}
        style={{
          opacity: showAlphabeticalScrollBar ? 1 : 0,
        }}
      >
        <AlphabeticalScrollBar dividerLetterRefs={dividerLetterRefs} />
      </div>
    </>
  );
}
