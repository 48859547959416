import { paths } from "@/routerPaths";
import { atoms } from "@/utils/helpers/atoms";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { ReactNode, Suspense, lazy, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "../../styles/global.styles";
import { ThreeLinesLoaderBox } from "../shared/Loaders/ThreeLines";
import TypingIndicator from "./chat/messages/TypingIndicator";
import ChatFooter, { ChatFooterRef } from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import TypeReplyOverlay from "./TypeReplyOverlay";

interface ChatOverlayProps {
  children: ReactNode;
  position: { x: number; y: number };
}

const ChatOverlay = ({ children, position }: ChatOverlayProps) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "30%",
        height: "50%",
        backgroundColor: "rgba(26, 32, 38, 0.85)",
        zIndex: "200",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        padding: "1em",
        color: colors.primaryTextColor,
        fontSize: "1rem",
        transform: `translate(-50%, -50%) translate(${position.x}px, ${position.y}px)`,
        display: "flex",
        flexDirection: "column",
        minWidth: "20em",
        minHeight: "20em",
      }}
    >
      {children}
    </div>
  );
};

const ChatBox = lazy(() => import("./ChatBox"));

const ChatScreenOverlay = () => {
  const [overlayRemote, setOverlayRemote] = useAtom(
    atoms.messaging.overlayRemote
  );
  const [show, setShow] = useState(false);
  const location = useLocation();

  const [readOnly, setReadOnly] = useState(false)
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const conversation = overlayRemote ? Conversation.getOrCreate({ phoneNumber: overlayRemote }).conversation : null;

    if (!conversation) {
      handleClose();
      return;
    }

    setReadOnly((conversation.getLastMessage()?.contact() ?? conversation.participants[0]).isOdienceFrontRow());
    setSelectedConversationId(conversation.id);
    setShow(true);
  }, [overlayRemote]);

  useEffect(() => {
    if (location.pathname.includes(paths.messages)) {
      handleClose();
    }
  }, [location]);

  const handleClose = () => {
    setShow(false);
    setOverlayRemote("");
  }

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isDragging) {
      setIsDragging(true);
      setStartPosition({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleOverlayClick = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const chatFooterRef = useRef<ChatFooterRef>(null);

  return (
    show && <ChatOverlay position={position}>
      <button
        onClick={handleClose}
        style={{
          position: "absolute",
          top: "0.8em",
          right: "0.8em",
        }}
      >
        <CloseIcon />
      </button>
      <div
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onClick={handleOverlayClick}
        onMouseDown={handleMouseDown}
      >
        <ChatHeader />
      </div>
      <Suspense fallback={<ThreeLinesLoaderBox />}>
        <ChatBox chatFooterRef={chatFooterRef} />
        <hr css={{ height: "1px", margin: "0", width: "100%" }} />
        <TypingIndicator />
        {!readOnly && (
          <>
            <TypeReplyOverlay
              chatFooterRef={chatFooterRef}
              isOnOverlay={true}
            />
            <ChatFooter ref={chatFooterRef} isOnOverlay={true} />
          </>
        )}
      </Suspense>
    </ChatOverlay>
  );
};

export default ChatScreenOverlay;
