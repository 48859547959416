import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { queryConfig } from "../queries/config";

export function useConfig() {
  return useQuery(queryConfig).data;
}

export function useSuspenseConfig() {
  return useSuspenseQuery(queryConfig).data;
}
