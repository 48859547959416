import React, { useEffect, useState } from "react";
import "./TextContainer.css"; // Assuming your CSS for animations is defined here

type TextContainerProps = {
  text: string;
  voiceBotActive: boolean;
};

interface CharProps {
  char: string;
  style: React.CSSProperties; // Ensures that style complies with React style objects
}

type Line = CharProps[];

const AnimatedTextContainer = ({
  text,
  voiceBotActive,
}: TextContainerProps) => {
  const [lines, setLines] = useState<Line[]>([]);
  const delayIncrement = 20;

  useEffect(() => {
    if (!voiceBotActive) {
      setLines([]);
    }
  }, [voiceBotActive]);

  useEffect(() => {
    console.log("Recorder: received text:", text);
    // Early return for specific text condition
    // TODO: maybe add more types to ignore?
    if (text === " [BLANK_AUDIO]\n") {
      return;
    }

    const newLine = Array.from(text).map((char, index) => ({
      char: char === " " ? "\u00A0" : char,
      style: { animationDelay: `${index * delayIncrement}ms` },
    }));

    setLines((prevLines) => [...prevLines, newLine]);
  }, [text]);

  return lines.map((line, lineIndex) => (
    <div key={lineIndex}>
      {line.map((item, charIndex) => (
        <span key={charIndex} className="letter" style={item.style}>
          {item.char}
        </span>
      ))}
    </div>
  ));
};

export default AnimatedTextContainer;
