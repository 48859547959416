import VerseLogoUrl from "@/assets/Verse_Logo.svg?url";
import { checkNotificationPermission, DENIED, GRANTED, hasBrowserNotification } from "@/utils/helpers/browserPermissions";

import { useEffect } from "react";

const DesktopNotification = ({ title, message, onClose, onNotification }: { title: string, message?: string, onClose?: VoidFunction, onNotification: (notification: Notification) => void }) => {

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (!document.hidden) {
				onClose?.();
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (hasBrowserNotification()) {
			const createNotification = () => {
				onNotification(new window.Notification(
					title,
					{
						icon: VerseLogoUrl,
						body: message
					}
				));
			};

			const permission = checkNotificationPermission();

			if (permission === GRANTED) {
				createNotification();
			} else if (permission !== DENIED) {
				const requestPermissionAndNotify = async () => {
					const permission = await window.Notification.requestPermission();
					if (permission === "granted") {
						createNotification();
					}
				}
				void requestPermissionAndNotify();
			}
		}
	}, [title, message]);

	return <></>;
}

export default DesktopNotification;