import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const Container = styled.div({
  width: "100%",
  height: "85%",
  display: "flex",
  flexDirection: "row",
});

export const DividerLine = styled.div({
  width: "95%",
  borderBottom: "solid 1px",
  borderColor: colors.secondaryTextColor,
  opacity: "0.2",
});

export const DividerLetter = styled.div({
  color: colors.primaryAccentColor,
  width: "5%",
});
