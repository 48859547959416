const OdienceLogoWithText = (props: React.ComponentProps<"svg">) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={138.225}
      height={35.176}
      viewBox="0 0 138 35"
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1={0.75}
          x2={0.25}
          y1={0.934}
          y2={0.067}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0.035} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.628} stopColor="#fff" />
          <stop offset={0.661} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
      <g data-name="Group 1">
        <path
          fill="url(#a)"
          d="M18.215.5A17.588 17.588 0 0 0 4.069 28.576a18.646 18.646 0 0 0 1.626 1.9 17.6 17.6 0 0 0 23.74 1.138 17.39 17.39 0 0 0 6.342-13.5A17.6 17.6 0 0 0 18.215.5Zm0 25.529a7.914 7.914 0 1 1 7.859-7.913 7.923 7.923 0 0 1-7.859 7.913Z"
          data-name="Path 1"
          transform="translate(-.6 -.5)"
        />
        <path
          fill="#fff"
          d="M10.352 20.759a8.549 8.549 0 0 1-.6-2.005 8.119 8.119 0 0 1-.108-1.192v-.054l-9-4.661a17.6 17.6 0 0 0 2.825 15.229 18.646 18.646 0 0 0 1.626 1.9 17.6 17.6 0 0 0 23.74 1.138c-12.954-.653-17.128-6.94-18.483-10.355Z"
          data-name="Path 2"
        />
      </g>
      <g fill="#fff" data-name="Group 2">
        <path
          d="M51.277 23.422a5.416 5.416 0 0 1-2.214-.4 5.55 5.55 0 0 1-3.022-3.021 6.29 6.29 0 0 1 0-4.428 5.55 5.55 0 0 1 3.022-3.022 6.29 6.29 0 0 1 4.428 0 5.55 5.55 0 0 1 3.019 3.019 6.29 6.29 0 0 1 0 4.428 5.55 5.55 0 0 1-3.019 3.019 5.119 5.119 0 0 1-2.214.405Zm0-1.912a3.214 3.214 0 0 0 1.409-.3 2.854 2.854 0 0 0 1.107-.805 3.62 3.62 0 0 0 .805-1.208 4.038 4.038 0 0 0 .3-1.51 3.677 3.677 0 0 0-.3-1.51 3.186 3.186 0 0 0-.805-1.208 4.469 4.469 0 0 0-1.107-.805 3.438 3.438 0 0 0-2.818 0 2.854 2.854 0 0 0-1.107.805 3.619 3.619 0 0 0-.805 1.208 4.038 4.038 0 0 0-.3 1.51 3.361 3.361 0 0 0 .3 1.51 3.186 3.186 0 0 0 .805 1.208 4.469 4.469 0 0 0 1.107.805 3.552 3.552 0 0 0 1.409.3Z"
          data-name="Path 3"
        />
        <path
          d="M63.152 12.352h3.623a5.9 5.9 0 0 1 2.315.4 4.208 4.208 0 0 1 1.711 1.107 5.023 5.023 0 0 1 1.107 1.711 6.29 6.29 0 0 1 0 4.428 5.023 5.023 0 0 1-1.107 1.711 6.23 6.23 0 0 1-1.711 1.107 5.593 5.593 0 0 1-2.315.4h-3.623Zm3.522 8.957a7.821 7.821 0 0 0 1.61-.2 3 3 0 0 0 1.107-.7 4.156 4.156 0 0 0 .7-1.107 5.032 5.032 0 0 0 0-2.818 4.156 4.156 0 0 0-.7-1.107 4.155 4.155 0 0 0-1.107-.7 3.924 3.924 0 0 0-1.61-.2h-1.51v6.944h1.51Z"
          data-name="Path 4"
        />
        <path d="M78.549 12.352h2.013V23.12h-2.013Z" data-name="Path 5" />
        <path
          d="M89.317 14.365v2.516h4.327v1.912h-4.327v2.516h4.831v1.912h-6.844V12.352h6.843v2.013Z"
          data-name="Path 6"
        />
        <path
          d="M100.487 12.352h2.415l4.327 7.246h.1l-.1-2.113v-5.133h2.013V23.12h-2.113l-4.629-7.648h-.1l.1 2.113v5.535h-2.013V12.352Z"
          data-name="Path 7"
        />
        <path
          d="M125.244 21.51a7.051 7.051 0 0 1-1.912 1.51 6.081 6.081 0 0 1-2.415.5 5.415 5.415 0 0 1-2.214-.4 5.55 5.55 0 0 1-3.022-3.018 6.29 6.29 0 0 1 0-4.428 5.55 5.55 0 0 1 3.019-3.019 5.415 5.415 0 0 1 2.214-.4 5.2 5.2 0 0 1 4.025 1.811l-1.409 1.409a3.146 3.146 0 0 0-1.107-.906 3.36 3.36 0 0 0-1.51-.3 3.214 3.214 0 0 0-1.409.3 4.155 4.155 0 0 0-1.107.7 3.619 3.619 0 0 0-.805 1.208 4.038 4.038 0 0 0-.3 1.51 3.36 3.36 0 0 0 .3 1.51 3.186 3.186 0 0 0 .805 1.208 2.605 2.605 0 0 0 1.107.7 3.214 3.214 0 0 0 1.409.3 3.623 3.623 0 0 0 2.818-1.409Z"
          data-name="Path 8"
        />
        <path
          d="M133.395 14.365v2.516h4.327v1.912h-4.327v2.516h4.831v1.912h-6.844V12.352h6.843v2.013Z"
          data-name="Path 9"
        />
      </g>
    </svg>
  </>
);
export default OdienceLogoWithText;
