import { atoms } from "@/utils/helpers/atoms";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useChatbotBanners } from "@/utils/hooks/useChatbotBanners";
import { useChatbotDirectory } from "@/utils/hooks/useChatbotDirectory";
import styled from "@emotion/styled";
import { useSetAtom } from "jotai";
import { useReducer, useRef, useState } from "react";
import { mod } from "../../utils";
import {
  useDraggableAndScrollableWithFocusedElements,
  useScrollIntoView,
} from "../../utils/hooks/useScrollIntoView";
import { CarouselButton } from "../shared/CarouselButton";

const carouselImageWidth = "80%";
const carouselImageGap = "0.75em";
const animationDuration = "0.35s";
const animationTiming = `${animationDuration} ease`;

const CarouselContainer = styled.div({
  display: "flex",
  gap: carouselImageGap,
  overflowX: "scroll",
  // disable scrollbar
  scrollbarWidth: "none", // Firefox
  msOverflowStyle: "none",
  "::-webkit-scrollbar": {
    width: "0",
    height: "0",
  },
});

const _CarouselImage = styled.img<{
  beforeFocused: boolean;
  focused: boolean;
}>(({ beforeFocused, focused }) => ({
  cursor: "pointer",
  objectFit: "cover",
  minWidth: "100%",
  maxWidth: "100%",
  maxHeight: "20vh",
  userSelect: "none",
  userDrag: "none",
  WebkitUserDrag: "none",
  borderRadius: "20px",
  overflow: "hidden",
  willChange: "transform, transform-origin, filter",
  transformOrigin: focused ? "center" : beforeFocused ? "right" : "left",
  transform: `scale(${focused ? "1" : "0.92"}) translateZ(0)`,
  backfaceVisibility: "hidden",
  filter: `brightness(${focused ? "1" : "0.5"})`,
  transition: `all ${animationTiming}`,
}));

// @ts-expect-error
_CarouselImage.defaultProps ??= {};
// @ts-expect-error
_CarouselImage.defaultProps.onMouseDown = (e) => {
  e.preventDefault();
};

const CarouselImage = ({
  ref,
  ...props
}: {
  ref?: React.Ref<HTMLDivElement | null>;
  beforeFocused: boolean;
  focused: boolean;
} & Omit<Parameters<typeof _CarouselImage>[0], "ref">) => {
  return (
    <div
      ref={ref}
      css={{
        minWidth: carouselImageWidth,
        maxWidth: carouselImageWidth,
        maxHeight: "20vh",
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
        ":first-of-type": {
          marginLeft: `calc((100% - ${carouselImageWidth}) / 2)`,
        },
        ":last-of-type": {
          marginRight: `calc((100% - ${carouselImageWidth}) / 2)`,
        },
      }}
    >
      <_CarouselImage {...props} />
    </div>
  );
};

const DotContainer = styled.div({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  bottom: "min(0.75vw, 0.6em)",
  display: "flex",
  gap: "0.25em",
  filter: "drop-shadow(0 0 4px rgba(0,0,0,40%))",
  height: "min(0.75vw, 0.6em)",
  width: "fit-content",
});

const Dot = styled.div<{ focused: boolean }>(({ focused }) => ({
  height: "100%",
  aspectRatio: "1",
  backgroundColor: focused ? "white" : "gray",
  transition: `background-color ${animationTiming}`,
  borderRadius: "50%",

  cursor: "pointer",
}));

export default function BannerCarousel() {
  const banners = useChatbotBanners().data;

  if (!banners || !banners.length) return null;

  return <InnerBannerCarousel key={banners.length} banners={banners} />;
}

function InnerBannerCarousel({
  banners: _banners,
}: {
  banners: NonNullable<ReturnType<typeof useChatbotBanners>["data"]>;
}) {
  const setSelectedChatbot = useSetAtom(atoms.chatbot.selectedChatbot);
  const [banners, setBanners] = useState(_banners);
  const [bots] = useChatbotDirectory().data;

  const [forceRerenderKey, refocus] = useReducer((prev) => prev + 1, 0);
  const [focusedIdx, moveFocused] = useReducer(
    (prev: number, val: (1 | -1 | 0) | { set: number }) => {
      if (!banners) return prev;

      if (val === 0) {
        refocus();
        return prev;
      }

      if (typeof val === "object") {
        return val.set;
      }

      const res = prev + val;
      if (res < 0 || res > banners.length - 1) {
        return mod(res, banners.length);
      }
      return res;
    },
    0
  );
  const isMin = focusedIdx === 0;
  const isMax = !banners || focusedIdx === banners.length - 1;

  const draggableRef = useRef<HTMLDivElement>(null!);
  useDraggableAndScrollableWithFocusedElements(draggableRef, moveFocused);

  const bannerRefs = useRef<(HTMLDivElement | null)[]>([]);
  useScrollIntoView({ getElem: () => bannerRefs.current[focusedIdx] }, [
    focusedIdx,
    forceRerenderKey,
  ]);

  if (!banners.length) return null;

  return (
    <div
      css={{
        width: "100%",
        position: "relative",
        mask: "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 4%, rgba(0,0,0,1) 96%, rgba(0,0,0,0) 100%)",
      }}
    >
      <CarouselContainer ref={draggableRef}>
        {banners.map((banner, idx) => (
          <CarouselImage
            key={idx}
            ref={(banner) => {
              bannerRefs.current[idx] = banner;
            }}
            src={banner.url}
            beforeFocused={idx < focusedIdx}
            focused={focusedIdx === idx}
            onClick={() => {
              const bot = bots?.find((bot) => bot.bot_id === banner.bot_id);
              const chatbotContact = WebGwContact.fromChatbotInfo(
                bot || /* backup bot, shouldn't be used */ {
                  id: banner.bot_id,
                  bot_id: banner.bot_id,
                  action_image_url: banner.url,
                  display_name: banner.name,
                }
              );
              setSelectedChatbot(chatbotContact);
            }}
            onError={() => {
              // remove this banner from banners
              setBanners((prevBanners) => {
                const idx = prevBanners.indexOf(banner);
                if (idx !== -1) {
                  prevBanners.splice(idx, 1);
                  return prevBanners.slice();
                }
                return prevBanners;
              });
              moveFocused({
                set: 0,
              });
            }}
          />
        ))}
      </CarouselContainer>
      {banners.length > 1 && (
        <>
          <CarouselButton
            disabled={isMin}
            css={{
              width: `calc(((100% - ${carouselImageWidth}) / 2) - ${carouselImageGap})`,
              transition: `opacity ${animationTiming}`,
            }}
            left
            onClick={() => {
              moveFocused(-1);
            }}
          />
          <CarouselButton
            disabled={isMax}
            css={{
              width: `calc(((100% - ${carouselImageWidth}) / 2) - ${carouselImageGap})`,
              transition: `opacity ${animationTiming}`,
            }}
            right
            onClick={() => {
              moveFocused(1);
            }}
          />
          <DotContainer>
            {banners.map((_, idx) => (
              <Dot
                key={idx}
                focused={focusedIdx === idx}
                onClick={() => {
                  moveFocused({
                    set: idx,
                  });
                }}
              />
            ))}
          </DotContainer>
        </>
      )}
    </div>
  );
}
