import { css } from "@emotion/react";
import { DEFAULT_AVATAR } from "../../../utils/hooks/useDirectorAuthentication";

type OdienceProfileAvatarProps = {
  odienceAvatar: string;
  handleOdienceAvatarError: () => void;
  handleOpenEditProfile: () => void;
};

const OdienceProfileAvatar = ({
  odienceAvatar,
  handleOdienceAvatarError,
  handleOpenEditProfile,
}: OdienceProfileAvatarProps) => (
  <button onClick={handleOpenEditProfile}>
    <img
      src={odienceAvatar || DEFAULT_AVATAR}
      alt="User Avatar"
      onError={handleOdienceAvatarError}
      css={odienceProfileAvatarStyles}
    />
  </button>
);

export default OdienceProfileAvatar;

const odienceProfileAvatarStyles = css({
  height: "4em",
  width: "4em",
  borderRadius: "10px",
});
