import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const Root = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "90%",
  marginBottom: "3%",
});

export const title = css({
  color: colors.primaryTextColor,
  fontSize: "1.75em",
});

export const titlePopupOverlay = css({
  color: colors.primaryTextColor,
  fontSize: "1.5em",
  fontWeight: "bold",
});
