import ArrowDown from "@/assets/ArrowDown";
import { overlayTransition } from "@/components/shared/DraggableOverlay";
import { colors } from "@/styles/global.styles";
import { useSnapshotAcceptUndefined } from "@/utils";
import { ease } from "@/utils/ease";
import { atoms } from "@/utils/helpers/atoms";
import { convertDateToHumanReadableShort } from "@/utils/helpers/time";
import Conversation from "@/utils/messaging/conversation/Conversation";
import {
  conversationsState,
  getSelectedConversation,
  useSelectedConversation,
} from "@/utils/messaging/conversation/ConversationState";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { isSameDay } from "date-fns/isSameDay";
import { useAtomValue } from "jotai";
import throttle from "lodash/throttle";
import { AnimatePresence, motion, MotionConfig } from "motion/react";
import React, {
  RefObject,
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { proxy, useSnapshot } from "valtio";
import {
  usePreviousRef,
  usePreviousState,
} from "../../../utils/hooks/usePrevious";
import NmsMessage from "../../../utils/messaging/NmsMessage";
import parseNmsToChatMessage from "../../../utils/messaging/parseNmsToChatMessage";
import { confState } from "./chatConf";
import MessageDetails from "./components/MessageDetails";
import { MessageAreaRef, setMessageAreaRef } from "./messageAreaRef";
import MessageHolder from "./MessageHolder";
import { scrollToBottomRepeated } from "./util/cardUtils";

export default function MessageArea({
  chatBoxRef,
}: {
  chatBoxRef: RefObject<HTMLElement>;
}) {
  const snap = useSnapshot(conversationsState);
  const conversation = getSelectedConversation(snap);
  const messages = conversation?.getMessages();

  const lastLiElementRef = useRef<HTMLLIElement>(null);
  const olRef = useRef<MessageAreaRef>(null);
  const [showMessageDetails, setShowMessageDetails] = useState<
    { conversation: Conversation; message: NmsMessage } | undefined
  >();

  /**
   * creating state that gets updated in this parent component,
   * but is only read in the child component and thus doesn't cause a re-render for this component
   */
  const goToBottomBubbleState = useMemo(
    () => proxy({ val: { show: false, count: 0 } }),
    []
  );

  const handleCloseMessageDetails = () => {
    setShowMessageDetails(undefined);
  };

  const handleShowMessageDetails = (
    conversation: Conversation,
    message: NmsMessage
  ) => {
    setShowMessageDetails({ conversation, message });
  };

  useLayoutEffect(() => {
    if (!olRef.current) return;

    const parent = olRef.current.parentElement;
    if (!parent) return;

    olRef.current.scrollToBottom = () => {
      parent.scrollTop = parent.scrollHeight;
    };

    olRef.current.scrollToBottomSmooth = () => {
      lastLiElementRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    setMessageAreaRef(olRef.current);
  }, [messages?.length]);

  useEffect(() => {
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      if (!olRef.current) {
        console.error("olRef.current is null");
        return;
      }
      olRef.current.style.setProperty("--transition-time", "0.35s");
      olRef.current.style.setProperty("--t", "0.35s ease");
    });
  }, [olRef.current]);

  const disableNewMsgAnimation = useRef(true);

  const checkNeedScrollToLastMessage = () => {
    return (
      scrollBarAtTheBottomRef.current ||
      // We also scroll down whenever last message is from local
      messages?.[messages.length - 1].Direction === "Out"
    );
  };

  const lastMessagesLengthRef = usePreviousRef(messages?.length);

  useEffect(() => {
    // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
    setTimeout(() => {
      disableNewMsgAnimation.current = false;
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    if (olRef.current && scrollBarAtTheBottomRef.current) {
      return scrollToBottomRepeated({
        scrollElem: olRef.current,
        stopAfterDelayMs: 100,
      });
    }
  });

  const handleScrollToBottom = () => {
    olRef.current?.lastElementChild?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const scrollBarAtTheBottomRef = useRef(true);
  const handleScroll = (scrollBarAtTheBottom: boolean) => {
    scrollBarAtTheBottomRef.current = scrollBarAtTheBottom;

    goToBottomBubbleState.val.show = !scrollBarAtTheBottom;

    if (scrollBarAtTheBottom) {
      goToBottomBubbleState.val.count = 0;
    } else if (
      messages &&
      lastMessagesLengthRef.current &&
      messages.length !== lastMessagesLengthRef.current &&
      messages[messages.length - 1].Direction === "In"
    ) {
      goToBottomBubbleState.val.count +=
        messages.length - lastMessagesLengthRef.current;
    }
  };

  const reply = useAtomValue(atoms.messaging.messageReply);

  return (
    <>
      <ol
        ref={olRef}
        className="chat"
        css={{
          "--transition-time": "0s",
          minHeight: "100%",
          marginBottom: 0,
          ...(reply && {
            [`& > *:not([data-message-id='${reply.id}'])`]: {
              opacity: "0.5 !important",
              pointerEvents: "none",
            },
          }),
        }}
      >
        {!conversation || !messages ? null : (
          <MessageAreaLimiter olRef={olRef} onScroll={handleScroll}>
            {messages.map((message, idx) => (
              <MessageOuterHolder
                conversation={conversation}
                chatBoxRef={chatBoxRef}
                ref={lastLiElementRef}
                key={message["imdn.Message-ID"]}
                msgIdx={idx}
                messagesLength={messages.length}
                disableNewMsgAnimation={disableNewMsgAnimation}
                onCheckNeedScrollToLastMessage={checkNeedScrollToLastMessage}
                onShowMessageDetails={handleShowMessageDetails}
              />
            ))}
          </MessageAreaLimiter>
        )}
      </ol>
      <GoToBottomBubble
        onClick={handleScrollToBottom}
        state={goToBottomBubbleState}
      />
      <MotionConfig transition={overlayTransition}>
        <AnimatePresence>
          {showMessageDetails && (
            <div
              style={{
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999,
              }}
            >
              <MessageDetails
                conversation={showMessageDetails.conversation}
                message={showMessageDetails.message}
                onClose={handleCloseMessageDetails}
              />
            </div>
          )}
        </AnimatePresence>
      </MotionConfig>
    </>
  );
}

function MessageAreaLimiter({
  children,
  olRef,
  onScroll,
}: {
  children: React.ReactNode[];
  olRef: RefObject<MessageAreaRef | null>;
  onScroll: (scrollBarAtTheBottom: boolean) => void;
}) {
  const initial = 20;
  const increment = 10;
  const incrementOnScrollRatio = 0.2;

  const [elementsToRender, setElementsToRender] = useState(initial);

  const previousChildrenLength = usePreviousState(children.length);
  const diff = previousChildrenLength
    ? children.length - previousChildrenLength
    : 0;

  const handleScroll = () => {
    const scrollElem = olRef.current?.parentElement;

    if (!scrollElem) return;
    const { scrollTop, clientHeight, scrollHeight } = scrollElem;
    const scrollRatio = scrollTop / (scrollHeight - clientHeight);

    if (allowIncrement.current) {
      if (
        scrollRatio < incrementOnScrollRatio ||
        (scrollTop === 0 && elementsToRender < children.length)
      ) {
        // Update elements to render dynamically based on scroll position
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setElementsToRender((prev) => {
          allowIncrement.current = false;
          const newElementsToRender = prev + increment;
          return Math.min(newElementsToRender, children.length);
        });
      }
    }

    // if scroll is at the very top, scroll down by a few pixels so that the user can scroll up more and trigger this callback again
    if (scrollTop === 0) {
      scrollElem.scrollBy({ top: 15, behavior: "smooth" });
    }

    // Add a 10 px to consider scrollbar at the bottom if user just moved it a bit
    onScroll(clientHeight + scrollTop >= scrollHeight - 10);
  };

  const allowIncrement = useRef(true);
  useEffect(() => {
    queueMicrotask(() => {
      allowIncrement.current = true;
    });
  }, [elementsToRender]);

  const loadedOnce = useRef(false);

  useEffect(() => {
    const scrollElem = olRef.current?.parentElement;

    if (!scrollElem) {
      console.warn("scrollElem is null", olRef.current);
      return;
    }

    if (!loadedOnce.current) {
      handleScroll();
      loadedOnce.current = true;
    }

    // SRA-10089
    // this may help to avoid a bug where the chat scrolls all the way to the top when loading more messages
    const throttledHandleScroll = throttle(handleScroll, 150);
    scrollElem.addEventListener("scroll", throttledHandleScroll);

    return () => {
      scrollElem?.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [children.length, increment, elementsToRender]);

  return children.slice(
    Math.max(0, children.length - (elementsToRender + diff))
  );
}

type MessageComponentProps = {
  msgIdx: number;
  messagesLength: number;
  disableNewMsgAnimation: RefObject<boolean>;
  chatBoxRef: RefObject<HTMLElement>;
  conversation: Conversation;
  onCheckNeedScrollToLastMessage: () => boolean;
  onShowMessageDetails: (
    conversation: Conversation,
    message: NmsMessage
  ) => void;
};
const MessageOuterHolder = ({
  ref,
  msgIdx,
  messagesLength,
  disableNewMsgAnimation,
  chatBoxRef,
  conversation,
  onCheckNeedScrollToLastMessage,
  onShowMessageDetails,
}: MessageComponentProps & {
  ref?: React.RefObject<HTMLLIElement | null>;
}) => {
  const conf = useSnapshot(confState);
  const [fallbackToGenericFile, setFallbackToGenericFile] = useState(false);

  const messages = useSelectedConversation()!.getMessages();

  const { prevMessageSnap, messageProxy, messageSnap, nextMessageSnap } =
    useSurroundingMessageSnapshots(messages, msgIdx);

  const chatMessage = useMemo(
    () => parseNmsToChatMessage(messageSnap, fallbackToGenericFile),
    [
      messageSnap,
      messageSnap.history,
      messageSnap.deleted,
      fallbackToGenericFile,
    ]
  );
  if (!chatMessage) return null;

  // remove suggested chip list if not last 2 messages
  if (msgIdx !== messagesLength - 1 && msgIdx !== messagesLength - 2) {
    delete chatMessage.suggestedChipList;
  }

  const separateTimeMs = conf.messageSeparateTimeMs ?? 5 * 60 * 1000;

  const separateFromLastMessage =
    !prevMessageSnap ||
    prevMessageSnap.Direction !== messageSnap.Direction ||
    messageSnap.Date.getTime() - prevMessageSnap.Date.getTime() >
      separateTimeMs ||
    (prevMessageSnap._isBeingDeleted &&
      // check the previous previous message
      messages[msgIdx - 2] &&
      (messages[msgIdx - 2].Direction !== prevMessageSnap.Direction ||
        prevMessageSnap.Date.getTime() - messages[msgIdx - 2].Date.getTime() >
          separateTimeMs));

  const separateFromNextMessage =
    !nextMessageSnap ||
    nextMessageSnap.Direction !== messageSnap.Direction ||
    nextMessageSnap.Date.getTime() - messageSnap.Date.getTime() >
      separateTimeMs;

  const sameDayAsPreviousMessage = prevMessageSnap
    ? prevMessageSnap._isBeingDeleted &&
      // check the previous previous message
      messages[msgIdx - 2]
      ? isSameDay(messageSnap.Date, messages[msgIdx - 2].Date)
      : isSameDay(messageSnap.Date, prevMessageSnap.Date)
    : false;

  const sameContactAsPreviousMessage = prevMessageSnap
    ? isSamePhoneNumber(messageSnap.From, prevMessageSnap.From)
    : false;

  const handleFallbackToGenericFile = () => {
    setFallbackToGenericFile(true);
  };

  return (
    <Suspense fallback={null} key={messageSnap.reactKeyIdList}>
      {!sameDayAsPreviousMessage && (
        <DateHeader
          date={new Date(chatMessage.time)}
          isBeingDeleted={messageSnap._isBeingDeleted}
          forceNoAnimation={disableNewMsgAnimation}
        />
      )}
      <MessageHolder
        conversation={conversation}
        showIncomingContactHeader={
          conversation.getIsGroupChat() &&
          chatMessage.direction === "In" &&
          !sameContactAsPreviousMessage
        }
        sameDayAsPreviousMessage={sameDayAsPreviousMessage}
        ref={!nextMessageSnap ? ref : undefined}
        chatBoxRef={chatBoxRef}
        nmsMessageProxy={messageProxy}
        message={chatMessage}
        forceNoAnimation={disableNewMsgAnimation}
        roundBorderTop={separateFromLastMessage}
        roundBorderBottom={separateFromNextMessage}
        isLastMessage={
          !nextMessageSnap ||
          // check if this message will be the last message after the next message is deleted
          (nextMessageSnap._isBeingDeleted && msgIdx === messages.length - 2)
        }
        onCheckNeedScrollToLastMessage={onCheckNeedScrollToLastMessage}
        onFallbackToGenericFile={handleFallbackToGenericFile}
        onShowMessageDetails={onShowMessageDetails}
      />
    </Suspense>
  );
};

function DateHeader({
  date,
  isBeingDeleted,
  forceNoAnimation,
}: {
  date: Date;
  isBeingDeleted: boolean;
  forceNoAnimation: RefObject<boolean>;
}) {
  return (
    <motion.div
      css={{
        textAlign: "center",
        fontSize: "0.8em",
        fontWeight: "bold",
        overflow: "hidden",
        lineHeight: "1",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
      initial={
        forceNoAnimation.current
          ? false
          : { opacity: 0, paddingTop: 0, paddingBottom: 0, maxHeight: 0 }
      }
      animate={
        isBeingDeleted
          ? { opacity: 0, paddingTop: 0, paddingBottom: 0, maxHeight: 0 }
          : {
              opacity: 1,
              maxHeight: "40px",
              paddingTop: "1rem",
              paddingBottom: "0.5rem",
            }
      }
      transition={{ duration: 0.35, ease: ease }}
    >
      {convertDateToHumanReadableShort(date, false, false)}
    </motion.div>
  );
}

function GoToBottomBubble({
  onClick,
  state,
}: {
  onClick: () => void;
  state: { val: { show: boolean; count: number } };
}) {
  const {
    val: { show, count: nbMessages },
  } = useSnapshot(state);

  if (!show) {
    return null;
  }

  const is3Digits = nbMessages > 99;
  const is2Digits = nbMessages > 9;

  const nbMessagesText = is3Digits ? "99+" : nbMessages;
  const style = {
    width: is3Digits ? "1.4em" : is2Digits ? "1.1em" : "0.9em",
    height: is3Digits ? "1.4em" : is2Digits ? "1.1em" : "0.9em",
    top: is3Digits ? "-17px" : is2Digits ? "-12px" : "-9px",
  };

  return (
    /**
     * Goal of this sticky div with a 0 height helps to keep the bubble at the bottom without any space taking, the embedded div absolute will take over it.
     * Directly using an absolute div wont keep the bubble at the bottom when scrolling
     */
    <div
      style={{
        position: "sticky",
        bottom: "0",
        zIndex: "1",
        height: "0",
      }}
    >
      <div
        css={{
          cursor: "pointer",
          borderRadius: "50%",
          background: colors.secondaryBackground,
          width: "34px",
          height: "34px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          filter: `drop-shadow(0px 2px 2px #151719)`,
        }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: nbMessages > 0 ? "visible" : "hidden",
            borderRadius: "50%",
            borderColor: colors.primaryBackground,
            borderStyle: "solid",
            borderWidth: "1px",
            backgroundColor: colors.primaryAccentColor,
            position: "absolute",
            ...style,
          }}
        >
          <span style={{ fontSize: "0.7em", fontWeight: "bold" }}>
            {nbMessagesText}
          </span>
        </div>

        <ArrowDown />
      </div>
    </div>
  );
}

function useSurroundingMessageSnapshots(
  messages: readonly NmsMessage[] | NmsMessage[],
  msgIdx: number
) {
  const messageProxy = messages[msgIdx];

  const messageSnap = useSnapshot(messageProxy);

  const prevMessageProxy = messages[msgIdx - 1] as NmsMessage | undefined;
  const prevMessageSnap = useSnapshotAcceptUndefined(prevMessageProxy);

  const nextMessageProxy = messages[msgIdx + 1] as NmsMessage | undefined;
  const nextMessageSnap = useSnapshotAcceptUndefined(nextMessageProxy);

  return { prevMessageSnap, messageProxy, messageSnap, nextMessageSnap };
}
