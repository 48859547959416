import { useSnapshotAcceptUndefined } from "@/utils";
import { atoms } from "@/utils/helpers/atoms";
import { derive } from "derive-valtio";
import { getDefaultStore } from "jotai";
import { proxy, useSnapshot } from "valtio";
import { devtools, proxyMap } from "valtio/utils";
import type Conversation from "./Conversation";
import { cleanPhoneNumber } from "./conversationUtils/phoneNumberUtils";

export type ConversationId = string; // +1xxxxxxxxxx or XXXXXX
export type ConversationMap = Map<ConversationId, Conversation>;

let _selectedConversationId: string | null;

export const conversationsState = proxy({
  get selectedConversationId() {
    if (
      _selectedConversationId &&
      !this.conversations.has(_selectedConversationId)
    ) {
      return null;
    }
    return _selectedConversationId;
  },
  set selectedConversationId(val: string | null) {
    _selectedConversationId = val;
  },
  conversations: proxyMap(new Map()) as ConversationMap,
  isLoading: true,
});

if (import.meta.env.DEV && "__REDUX_DEVTOOLS_EXTENSION__" in window) {
  const debugConversationsState = derive({
    ...Object.assign(
      // @ts-expect-error
      ...Object.keys(conversationsState).map((key) => ({
        [key]: (get) => get(conversationsState)[key],
      }))
    ),
    conversations: (get) =>
      Object.fromEntries(get(conversationsState).conversations),
  });

  devtools(debugConversationsState);
}

export function setSelectedConversationId(conversationId: string | null) {
  console.log("setSelectedConversationId ", conversationId);
  conversationId = cleanPhoneNumber(conversationId);
  if (conversationsState.selectedConversationId === conversationId) return;

  // before a conversation is changed, remove the currently selected conversation if it doesn't have any messages
  if (
    conversationsState.selectedConversationId &&
    !getSelectedConversation()?.getMessages().length
  ) {
    conversationsState.conversations.delete(
      conversationsState.selectedConversationId
    );
  }

  getDefaultStore().set(atoms.messaging.messageReply, null);
  conversationsState.selectedConversationId = conversationId;
}

export function getSelectedConversationId(state = conversationsState) {
  return state.selectedConversationId;
}
/**
 * The conversation returned is editable (ex: ``conversation.pushMessage(...)``)
 */

export function getSelectedConversation(state = conversationsState) {
  if (state.selectedConversationId) {
    return state.conversations.get(state.selectedConversationId);
  }
}
/**
 * The conversation returned is not editable and it will update with changes
 */

export function useSelectedConversation({ enabled = true } = {}) {
  const snap = useSnapshotAcceptUndefined(
    enabled ? conversationsState : undefined
  );
  if (!snap) return undefined;
  return getSelectedConversation(snap);
}

export function useSelectedConversationId() {
  return useSnapshot(conversationsState).selectedConversationId;
}

export function setConversations(
  newConversations: typeof conversationsState.conversations
) {
  // conversationsState.conversations = proxyMap(new Map(newConversations));
  for (const [id, c] of newConversations.entries()) {
    // ? not sure why cloning the messages is necessary to reflect an update
    console.debug("Setting conversation ID:", id);
    conversationsState.conversations.set(id, c.deepClone());
  }
}
