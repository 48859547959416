import { Interpolation, Theme } from "@emotion/react";
import RemoveIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { useId } from "react";
import { IconButton } from "./Button";
import { Input } from "./Input";
import { Root, icon } from "./SearchBar.style";

type Props = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  placeholder?: string;
  css?: Interpolation<Theme>;
} & React.ComponentProps<typeof Root>;

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  placeholder,
  ...props
}: Props) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const inputId = useId();

  return (
    <Root {...props}>
      <label css={icon} htmlFor={inputId}>
        <SearchIcon />
      </label>
      <Input
        id={inputId}
        type="text"
        placeholder={placeholder || "Search"}
        value={searchQuery}
        onChange={handleChange}
        css={{
          margin: "0",
          height: "3em",
        }}
      />
      <IconButton
        onClick={handleClearSearch}
        css={{
          marginRight: "0.5em",
          position: "relative",
        }}
        style={{ opacity: searchQuery ? "1" : "0" }}
        disabled={!searchQuery}
      >
        <RemoveIcon />
      </IconButton>
    </Root>
  );
};

export default SearchBar;
