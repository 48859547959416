import { SetStateAction, createContext, useContext, useState } from "react";

type ProfileModalContextType = {
  showModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  userAvatar: string;
  updateUserAvatar: (avatar: string) => void;
  userDisplayName: string;
  updateUserDisplayName: (displayName: string) => void;
  updateUserEmail: (email: string) => void;
};

const ProfileModalContext = createContext<ProfileModalContextType>(
  {} as ProfileModalContextType
);

export const useProfileModal = () => {
  return useContext(ProfileModalContext);
};

export const ProfileModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");
  const [userDisplayName, setUserDisplayName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const updateUserAvatar = (newAvatar: SetStateAction<string>) => {
    setUserAvatar(newAvatar);
  };
  const updateUserDisplayName = (newDisplayName: SetStateAction<string>) => {
    setUserDisplayName(newDisplayName);
  };

  const updateUserEmail = (newUserEmail: SetStateAction<string>) => {
    setNewUserEmail(newUserEmail);
  };

  return (
    <ProfileModalContext
      value={{
        showModal,
        openModal,
        closeModal,
        userAvatar,
        updateUserAvatar,
        userDisplayName,
        updateUserDisplayName,
        updateUserEmail,
      }}
    >
      {children}
    </ProfileModalContext>
  );
};
