import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useDeleteConversationModal } from "@/utils/hooks/useDeleteConversationModal";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { getConversationTitle } from "@/utils/messaging/conversation/conversationUtils/getConversationTitle";
import React from "react";
import { PrimaryButton } from "../shared/Button";

export const BlockedContact = ({
  handleUnblockContact,
  contact,
}: {
  handleUnblockContact: VoidFunction;
  contact?: WebGwContact;
}) => {
  if (contact) {
    const { conversation } = Conversation.getOrCreate({
      phoneNumber: contact.getMainPhoneNumber(),
    });
    return conversation.getMessages().length === 0 ? (
      <InnerBlockedContact handleUnblockContact={handleUnblockContact} />
    ) : (
      <BlockedContactWithConversation
        handleUnblockContact={handleUnblockContact}
        conversation={conversation}
      />
    );
  }

  return <InnerBlockedContact handleUnblockContact={handleUnblockContact} />;
};

const BlockedContactWithConversation = ({
  handleUnblockContact,
  conversation,
}) => {
  const { conversationTitle } = getConversationTitle(conversation);
  const { modal, show } = useDeleteConversationModal(
    conversation,
    conversationTitle
  );

  return (
    <InnerBlockedContact
      handleUnblockContact={handleUnblockContact}
      showDeleteConversationModal={show}
      deleteConversationModal={modal}
    />
  );
};

const InnerBlockedContact = ({
  handleUnblockContact,
  showDeleteConversationModal,
  deleteConversationModal,
}: {
  handleUnblockContact: VoidFunction;
  showDeleteConversationModal?: VoidFunction;
  deleteConversationModal?: React.ReactNode;
}) => {
  return (
    <div
      css={{
        display: "flex",
        padding: "1.5em 2vw",
        gap: "1.5vw",
        overflow: "hidden",
        minHeight: "5vh",
        justifyContent: "center",
        alignItems: "center",
        color: colors.primaryTextColor,
      }}
    >
      <div css={{ display: "flex", flexDirection: "column" }}>
        <span css={{ fontSize: "0.92em" }}>
          This number is blocked. You won't be able to send or receive any new
          calls or messages until you unblock them.
        </span>
        {showDeleteConversationModal && (
          <>
            <span
              css={{
                color: colors.secondaryTextColor,
                cursor: "pointer",
                paddingTop: "0.5em",
                "&:hover": {
                  color: colors.primaryAccentColor,
                  textDecoration: "underline",
                },
              }}
              onClick={showDeleteConversationModal}
            >
              Delete Conversation
            </span>
          </>
        )}
      </div>
      <PrimaryButton
        css={{
          padding: "1em 2em",
          borderRadius: "1rem",
        }}
        onClick={handleUnblockContact}
      >
        Unblock
      </PrimaryButton>
      {deleteConversationModal}
    </div>
  );
};
