import { ease } from "@/utils/ease";
import { css } from "@emotion/react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { AnimatePresence, motion } from "motion/react";
interface CheckboxProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  className?: string;
  partiallyChecked?: boolean;
  checkboxId?: string;
  disabled?: boolean;
}

export default function Checkbox({
  onChange,
  checked,
  partiallyChecked,
  checkboxId,
  disabled,
  className,
}: CheckboxProps) {
  return (
    <label
      className={className}
      css={checkboxLabelStyle}
      style={
        !disabled
          ? {
              cursor: "pointer",
            }
          : undefined
      }
    >
      <input
        id={checkboxId}
        type="checkbox"
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        hidden
      />
      <span id={checkboxId} css={checkboxStyle}>
        <AnimatePresence>
          {checked ? (
            <AnimatedIcon>
              <CheckBoxIcon css={checkboxIconStyle} />
            </AnimatedIcon>
          ) : partiallyChecked ? (
            <AnimatedIcon>
              <IndeterminateCheckBoxIcon css={checkboxIconStyle} />
            </AnimatedIcon>
          ) : null}
        </AnimatePresence>
      </span>
    </label>
  );
}

const checkboxLabelStyle = css({
  display: "inline-block",
  position: "relative",
  userSelect: "none",
  lineHeight: "0",
});

const checkboxStyle = css({
  display: "inline-block",
  position: "relative",
  width: "16px",
  height: "16px",
  border: "2px solid white",
  borderRadius: "4px",
});

const checkboxIconStyle = css({
  width: "18px",
  height: "18px",
  overflow: "hidden",
  position: "absolute",
  top: "-3px",
  left: "-3px",
});

function AnimatedIcon(props: { children: React.ReactNode }) {
  return (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: ease }}
    >
      {props.children}
    </motion.span>
  );
}
