import { useSnapshotAcceptUndefined } from "@/utils";
import { getSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import { sanitizeMessage } from "@/utils/messaging/sanitize";
import linkifyHtml from "linkify-html";
import { useMemo } from "react";
import Suggestions from "../components/Suggestions";
import { IMessageTypeProps } from "../typings";
import { useRoundBordersCss } from "../util/cardUtils";
import { RepliedMessage } from "./RepliedMessage";
import { msgContentCss, textMessageCss } from "./style";

export default function TextMessage({
  message,
  removeTopMargin,
  isLastMessage,
  direction,
  roundBorderTop,
  roundBorderBottom,
  isReply,
  showReplyMessage,
}: IMessageTypeProps) {
  const { textMessage, suggestedChipList } = message;

  const roundBorders = useRoundBordersCss(
    direction,
    roundBorderTop,
    roundBorderBottom
  );

  const onlyEmojis = useMemo(
    () => containsOnlyEmojis(textMessage ?? ""),
    [textMessage]
  );

  const messageHTML = useMemo(
    () =>
      textMessage
        ? linkifyHtml(sanitizeMessage(textMessage), { target: "_blank" })
        : "",
    [textMessage]
  );

  const messageIsReply =
    showReplyMessage &&
    message.referenceType === "Reply" &&
    !!message.referenceId;

  const repliedMessageProxy =
    !isReply && messageIsReply && message.referenceId
      ? getSelectedConversation()?.getMessage(message.referenceId)
      : undefined;
  const repliedMessage = useSnapshotAcceptUndefined(repliedMessageProxy);

  if (!textMessage) return null;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: isReply
          ? direction === "In"
            ? "flex-end"
            : "flex-start"
          : direction === "In"
            ? "flex-start"
            : "flex-end",
      }}
    >
      {!isReply && messageIsReply && (
        <RepliedMessage
          repliedMessage={repliedMessage}
          incoming={message.direction === "In"}
        />
      )}
      <div
        css={[msgContentCss, textMessageCss, roundBorders]}
        style={{
          marginTop:
            removeTopMargin || messageIsReply || isReply ? "0" : undefined,
          ...(onlyEmojis
            ? { backgroundColor: "transparent", boxShadow: "none" }
            : {
                boxShadow: isReply
                  ? "inset 0 0 2px 1px #696C6F, 0 0 2px 0px #696C6F"
                  : undefined,
              }),
        }}
      >
        <p
          style={
            onlyEmojis
              ? { fontSize: "2em" }
              : message.deleted
                ? {
                    opacity: "0.75",
                    fontStyle: "italic",
                  }
                : undefined
          }
          dangerouslySetInnerHTML={{ __html: messageHTML }}
        />
      </div>
      {suggestedChipList && (
        <Suggestions
          hide={!isLastMessage}
          suggestions={suggestedChipList.suggestions}
          outsideOfCard
        />
      )}
    </div>
  );
}

function containsOnlyEmojis(message: string, nbMaxEmojis = 3): boolean {
  const emojiPattern = `data-emoji-native="([^"]+)"`;
  const regex = new RegExp(emojiPattern, "g");
  const emojiMatches = Array.from(message.matchAll(regex));

  if (emojiMatches.length === 0 || emojiMatches.length > nbMaxEmojis) {
    return false;
  }

  // Build a string only the emojis
  const emojiString = emojiMatches.map((current) => current[1]).join("");

  // Replace all <img> tags with the emoji native codes
  const messageWithoutTags = message
    .replaceAll(
      new RegExp(`<img[^>]*${emojiPattern}[^>]*>`, "g"),
      (_, p1) => p1
    )
    .replaceAll(/\s+/g, "");

  // Check if the string are equals, which means it contains only the emojis
  return messageWithoutTags === emojiString;
}
