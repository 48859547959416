import { IconButton } from "./Button";
import { Root, title } from "./SectionHeader.style";

// TODO this the SectionHeader component doesn't wrap a button. It shouldn't have the same props as one.
type Props = React.ComponentProps<"button"> & {
  pageName: string;
  iconButton?: React.ReactNode;
  disabled?: boolean;
};

const SectionHeader = ({
  pageName,
  iconButton,
  children,
  disabled,
  ...buttonProps
}: Props & { children?: React.ReactNode }) => {
  return (
    <Root>
      <div css={title}>{pageName}</div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5em",
        }}
      >
        {children}
        {iconButton && (
          <IconButton {...buttonProps} disabled={disabled}>
            {iconButton}
          </IconButton>
        )}
      </div>
    </Root>
  );
};

export default SectionHeader;
