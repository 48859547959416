import VerseLogo from "@/assets/VerseLogo";
import { textSecondary } from "@/pages/provisioning/index.style";
import { paths } from "@/routerPaths";
import { colors, globalStyles } from "@/styles/global.styles";
import { Global } from "@emotion/react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";
import ScrollToTopButton from "./ScrollToTopButton";
import { VerticalRule } from "./VerticalRule";

export default function PolicyScreen({
  titleText,
  bodyText,
}: {
  titleText: string;
  bodyText: string;
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Global styles={globalStyles} />
      <BackButton />
      <div
        css={{
          padding: "4em 8vw",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: colors.primaryTextColor,
        }}
      >
        <Link to={paths.root}>
          <VerseLogo css={{ height: "8em" }} />
        </Link>
        <h1 css={{ textAlign: "center" }}>{titleText}</h1>

        <p
          css={{
            width: "100%",
            maxWidth: "50em",
            whiteSpace: "pre-wrap",
            margin: "1em 0",
          }}
        >
          {bodyText}
        </p>

        <div css={{ padding: "2em 0" }}>
          © Summit Tech. All rights reserved.
        </div>
        <ScrollToTopButton />
      </div>
    </>
  );
}

export function PolicyLinks() {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "1.5em",
        margin: "2em",
      }}
    >
      <Link css={[textSecondary, { whiteSpace: "nowrap" }]} to={paths.eula}>
        Terms of Service
      </Link>
      <VerticalRule />
      <Link
        css={[textSecondary, { whiteSpace: "nowrap" }]}
        to={paths.privacyPolicy}
      >
        Privacy Policy
      </Link>
    </div>
  );
}
