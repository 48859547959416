import { css } from "@emotion/react";
import { ReactNode, useState } from "react";

type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  contentRef?: React.Ref<HTMLDivElement>;
  position?: "top" | "bottom" | "left" | "right";
};

const Tooltip = ({
  ref,
  children,
  content,
  contentRef,
  position = "top",
}: TooltipProps & {
  ref?: React.Ref<HTMLElement>;
}) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  const tooltipContainer = css`
    position: relative;
  `;

  const tooltipTarget = css`
    cursor: pointer;
  `;

  const tooltipBubble = css`
    position: absolute;
    padding: 8px;
    background: #333;
    color: #fff;
    border-radius: 4px;
    z-index: 9999;
    white-space: nowrap;
    visibility: ${visible ? "visible" : "hidden"};
    opacity: ${visible ? 1 : 0};
    transition: opacity 0.3s ease;
    ${position === "top" &&
    `
    top: -2em;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px;
  `}
    ${position === "bottom" &&
    `
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
  `}
  ${position === "left" &&
    `
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 8px;
  `}
  ${position === "right" &&
    `
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 8px;
  `}
  `;

  return (
    <div css={tooltipContainer}>
      <div
        css={tooltipTarget}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        ref={ref as any}
      >
        {children}
      </div>
      <div css={tooltipBubble} ref={contentRef}>
        {content}
      </div>
    </div>
  );
};

export default Tooltip;
