import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useIntersectionObserver } from "usehooks-ts";
import PlayButton from "../../../assets/play-button-svgrepo-com.svg";

type VideoPlayerProps = {
  url: string;
  playing?: boolean;
  onTogglePlay?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
};

const VideoPlayer = ({
  url,
  playing,
  onTogglePlay,
  onPlay,
  onPause,
  onEnded,
}: VideoPlayerProps) => {
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    setIsPlaying(true);
    setControlsVisible(true);
    if (isMuted) {
      setIsMuted(false);
    }
  };

  useEffect(() => {
    if (isIntersecting) {
      setIsPlaying(true);
      setControlsVisible(true);
    }
  }, [isIntersecting]);

  return (
    <div
      ref={ref}
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%",
        position: "relative",
        paddingBottom: "56.25%", // This maintains the 16:9 aspect ratio
        height: 0,
        overflow: "hidden",
        borderRadius: "20px",
        marginBottom: "2vh",
      }}
    >
      <div
        onClick={handleVideoClick}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <ReactPlayer
          ref={videoRef}
          url={url}
          width="100%"
          height="100%"
          controls={controlsVisible}
          playing={isPlaying}
          loop={true}
          muted={isMuted}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          // onEnded={onEnded}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: "inherit",
          }}
        />

        {!isPlaying && (
          <img
            src={PlayButton}
            onClick={handleVideoClick}
            alt="Play Button"
            css={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              height: "35%",
              width: "35%",
              filter: "drop-shadow(0px 0px 6px rgba(0,0,0,0.9))",
              opacity: 0.5,
              zIndex: 2,
            }}
          />
        )}

        {/* Invisible Overlay Div to capture clicks and toggle play/pause */}
        {/* <div
          onClick={handleVideoClick}
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            cursor: "pointer",
            backgroundColor: "transparent",
          }}
        /> */}
      </div>
    </div>
  );
};

export default VideoPlayer;
