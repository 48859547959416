import ShareIcon from "@/assets/sketch/Share.svg?react";
import SketchIcon from "@/assets/sketch/Sketch.svg?react";
import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import {
  SketchBackgroundType,
  SketchDrawingType,
  SketchIdType,
  SketchImageType,
  SketchUndoType,
} from "@/utils/helpers/notificationChannel";
import { fileToBase64 } from "@/utils/messaging/conversation/conversationUtils";
import { convertColorForSketch, sendSketch } from "@/utils/sketch";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAtomValue } from "jotai";
import { motion } from "motion/react";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { SKETCH_EXPORT_IMAGE_TYPE, SketchRefType } from ".";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import { buttonCommon } from "../shared/Button";
import { DraggableOverlay } from "../shared/DraggableOverlay";
import Sketch from "./Sketch";
import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_BRUSH_COLOR_INCOMING_INVITE,
  DEFAULT_BRUSH_COLOR_OUTGOING_INVITE,
  DEFAULT_BRUSH_SIZE,
  SketchButtonBar,
} from "./SketchButtonBar";

type SketchOverlayProps = {
  isFullScreen: boolean;
  contact: WebGwContact | undefined;
  toggleFullScreen: VoidFunction;
  active: boolean;
  end: (svg: string) => void;
  sketchId: () => number;
  ended: boolean;
};

type SendSketchType =
  | SketchDrawingType
  | SketchUndoType
  | SketchBackgroundType
  | SketchImageType;

function ContactHeader({ contact }: { contact: WebGwContact }) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "1em",
      }}
    >
      <Avatar contact={contact} style={{ width: "2em", height: "2em" }} />
      <div
        css={{
          whiteSpace: "nowrap",
        }}
      >
        Sketch with {contact.noNameReturnPhoneNumber()}
      </div>
    </div>
  );
}

export function SketchSessionOverlay({
  isFullScreen,
  toggleFullScreen,
  active,
  contact,
  end,
  sketchId,
  ended,
}: SketchOverlayProps) {
  const [isHovered, setIsHovered] = useState(false);

  useLayoutEffect(() => {
    setIsHovered(false);
  }, [isFullScreen]);

  const incomingSketchDrawing = useAtomValue(
    atoms.sketch.incomingSketchDrawing
  );
  const incomingSketchBackground = useAtomValue(
    atoms.sketch.incomingSketchBackground
  );
  const incomingSketchImage = useAtomValue(atoms.sketch.incomingSketchImage);
  const incomingSketchUndo = useAtomValue(atoms.sketch.incomingSketchUndo);
  const sketchRef = useRef<SketchRefType>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const sketchOptions = useMemo(() => {
    // Outgoing invite is always !active first
    return {
      defaultBrushColor: !active
        ? DEFAULT_BRUSH_COLOR_OUTGOING_INVITE
        : DEFAULT_BRUSH_COLOR_INCOMING_INVITE,
      defaultBrushSize: DEFAULT_BRUSH_SIZE,
      defaultBackgroundColor: DEFAULT_BACKGROUND_COLOR,
    };
  }, []);
  const [sketchCurrentBackgroundColor, setSketchCurrentBackgroundColor] =
    useState(sketchOptions.defaultBackgroundColor);

  if (!contact) return null;

  // Sketch server only care about the base64 string, not the data
  const adjustBase64ForSketch = (base64: string) => {
    return base64.split(",")[1];
  };

  const handleSendSketch = async (sketch: SendSketchType) => {
    if ("drawing" in sketch) {
      sketch.drawing.color = convertColorForSketch(sketch.drawing.color, true);
    } else if ("background_color" in sketch) {
      sketch.background_color.color = convertColorForSketch(
        sketch.background_color.color,
        true
      );
    }

    sendSketch(contact.getMainPhoneNumber(), sketch);
  };

  const handleDraw = async (sketch: {
    drawing: Omit<SketchDrawingType["drawing"], keyof SketchIdType>;
  }) => {
    const sketchWithId = sketch as SketchDrawingType;
    sketchWithId.drawing.id = sketchId();
    handleSendSketch(sketchWithId);
  };

  const handleSketchBackgroundColorChange = (color: string) => {
    setSketchCurrentBackgroundColor(color);
  };

  const handleSelectBackgroundColor = async (color: string) => {
    if (!sketchRef.current) {
      return;
    }

    const backgroundColor = {
      background_color: { id: sketchId(), color },
    };
    sketchRef.current.setBackgroundColor(backgroundColor);
    await handleSendSketch(backgroundColor);
  };

  const handleSelectBackgroundImage = async (file: File) => {
    if (!sketchRef.current) {
      return;
    }
    const image = {
      image: { base64: await fileToBase64(file), id: sketchId() },
    };
    sketchRef.current.setBackgroundImage(image, bgImageCallback);
  };
  const bgImageCallback = async (blob: string) => {
    if (!blob) return;
    const image = {
      image: { base64: adjustBase64ForSketch(blob), id: sketchId() },
    };
    await handleSendSketch(image);
  };

  const handleSelectBrushColor = (color: string) => {
    sketchRef.current?.setBrushColor(color);
  };

  const handleSelectBrushSize = (size: number) => {
    sketchRef.current?.setBrushSize(size);
  };

  const handleSelectBrush = () => {
    sketchRef.current?.setBrush();
  };

  const handleSelectUndo = async () => {
    if (!sketchRef.current) {
      return;
    }

    const undoInfo = { undo: { id: sketchId() } };
    sketchRef.current.undo(undoInfo);
    await handleSendSketch(undoInfo);
  };

  const handleSelectEraser = () => {
    sketchRef.current?.setEraser();
  };

  const handleClose = () => {
    end(
      isEmpty ? "" : sketchRef.current?.toBase64(SKETCH_EXPORT_IMAGE_TYPE) || ""
    );
  };

  const isReady = active && !ended;

  return (
    <DraggableOverlay
      isFullScreen={isFullScreen}
      contact={contact}
      onDoubleClick={() => {
        if (!isFullScreen) toggleFullScreen?.();
      }}
      onBackgroundDoubleClick={toggleFullScreen}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      open={true}
      component="sketch"
    >
      {/* Minimized view */}
      <div
        style={{
          position: "relative",
          display: isFullScreen ? "none" : "block",
        }}
      >
        <ToggleFullScreenOverlayButtonBox
          isFullScreen={isFullScreen}
          isHovered={isHovered}
          toggleFullScreen={toggleFullScreen}
          css={{
            position: "absolute",
            top: "0",
            right: "0",
            padding: "0.5em",
            zIndex: "2",
          }}
        />
        <div
          css={{
            borderRadius: "10px",
            boxShadow: "0px 4px 8px #00000029",
            width: "8em",
            height: "8em",
            backgroundColor: colors.primaryBackgroundLighter,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            gap: "0.5em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              backgroundColor: colors.secondaryTextColor,
              width: "60%",
              height: "60%",
              position: "relative",
            }}
          >
            <SketchIcon
              style={{
                width: "2.5em",
                height: "2.5em",
                color: colors.primaryTextColor,
              }}
            />
            <Avatar
              contact={contact}
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "1em",
                height: "1em",
                fontSize: "0.8em",
              }}
            />
          </div>
          <span style={{ color: colors.primaryTextColor, fontSize: "0.7em" }}>
            Sketch Session
          </span>
        </div>
      </div>
      {/* Expanded view */}
      <div
        style={{
          display: isFullScreen ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          css={{
            color: colors.primaryTextColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            justifyContent: "center",
          }}
        >
          <div
            css={{
              color: colors.primaryTextColor,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "2em",
              position: "relative",
              margin: "1em",
            }}
          >
            <ContactHeader contact={contact} />
            <div
              css={{
                color: colors.primaryTextColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "right",
                gap: "0.5em",
                position: "relative",
              }}
            >
              <ToggleFullScreenOverlayButtonBox
                isFullScreen={isFullScreen}
                isHovered={isHovered}
                toggleFullScreen={toggleFullScreen}
              />
              {isEmpty ? (
                <CloseOverlayButtonBox
                  isHovered={isHovered}
                  close={handleClose}
                />
              ) : (
                <SendSketchOverlayButtonBox
                  isHovered={isHovered}
                  close={handleClose}
                />
              )}
            </div>
          </div>

          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isReady && (
              <>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    opacity: "0.6",
                    zIndex: "2",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    width: "fit-content",
                    height: "fit-content",
                    padding: "3em",
                    display: "flex",
                    zIndex: "3",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    backgroundColor: colors.primaryBackgroundLighter,
                    fontSize: "0.9em",
                  }}
                >
                  {!active ? (
                    <>
                      Waiting for {contact.noNameReturnPhoneNumber()} to
                      respond.
                    </>
                  ) : (
                    <>Sketch session ended</>
                  )}
                </div>
              </>
            )}
            <Sketch
              onDraw={handleDraw}
              onBackgroundColorChange={handleSketchBackgroundColorChange}
              sketchDrawing={incomingSketchDrawing}
              sketchBackground={incomingSketchBackground}
              sketchUndo={incomingSketchUndo}
              sketchImage={incomingSketchImage}
              ref={sketchRef}
              options={sketchOptions}
              canDraw={isReady}
              onSketchChange={() => setIsEmpty(false)}
            />
          </div>

          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isReady && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: "0.6",
                  zIndex: "2",
                }}
              />
            )}
            <SketchButtonBar
              onSelectBackgroundColor={handleSelectBackgroundColor}
              onSelectBrush={handleSelectBrush}
              onSelectEraser={handleSelectEraser}
              onSelectUndo={handleSelectUndo}
              onSelectBrushColor={handleSelectBrushColor}
              onSelectBrushSize={handleSelectBrushSize}
              onSelectBackgroundImage={handleSelectBackgroundImage}
              backgroundColor={sketchCurrentBackgroundColor}
              options={sketchOptions}
            />
          </div>
        </div>
      </div>
    </DraggableOverlay>
  );
}

type ToggleFullScreenOverlayButtonProps = {
  isFullScreen: boolean;
  toggleFullScreen: VoidFunction;
};
function ToggleFullScreenOverlayButton({
  isFullScreen,
  toggleFullScreen,
}: ToggleFullScreenOverlayButtonProps) {
  return (
    <button
      onClick={toggleFullScreen}
      css={[
        buttonCommon,
        {
          color: colors.primaryTextColor,
          filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
          background: colors.secondaryBackground,
          ":hover": { background: colors.secondaryBackgroundLighter },
        },
      ]}
    >
      <ExpandMoreIcon
        css={
          isFullScreen
            ? { fontSize: "1.3em" }
            : {
                transform: "rotate(180deg)",
                width: "0.8em",
                height: "0.8em",
              }
        }
      />
    </button>
  );
}

export function ToggleFullScreenOverlayButtonBox({
  isFullScreen,
  isHovered,
  toggleFullScreen,
  ...props
}: ToggleFullScreenOverlayButtonProps & {
  isHovered: boolean;
} & React.ComponentProps<typeof motion.div>) {
  return (
    <motion.div
      {...(!isFullScreen && {
        initial: { y: "-100%" },
        animate: { y: isHovered ? 0 : "-100%" },
      })}
      {...props}
      css={[(props as any).css]}
    >
      <ToggleFullScreenOverlayButton
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
      />
    </motion.div>
  );
}

type CloseOverlayButtonProps = {
  close: VoidFunction;
};
function CloseOverlayButton({ close }: CloseOverlayButtonProps) {
  return (
    <button
      onClick={close}
      css={[
        buttonCommon,
        {
          color: colors.primaryTextColor,
          filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
          background: colors.secondaryBackground,
          ":hover": { background: colors.secondaryBackgroundLighter },
        },
      ]}
    >
      <CloseIcon style={{ fontSize: "1.3em" }} />
    </button>
  );
}

export function CloseOverlayButtonBox({
  isHovered,
  close,
  ...props
}: CloseOverlayButtonProps & { isHovered: boolean } & React.ComponentProps<
    typeof motion.div
  >) {
  return (
    <motion.div {...props} css={[(props as any).css]}>
      <CloseOverlayButton close={close} />
    </motion.div>
  );
}

function SendSketchOverlayButton({ close }: CloseOverlayButtonProps) {
  return (
    <button
      onClick={close}
      css={[
        buttonCommon,
        {
          color: colors.primaryTextColor,
          filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
          background: colors.secondaryBackground,
          ":hover": { background: colors.secondaryBackgroundLighter },
        },
      ]}
    >
      <ShareIcon style={{ fontSize: "1.3em" }} />
    </button>
  );
}

export function SendSketchOverlayButtonBox({
  isHovered,
  close,
  ...props
}: CloseOverlayButtonProps & { isHovered: boolean } & React.ComponentProps<
    typeof motion.div
  >) {
  return (
    <motion.div {...props} css={[(props as any).css]}>
      <SendSketchOverlayButton close={close} />
    </motion.div>
  );
}
