import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { css } from "@emotion/react";
import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToEventDetails } from "../helpers/EventListUtils";

type EventCardProps = {
  event: OdienceEvent;
};

const EventCard = ({ event }: EventCardProps) => {
  const navigate = useNavigate();

  const handleClickEventCard = async (selectedEvent: OdienceEvent) => {
    if (!isProvisioned()) {
      void navigate(
        navigateToEventDetails(
          paths.previewOdienceDetails,
          selectedEvent.organization_id,
          selectedEvent.id
        )
      );
    } else {
      void navigate(
        navigateToEventDetails(
          paths.details,
          selectedEvent.organization_id,
          selectedEvent.id
        )
      );
    }
  };

  const formatDate = (date: number) => {
    const eventDate = new Date(date * 1000);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const parts = new Intl.DateTimeFormat("en", options).formatToParts(
      eventDate
    );
    let formattedDay = "";
    let formattedMonth = "";
    let formattedYear = "";

    for (const part of parts) {
      if (part.type === "day") {
        formattedDay = part.value;
      } else if (part.type === "month") {
        formattedMonth = part.value;
      } else if (part.type === "year") {
        formattedYear = part.value;
      }
    }

    return { formattedDay, formattedMonth, formattedYear };
  };

  const { formattedDay, formattedMonth, formattedYear } = formatDate(
    event.date
  );

  return (
    <div
      key={event.id}
      onClick={() => handleClickEventCard(event)}
      css={eventCardStyles}
    >
      <div css={eventCardOverlay}>
        <div css={backgroundImageStyles}>
          <img
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.src = "/odience/event/empty_event_image.jpg";
            }}
            src={event.imageUrl}
            alt={event.name}
            css={imageStyles}
          />
        </div>
      </div>

      <div css={eventSummaryStyles}>
        <div css={dateContainerStyles}>
          <div>{formattedMonth}</div>
          <div css={dateDayStyles}>{formattedDay}</div>
          <div>{formattedYear}</div>
        </div>
        <div css={labelContainerStyles}>
          <div css={labelStyles(event.label)}>{event.label}</div>
          {event.is_5g && (
            <div css={connectivity5gStyles}>
              <img css={iconStyles} src="/odience/event/5G.svg" alt="5G" />
            </div>
          )}
        </div>
      </div>
      <div css={eventDescriptionStyles}>
        <div>
          {event.category.charAt(0).toLocaleUpperCase() +
            event.category.slice(1)}
        </div>
        <div css={eventNameStyles}>{event.name}</div>
        <div>
          <div>Hosted by {event.organization}</div>
        </div>
      </div>
      {(event.invitation_accepted || event.capacity > 0) && (
        <div css={invitationAndCapStyles(event.invitation_accepted)}>
          {event.invitation_accepted ? (
            <>
              You're Invited!
              <img src="/odience/web_client/invited.svg" alt="Invited" />
            </>
          ) : (
            <>CAP: {event.capacity}</>
          )}
        </div>
      )}
    </div>
  );
};

export default EventCard;

const eventCardStyles = css({
  position: "relative",
  backgroundColor: "#272727",
  color: colors.primaryTextColor,
  borderRadius: "10px",
  width: "21em",
  height: "21em",
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  padding: "1em",
  cursor: "pointer",
  overflow: "hidden",
});

const eventCardOverlayInnerCommon: CSSProperties = {
  content: '""',
  position: "absolute",
  width: "100%",
};

// Make the full card a bit more darker in case event card is light color, making card content text color visible (white)
const eventCardOverlay = css({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,

  "&::before": {
    ...eventCardOverlayInnerCommon,
    height: "40%",
    top: 0,
    background: "linear-gradient(to bottom, #0000006e 0%, transparent 100%)",
  },

  "&::after": {
    ...eventCardOverlayInnerCommon,
    height: "40%",
    bottom: 0,
    background: "linear-gradient(to bottom, transparent 0%, #0000006e 100%)",
  },
});

const backgroundImageStyles = css({
  objectFit: "cover",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: -1,
  backgroundImage: "linear-gradient(to top, #000000 0%, #6e6e6e 100%)",
});

const imageStyles = css({
  height: "100%",
  width: "100%",
  objectFit: "cover",
});

const eventSummaryStyles = css({
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
});

const eventDescriptionStyles = css({
  marginTop: "auto",
  position: "relative",
});

const dateContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  textTransform: "uppercase",
  fontWeight: "bold",
});

const dateDayStyles = css({
  fontSize: "3em",
  lineHeight: "0.8em",
});

const labelContainerStyles = css({
  display: "flex",
  alignItems: "center",
});
const labelStyles = (label?: string) =>
  css({
    display: "flex",
    justifyContent: "center",
    fontSize: "0.6em",
    fontWeight: "bold",
    letterSpacing: "0.1rem",
    height: "1em",
    padding: "2em",
    borderRadius: "3rem",
    whiteSpace: "nowrap",
    alignItems: "center",
    textTransform: "uppercase",
    transition: "all 0.6s ease-in-out",
    backgroundColor:
      label === "live"
        ? colors.primaryAccentColor
        : label === "upcoming"
          ? colors.primaryBackgroundLighter
          : "#882f3d",
  });

const connectivity5gStyles = css({
  position: "absolute",
  bottom: "-1em",
  right: "0",
});

const eventNameStyles = css({
  fontSize: "1.3em",
  fontWeight: "bold",
  lineHeight: "1.2em",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const iconStyles = css({
  width: "2em",
});

const invitationAndCapStyles = (forInvitation: boolean) =>
  css({
    position: "absolute",
    bottom: "0.5em",
    right: "0.5em",
    gap: "0.8em",
    alignItems: "center",
    display: "flex",
    height: "2.4em",
    borderRadius: "10px",
    fontSize: "0.8rem",
    justifyContent: "center",
    backgroundColor: forInvitation ? colors.primaryBackground : "transparent",
    whiteSpace: "nowrap",
    padding: "10px",
  });
