import {
  DeleteDropDownContainer,
  DropDownButton,
} from "@/components/keyPad.style";
import BodyPortal from "@/components/shared/BodyPortal";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import {
  deleteDropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "@/components/shared/Dropdown.style";
import Popover from "@/components/shared/Popover";
import Tooltip from "@/components/shared/ToolTipDescription";
import { colors } from "@/styles/global.styles";
import { ReactionList } from "@/types/OmaNms";
import { useMessageActions } from "@/utils/helpers/MessageActionsUtils";
import { atoms } from "@/utils/helpers/atoms";
import { useToast } from "@/utils/helpers/toastManager";
import NmsMessage from "@/utils/messaging/NmsMessage";
import { addOrRemoveEmoji } from "@/utils/messaging/addOrRemoveEmoji";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { formatFileSizeToHumanReadable } from "@/utils/messaging/conversation/conversationUtils";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { IconButton } from "@mui/material";
import { isAfter } from "date-fns/isAfter";
import { milliseconds } from "date-fns/milliseconds";
import { subMinutes } from "date-fns/subMinutes";
import { subSeconds } from "date-fns/subSeconds";
import { useSetAtom } from "jotai";
import { AnimatePresence, motion } from "motion/react";
import React, {
  ChangeEvent,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useOnClickOutside } from "usehooks-ts";
import EmojiPicker from "../../ChatFooter/EmojiPicker";
import { messageAreaRef } from "../messageAreaRef";
import { isOverflownX } from "../util/cardUtils";
import ReactionPicker from "./ReactionPicker";

function isWithinLastMinutes(date: Date, minutes: number) {
  return isAfter(date, subMinutes(new Date(), minutes));
}

function isWithinLastSeconds(date: Date, seconds: number) {
  return isAfter(date, subSeconds(new Date(), seconds));
}

export type MessageActionsApi = {
  handleMouseEnter: (
    event: React.MouseEvent<Element, MouseEvent>,
    actionsId: string
  ) => void;
  handleMouseLeave: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

export type MessageActionsProps = {
  message: NmsMessage;
  beforeDelete: (softDelete: boolean) => void | Promise<void>;
  direction: "In" | "Out";
  reactions?: ReactionList;
  conversation: Conversation;
  onShowMessageDetails: (
    conversation: Conversation,
    message: NmsMessage
  ) => void;
};

const recallTimeoutSeconds = 30;
const editTimeoutMins = 15;
const MAX_SIZE_CUSTOM_REACTION = 500 * 1024;
const MessageActions = ({
  ref,
  message,
  beforeDelete,
  reactions,
  conversation,
  onShowMessageDetails,
}: MessageActionsProps & {
  ref?: React.RefObject<MessageActionsApi | null>;
}) => {
  const { openActionsId, setOpenActionsId, toggleEmojiPicker, emojiPicker } =
    useMessageActions();
  const { showToast } = useToast();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [parentMessageId, setParentMessageId] = useState("");
  const messageForDropDownRef = useRef<HTMLDivElement>(null!);
  const setReply = useSetAtom(atoms.messaging.messageReply);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const emojiMenuButtonRef = useRef<HTMLButtonElement>(null);
  const participants = conversation.participants;
  const isChatbot = participants[0]?.isChatbot;
  const isOdienceFrontRow = participants?.[0]?.isOdienceFrontRow();
  const incomingMessage = message.Direction === "In";
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<
    "remote" | "local" | undefined
  >();
  useImperativeHandle(ref, () => ({
    handleMouseEnter,
    handleMouseLeave,
  }));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        messageForDropDownRef.current &&
        !messageForDropDownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (!emojiPicker) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as globalThis.Node) &&
        emojiMenuButtonRef.current &&
        !emojiMenuButtonRef.current.contains(event.target as globalThis.Node)
      ) {
        toggleEmojiPicker();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPicker]);

  useEffect(() => {
    if (!openActionsId && emojiPicker) {
      toggleEmojiPicker();
    }
  }, [emojiPicker, openActionsId]);

  const handleMouseEnter = (
    event: React.MouseEvent<Element, MouseEvent>,
    actionsId: string
  ) => setOpenActionsId(actionsId);

  const handleMouseLeave = () => {
    // If emoji picker is opened do not close the action menu
    if (emojiPicker || moreOptionsOpen) {
      return;
    }

    setOpenActionsId(null);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
    setOpenActionsId(null);
    toggleEmojiPicker();
  };

  const handleDeleteMessage = async () => {
    await beforeDelete(false);
    await conversation.deleteMessage(message.getOriginalMessageId());
  };
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    fileInputRef.current!.value = "";

    if (file.size > MAX_SIZE_CUSTOM_REACTION) {
      toast(
        `Custom reaction file size cannot be more than ${formatFileSizeToHumanReadable(
          MAX_SIZE_CUSTOM_REACTION
        )}`,
        {
          duration: 5000,
          position: "top-center",
          style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
        }
      );
      return;
    }

    void conversation?.sendFile(file, {
      id: parentMessageId,
      type: "+Custom-Reaction",
    });
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFilePickerClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    key: string
  ) => {
    setParentMessageId(key);
    setMoreOptionsOpen(false);
    fileInputRef.current!.click();
  };
  const handleShowMessageDetails = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    onShowMessageDetails(conversation, message);
  };
  const handleConfirmDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    isLocal = false
  ) => {
    event.stopPropagation();
    closeDropdown();
    setMoreOptionsOpen(false);
    setShowDeleteConfirmation(isLocal ? "local" : "remote");
  };

  const handleRemoveMessage = async () => {
    closeDropdown();
    const softDelete = !isWithinLastSeconds(message.Date, recallTimeoutSeconds);
    await beforeDelete(softDelete);
    const res = await (softDelete
      ? conversation?.softDeleteMessage(message.getOriginalMessageId())
      : conversation?.recallMessage(message.getOriginalMessageId()));
    showToast(
      res ? "Message was successfully unsent" : "Error un-sending message",
      "top-center",
      "1rem",
      undefined,
      undefined,
      res ? colors.primaryTextColor : colors.primaryAccentColorDarker
    );
  };

  const handleAddOrRemoveEmoji = (emoji: string) => {
    void addOrRemoveEmoji(
      emoji,
      message.getOriginalMessageId(),
      conversation,
      reactions
    );

    toggleEmojiPicker();
  };

  const moreOptionsRef = useRef<HTMLDivElement>(null);
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const toggleMoreOptions = () => {
    setMoreOptionsOpen((prev) => !prev);
  };

  useOnClickOutside(
    messageForDropDownRef,
    (e) => {
      if (emojiPickerRef.current?.contains(e.target as Node)) {
        return;
      }
      setOpenActionsId(null);
      setMoreOptionsOpen(false);
    },
    "mouseup"
  );

  const moreBtnRef = useRef<HTMLElement>(null);

  const [showEditButton, setShowEditButton] = useState(
    !message.deleted &&
      message.Direction === "Out" &&
      message["Content-Type"] === "text/plain" &&
      isWithinLastMinutes(message.Date, editTimeoutMins)
  );

  useEffect(() => {
    if (!showEditButton) return;

    // disable edit button once the editTimeoutMins are up
    const timeUntilEdit =
      milliseconds({ minutes: editTimeoutMins }) -
      (Date.now() - message.Date.getTime());
    const t = window.setTimeout(() => {
      setShowEditButton(false);
      setReply(null);
    }, timeUntilEdit);

    return () => {
      window.clearTimeout(t);
    };
  }, []);

  const isOpen = openActionsId !== null;

  useLayoutEffect(() => {
    if (!messageAreaRef) return;
    const elem = messageForDropDownRef.current;
    if (!elem) return;
    elem.classList.remove("overflown-x");
    const doesOverflow = isOverflownX(elem, messageAreaRef);
    elem.classList.toggle("overflown-x", doesOverflow);
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            ref={messageForDropDownRef}
            css={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "opacity 0.35s ease",
              height: "2em",
              zIndex: 1,
              position: "absolute",
              width: "fit-content",
              background: colors.primaryBackground,
              boxShadow: `0 0 8px 4px ${colors.primaryBackground}`,
              bottom: "0",
              ".In &": {
                left: "100%",
                "&.overflown-x": {
                  top: "100%",
                  right: "0",
                  bottom: "unset",
                  left: "unset",
                },
              },
              ".Out &": {
                right: "100%",
                "&.overflown-x": {
                  top: "100%",
                  right: "unset",
                  bottom: "unset",
                },
              },
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              css={{
                display: "flex",
                gap: "0.25em",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={onFileChange}
                style={{ display: "none" }}
              />
              {!isOdienceFrontRow && !isChatbot && !message.deleted && (
                <Tooltip content="React">
                  <IconButton
                    ref={emojiMenuButtonRef}
                    css={{
                      color: colors.secondaryTextColor,
                      "&:hover": { color: colors.primaryTextColor },
                    }}
                    onClick={() => {
                      setMoreOptionsOpen(false);
                      toggleEmojiPicker();
                    }}
                  >
                    <TagFacesIcon
                      css={{
                        color: emojiPicker
                          ? colors.primaryAccentColor
                          : colors.secondaryTextColor,
                        "&:hover, &:focus": {
                          color: colors.primaryAccentColor,
                        },
                        transition: "color 0.25s ease",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}

              {!isOdienceFrontRow && !isChatbot && !message.deleted && (
                <Tooltip content="Reply">
                  <IconButton
                    css={{
                      color: colors.secondaryTextColor,
                      "&:hover": { color: colors.primaryTextColor },
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setMoreOptionsOpen(false);
                      setReply({
                        id: message.getOriginalMessageId(),
                        message,
                        type: "Reply",
                      });
                    }}
                  >
                    <MessageOutlinedIcon
                      css={{
                        color: colors.secondaryTextColor,
                        "&:hover, &:focus": {
                          color: colors.primaryAccentColor,
                        },
                        transition: "color 0.25s ease",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip content="More" ref={moreBtnRef}>
                <IconButton
                  onClick={toggleMoreOptions}
                  css={{
                    color: colors.secondaryTextColor,
                    "&:hover": { color: colors.primaryTextColor },
                  }}
                >
                  <MoreVertIcon
                    css={{
                      color: colors.secondaryTextColor,
                      "&:hover, &:focus": {
                        color: colors.primaryAccentColor,
                      },
                      transition: "color 0.25s ease",
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Popover
                open={moreOptionsOpen}
                referenceElemRef={moreBtnRef}
                placements={
                  incomingMessage
                    ? ["top-start", "bottom-start"]
                    : ["top-end", "bottom-end"]
                }
              >
                <DeleteDropDownContainer
                  css={[
                    deleteDropdownContainer,
                    headerDropdown,
                    {
                      position: "relative",
                    },
                  ]}
                  ref={moreOptionsRef}
                >
                  {!isOdienceFrontRow && (
                    <>
                      <DropDownButton
                        css={footerDropdownItem}
                        onClick={(event) => {
                          handleConfirmDelete(event, true);
                        }}
                      >
                        <DeleteIcon /> Delete (locally)
                      </DropDownButton>
                      {!message.deleted && (
                        <DropDownButton
                          css={footerDropdownItem}
                          onClick={(event) => {
                            handleFilePickerClick(
                              event,
                              message.getOriginalMessageId()
                            );
                          }}
                        >
                          <AttachFileIcon /> Custom Reaction
                        </DropDownButton>
                      )}
                      {showEditButton && !message.deleted && (
                        <DropDownButton
                          css={footerDropdownItem}
                          onClick={() => {
                            setReply({
                              id: message.getOriginalMessageId(),
                              message,
                              type: "Edit",
                            });
                            setMoreOptionsOpen(false);
                          }}
                        >
                          <EditIcon /> Edit Message
                        </DropDownButton>
                      )}
                      {message.Direction === "Out" && !message.deleted && (
                        <DropDownButton
                          css={footerDropdownItem}
                          onClick={handleConfirmDelete}
                        >
                          <DeleteIcon /> Unsend
                        </DropDownButton>
                      )}
                    </>
                  )}
                  <DropDownButton
                    css={footerDropdownItem}
                    onClick={(event) => {
                      handleShowMessageDetails(event);
                      setMoreOptionsOpen(false);
                    }}
                  >
                    <InfoOutlinedIcon /> Details
                  </DropDownButton>
                </DeleteDropDownContainer>
              </Popover>
            </div>
          </motion.div>

          <Popover
            open={emojiPicker && !!openActionsId}
            referenceElemRef={emojiMenuButtonRef}
            placements={
              incomingMessage
                ? ["top-start", "bottom-start"]
                : ["top-end", "bottom-end"]
            }
            hideOnOverflow
            mountToBody
          >
            {emojiPicker && !!openActionsId && (
              <EmojiPickers
                emojiPickerRef={emojiPickerRef}
                handleAddOrRemoveEmoji={handleAddOrRemoveEmoji}
              />
            )}
          </Popover>
          <AnimatePresence>
            <BodyPortal>
              {showDeleteConfirmation && (
                <ConfirmationPopup
                  title={"Delete Message"}
                  confirmationMessage={
                    "Are you sure you want to delete this message?"
                  }
                  togglePopup={() => setShowDeleteConfirmation(undefined)}
                  handleAction={() => {
                    if (showDeleteConfirmation === "local") {
                      void handleDeleteMessage();
                    } else {
                      void handleRemoveMessage();
                    }
                    setShowDeleteConfirmation(undefined);
                  }}
                  primaryButtonText={"Delete"}
                />
              )}
            </BodyPortal>
          </AnimatePresence>
        </>
      )}
    </AnimatePresence>
  );
};

function EmojiPickers({
  emojiPickerRef,
  handleAddOrRemoveEmoji,
}: {
  emojiPickerRef: React.RefObject<HTMLDivElement | null>;
  handleAddOrRemoveEmoji: (emoji: string) => void;
}) {
  const [expandToAll, setExpandToAll] = useState(false);

  return expandToAll ? (
    <EmojiPicker
      ref={emojiPickerRef}
      handleEmojiClick={handleAddOrRemoveEmoji}
      visible
      uiMode="MESSAGE_REACTION"
    />
  ) : (
    <ReactionPicker
      ref={emojiPickerRef}
      handleEmojiClick={handleAddOrRemoveEmoji}
      expandToAll={() => setExpandToAll(true)}
    />
  );
}

export default MessageActions;
