import { colors } from "@/styles/global.styles";
import { proxy } from "valtio";
import { IConfiguration } from "./typings";

export const confState = proxy<IConfiguration>({
  /* 
  FOR EXAMPLE, THESE WILL BECOME:
  --main-color: ...;
  --msg-background: ...;
  --msg-background-darker: ...;
  --msg-text-color: ...;
  */
  style: {
    mainColor: colors.primaryAccentColor,
    msgBackground: colors.secondaryBackground,
    msgBackgroundDarker: colors.fourthBackground,
    msgTextColor: colors.primaryTextColor,
  },
  messageSeparateTimeMs: 5 * 60 * 1000,
});
