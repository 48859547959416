import { colors } from "@/styles/global.styles";
import { usePageTracking } from "@/utils/helpers/googleAnalytics";
import useNmsWebSocket, {
  useAllNmsNotifications,
} from "@/utils/helpers/nmsWebsocket";
import { useOnboardingNavigationAlert } from "@/utils/helpers/Utils";
import { useConfigPeriodicRefetch } from "@/utils/hooks/useConfigPeriodicRefetch";
import { useInitCalls } from "@/utils/hooks/useInitCalls";
import { useInitNmsMessages } from "@/utils/hooks/useInitNmsMessages";
import { useOdienceOrganization } from "@/utils/hooks/useOdienceOrganization";
import useWebgwSubscription, {
  useAllWebGwNotifications,
} from "@/utils/hooks/useWebgwSubscription";
import { useInitConversations } from "@/utils/messaging/conversation/useInitConversations";

import CallsIcon from "@/assets/syncingAnimation/Calls.svg";
import MessagesIcon from "@/assets/syncingAnimation/Messages.svg";
import VerseLogo from "@/assets/syncingAnimation/VerseSyncLogo.svg";
import Carousel from "@/components/shared/Carousel";
import { LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function VerseServices() {
  const { embeddedMode } = useOdienceOrganization();
  const location = useLocation();
  const odiencePath =
    location.pathname.includes("odience") ||
    location.pathname.includes("event");
  const settingsPath = location.pathname.includes("settings");

  const handleSyncData = !embeddedMode && !odiencePath;
  const isSynced = useInitNmsMessages(handleSyncData);

  const images = [CallsIcon, VerseLogo, MessagesIcon, VerseLogo];

  useWebgwSubscription(false);
  usePageTracking();
  useConfigPeriodicRefetch();
  useNmsWebSocket();
  useInitConversations();
  useInitCalls();
  useAllWebGwNotifications();
  useAllNmsNotifications();
  useOnboardingNavigationAlert();

  if (isSynced) return;

  return (
    <div
      css={{
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 100,
        backgroundColor: colors.tertiaryBackground,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2vh",
        visibility: odiencePath || settingsPath ? "hidden" : "visible",
      }}
    >
      <Carousel
        images={images}
        slideDuration={1000}
        visible={!odiencePath && !settingsPath}
      />
      <div css={{ width: "20vw" }}>
        <LinearProgress color="error" />
      </div>
      <div css={{ color: colors.primaryTextColor, fontSize: "1.5rem" }}>
        Syncing ...
      </div>
      <div css={{ color: colors.secondaryTextColor, fontSize: "1rem" }}>
        Call History and Messages
      </div>
    </div>
  );
}
