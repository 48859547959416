import DevicesIcon from "@/assets/DevicesIcon";
import BackToEvents from "@/assets/Exit.svg";
import OdienceLogoWithText from "@/assets/odience_logo_with_text";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { getLastEventId, getLastGroupId } from "@/utils/helpers/sessionStorage";
import { openOdienceDownloadAppWebPage } from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { useOdienceOrganization } from "../../../utils/hooks/useOdienceOrganization";

type Props = {
  participantCount?: number;
  isMainOdiencePage?: boolean;
  page: "eventDetails" | "eventList" | "stream";
};

const OdiencePreviewMobileHeader = ({
  participantCount,
  isMainOdiencePage,
  page,
}: Props) => {
  const navigate = useNavigate();
  const { organizationId, embeddedMode } = useOdienceOrganization();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleNavigateBack = () => {
    switch (page) {
      case "stream":
        if (getLastGroupId() && getLastEventId()) {
          navigate(
            generatePath(paths.previewOdienceDetails, {
              groupId: getLastGroupId(),
              eventId: getLastEventId(),
            })
          );
        } else {
          navigate(paths.previewOdience);
        }
        break;
      case "eventDetails":
        navigate(paths.previewOdience);
        break;
      case "eventList":
        navigate(paths.onboarding);
        break;
    }
  };

  console.log(participantCount);
  return (
    <div css={headerStyles}>
      {(!embeddedMode || !isMainOdiencePage) && (
        <IconButton css={iconButtonStyles} onClick={handleNavigateBack}>
          {currentPath === paths.previewOdience ? (
            <img css={backToEventsIconStyles} src={BackToEvents} />
          ) : (
            <ExpandMoreIcon css={expandIconStyles} />
          )}
        </IconButton>
      )}

      {(!embeddedMode || !organizationId) && (
        <div css={logoContainerStyles}>
          <div css={logoStyles}>
            <OdienceLogoWithText css={logoImageStyles} />
          </div>
        </div>
      )}

      <div css={rightSectionStyles}>
        {currentPath === paths.previewOdience ||
        currentPath.includes(`${paths.previewOdience}/event/details`) ? (
          <div css={buttonContainerStyles}>
            <button css={buttonStyles} onClick={openOdienceDownloadAppWebPage}>
              <DevicesIcon css={devicesIconStyles} />
              <div css={buttonTextStyles}>Get the App</div>
            </button>
          </div>
        ) : participantCount &&
          participantCount > 0 &&
          currentPath !== paths.previewOdience ? (
          <div css={participantCountStyles}>
            <PersonOutlineOutlinedIcon css={participantIconStyles} />
            {participantCount}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OdiencePreviewMobileHeader;

const headerStyles = css({
  backgroundColor: "#333333",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: colors.primaryTextColor,
  height: "4em",
  position: "fixed",
  width: "100%",
  zIndex: 9999,
  top: 0,
  gap: "1vw",
});

const iconButtonStyles = css({
  transform: "rotate(90deg)",
  color: colors.primaryTextColor,
  padding: "0em 1em",
});

const expandIconStyles = css({
  height: "1.25em",
  width: "1.25em",
});

const backToEventsIconStyles = css({
  height: "0.8em",
  width: "0.8em",
  transform: "rotate(270deg)",
});

const logoContainerStyles = css({});

const logoStyles = css({
  width: "40vw",
  fontSize: "1.25rem",
  gap: "2vw",
  letterSpacing: "5px",
  marginLeft: "-1em",
});

const logoImageStyles = css({
  height: "1.2em",
});

const participantCountStyles = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1rem",
  marginRight: "1.75em",
});

const participantIconStyles = css({
  height: "1em",
  width: "1em",
});

const buttonContainerStyles = css({
  padding: "0 0.5em",
});

const buttonStyles = css({
  border: "1px solid #525252",
  borderRadius: "2em",
  width: "8em",
  backgroundColor: "#525252",
  height: "3em",
  color: colors.primaryTextColor,
  boxShadow: "none",
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  marginRight: "1em",
  fontSize: "0.7rem",
});

const devicesIconStyles = css({
  height: "2em",
  width: "2em",
  marginLeft: "1em",
  marginRight: "1em",
});

const buttonTextStyles = css({
  alignItems: "start",
  minWidth: "5vh",
});

const rightSectionStyles = css({
  position: "absolute",
  right: 0,
  fontSize: "1rem",
});
