import { useEffect } from "react";

type UseParentIframeUrlOptions = {
  initialUrl?: string;
  embeddedMode: boolean;
};

const useParentIframeUrl = ({
  initialUrl,
  embeddedMode,
}: UseParentIframeUrlOptions) => {
  //Target origins for iframe communication, this is a list of domains that are allowed to communicate with the iframe.
  //However since a user can be on any of these domains, to send a message we would need to do a for each to each domain since we can't access window.parent.orign to find out.
  //and since this data we are sending is not sensitive, we can just send it to all domains using the *.

  // const iframeOrigins = [
  //   "https://www.odience.com",
  //   "https://odience.com",
  //   "https://www.odience.ca",
  //   "https://odience.ca",
  // ];

  const updateParentIframeUrl = (newUrl: string) => {
    console.log("updateParentIframeUrl", newUrl);
    window.parent.postMessage(
      { type: "UPDATE_IFRAME_URL", url: newUrl },
      "*" // targetOrigin
    );
  };

  useEffect(() => {
    if (embeddedMode) {
      updateParentIframeUrl(initialUrl || window.location.href);
    }
  }, [embeddedMode, initialUrl]);

  return updateParentIframeUrl;
};

export default useParentIframeUrl;
