import sanitizeHtml from "sanitize-html";
import { TRANSPARENT_GIF } from "../helpers/emoji";

const sanitizeMessagePreviewOptions = {
  allowedTags: ["span", "img"],
  allowedAttributes: {
    img: ["src", "alt", "style", "class", "data-emoji-native"],
    span: ["style", "class", "data-emoji-native"],
  },
  allowedSchemesByTag: {
    img: ["data"],
  },
  transformTags: {
    img: (tagName, attribs) => {
      let parsedUrl: URL | null = null;
      try {
        parsedUrl = new URL(attribs.src);
      } catch {
        /* empty */
      }

      if (!parsedUrl || attribs.src !== TRANSPARENT_GIF) {
        delete attribs.src;
      }

      return {
        tagName,
        attribs,
      };
    },
  },
} as const satisfies sanitizeHtml.IOptions;

export function sanitizeMessagePreview(text: string) {
  return sanitizeHtml(text, sanitizeMessagePreviewOptions);
}

const sanitizeMessageOptions = {
  ...sanitizeMessagePreviewOptions,
  allowedTags: [
    ...sanitizeHtml.defaults.allowedTags,
    ...sanitizeMessagePreviewOptions.allowedTags,
  ],
  disallowedTagsMode: "recursiveEscape",
} as const satisfies sanitizeHtml.IOptions;

export function sanitizeMessage(text: string) {
  return sanitizeHtml(text, sanitizeMessageOptions);
}
