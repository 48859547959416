import { atoms } from "@/utils/helpers/atoms";
import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";

type SliderProps = {
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
};

const Slider = ({
  min = 0,
  max = 100,
  step = 1,
  initialValue = 0,
}: SliderProps) => {
  const [value, setValue] = useState<number>(initialValue);
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [streamVolume, setStreamVolume] = useAtom(atoms.odience.streamVolume);
  const isFeaturedCaller = useAtomValue(atoms.odience.featuredCaller);

  useEffect(() => {
    if (isFeaturedCaller) {
      setIsDragging(false);
      setValue(0);
    } else setValue(streamVolume);
  }, [streamVolume, isFeaturedCaller]);

  const calculateValue = (clientX: number) => {
    if (sliderRef.current) {
      const { left, width } = sliderRef.current.getBoundingClientRect();
      const relativeX = clientX - left;
      let newValue = (relativeX / width) * (max - min) + min;
      newValue = Math.round(newValue / step) * step;
      return Math.min(Math.max(newValue, min), max);
    }

    return value;
  };

  const handleMouseMove = (event: MouseEvent) => {
    const newValue = calculateValue(event.clientX);
    setValue(newValue);
    setStreamVolume(newValue);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging && !isFeaturedCaller) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const ballPosition = ((value - min) / (max - min)) * 100;

  return (
    <div
      css={sliderContainerStyle}
      ref={sliderRef}
      onMouseDownCapture={handleMouseDown}
    >
      <div css={sliderTrackStyle}>
        <div css={sliderFilledStyle(ballPosition)}></div>
        <div css={sliderOverlayStyle(100 - ballPosition)}></div>
      </div>
      <div css={sliderBallStyle(ballPosition)} />
    </div>
  );
};

export default Slider;

const sliderContainerStyle = css({
  position: "relative",
  width: "50px",
  height: "5px",
  cursor: "pointer",
});

const sliderTrackStyle = css({
  position: "absolute",
  width: "100%",
  height: "5px",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  borderRadius: "5px",
});

const sliderFilledStyle = (width: number) =>
  css({
    position: "absolute",
    height: "100%",
    backgroundColor: "white",
    borderRadius: "5px 0 0 5px",
    width: `${width}%`,
    zIndex: 1,
  });

const sliderOverlayStyle = (width: number) =>
  css({
    position: "absolute",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "0 5px 5px 0",
    right: 0,
    width: `${width}%`,
  });

const sliderBallStyle = (leftPosition: number) =>
  css({
    position: "absolute",
    top: "-5px",
    width: "1rem",
    height: "1rem",
    backgroundColor: "white",
    borderRadius: "50%",
    zIndex: 2,
    cursor: "pointer",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.1s ease",
    left: `calc(${leftPosition}% - 10px)`,
    "&:active": {
      transform: "scale(1.2)",
    },
  });
