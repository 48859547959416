import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import AddEmailModal from "@/pages/profile/components/AddEmailModal";
import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { checkIfUserHasInvitedEmail } from "@/pages/profile/utils/ProfileScreenUtils";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { CategoryKey } from "@/types/odience";
import { isMobile } from "@/utils/helpers/Utils";
import { atoms } from "@/utils/helpers/atoms";
import {
  getLocalOdienceUser,
  getOdienceSelectedCategory,
  setOdienceSelectedCategory,
} from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import {
  getEmail,
  getInvite,
  getInvitedEventId,
  removeInvitedEventId,
} from "@/utils/helpers/sessionStorage";
import useParentIframeUrl from "@/utils/hooks/useUpdateIframeParent";
import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_AVATAR } from "../../../../utils/hooks/useDirectorAuthentication";
import { useOdienceEvents } from "../../../../utils/hooks/useOdienceEvents";
import GetAppModal from "../../components/GetAppModal";
import LoadingAnimation from "../../components/LoadingAnimation";
import OdiencePreviewMobileHeader from "../../components/OdiencePreviewMobileHeader";
import EventCard from "./components/EventCard";
import EventCategories from "./components/EventCategories";
import EventListHeader from "./components/EventListHeader";
import { navigateToEventDetails } from "./helpers/EventListUtils";

type EventListProps = {
  odienceOnly: boolean;
};

const EventList = ({ odienceOnly }: EventListProps) => {
  const isChecked = useAtomValue(atoms.odience.webOnlyEvents);
  const [getAppModalOpen, setGetAppModalOpen] = useState(false);
  const { openModal, userAvatar, updateUserAvatar } = useProfileModal();

  const [odienceAvatar, setOdienceAvatar] = useState<string>(
    userAvatar || DEFAULT_AVATAR
  );
  const [selectedCategory, setSelectedCategory] = useState(
    getOdienceSelectedCategory()
  );
  const [showPopup, setShowPopup] = useAtom(atoms.odience.privateEventPopup);

  const toggleGetAppModal = () => setGetAppModalOpen(!getAppModalOpen);
  const handleOdienceAvatarError = () => setOdienceAvatar(DEFAULT_AVATAR);

  const navigate = useNavigate();
  const invitedEventId = getInvitedEventId();
  const inviteId = getInvite();

  const { objEvents, eventsPending, embeddedMode } = useOdienceEvents({
    odienceOnly,
    updateUserAvatar,
  });
  const odienceUser = getLocalOdienceUser();

  const [showAddEmailPopup, setShowAddEmailPopup] = useState(false);

  const updateIframeUrl = useParentIframeUrl({
    initialUrl: window.location.href,
    embeddedMode,
    // targetOrigin: "https://www.odience.com",
  });

  const userNotProvisioned = !isProvisioned();
  const showLoginPopup =
    (showPopup && embeddedMode && userNotProvisioned) ||
    ((getInvite() || getEmail()) && userNotProvisioned);

  const toggleEmailPopup = () => {
    setShowAddEmailPopup(!showAddEmailPopup);
  };

  useEffect(() => {
    if (inviteId && isProvisioned() && odienceUser) {
      checkIfUserHasInvitedEmail(inviteId);
    }
  }, [inviteId, odienceUser]);

  useEffect(() => {
    if (isProvisioned() && getInvite()) {
      {
        setShowAddEmailPopup(true);
      }
    }
  }, []);

  useEffect(() => {
    updateIframeUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (!invitedEventId || !objEvents) {
      return;
    }
    const matchingEvent = objEvents.find(
      (event) => event.id === invitedEventId
    );

    if (matchingEvent) {
      const organizationId = matchingEvent.organization_id;
      navigate(
        navigateToEventDetails(
          paths.previewOdienceDetails,
          organizationId,
          invitedEventId
        )
      );
      removeInvitedEventId();
    }
  }, [objEvents, invitedEventId]);

  useEffect(() => {
    setOdienceAvatar(userAvatar);
  }, [userAvatar]);

  const odienceDetail = `
    Experience immersive 360 shopping, concerts and more. Interact with the event host,
    video call a friend, explore the space, or video call a friend and share the experience.
    Available for mobile devices, tablets, desktops, and VR glasses.
  `;

  const renderEvents = () => {
    return objEvents
      ?.filter((event) => {
        if ((embeddedMode || isChecked) && event.web_allowed === 0)
          return false;
        if (event.label === "ended") return false;
        if (selectedCategory !== "all") {
          if (selectedCategory === "invited") return event.invitation_accepted;
          return event.category === selectedCategory;
        }
        return true;
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .map((event) => <EventCard key={event.id} event={event} />);
  };

  if (eventsPending) {
    return (
      <div css={{ width: "100vw", height: "100vh" }}>
        <LoadingAnimation />
      </div>
    );
  }
  const eventsAvailable = renderEvents().length > 0;

  const handleOnSelectedCategory = (selectedCategory: CategoryKey) => {
    setSelectedCategory(selectedCategory);
    setOdienceSelectedCategory(selectedCategory);
  };

  return (
    <>
      {isMobile() && (
        <OdiencePreviewMobileHeader
          page={"eventList"}
          isMainOdiencePage={true}
        />
      )}
      <div
        css={[
          containerStyle,
          {
            height:
              embeddedMode && isProvisioned()
                ? "100vh"
                : !isProvisioned() || embeddedMode
                  ? "100vh"
                  : "90vh",
          },
        ]}
      >
        {!isMobile() && (
          <EventListHeader
            handleGetAppModal={toggleGetAppModal}
            odienceAvatar={odienceAvatar}
            handleOdienceAvatarError={handleOdienceAvatarError}
            handleOpenEditProfile={openModal}
          />
        )}
        {!embeddedMode && <div css={headerStyle}>{odienceDetail}</div>}
        {!embeddedMode && (
          <EventCategories
            events={objEvents}
            onSelectedCategory={handleOnSelectedCategory}
            selectedCategory={selectedCategory}
          />
        )}
        {showLoginPopup ? (
          <div css={eventListStyle}>
            <ConfirmationPopup
              title="Event Notification"
              confirmationMessage="A log in is required to access this event"
              handleAction={() => {
                setShowPopup(false);
                navigate(paths.provisioningFromOdience);
              }}
              primaryButtonText="Log in"
              togglePopup={() => setShowPopup(false)}
              closeButtonActive={false}
              secondaryButtonActive={false}
            />
          </div>
        ) : eventsAvailable ? (
          <div css={eventListStyle}>{renderEvents()}</div>
        ) : userNotProvisioned ? (
          <div css={noEventsAvailableStyle}>
            Log in to view the full list of events
          </div>
        ) : (
          <div css={noEventsAvailableStyle}>No Events Available</div>
        )}
        {getAppModalOpen && (
          <GetAppModal
            show={getAppModalOpen}
            onCloseModal={toggleGetAppModal}
            objEvent={null}
          />
        )}
        {showAddEmailPopup && <AddEmailModal togglePopup={toggleEmailPopup} />}
      </div>
    </>
  );
};

export default EventList;

const containerStyle = css({
  padding: isMobile() ? "5em 0em" : "3em 2em",
  color: colors.primaryTextColor,
  backgroundColor: "rgb(26, 32, 38)",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile() ? "center" : "unset",
  overflowY: "auto",
  fontFamily: "Figtree, sans-serif",
  borderRadius: "16px",
  width: "100%",
  "::-webkit-scrollbar": {
    width: "6px",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: "#1c1c1c",
    borderRadius: "6px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#2e3237",
    borderRadius: "6px",
  },
});

const headerStyle = css({
  color: colors.primaryTextColor,
  marginTop: "2.75em",
  opacity: "0.5",
  marginBottom: "3rem",
  width: isMobile() ? "90%" : "80%",
  display: "flex",
  justifyContent: "center",
  fontSize: isMobile() ? "0.9rem" : "1.125rem",
});

const eventListStyle = css({
  display: "flex",
  gap: "1.5em",
  flexWrap: "wrap",
  marginTop: "1.5em",
  width: "100%",
  justifyContent: isMobile() ? "center" : undefined,
});

const noEventsAvailableStyle = css({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  fontSize: "1.75rem",
  paddingTop: "2em",
});
