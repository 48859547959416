import { css, keyframes } from "@emotion/react";
import { useEffect, useState } from "react";

type CarouselProps = {
  images: string[];
  slideDuration: number;
  visible?: boolean;
};

const Carousel = ({ images, slideDuration, visible }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [exitingIndex, setExitingIndex] = useState<number | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setExitingIndex(currentIndex);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, slideDuration * 2); // Wait for the slide-out and slide-in animation to complete

    return () => clearInterval(interval);
  }, [currentIndex, images.length, slideDuration]);

  return (
    <div css={carouselContainerStyle}>
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Slide ${index + 1}`}
          css={imageStyle(
            index === currentIndex,
            index === exitingIndex,
            slideDuration,
            visible
          )}
        />
      ))}
    </div>
  );
};

export default Carousel;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const carouselContainerStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 1em;
  width: 20em;
  height: 20em;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 10%,
    black 90%,
    transparent
  );
`;

const imageStyle = (
  isActive: boolean,
  isExiting: boolean,
  slideDuration: number,
  visible?: boolean
) => css`
  position: absolute;
  top: 50;
  left: 50;
  object-fit: cover;
  animation: ${isActive ? slideIn : isExiting ? slideOut : undefined}
    ${slideDuration}ms ease-in-out forwards;
  opacity: ${isActive || isExiting ? 1 : 0};
  visibility: ${(isActive || isExiting) && visible ? "visible" : "hidden"};
  z-index: ${isActive ? 2 : isExiting ? 1 : 0};
  padding: 2em 5em;
`;
