import { colors } from "@/styles/global.styles";
import { OdienceUser } from "@/utils/hooks/useDirectorAuthentication";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

type EmailListProps = {
  authenticatedUser: OdienceUser;
  isDropdownOpen: boolean;
  primaryEmail: string;
  toggleRemoveEmail: (email: string) => void;
};

const EmailList = ({
  authenticatedUser,
  isDropdownOpen,
  primaryEmail,
  toggleRemoveEmail,
}: EmailListProps) => {
  if (!authenticatedUser.emails) return null;

  return (
    isDropdownOpen && (
      <div>
        {authenticatedUser.emails.length > 0 ? (
          authenticatedUser.emails.map((email, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
                width: "100%",
                color: colors.secondaryTextColor,
              }}
            >
              <div>{email}</div>
              <div>
                {email === primaryEmail && (
                  <span css={{ color: colors.secondaryAccentColor }}>
                    Primary Email
                  </span>
                )}
                <IconButton
                  css={{
                    color: colors.primaryAccentColor,
                    ":disabled": {
                      color: colors.secondaryTextColor,
                      pointerEvents: "none",
                    },
                  }}
                  onClick={() => toggleRemoveEmail(email)}
                  disabled={email === primaryEmail}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))
        ) : (
          <p>No emails available</p>
        )}
      </div>
    )
  );
};

export default EmailList;
