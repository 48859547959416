export const categories = {
  all: "All Events",
  activities: "Activities",
  bingo: "Bingo",
  collaboration: "Collaboration",
  concert: "Concert",
  conference: "Conference",
  entertainment: "Entertainment",
  gaming: "eSports",
  location: "Location",
  personal: "Personal",
  shopping: "Shopping",
  simulation: "Simulation",
  sport: "Sporting Event",
  invited: "My Invitations",
} as const;

export const categoryImages = {
  all: "/odience/director/menu/menu-event.svg",
  activities: "/odience/tags/activities.svg",
  bingo: "/odience/tags/bingo.svg",
  collaboration: "/odience/tags/collaboration.svg",
  concert: "/odience/tags/concert.svg",
  conference: "/odience/tags/conference.svg",
  entertainment: "/odience/tags/entertainment.svg",
  gaming: "/odience/tags/gaming.svg",
  location: "/odience/tags/location.svg",
  personal: "/odience/tags/personal.svg",
  shopping: "/odience/tags/shopping.svg",
  simulation: "/odience/tags/simulation.svg",
  sport: "/odience/tags/sport.svg",
  invited: "/odience/director/login/email.svg",
} as const;
