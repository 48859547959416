import { useEffect } from "react";
import { checkAndRefetchConfig } from "../helpers/config";
import { queryConfigStaleTimeMs } from "../queries/config";

export function useConfigPeriodicRefetch() {
  useEffect(() => {
    const intervalId = setInterval(
      checkAndRefetchConfig,
      queryConfigStaleTimeMs
    );

    return () => clearInterval(intervalId);
  }, []);
}
