import { noop } from "@/utils";
import { getLocalUser } from "@/utils/helpers/localstorage";
import { useBlock } from "@/utils/hooks/useBlock";
import { useCall } from "@/utils/hooks/useCall";
import { useContacts } from "@/utils/hooks/useContacts";
import { useSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { keyframes } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import PhoneIcon from "@mui/icons-material/Phone";
import VideoIcon from "@mui/icons-material/Videocam";
import { grey } from "@mui/material/colors";
import Color from "color";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";
import {
  Avatar,
  AvatarBackground,
  AvatarWithFallback,
  DefaultGroupChatAvatar,
} from "../shared/Avatar";
import { IconButton } from "../shared/Button";

type ChatHeaderProps = {
  openChatInfo?: () => void;
  closeChat?: () => void;
};

export const headerHeight = "5em";

export default function ChatHeader({
  openChatInfo,
  closeChat,
}: ChatHeaderProps) {
  const savedContacts = useContacts();
  const conversation = useSelectedConversation();
  const isGroupChat = !!conversation?.getIsGroupChat();
  const participants = conversation?.participants;
  const isChatbot = participants?.[0]?.isChatbot;
  const isOdienceFrontRow = participants?.[0]?.isOdienceFrontRow();
  const { callWithAudio, callWithVideo, canAcceptOrMakeCall } = useCall();
  const block = useBlock(participants?.[0]);
  const localUserPhoneNumber = getLocalUser();

  const selectedContact = participants?.[0];
  const participant = !isGroupChat
    ? (savedContacts?.find((pContact) => pContact.id === selectedContact?.id) ??
      selectedContact)
    : undefined;

  const contacts = isGroupChat
    ? participants
    : participant
      ? [participant]
      : [];

  if (!conversation || !contacts) return null;

  const isMyself = isSamePhoneNumber(
    localUserPhoneNumber,
    contacts.at(0)?.getMainPhoneNumber()
  );

  return (
    <>
      <div
        css={{
          width: "100%",
          minHeight: headerHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            padding: "0.5em 2em",
          }}
        >
          <ConversationInnerHeader
            contacts={contacts}
            isGroupChat={isGroupChat}
            subject={conversation.getName()}
            iconUrl={conversation.getIconUrl()}
          />
          <div
            css={{
              display: "flex",
              alignItems: "center",
              gap: "0.25em",
            }}
          >
            {!isOdienceFrontRow && !isChatbot && !isGroupChat && (
              <>
                {!isMyself && block.value === "Block" && canAcceptOrMakeCall ? (
                  <IconButton onClick={() => callWithAudio(conversation.id)}>
                    <PhoneIcon />
                  </IconButton>
                ) : (
                  <IconButton disabled={true} onClick={noop}>
                    <PhoneIcon style={{ color: grey[500] }} />
                  </IconButton>
                )}
                {!isMyself && block.value === "Block" && canAcceptOrMakeCall ? (
                  <IconButton onClick={() => callWithVideo(conversation.id)}>
                    <VideoIcon />
                  </IconButton>
                ) : (
                  <IconButton disabled={true} onClick={noop}>
                    <VideoIcon style={{ color: grey[500] }} />
                  </IconButton>
                )}
              </>
            )}
            {!isOdienceFrontRow && openChatInfo && (
              <IconButton onClick={openChatInfo}>
                <InfoIcon />
              </IconButton>
            )}

            {closeChat && (
              <IconButton
                onClick={closeChat}
                css={{ color: colors.primaryTextColor }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <hr css={{ height: "1px", margin: "0", zIndex: "1" }} />
      </div>
    </>
  );
}

function ConversationInnerHeader({
  contacts,
  isGroupChat,
  subject,
  iconUrl,
}: {
  contacts: WebGwContact[];
  isGroupChat: boolean;
  subject?: string;
  iconUrl?: string;
}) {
  if (contacts.length === 0) {
    console.error("No contacts provided");
    return;
  }

  if (isGroupChat) {
    return (
      <GroupChatInnerInnerHeader
        contacts={contacts}
        subject={subject}
        iconUrl={iconUrl}
      />
    );
  } else {
    return <SingleContactInnerHeader contact={contacts[0]} />;
  }
}

function GroupChatInnerInnerHeader({
  subject,
  iconUrl,
}: {
  contacts: WebGwContact[];
  subject?: string;
  iconUrl?: string;
}) {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "1em",
      }}
    >
      {iconUrl ? (
        <AvatarBackground>
          <AvatarWithFallback src={iconUrl} />
        </AvatarBackground>
      ) : (
        <DefaultGroupChatAvatar />
      )}
      <h3
        css={{
          fontWeight: "600",
          margin: "1em 0",
          whiteSpace: "nowrap",
        }}
      >
        {subject || "Group Chat"}
      </h3>
    </div>
  );
}

function SingleContactInnerHeader({
  contact,
  skeleton,
}:
  | {
      contact: WebGwContact;
      skeleton?: never;
    }
  | {
      contact?: never;
      skeleton: boolean;
    }) {
  if (skeleton) {
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <AvatarBackground />
        <h3
          css={{
            fontWeight: "600",
            margin: "1em 0",
            whiteSpace: "nowrap",
            color: new Color(colors.secondaryTextColor).lighten(0.5).toString(),
            animation: `${keyframes({
              "50%": { opacity: 0.5 },
            })} 1.5s ease infinite`,
          }}
        >
          Loading...
        </h3>
      </div>
    );
  }

  if (!contact) return null;

  return <SingleContactInnerInnerHeader contact={contact} />;
}

function SingleContactInnerInnerHeader({ contact }: { contact: WebGwContact }) {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <Avatar contact={contact} />
      <h3
        css={{
          fontWeight: "600",
          whiteSpace: "nowrap",
          textWrap: "wrap",
          margin: "1em 0",
        }}
      >
        {contact.noNameReturnPhoneNumber()}
      </h3>
    </div>
  );
}
