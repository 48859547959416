import OdienceLogoNavWhite from "@/assets/OdienceLogoNavWhite";
import { avatar } from "@/components/contacts/ContactInfo.style";
import { DefaultAvatar } from "@/components/shared/Avatar";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import SignOutPopup from "@/components/shared/SignOutPopup";
import SvgGraphic from "@/components/shared/SvgGraphic";
import { colors } from "@/styles/global.styles";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { logoutEvent } from "@/utils/helpers/removeDevice";
import {
  OdienceUser,
  authenticateDirector,
} from "@/utils/hooks/useDirectorAuthentication";
import BlockIcon from "@mui/icons-material/Block";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { AnimatePresence } from "motion/react";
import { Fragment, useEffect, useState } from "react";
import SectionHeader from "../../components/shared/SectionHeader";
import { getDeviceName, getLocalUser } from "../../utils/helpers/localstorage";
import { Box } from "../layout/Layout.style";
import { useProfileModal } from "../profile/utils/ProfileModalContext";
import AboutPage from "./AboutPage";
import BlockedList from "./BlockedList";
import DeviceManagement from "./DeviceManagement";
import {
  DividerLine,
  DividerSpace,
  SettingTitles,
} from "./SettingsScreen.style";

const SettingsScreen = () => {
  const { openModal } = useProfileModal();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [deleteAcountOverlay, setDeleteAccountOverlay] = useState(false);
  const [settingsOptionPage, setSettingsOptionPage] = useState<
    "Default" | "Device Management" | "Blocked Contact List" | "About"
  >("Default");
  const [isOdienceAuthenticated, setIsOdienceAuthenticated] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const authenticatedUser = (await authenticateDirector()) as OdienceUser;
      if (authenticatedUser?.name) {
        setIsOdienceAuthenticated(true);
      }
    };
    fetchUser();
  }, [isOdienceAuthenticated]);

  const toggleDeleteAccountOverlay = () => {
    setDeleteAccountOverlay(!deleteAcountOverlay);
  };

  const toggleOverlay = () => {
    setOverlayOpen(!overlayOpen);
  };

  const user = getLocalUser();
  const deviceName = getDeviceName();

  if (!user || !deviceName) return;

  const settingsGeneralOptions = [
    {
      logo: <DevicesOutlinedIcon />,
      title: "Device management",
      featureAvailable: true,
      onClick: () => setSettingsOptionPage("Device Management"),
    },
    {
      logo: <BlockIcon />,
      title: "Blocked Contact List",
      featureAvailable: true,
      onClick: () => setSettingsOptionPage("Blocked Contact List"),
    },
    {
      logo: <InfoOutlinedIcon />,
      title: "About",
      featureAvailable: true,
      onClick: () => setSettingsOptionPage("About"),
    },
    {
      logo: <OdienceLogoNavWhite />,
      title: "Edit Odience Profile",
      featureAvailable: isOdienceAuthenticated,
      onClick: () => openModal(),
    },
    {
      logo: <RemoveCircleOutlineOutlinedIcon />,
      title: "Do not disturb",
      featureAvailable: false,
      onClick: undefined,
    },
    {
      logo: <BrushOutlinedIcon />,
      title: "App theme",
      featureAvailable: false,
      onClick: undefined,
    },
    {
      logo: <LockOutlinedIcon />,
      title: "Security",
      featureAvailable: false,
      onClick: undefined,
    },
  ];

  const settingsChatOptions = [
    {
      logo: <MessageOutlinedIcon />,
      title: "Chat settings",
      featureAvailable: false,
      onClick: undefined,
    },
  ];

  const settingsAccountOptions = [
    {
      logo: <LogoutOutlinedIcon />,
      title: "Sign out",
      featureAvailable: true,
      onClick: toggleOverlay,
    },
    {
      logo: <DeleteOutlineOutlinedIcon />,
      title: "Delete account",
      featureAvailable: true,
      onClick: toggleDeleteAccountOverlay,
    },
  ];

  const SettingsGeneralCard = settingsGeneralOptions.map((setting) => {
    return (
      <Fragment key={setting.title}>
        <DividerSpace />
        <div
          key={setting.title}
          css={{
            display: setting.featureAvailable ? "flex" : "none",
            flexDirection: "row",
            minHeight: "5vh",
            alignItems: "center",
            color: setting.featureAvailable
              ? colors.primaryTextColor
              : colors.secondaryTextColor,
            cursor: setting.featureAvailable ? "pointer" : "default",
            "&:hover, &:focus": {
              backgroundColor: colors.secondaryBackground,
            },
            fontSize: "1.125em",
            transition: "color 0.25s ease",
            borderRadius: "10px",
            marginRight: "1.5em",
            padding: "0.5em 1em",
          }}
          onClick={setting.onClick}
        >
          <div
            style={{
              display: "flex",
              width: "10%",
              position: "relative",
              opacity: 1,
            }}
          >
            {setting.logo}
          </div>
          <div style={{ width: "80%" }}>
            <div>{setting.title}</div>
          </div>
          <div style={{ width: "10%", textAlign: "right" }}>
            <ExpandMoreIcon css={{ transform: "rotate(270deg)" }} />
          </div>
        </div>
      </Fragment>
    );
  });

  const SettingsChatCard = settingsChatOptions.map((setting) => {
    return (
      <Fragment key={setting.title}>
        <DividerSpace />
        <div
          key={setting.title}
          css={{
            display: setting.featureAvailable ? "flex" : "none",
            flexDirection: "row",
            minHeight: "5vh",
            alignItems: "center",
            color: setting.featureAvailable
              ? colors.primaryTextColor
              : colors.secondaryTextColor,
            cursor: setting.featureAvailable ? "pointer" : "default",
            "&:hover, &:focus": {
              backgroundColor: colors.secondaryBackground,
            },
            fontSize: "1.125em",
            transition: "color 0.25s ease",
            borderRadius: "10px",
            marginRight: "1.5em",
            padding: "0.5em 1em",
          }}
          onClick={setting.onClick}
        >
          <div style={{ display: "flex", width: "10%" }}>{setting.logo}</div>
          <div style={{ width: "80%" }}>
            <div>{setting.title}</div>
          </div>
          <div style={{ width: "10%", textAlign: "right" }}>
            <ExpandMoreIcon css={{ transform: "rotate(270deg)" }} />
          </div>
        </div>
      </Fragment>
    );
  });

  const SettingsAccountCard = settingsAccountOptions.map((setting) => {
    return (
      <Fragment key={setting.title}>
        <DividerSpace />
        <div
          key={setting.title}
          css={{
            display: setting.featureAvailable ? "flex" : "none",
            flexDirection: "row",
            minHeight: "5vh",
            alignItems: "center",
            color: setting.featureAvailable
              ? colors.primaryTextColor
              : colors.secondaryTextColor,
            cursor: setting.featureAvailable ? "pointer" : "default",
            "&:hover, &:focus": {
              backgroundColor: colors.secondaryBackground,
            },
            fontSize: "1.125em",
            transition: "color 0.25s ease",
            borderRadius: "10px",
            marginRight: "1.5em",
            padding: "0.5em 1em",
          }}
          onClick={setting.onClick}
        >
          <div style={{ display: "flex", width: "10%" }}>{setting.logo}</div>
          <div style={{ width: "80%" }}>
            <div>{setting.title}</div>
          </div>
          <div style={{ width: "10%", textAlign: "right" }}>
            <ExpandMoreIcon css={{ transform: "rotate(270deg)" }} />
          </div>
        </div>
      </Fragment>
    );
  });

  return (
    <>
      <Box>
        <SectionHeader
          pageName="Settings"
          // iconButton={<EditIcon />} TODO Add these back when we add feature to edit verse profile
          // onClick={openModal}
        />
        <div css={{ display: "flex", flexDirection: "column" }}>
          <div css={[avatar, { margin: "0 auto" }]}>
            <DefaultAvatar />
          </div>

          <h3
            css={{
              color: colors.primaryTextColor,
              fontSize: "2em",
              fontWeight: "normal",
              margin: "1em 0",
            }}
          >
            {formatPhoneNumber(user, "E123")}
          </h3>
        </div>
        <div css={{ overflowY: "auto", width: "90%" }}>
          <div css={SettingTitles}>ACCOUNT</div>
          {/* <DividerSpace /> */}
          {SettingsAccountCard}
          <DividerSpace />
          <DividerLine />
          <div css={SettingTitles}>GENERAL</div>
          {SettingsGeneralCard}
          {/*TODO UNCOMMENT WHEN FEATURES IN THIS AREA BECOME AVAILABLE */}
          {/* <DividerLine /> */}
          {/* <div css={SettingTitles}>TOOLS</div> */}
          {/* {SettingsToolsCard} */}
          {/* <DividerLine />
          <div css={SettingTitles}>CHAT</div> */}
          {SettingsChatCard}
        </div>
      </Box>
      <Box>
        <>
          {settingsOptionPage === "Default" && (
            <SvgGraphic src="/assets/Settings_Graphic.svg" />
          )}
          {settingsOptionPage === "Device Management" && <DeviceManagement />}
          {settingsOptionPage === "Blocked Contact List" && <BlockedList />}
          {settingsOptionPage === "About" && <AboutPage />}

          {overlayOpen && (
            <SignOutPopup
              toggleOverlay={toggleOverlay}
              logoutEvent={logoutEvent}
            />
          )}

          <AnimatePresence>
            {deleteAcountOverlay && (
              <ConfirmationPopup
                togglePopup={toggleDeleteAccountOverlay}
                title="Delete Account"
                confirmationMessage="In order to delete your account you will be redirected to an external page."
                handleAction={() => {
                  window.open(
                    "https://www.odience.com/deleteaccount",
                    "_blank"
                  );
                  toggleDeleteAccountOverlay();
                }}
                primaryButtonText="Continue"
              />
            )}
          </AnimatePresence>
        </>
      </Box>
    </>
  );
};

export default SettingsScreen;
