import { colors } from "../../styles/global.styles";

export default function HighlightedText({
  text,
  highlightIndex,
  searchQueryLength,
  ...divProps
}: React.ComponentPropsWithRef<"div"> & {
  text: string | null | undefined;
  highlightIndex?: number | -1;
  searchQueryLength: number;
}) {
  if (!text) return null;

  if (highlightIndex === undefined || highlightIndex === -1) {
    return <div {...divProps}>{text}</div>;
  }

  const textStart = text.substring(0, highlightIndex);
  const middleEnd = highlightIndex + searchQueryLength;
  const textMiddle = text.substring(highlightIndex, middleEnd);
  const textEnd = text.substring(middleEnd);

  return (
    <div css={{ color: colors.primaryTextColor }} {...divProps}>
      {textStart}
      <span style={{ color: colors.primaryAccentColor }}>{textMiddle}</span>
      {textEnd}
    </div>
  );
}
