const LOG_LEVELS = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  LOG: 4,
  DEBUG: 5,
};

const currentLogLevel = Number(window._env_.LOG_LEVEL) || 5;
console.info("currentLogLevel:", currentLogLevel);

const NO_OP = () => {};

if (currentLogLevel < LOG_LEVELS.DEBUG) {
  console.debug = NO_OP;
}
if (currentLogLevel < LOG_LEVELS.LOG) {
  console.log = NO_OP;
}
if (currentLogLevel < LOG_LEVELS.INFO) {
  console.info = NO_OP;
}
if (currentLogLevel < LOG_LEVELS.WARN) {
  console.warn = NO_OP;
}
if (currentLogLevel < LOG_LEVELS.ERROR) {
  console.error = NO_OP;
}
