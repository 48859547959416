import SketchIcon from "@/assets/sketch/Sketch.svg?react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { motion } from "motion/react";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar, AvatarBackground } from "../shared/Avatar";

export function ContactAvatar({ contact }: { contact: WebGwContact }) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2em",
      }}
    >
      <Avatar
        contact={contact}
        style={
          contact.photo || !contact.initials
            ? { width: "8em", height: "8em" }
            : { height: "3.335em", width: "3.345em", fontSize: "3em" }
        }
      />
      <div
        css={{
          margin: "0",
          whiteSpace: "nowrap",
          fontSize: "2em",
        }}
      >
        {contact.noNameReturnPhoneNumber()}
      </div>
    </div>
  );
}

interface IncomingSketchSessionProps {
  contact: WebGwContact;
  accept: VoidFunction;
  reject: VoidFunction;
}

export function IncomingSketchSessionOverlay({
  contact,
  accept,
  reject,
}: IncomingSketchSessionProps) {
  if (!contact) return null;

  return (
    <motion.div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        css={{
          width: "fit-content",
          height: "fit-content",
          backgroundColor: colors.primaryBackground,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: "white",
          padding: "3vw",
        }}
      >
        <ContactAvatar contact={contact} />

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5em",
          }}
        >
          <div>Incoming shared sketch session </div>
          <SketchIcon style={{ width: "1.5em", height: "1.5em" }} />
        </div>

        <div
          css={{
            gap: "5em",
            display: "flex",
            flexDirection: "row",
            padding: "3vw",
          }}
        >
          <button onClick={reject}>
            <AvatarBackground
              css={{
                backgroundColor: "#DC3851",
                width: "3em",
                height: "3em",
              }}
            >
              <CloseOutlinedIcon
                css={{ color: "white", width: "1em", height: "1em" }}
              />
            </AvatarBackground>
          </button>

          <button onClick={accept}>
            <AvatarBackground
              css={{
                backgroundColor: colors.secondaryAccentColor,
                width: "3em",
                height: "3em",
              }}
            >
              <CheckOutlinedIcon
                css={{ color: "white", width: "1em", height: "1em" }}
              />
            </AvatarBackground>
          </button>
        </div>
      </div>
    </motion.div>
  );
}
