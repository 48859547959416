import { useLayoutEffect, useMemo, useReducer, useRef } from "react";
import { SuggestionsApi } from "../components/Suggestions";
import { messageAreaRef } from "../messageAreaRef";
import { roundBorderCss } from "../messages/style";
import { ChatMessage, Suggestions } from "../typings";

export const cardConstants = {
  shrinkMaxHeight: 478, // spec says 344px
  shrinkMaxHeightSmall: 400, // spec says 224px
};

export function isOverflownY(elem: Element, container: Element): boolean {
  const elemRect = elem.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  return (
    elemRect.bottom > containerRect.bottom || elemRect.top < containerRect.top
  );
}

export function isOverflownX(elem: Element, container: Element): boolean {
  const elemRect = elem.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  return (
    elemRect.right > containerRect.right || elemRect.left < containerRect.left
  );
}

export function getHeightWithMargins(
  elem: HTMLElement,
  withPadding = true,
  withMargin = true
): number {
  const style = getComputedStyle(elem);

  let height = 0;

  const heightStyles = ["height"];
  if (withPadding) heightStyles.push("padding-top", "padding-bottom");
  if (withMargin) heightStyles.push("margin-top", "margin-bottom");
  for (const key of heightStyles) {
    height += parseFloat(style.getPropertyValue(key));
  }

  return height;
}

export function getMediaHeight(media: HTMLElement | null | undefined) {
  if (!media) return 0;
  return media.offsetHeight;
}

export function getTitleHeight(title: HTMLElement | null | undefined) {
  if (!title) return 0;
  return getHeightWithMargins(title);
}

export function getDescriptionHeight(
  description: HTMLElement | null | undefined
) {
  if (!description) return 0;
  return description.offsetHeight;
}

export function getSuggestionsHeight(
  suggestionsElem: HTMLElement | null | undefined,
  suggestions: Suggestions | undefined,
  doesOverflow: boolean
) {
  if (!suggestionsElem) return 0;
  return (
    suggestionsElem.offsetHeight +
    // add the 40px height of the expand button since it's appended to the suggestions when there are more than 1
    (doesOverflow && suggestions && suggestions.length > 1 ? 40 : 0)
  );
}

export function scrollToBottomDuringAnimation(
  scrollElem: Element = document.body
) {
  let scroll = true;
  const stopScroll = () => {
    scroll = false;
    scrollElem.removeEventListener("wheel", stopScroll);
    scrollElem.removeEventListener("touchmove", stopScroll);
  };

  // stop auto scrolling if the user scrolls
  scrollElem.addEventListener("wheel", stopScroll, {
    once: true,
    passive: true,
  });
  scrollElem.addEventListener("touchmove", stopScroll, {
    once: true,
    passive: true,
  });

  const scrollToBottom = () => {
    if (!scroll || !messageAreaRef) return;
    messageAreaRef.scrollToBottom();
  };
  return [scrollToBottom, stopScroll] as [
    scrollToBottom: typeof scrollToBottom,
    stopScroll: typeof stopScroll,
  ];
}

export function scrollToBottomRepeated({
  scrollElem = document.body,
  stopAfterDelayMs,
}: {
  scrollElem?: Element;
  stopAfterDelayMs?: number;
} = {}) {
  const [scrollToBottom, stopScroll] =
    scrollToBottomDuringAnimation(scrollElem);
  let scroll = true;
  const doScroll = () => {
    if (!scroll) return;
    scrollToBottom();
    requestAnimationFrame(doScroll);
  };
  doScroll();
  const stopScrollWrapper = () => {
    scroll = false;
    stopScroll();
  };
  if (stopAfterDelayMs != null) {
    setTimeout(stopScrollWrapper, stopAfterDelayMs);
  }
  return stopScrollWrapper;
}

export type CardState = { isShrunk: boolean; allowShrink: boolean };
export function useCardState(initialState: Partial<CardState> = {}) {
  type Action = { type: "toggle" | "allow" | "shrink" | "unshrink" };

  const reducerActions: Record<
    Action["type"],
    (state: CardState) => CardState
  > = {
    toggle: (state: CardState) => ({
      ...state,
      isShrunk: state.allowShrink ? !state.isShrunk : false,
    }),
    allow: (state: CardState) => ({ ...state, allowShrink: true }),
    shrink: (state: CardState) => ({
      ...state,
      isShrunk: state.allowShrink ? true : false,
    }),
    unshrink: (state: CardState) => ({ ...state, isShrunk: false }),
  };

  const [cardState, dispatch] = useReducer(
    (state: CardState, action: Action) => reducerActions[action.type](state),
    { isShrunk: false, allowShrink: false, ...initialState }
  );

  const actionCreators = useMemo(
    () => ({
      toggleShrink: () => dispatch({ type: "toggle" }),
      allowShrink: () => dispatch({ type: "allow" }),
      shrink: () => dispatch({ type: "shrink" }),
      unshrink: () => dispatch({ type: "unshrink" }),
    }),
    []
  );

  return { cardState, ...actionCreators } as const;
}

export function useCalculateAllowShrink(
  allowShrink: () => void,
  shrink: () => void,
  smallCardWidth?: boolean,
  cardOrientation?: string
) {
  const attachmentRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const suggestionsRef = useRef<SuggestionsApi | null>(null);

  const allowShrinkRef = useRef(false);

  useLayoutEffect(() => {
    const isHorizontal = cardOrientation === "HORIZONTAL";

    const mediaHeight = isHorizontal
      ? 0
      : getMediaHeight(attachmentRef.current);
    const titleHeight = getTitleHeight(titleRef.current);
    const descriptionHeight = getDescriptionHeight(descriptionRef.current);
    const suggestionsHeight = suggestionsRef.current?.getHeight() ?? 0;
    const topPadding = suggestionsRef.current?.elem
      ? suggestionsRef.current.getTopPadding()
      : 0;
    const innerCardHeight =
      mediaHeight +
      titleHeight +
      descriptionHeight +
      suggestionsHeight +
      topPadding;

    // Small carousel cards have a different height
    const maxHeight = smallCardWidth
      ? cardConstants.shrinkMaxHeightSmall
      : cardConstants.shrinkMaxHeight;

    const doesOverflow = innerCardHeight > maxHeight;

    if (doesOverflow) {
      allowShrink();
      shrink();
      allowShrinkRef.current = true;
    }
  }, []);

  return {
    attachmentRef,
    titleRef,
    descriptionRef,
    suggestionsRef,
    allowShrinkRef,
  };
}

function getRoundBordersCss(
  direction: ChatMessage["direction"],
  roundBorderTop: boolean,
  roundBorderBottom: boolean
) {
  const rb = [roundBorderCss.init];
  if (direction === "In") {
    rb.push(roundBorderCss.topRight);
    rb.push(roundBorderCss.bottomRight);
    if (roundBorderTop) {
      rb.push(roundBorderCss.topLeft);

      rb.push(roundBorderCss.topSpacing);
    }
    if (roundBorderBottom) {
      rb.push(roundBorderCss.bottomLeft);
    }
  } else {
    rb.push(roundBorderCss.topLeft);
    rb.push(roundBorderCss.bottomLeft);
    if (roundBorderTop) {
      rb.push(roundBorderCss.topRight);

      rb.push(roundBorderCss.topSpacing);
    }
    if (roundBorderBottom) {
      rb.push(roundBorderCss.bottomRight);
    }
  }
  return rb;
}
export function useRoundBordersCss(
  direction: ChatMessage["direction"],
  roundBorderTop: boolean,
  roundBorderBottom: boolean
) {
  return useMemo(
    () => getRoundBordersCss(direction, roundBorderTop, roundBorderBottom),
    [roundBorderTop, roundBorderBottom]
  );
}

export function hasTextSelected() {
  const selection = window.getSelection();
  return !!selection && selection.toString().length > 0;
}
