import LaptopOutlinedIcon from "@mui/icons-material/LaptopOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// import TabletAndroidOutlinedIcon from "@mui/icons-material/TabletAndroidOutlined";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { DeviceIdentityData } from "@/types/common";
import { atoms } from "@/utils/helpers/atoms";
import { generateDeviceId } from "@/utils/helpers/loginAndCaps/publishCaps";
import { fetchAllDevices, removeDevice } from "@/utils/helpers/removeDevice";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { useAtom } from "jotai";
import { AnimatePresence } from "motion/react";
import { useEffect, useState } from "react";
import { colors } from "../../styles/global.styles";
import { DeviceCard, DeviceCategoryTitles } from "./SettingsScreen.style";

type Props = {
  devices?: DeviceIdentityData[];
  deviceLimit?: string | number;
  next?: (next?: any) => void;
};

const DeviceManagement = ({ devices, next, deviceLimit }: Props) => {
  const [data, setData] = useAtom(atoms.provisioning.devices);
  const [deviceIdForRemoval, setDeviceIdForRemoval] = useState("");
  const deviceId = generateDeviceId();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [friendlyDeviceName, setFriendlyDeviceName] = useState("");
  const [maxDevices, setMaxDevices] = useAtom(atoms.provisioning.maxDevices);
  const registeredDevices = devices ? devices : data;

  const toggleOverlay = () => {
    setOverlayOpen(!overlayOpen);
  };

  const handleDeleteDevicePrompt = (
    deviceId: string,
    friendlyDeviceName: string
  ) => {
    setOverlayOpen(true);
    setDeviceIdForRemoval(deviceId);
    setFriendlyDeviceName(friendlyDeviceName);
  };

  useEffect(() => {
    if (!devices) {
      fetchAllDevices(setData, setMaxDevices);
    }
  }, []);

  const handleDelete = (devID: string) => {
    setDeviceIdForRemoval(devID);
    removeDevice(
      registeredDevices as DeviceIdentityData[],
      deviceIdForRemoval,
      setData,
      setMaxDevices,
      next
    );
    toggleOverlay();
  };

  const currentDeviceCard = registeredDevices?.map((device) => {
    const deviceLogo = device.terminal_model.includes("iPhone") ? (
      <PhoneAndroidOutlinedIcon css={{ height: "4vh", width: "4vh" }} />
    ) : (
      <LaptopOutlinedIcon css={{ height: "4vh", width: "4vh" }} />
    );
    if (deviceId === device.device_id) {
      return (
        <div css={DeviceCard} key={device.device_id}>
          <div css={{ display: "inline-block", padding: "0 1em" }}>
            {deviceLogo}
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <div css={{ fontSize: "1em" }}>{device.friendly_device_name}</div>
            <div
              css={{
                fontSize: "0.8em",
                color: colors.secondaryTextColor,
              }}
            >
              {device.terminal_model} {device.terminal_vendor}
            </div>
          </div>
          <div
            css={{
              width: "10%",
              display: "flex",
              justifyContent: "end",
              paddingRight: "1em",
            }}
          ></div>
        </div>
      );
    }
    return null;
  });

  const otherDeviceCard = registeredDevices?.map((device) => {
    const deviceLogo =
      device.terminal_model.includes("iPhone") ||
      device.terminal_model.includes("android") ? (
        <PhoneAndroidOutlinedIcon css={{ height: "4vh", width: "4vh" }} />
      ) : (
        <LaptopOutlinedIcon css={{ height: "4vh", width: "4vh" }} />
      );

    if (deviceId !== device.device_id) {
      return (
        <div css={DeviceCard} key={device.device_id}>
          <div css={{ display: "inline-block", padding: "0 1em" }}>
            {deviceLogo}
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <div css={{ fontSize: "1em" }}>{device.friendly_device_name}</div>
            <div
              css={{
                fontSize: "0.8em",
                color: colors.secondaryTextColor,
              }}
            >
              {device.terminal_model} {device.terminal_vendor}
            </div>
          </div>
          <div
            css={{
              width: "10%",
              display: "flex",
              justifyContent: "end",
              paddingRight: "1em",
            }}
            onClick={() =>
              handleDeleteDevicePrompt(
                device.device_id,
                device.friendly_device_name
              )
            }
          >
            <LogoutOutlinedIcon />
          </div>
        </div>
      );
    }

    return null;
  });

  const maximumDevices = deviceLimit ? deviceLimit : maxDevices;

  return (
    <>
      <div
        css={{
          color: colors.primaryTextColor,
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <div css={{ fontSize: "1.75em", marginBottom: "4vh" }}>
          Device Management
        </div>

        <div css={{ marginBottom: "8vh" }}>
          <div>
            VERSE can be activated on a maximum of{" "}
            <span css={{ color: colors.primaryAccentColor }}>
              {maximumDevices} devices simultaneously
            </span>
            .
          </div>
          <div css={{ paddingTop: "2vh" }}>
            Your account is using{" "}
            <span css={{ color: colors.primaryAccentColor }}>
              {registeredDevices?.length} out of {maximumDevices}
            </span>{" "}
            devices.
          </div>
        </div>

        <div css={{ paddingRight: "1vw" }}>
          <div css={DeviceCategoryTitles}>THIS DEVICE</div>
          {currentDeviceCard}
          <div css={DeviceCategoryTitles}>OTHER DEVICES</div>
          {otherDeviceCard}
        </div>
      </div>

      <AnimatePresence>
        {overlayOpen && (
          <ConfirmationPopup
            togglePopup={toggleOverlay}
            title={`Sign Out from ${friendlyDeviceName}`}
            confirmationMessage={`You are about to sign out from ${friendlyDeviceName}. Once signed out, you will need to reactivate VERSE on the device again for future use.`}
            handleAction={() => handleDelete(deviceIdForRemoval)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default DeviceManagement;
