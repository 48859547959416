// TODO
/* eslint-disable react-compiler/react-compiler */
/* eslint-disable react-hooks/rules-of-hooks */
import AudioPlayer, { AudioPlayerAPI } from "@/components/shared/AudioPlayer";
import Map, { LatLng } from "@/components/shared/Map";
import cn from "@/utils/cn";
import { atoms } from "@/utils/helpers/atoms";
import { cancel } from "@/utils/helpers/fileUtils";
import { formatFileSizeToHumanReadable } from "@/utils/messaging/conversation/conversationUtils/";
import { queryClient } from "@/utils/queryClient";
import { keyframes } from "@emotion/react";
import Error from "@mui/icons-material/Error";
import GenericFile from "@mui/icons-material/InsertDriveFileOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { milliseconds } from "date-fns/milliseconds";
import { useAtom } from "jotai";
import mergeRefs from "merge-refs";
import React, {
  MouseEventHandler,
  ReactNode,
  RefObject,
  memo,
  useEffect,
  useReducer,
  useRef,
  useState,
  type CSSProperties,
} from "react";
import { useUnmount } from "usehooks-ts";
import PlayButton from "../../../../assets/play-button-svgrepo-com.svg";
import { colors } from "../../../../styles/global.styles";
import {
  mediaAspectRatio,
  mediaHeightStyle,
  mediaMaxHeightVertical,
} from "../RcsStyling";
import { isDragging } from "../isDragging";
import { messageAreaRef } from "../messageAreaRef";
import type { ChatMessageStatus, IMedia } from "../typings";
import { MediaExtraInfo } from "../typings/moderatorChatbotInfo";
import { fullScreenMedia, unFullscreenMedia } from "../util/mediaUtils";

function useIsPlaying(videoRef: React.RefObject<HTMLVideoElement>) {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const playHandler = () => {
      setIsPlaying(true);
      video.setAttribute("controls", "");
    };

    const pauseHandler = () => {
      setIsPlaying(false);
      if (!video.classList.contains("fullscreen")) {
        video.removeAttribute("controls");
      }
    };

    video.addEventListener("play", playHandler);
    video.addEventListener("pause", pauseHandler);

    return () => {
      video.removeEventListener("play", playHandler);
      video.removeEventListener("pause", pauseHandler);
    };
  }, [videoRef.current]);

  return isPlaying;
}

function useIsFullscreen(videoRef: React.RefObject<HTMLVideoElement>) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        const node = mutation.target as HTMLElement;

        if (node.classList.contains("fullscreen")) {
          setIsFullScreen(true);
          video.setAttribute("controls", "");
        } else {
          setIsFullScreen(false);
        }
      }
    });

    observer.observe(video, {
      childList: false,
      subtree: false,
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, [videoRef.current]);
  return isFullScreen;
}

function UploadProgressSpinner({
  progress,
  messageId,
}: {
  progress: number;
  messageId: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        onClick={() => cancel(messageId)}
        style={{
          cursor: "pointer",
          zIndex: "2",
          position: "absolute",
          width: "40%",
          height: "40%",
          backgroundColor: colors.primaryAccentColor,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={progress}
        css={{ color: colors.primaryTextColor }}
      />
    </div>
  );
}

function LoopProgressSpinner() {
  return <CircularProgress css={{ color: colors.primaryTextColor }} />;
}

function ProgressSpinner({
  progress,
  messageId,
}: {
  progress: number | boolean | undefined;
  messageId: string;
}) {
  const upload = typeof progress == "number" && progress < 100;
  const loop = typeof progress == "boolean" && progress;

  return upload || loop ? (
    <div
      css={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "2",
      }}
    >
      {upload ? (
        <UploadProgressSpinner progress={progress} messageId={messageId} />
      ) : (
        <LoopProgressSpinner />
      )}
    </div>
  ) : null;
}

function ErrorOverlay() {
  return (
    <div
      css={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
      }}
    >
      <Error css={{ color: colors.primaryAccentColor }} />
    </div>
  );
}

function MediaBackground({
  mediaRef,
}: {
  mediaRef?: React.RefObject<HTMLDivElement>;
}) {
  const backgroundRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      // stop the event from triggering on anything behind the element like the card expanding
      e.stopPropagation();

      const mediaElem = backgroundRef.current
        .previousElementSibling as HTMLElement | null;

      if (!mediaElem || e.target === mediaElem) {
        return;
      }

      if (
        !mediaElem.classList.contains("fullscreen") &&
        !backgroundRef.current.contains(e.target as Node)
      ) {
        return;
      }

      if (!messageAreaRef?.parentElement) {
        console.debug("messageAreaRef parentElement not found");
        return;
      }

      unFullscreenMedia(
        mediaElem,
        mediaRef!.current,
        messageAreaRef.parentElement
      );
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return <div ref={backgroundRef} className="media-background" />;
}

type MediaProps = {
  style?: CSSProperties;
  children: ReactNode;
  background?: boolean;
  isFailed: boolean;
  progress: number | boolean | undefined;
  messageId: string;
};

function Media({
  ref,
  children,
  style,
  isFailed,
  progress: uploadProgress,
  background = true,
  messageId,
}: MediaProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  const mediaRef = useRef<HTMLDivElement>(null!);

  const fade =
    (typeof uploadProgress == "number" && uploadProgress < 100) ||
    (typeof uploadProgress == "boolean" && uploadProgress);

  return (
    <>
      {isFailed && <ErrorOverlay />}
      <ProgressSpinner progress={uploadProgress} messageId={messageId} />
      <div
        ref={mergeRefs(ref, mediaRef)}
        className={cn(
          "media relative leading-[0] transition-opacity duration-[0.35s] ease-out",
          fade && "opacity-50"
        )}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
        {background && <MediaBackground mediaRef={mediaRef} />}
      </div>
    </>
  );
}

interface AttachmentProps {
  attachment: IMedia;
  mediaHeight?: IMedia["height"];
  /**
   * container to set a property containing the height of the media element
   */
  container: RefObject<HTMLElement>;
  mediaOnlyMessage?: boolean;
  uploadProgress?: number;
  messageStatus?: ChatMessageStatus;
  cardOrientation?: "HORIZONTAL" | "VERTICAL";
  mediaExtraInfo?: MediaExtraInfo;
  messageId: string;
}

const mediaAlignmentMap = {
  bestfit_height_left: "left",
  bestfit_height_right: "right",
  bestfit_width_center: "center",
  bestfit_width_top: "top",
  bestfit_width_bottom: "bottom",
};

const toObjectPosition = (mediaExtraInfo?: MediaExtraInfo) => {
  if (!mediaExtraInfo?.mediaAlignment) return undefined;
  return mediaAlignmentMap[mediaExtraInfo?.mediaAlignment];
};

const Attachment = ({
  ref,
  attachment,
  mediaHeight,
  container,
  mediaOnlyMessage = false,
  uploadProgress,
  messageStatus,
  cardOrientation,
  mediaExtraInfo,
  messageId,
}: AttachmentProps & {
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const {
    mediaContentType: type,
    mediaUrl: url,
    height,
    thumbnailUrl,
    contentDescription,
    mediaFileSize,
  } = attachment;

  if (!type) {
    return (
      <a href={url} className="shrink" style={{ maxWidth: "80%" }}>
        {url}
      </a>
    );
  }

  const [mapOpen, setMapOpen] = useState(false);

  const position = toObjectPosition(mediaExtraInfo);
  const mediaCss: CSSProperties = mediaOnlyMessage
    ? {
        width: "200px",
        height: "200px",
        objectFit: "cover",
      }
    : {
        objectFit: position === "center" ? "contain" : "cover",
        objectPosition: position,
        minHeight: mediaHeight
          ? mediaHeightStyle[mediaHeight]
          : (mediaHeightStyle[height] ?? "100%"),
        ...(cardOrientation === "VERTICAL"
          ? {
              maxHeight: mediaMaxHeightVertical,
            }
          : {
              height: mediaHeight
                ? mediaHeightStyle[mediaHeight]
                : (mediaHeightStyle[height] ?? "100%"),
            }),
        aspectRatio: mediaHeight
          ? mediaAspectRatio[mediaHeight]
          : mediaAspectRatio[height],
      };

  const otherCss: CSSProperties = {
    padding: "0.3em",
    cursor: "pointer",
    background: "none",
    width: "auto",
  };

  useEffect(() => {
    container.current?.style.setProperty(
      "--media-height",
      mediaCss.height as string
    );
    if (type.includes("application")) {
      container.current?.style.setProperty("min-height", "0");
    } else if (type.includes("audio")) {
      container.current?.style.setProperty("background", "none");
      container.current?.style.setProperty("min-height", "0");
      container.current?.style.setProperty("min-width", "20em");
    }
  }, []);

  const isUploading = uploadProgress !== undefined && uploadProgress < 100;
  const isFailed = (messageStatus && messageStatus === "failed") as boolean;
  const cssIfFailOrProgress = (isUploading || isFailed) && {
    opacity: "0.2 !important",
  };

  const fileName = contentDescription ?? "File";

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (type.includes("image")) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgLoadErr, setImgLoadErr] = useState(false);
    const [mediaUrl, setMediaUrl] = useState(url);

    const fetchedRef = useRef(false);

    useEffect(() => {
      if (!imgLoadErr) return;

      const isHeicImage = type.includes("image/heic");
      if (!isHeicImage) return;

      console.log("Error loading image, retrying using heic convert", url);

      if (fetchedRef.current) return;
      fetchedRef.current = true;

      const abortController = new AbortController();

      const fetchMedia = async () => {
        try {
          const res = await queryClient.fetchQuery({
            queryKey: ["heicConvert", url],
            queryFn: async () => {
              const heicConvertImport = import(
                "@/utils/messaging/conversation/conversationUtils/heicConvert"
              );

              const imageDataAbortController = new AbortController();
              const imageDataRes = await fetch(url, {
                credentials: "include",
                signal: imageDataAbortController.signal,
              });

              const contentType = imageDataRes.headers.get("content-type");
              if (contentType !== "image/heic") {
                const message = `Invalid image type: ${contentType}`;
                imageDataAbortController.abort(message);
                throw new window.Error(message);
              }

              const imageData = await imageDataRes.arrayBuffer();

              const { heicConvert } = await heicConvertImport;

              const convertTimeMarker = `Converting HEIC to PNG ${url.substring(20)}`;
              console.time(convertTimeMarker);
              const res = await heicConvert(
                {
                  buffer: imageData,
                  format: "PNG",
                },
                abortController.signal
              );
              console.timeEnd(convertTimeMarker);
              return res;
            },
            staleTime: milliseconds({ hours: 1 }),
            gcTime: milliseconds({ hours: 1 }),
            retry: false,
          });

          if ("error" in res) {
            console.error("Error converting heic to png", res.error);
            return;
          }
          const imageBlob = new Blob([res.imageData], { type: "image/png" });

          const blobUrl = URL.createObjectURL(imageBlob);
          setMediaUrl(blobUrl);
          // reset states
          setImgLoaded(false);
          setImgLoadErr(false);
        } catch (error) {
          console.error("Error fetching media:", error);
        }
      };

      fetchMedia();

      return () => {
        abortController.abort();
      };
    }, [url, imgLoadErr]);

    useUnmount(() => {
      if (mediaUrl.startsWith("blob:")) {
        URL.revokeObjectURL(mediaUrl);
      }
    });

    const mediaRef = useRef<HTMLDivElement>(null!);

    const loadThumbnail =
      thumbnailUrl &&
      !imgLoaded &&
      !isUploading &&
      (mediaFileSize > 200_000 || !mediaFileSize);

    return (
      <Media
        ref={mergeRefs(ref, mediaRef)}
        style={mediaCss}
        background={false}
        isFailed={isFailed}
        progress={isUploading ? uploadProgress : undefined}
        messageId={messageId}
      >
        <div
          style={{
            ...mediaCss,
            ...(!imgLoaded &&
              mediaOnlyMessage && {
                minHeight: "100%",
                minWidth: "100%",
              }),
          }}
          css={
            loadThumbnail
              ? {
                  backgroundImage: `url(${thumbnailUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  ":before": {
                    content: '""',
                    position: "absolute",
                    inset: "0",
                    opacity: "0",
                    background: "rgba(255,255,255,0.25)",
                    animation: `${keyframes({
                      "50%": {
                        opacity: "0.5",
                      },
                    })} 2s ease-in-out infinite`,
                  },
                  ":after": {
                    content: '""',
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backdropFilter: "blur(10px)",
                    animation: `${keyframes({
                      "100%": {
                        opacity: "0.5",
                      },
                    })} 6s ease-in-out forwards`,
                  },
                }
              : undefined
          }
        >
          <img
            onClick={(e) => {
              e.stopPropagation();
              if (isDragging) {
                return;
              }

              const thisImage = e.currentTarget as HTMLImageElement;
              if (imgLoaded) fullScreenMedia(thisImage, mediaRef.current);
            }}
            src={mediaUrl}
            title={contentDescription}
            alt={
              imgLoadErr
                ? contentDescription ||
                  url.replace("https://", "").substring(0, 20) + "..."
                : undefined
            }
            style={{
              ...mediaCss,
              ...(!imgLoaded &&
                mediaOnlyMessage && {
                  minHeight: "100%",
                  minWidth: "100%",
                }),
              ...(thumbnailUrl && {
                opacity: imgLoaded ? "1" : "0",
                transition: "opacity 200ms ease-in-out !important",
              }),
            }}
            css={cssIfFailOrProgress}
            draggable={false}
            onLoad={() => setImgLoaded(true)}
            onError={() => setImgLoadErr(true)}
            loading="lazy"
          />
          <MediaBackground mediaRef={mediaRef} />
        </div>
      </Media>
    );
  } else if (type.includes("video")) {
    const mediaRef = useRef<HTMLDivElement>(null!);
    const videoRef = useRef<HTMLVideoElement>(null!);
    const isFullScreen = useIsFullscreen(videoRef);
    const isPlaying = useIsPlaying(videoRef);
    const [loaded, setLoaded] = useState(false);

    const togglePlayVideo = () => {
      if (!isPlaying) {
        void videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    };

    const isUploadProgress =
      typeof uploadProgress == "number" && uploadProgress < 100;
    const canPlay = !isUploadProgress && loaded;

    const handleVideoClick: MouseEventHandler = (e) => {
      e.stopPropagation();

      if (isDragging) return;

      if (canPlay) {
        fullScreenMedia(videoRef.current, mediaRef.current);
        togglePlayVideo();
      }
    };

    useEffect(() => {
      const handleLoaded = () => setLoaded(true);
      const video = videoRef.current;

      if (video) {
        if (video.readyState >= 3) {
          handleLoaded();
        } else {
          video.addEventListener("canplay", handleLoaded);

          return () => {
            video.removeEventListener("canplay", handleLoaded);
          };
        }
      }
    }, [videoRef]);

    return (
      <Media
        ref={mergeRefs(ref, mediaRef)}
        isFailed={isFailed}
        progress={isUploadProgress ? uploadProgress : !loaded}
        style={mediaCss}
        messageId={messageId}
      >
        <video
          ref={videoRef}
          disablePictureInPicture
          onClick={handleVideoClick}
          controls={false}
          draggable={false}
          css={cssIfFailOrProgress}
          style={mediaCss}
        >
          <source
            src={url}
            type={type === "video/x-matroska" ? "video/mp4" : type}
          />
        </video>
        {canPlay && !isPlaying && !isFullScreen && (
          <img
            src={PlayButton}
            onClick={handleVideoClick}
            alt="Play Button"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              height: "46px",
              width: "46px",
              color: "white",
              filter: "drop-shadow(0px 0px 6px rgba(0,0,0,0.9))",
            }}
          />
        )}
      </Media>
    );
  } else if (type.includes("audio")) {
    const [audioPlaying, setAudioPlaying] = useAtom(
      atoms.messaging.audioPlaying
    );

    const audioPlayerRef = useRef<AudioPlayerAPI>(null);

    const handleAudioPlayClick = () => {
      if (audioPlayerRef.current) {
        if (audioPlaying) {
          console.log("pause the current playing audio");
          audioPlaying.pause();
        }
        setAudioPlaying(audioPlayerRef.current);
      }
    };

    const handleAudioPauseClick = () => {
      if (audioPlayerRef.current === audioPlaying) {
        setAudioPlaying(undefined);
      }
    };

    return (
      <Media
        ref={ref}
        background={false}
        isFailed={isFailed}
        progress={uploadProgress}
        messageId={messageId}
      >
        <AudioPlayer
          ref={audioPlayerRef}
          src={url}
          type={type}
          onPlay={handleAudioPlayClick}
          onPause={handleAudioPauseClick}
        />
      </Media>
    );
  } else if (type.includes("rcspushlocation")) {
    const [imgLoaded, setImgLoaded] = useReducer(() => true, false);
    const [imgLoadErr, setImgLoadErr] = useReducer(() => true, false);

    const latLng = attachment.contentDescription
      ? (JSON.parse(attachment.contentDescription) as LatLng)
      : undefined;

    return (
      <>
        <Map
          defaultLocation={latLng}
          closeMap={() => setMapOpen(false)}
          open={mapOpen}
        />

        <Media
          ref={ref}
          style={mediaCss}
          background={false}
          isFailed={isFailed}
          progress={uploadProgress}
          messageId={messageId}
        >
          {isFailed && <ErrorOverlay />}
          <ProgressSpinner progress={uploadProgress} messageId={messageId} />
          <div
            style={{
              ...mediaCss,
              ...(!imgLoaded &&
                mediaOnlyMessage && {
                  minHeight: "100%",
                  minWidth: "100%",
                }),
              cursor: "pointer",
            }}
          >
            <img
              onClick={(e) => {
                e.stopPropagation();
                if (isDragging) {
                  return;
                }

                setMapOpen(true);
              }}
              src={url}
              title={contentDescription}
              alt={
                imgLoadErr
                  ? contentDescription ||
                    url.replace("https://", "").substring(0, 20) + "..."
                  : undefined
              }
              style={{
                ...mediaCss,
                ...(!imgLoaded &&
                  mediaOnlyMessage && {
                    minHeight: "100%",
                    minWidth: "100%",
                  }),
              }}
              css={cssIfFailOrProgress}
              draggable={false}
              onLoad={setImgLoaded}
              onError={setImgLoadErr}
              loading="lazy"
            />
            <MediaBackground />
          </div>
        </Media>
      </>
    );
    // Special case for text files
  } else if (type.includes("application") || type.includes("text")) {
    return (
      <Media
        ref={ref}
        style={otherCss}
        background={false}
        isFailed={isFailed}
        progress={uploadProgress}
        messageId={messageId}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            lineHeight: "1",
            whiteSpace: "nowrap",
          }}
          css={cssIfFailOrProgress}
          onClick={downloadFile}
        >
          <GenericFile
            css={{
              height: "1.5em !important",
              width: "1.5em !important",
              color: colors.primaryTextColor,
            }}
          />
          <div
            style={{
              padding: "0.4rem",
            }}
          >
            <div
              style={{
                padding: "0.2rem 0",
              }}
            >
              <div
                style={{
                  borderRadius: "0",
                  display: "-webkit-inline-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "2",
                  whiteSpace: "normal",
                  lineHeight: "1.1",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "left",
                  width: "auto",
                }}
              >
                {fileName}
              </div>
            </div>
            <div
              style={{
                color: colors.secondaryTextColor,
                fontSize: "0.8em",
                fontWeight: "normal",
                marginTop: "0.1rem",
                textAlign: "left",
                width: "auto",
              }}
            >
              {formatFileSizeToHumanReadable(mediaFileSize)}
            </div>
          </div>
        </div>
      </Media>
    );
  }
  return null;
};

export default memo(Attachment);
