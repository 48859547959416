export type MessageAreaRef = HTMLOListElement & {
  scrollToBottom: () => void;
  scrollToBottomSmooth: () => void;
  isFirstRender: () => boolean;
};

export let messageAreaRef: MessageAreaRef | undefined;

export function setMessageAreaRef(ref: MessageAreaRef) {
  messageAreaRef = ref;
}
