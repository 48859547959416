import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { paths } from "@/routerPaths";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { isMobile, openOdienceDownloadAppWebPage } from "@/utils/helpers/Utils";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { useNavigate } from "react-router-dom";

type GuestListPopupProps = {
  objEvent: OdienceEvent;
  handleRequestToJoinEvent: (event: any) => void;
  handleShowRequestToJoinPopup: (show: boolean) => void;
};

const GuestListPopup = ({
  objEvent,
  handleRequestToJoinEvent,
  handleShowRequestToJoinPopup,
}: GuestListPopupProps) => {
  const navigate = useNavigate();

  const handleClosePopup = () => {
    handleShowRequestToJoinPopup(false);
  };

  const handleDesktopAction = () => {
    if (isProvisioned()) {
      handleRequestToJoinEvent(objEvent);
    } else {
      navigate(paths.provisioningFromOdience);
    }
    handleClosePopup();
  };

  const handleMobileAction = () => {
    openOdienceDownloadAppWebPage();
    handleClosePopup();
  };

  return (
    <>
      {!isMobile() ? (
        <ConfirmationPopup
          title="Guest List"
          confirmationMessage={
            isProvisioned()
              ? "This event requires Guest List access. Send a request to join this event?"
              : "Login to access Guest List"
          }
          handleAction={handleDesktopAction}
          primaryButtonText={isProvisioned() ? "Request to Join" : "Log in"}
          togglePopup={handleClosePopup}
          closeButtonActive={true}
          secondaryButtonActive={true}
        />
      ) : (
        <ConfirmationPopup
          title="Guest List"
          confirmationMessage={
            "To access Guest List, please download the Odience app, or access the event from a desktop browser"
          }
          handleAction={handleMobileAction}
          primaryButtonText="Get the app"
          togglePopup={handleClosePopup}
          closeButtonActive={true}
          secondaryButtonActive={true}
        />
      )}
    </>
  );
};

export default GuestListPopup;
