export const mediaHeightStyle: Record<string, string> = {
  SHORT_HEIGHT: "100px",
  MEDIUM_HEIGHT: "140px",
  TALL_HEIGHT: "220px",
};

export const mediaMaxHeightVertical = "340px";

export const mediaAspectRatio: Record<string, string> = {
  SHORT_HEIGHT: "3 / 1",
  MEDIUM_HEIGHT: "1.56 / 1",
  TALL_HEIGHT: "9 / 10",
};
