import {
  Avatar,
  AvatarBackground,
  DefaultAvatar,
} from "@/components/shared/Avatar";
import { colors } from "@/styles/global.styles";
import { useEffect } from "react";
import { useSnapshot } from "valtio/react";
import type { IMessageTypeProps } from "../typings";
import { useRoundBordersCss } from "../util/cardUtils";
import { cardContentCss, msgContentCss } from "./style";

export default function ContactCard({
  message,
  removeTopMargin,
  onRendered,
  onFallbackToGenericFile,
  direction,
  roundBorderTop,
  roundBorderBottom,
}: IMessageTypeProps) {
  const {
    webGwContact: contact,
    error: error,
    loading,
  } = useSnapshot(message.contactCard!);

  const isLoading = loading?.valueOf();

  // Vcard are fetched asynchronously, make sure to notify parent when contact is set
  useEffect(() => {
    if (contact) {
      onRendered?.();
    }
  }, [contact]);

  useEffect(() => {
    if (error) {
      onFallbackToGenericFile?.();
    }
  }, [error]);

  const roundBorders = useRoundBordersCss(
    direction,
    roundBorderTop,
    roundBorderBottom
  );

  if (!contact && isLoading === false) return null;

  const mainPhoneNumber = contact?.getMainPhoneNumber();

  return (
    <div
      css={[
        cardContentCss,
        msgContentCss,
        roundBorders,
        {
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          backgroundColor: colors.fourthBackground,
          ...(isLoading && {
            opacity: "0.5",
          }),
        },
      ]}
      style={removeTopMargin ? { marginTop: "0 !important" } : undefined}
    >
      <AvatarBackground css={{ margin: "1rem", marginRight: "0" }}>
        {contact?.photo ? (
          <Avatar contact={contact} />
        ) : contact?.initials && /[A-z]/.test(contact.initials) ? (
          contact.initials
        ) : (
          <DefaultAvatar />
        )}
      </AvatarBackground>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "1em",
          gap: "0.5em",
        }}
      >
        {isLoading ? (
          <div css={{ textAlign: "center" }}>Loading...</div>
        ) : (
          <>
            {contact?.name && (
              <span css={{ fontSize: "1.2em" }}>{contact.name}</span>
            )}
            {mainPhoneNumber && (
              <span css={{ color: colors.secondaryTextColorLighter }}>
                {mainPhoneNumber}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
}
