import toast from "react-hot-toast";
import { baseWebGwUrl } from "..";
import { callsState } from "../calls/callState";
import { updateCallsInDatabase } from "../calls/callUtils";
import { isValidContact } from "../helpers/Utils";
import WebGwContact from "../helpers/WebGwContact";
import { isChatbot } from "../helpers/chatbots";
import { getLocalAccessToken, getLocalUser } from "../helpers/localstorage";
import { fetchCaps } from "../helpers/loginAndCaps/capabilities";
import { conversationsState } from "../messaging/conversation/ConversationState";
import { updateConversationsInDatabase } from "../messaging/conversation/conversationUtils/";
import { isSamePhoneNumber } from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import Contact from "./contact";
import { contactState } from "./contactState";
import { getLoadedContacts, refreshContacts } from "./contactUtils";

const contactCapabilities = (newContact: any) => {
  const phoneAttribute = newContact.contact.attributeList.attribute.find(
    (attr: any) => attr.name.includes("phone")
  );
  const [phoneValue, _] = phoneAttribute ? phoneAttribute.value : [];
  if (!phoneValue) {
    return;
  }

  const formatted = phoneValue.replaceAll(/\D/g, "");

  fetchCaps(formatted, true);
};

export const createContact = async (contact: typeof contactState) => {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();
  if (!user || !accessToken) {
    console.error("User not provisioned");
    return;
  }

  const newContact = new Contact(contact);

  const isContactValid = isValidContact(newContact);

  const url = new URL(
    `/addressbook/v1/${user}/contacts/${newContact.contactId}?access_token=${accessToken}`,
    baseWebGwUrl
  );
  const body = newContact.body();
  console.log(`Created contact JSON card :\n${body}`);
  if (isContactValid) {
    const response = await fetch(url, {
      method: "POST",
      body: body,
    });
    if (response.status === 201) {
      const contactCreated = await response.json();
      contactCapabilities(contactCreated);
      console.log("Success creating contact");
      await refreshContacts();
      // Timeout function to update the contacts list
      setTimeout(function () {
        document.getElementById("ContactPickerFilter")?.click();
        document.getElementById("allContactsFilter")?.click();
        document.getElementById("root")?.click();
        document.getElementById("ContactPickerFilter")?.click();
      }, 100);

      const contact = new WebGwContact(contactCreated.contact);
      void updateContactInExistingConversations(contact);
      void updateContactInExistingCallLogs(contact);
    }
  } else {
    toast("Can't create empty contact", {
      duration: 5000,
      style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
    });
  }
};

/**
 * Update the contact in conversations matching its phone number
 */
export const updateContactInExistingConversations = (
  contact?: WebGwContact
) => {
  if (!contact || !contact.getMainPhoneNumber()) {
    return;
  }

  const phoneNumber = contact.getMainPhoneNumber();
  const conversationIdsToUpdate: string[] = [];

  for (const conversation of conversationsState.conversations.values()) {
    if (!isChatbot(conversation.id)) {
      const index = conversation.participants.findIndex((participant) => {
        return isSamePhoneNumber(participant.getMainPhoneNumber(), phoneNumber);
      });

      if (index !== -1) {
        conversation.participants[index] = contact;
        conversationIdsToUpdate.push(conversation.id);
      }
    }
  }

  void updateConversationsInDatabase(conversationIdsToUpdate);
  void deleteContactFromExistingConversations(contact.id, phoneNumber);
};

/**
 * Delete the contact from existing conversations that does not match its phone number and replace with a good contact
 */
const deleteContactFromExistingConversations = (
  contactId: string,
  contactPhoneNumber?: string
) => {
  const contacts = getLoadedContacts();
  const conversationIdsToUpdate: string[] = [];

  for (const conversation of conversationsState.conversations.values()) {
    if (!isChatbot(conversation.id)) {
      const index = conversation.participants.findIndex(
        (participant) =>
          participant.id === contactId &&
          !isSamePhoneNumber(
            participant.getMainPhoneNumber(),
            contactPhoneNumber
          )
      );

      if (index !== -1) {
        const number = conversation.participants[index].getMainPhoneNumber();
        const newContactForConversation =
          contacts?.findWithNumber(number) ||
          WebGwContact.fromPhoneNumber(number)!;
        conversation.participants.splice(index, 1, newContactForConversation);
        conversationIdsToUpdate.push(conversation.id);
      }
    }
  }

  void updateConversationsInDatabase(conversationIdsToUpdate);
};

/**
 * Update the contact in call logs matching its phone number
 */
const updateContactInExistingCallLogs = (contact?: WebGwContact) => {
  if (!contact || !contact.getMainPhoneNumber()) {
    return;
  }

  const phoneNumber = contact.getMainPhoneNumber();
  const callIdsToUpdate: string[] = [];

  for (const call of callsState.calls.values()) {
    if (isSamePhoneNumber(call.peer.getMainPhoneNumber(), phoneNumber)) {
      call.peer = contact;
      callIdsToUpdate.push(call.callId);
    }
  }

  void updateCallsInDatabase(callIdsToUpdate);
  void deleteContactFromExistingCallLogs(contact.id, phoneNumber);
};

/**
 * Delete the contact from existing call logs that does not match its phone number and replace with a good contact
 */
const deleteContactFromExistingCallLogs = (
  contactId: string,
  contactPhoneNumber?: string
) => {
  const contacts = getLoadedContacts();
  const callIdsToUpdate: string[] = [];

  for (const call of callsState.calls.values()) {
    if (
      call.peer.id === contactId &&
      !isSamePhoneNumber(call.peer.getMainPhoneNumber(), contactPhoneNumber)
    ) {
      call.peer =
        contacts?.findWithNumber(call.peer.getMainPhoneNumber()) ||
        WebGwContact.fromPhoneNumber(call.peer.getMainPhoneNumber())!;
      callIdsToUpdate.push(call.callId);
    }
  }

  void updateCallsInDatabase(callIdsToUpdate);
};

export const editContact = async (contact: typeof contactState) => {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();
  if (!user || !accessToken) {
    console.error("User not provisioned");
    return false;
  }

  const newContact = new Contact(contact);

  const isContactValid = isValidContact(newContact);

  const url = new URL(
    `/addressbook/v1/${user}/contacts/${contact.id}?access_token=${accessToken}`,
    baseWebGwUrl
  );

  const body = newContact.body();
  console.log(`Edited contact JSON card :\n${body}`);
  if (isContactValid) {
    const response = await fetch(url, {
      method: "PUT",
      body: body,
    });
    if (response.ok) {
      console.log("Success editing contact");
      await refreshContacts();
    }
    const message = await response.text();
    console.log(message);

    const contact = new WebGwContact(JSON.parse(message)?.contact);
    void updateContactInExistingConversations(contact);
    void updateContactInExistingCallLogs(contact);

    return response.ok;
  }
};

export const deleteContact = async (
  contactId: string | undefined,
  accessToken: any
) => {
  if (!contactId) {
    return;
  }

  const user = getLocalUser();
  const removeDeleteContactURL = `${baseWebGwUrl}/addressbook/v1/${user}/contacts/${contactId}?access_token=${accessToken}`;

  const response = await fetch(removeDeleteContactURL, {
    method: "DELETE",
  });

  if (response.ok) {
    console.log("Success deleting contact ", contactId);
    await refreshContacts();
    // Timeout function to update the contacts list
    setTimeout(function () {
      document.getElementById("allContactsFilter")?.click();
      document.getElementById("root")?.click();
    }, 100);

    deleteContactFromExistingConversations(contactId);
    deleteContactFromExistingCallLogs(contactId);
  }

  return response.ok;
};
