import { type CSSProperties } from "react";
import { suspend } from "suspend-react";
import type { ChatMessage } from "../typings";

const fetchTimeout = 1000;

async function addStyleSheet(url: string) {
  const existingSheet = document.head.querySelector(
    `style[data-url="${url}"]`
  ) as HTMLStyleElement;
  if (existingSheet?.sheet) {
    return existingSheet.sheet;
  }

  const style = document.createElement("style");

  const controller = new AbortController();
  const abortTimeout = setTimeout(() => controller.abort(), fetchTimeout);
  try {
    const res = await fetch(url, { signal: controller.signal });
    clearTimeout(abortTimeout);

    style.textContent = await res.text();
    style.setAttribute("data-url", url);

    document.head.appendChild(style);

    style.sheet!.disabled = true;

    return style.sheet!;
  } catch {
    console.error("Couldn't fetch CSSStyle");
    return null;
  }
}

function snakeToCamel(s: string) {
  return s.replaceAll(/(-\w)/g, (m) => m[1].toUpperCase());
}

export const cssSelectors: Record<string, keyof RichCardStyleDeclarations> = {
  message: "message",
  "message.content.title": "title",
  "message.content.description": "description",
  "message.content.suggestions": "suggestions",
} as const;

export type RichCardStyleDeclarations = {
  message?: CSSProperties;
  title?: CSSProperties;
  description?: CSSProperties;
  suggestions?: CSSProperties;
};

async function getRichCardStylesFromSheet(url: string | undefined) {
  if (!url) return null;

  try {
    const styleSheet = await addStyleSheet(url);

    if (styleSheet) {
      const newStyles: RichCardStyleDeclarations = {};
      Array.from<CSSRule>(styleSheet.cssRules)?.forEach((rule) => {
        if (!(rule instanceof CSSStyleRule)) return;

        const selector = cssSelectors[rule.selectorText];
        if (!rule.style.length || !selector) return;
        // loop through the rules of this selector and add them to the new styles (excludes empty rules)
        newStyles[selector] = {} as CSSProperties;
        for (const property of Array.from(rule.style)) {
          newStyles[selector][snakeToCamel(property)] =
            rule.style.getPropertyValue(property);
        }
      });
      return newStyles;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
}

export function useRichCardCustomStyles(msg: ChatMessage) {
  // makes use of Suspense to prevent calculations from happening until the user's stylesheet is loaded
  return suspend(getRichCardStylesFromSheet, [getCustomStyleSheet(msg)]);
}

function getCustomStyleSheet(msg: ChatMessage) {
  return (
    msg.richcardMessage?.message?.generalPurposeCardCarousel?.layout.style ||
    msg.richcardMessage?.message?.generalPurposeCard?.layout.style
  );
}
