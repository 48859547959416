import BackgroundIcon from "@/assets/sketch/Background_Colour.svg?react";
import BrushIcon from "@/assets/sketch/Brush.svg?react";
import EraserIcon from "@/assets/sketch/Eraser.svg?react";
import ImageIcon from "@/assets/sketch/Image.svg?react";
import SpotsIcon from "@/assets/sketch/Spots.svg?react";
import UndoIcon from "@/assets/sketch/Undo.svg?react";
import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import { Tooltip } from "antd";
import { AnimatePresence, motion } from "motion/react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { SketchOptionsType } from ".";
import SketchToolBrushSize from "./SketchToolBrushSize";
import SketchToolColors from "./SketchToolColors";
const BRUSH_AND_BACKGROUND_COLORS = [
  "#ffffff",
  "#f44336",
  "#e91e63",
  "#ff2c93",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3c",
  "#ffc107",
  "#ff9800",
  "#795549",
  "#607d8b",
  "#9e9e9e",
];

// Default brush color is different depending who initiates the sketch
export const DEFAULT_BRUSH_COLOR_OUTGOING_INVITE =
  BRUSH_AND_BACKGROUND_COLORS[1];
export const DEFAULT_BRUSH_COLOR_INCOMING_INVITE =
  BRUSH_AND_BACKGROUND_COLORS[7];
export const DEFAULT_BRUSH_SIZE = 20;
export const DEFAULT_BACKGROUND_COLOR = BRUSH_AND_BACKGROUND_COLORS[0];

type SketchButtonBarProps = {
  onSelectBrush: VoidFunction;
  onSelectBrushColor: (color: string) => void;
  onSelectBrushSize: (size: number) => void;
  onSelectEraser: VoidFunction;
  onSelectBackgroundColor: (color: string) => void;
  onSelectBackgroundImage: (file: File) => void;
  onSelectUndo: VoidFunction;
  backgroundColor: string;
  options: SketchOptionsType;
};

const buttonCss = css({
  width: "1.5em",
  height: "1.5em",
});

type SelectedSketchToolType = {
  selected: SketchToolType;
  tool?: {
    type: SketchToolComponentType;
    component: React.ReactNode;
    centerX?: number;
  };
};

type SketchToolType = "brush" | "eraser";

type SketchToolComponentType = "brushColor" | "backgroundColor" | "brushSize";

export function SketchButtonBar({
  onSelectBrush,
  onSelectBrushColor,
  onSelectEraser,
  onSelectBackgroundColor,
  onSelectUndo,
  onSelectBrushSize,
  onSelectBackgroundImage,
  backgroundColor,
  options,
}: SketchButtonBarProps) {
  const divToolRef = useRef<HTMLDivElement>(null);
  const buttonBrushSizeRef = useRef<HTMLButtonElement>(null);
  const buttonBrushColorRef = useRef<HTMLButtonElement>(null);
  const buttonBackgroundColorRef = useRef<HTMLButtonElement>(null);
  const [savedBackgroundColors, setSavedBackgroundColors] = useState<string[]>(
    []
  );
  const [savedBrushColors, setSavedBrushColors] = useState<string[]>([]);
  const [selectedBrushColor, setSelectedBrushColor] = useState(
    options.defaultBrushColor
  );
  const [selectedBrushSize, setSelectedBrushSize] = useState(
    options.defaultBrushSize
  );
  const [selectedSketchTool, setSelectedSketchTool] =
    useState<SelectedSketchToolType>({ selected: "brush" });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    onSelectBackgroundImage(file);
    fileInputRef.current!.value = "";
  };

  useEffect(() => {
    if (!selectedSketchTool.tool) {
      return;
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        divToolRef.current &&
        !divToolRef.current.contains(event.target as Node) &&
        // Since we hide the sketch tool when clicking on it again, this avoid a hide/show again
        buttonBrushColorRef.current &&
        !buttonBrushColorRef.current.contains(
          event.target as globalThis.Node
        ) &&
        buttonBrushSizeRef.current &&
        !buttonBrushSizeRef.current.contains(event.target as globalThis.Node) &&
        buttonBackgroundColorRef.current &&
        !buttonBackgroundColorRef.current.contains(
          event.target as globalThis.Node
        )
      ) {
        setSelectedSketchTool((current) => {
          return { selected: current.selected };
        });
      }
    }

    window.addEventListener("pointerdown", handleClickOutside);
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("pointerdown", handleClickOutside);
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedSketchTool]);

  const handleSelectBrushColor = (color: string) => {
    closeSketchTool();
    setSelectedBrushColor(color);
    onSelectBrushColor(color);
  };

  const handleSaveBrushColor = (color: string) => {
    handleSaveColor(color, savedBrushColors, setSavedBrushColors);
  };

  const handleSelectBrushSize = (size: number) => {
    setSelectedBrushSize(size);
    onSelectBrushSize(size);
  };

  const handleSelectBackgroundColor = (color: string) => {
    closeSketchTool();
    onSelectBackgroundColor(color);
  };

  const handleSaveBackgroundColor = (color: string) => {
    handleSaveColor(color, savedBackgroundColors, setSavedBackgroundColors);
  };

  const handleSaveColor = (
    color: string,
    state: string[],
    setState: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    if (state.includes(color)) {
      return;
    }

    setState((previous) => [color, ...previous].slice(0, 4));
  };

  const handleSelectSketchToolUndo = () => {
    onSelectUndo();
    setSelectedSketchTool((current) => {
      return { selected: current.selected };
    });
  };

  const handleSelectSketchToolBrush = () => {
    onSelectBrush();
    setSelectedSketchTool({ selected: "brush" });
  };

  const handleSelectSketchToolEraser = () => {
    onSelectEraser();
    setSelectedSketchTool({ selected: "eraser" });
  };

  const handleSelectSketchToolBrushColor = () => {
    setSelectedSketchTool((current) => {
      return buildSelectedSketchTool(
        current,
        "brushColor",
        <SketchToolColors
          keyId={"brush"}
          selected={selectedBrushColor}
          onSelect={handleSelectBrushColor}
          onSaveColor={handleSaveBrushColor}
          savedHexColors={savedBrushColors}
          hexColors={BRUSH_AND_BACKGROUND_COLORS}
        />,
        buttonBrushColorRef.current
      );
    });
  };

  const handleSelectSketchToolBrushSize = () => {
    setSelectedSketchTool((current) => {
      return buildSelectedSketchTool(
        current,
        "brushSize",
        <SketchToolBrushSize
          selected={selectedBrushSize}
          onSelect={handleSelectBrushSize}
          min={DEFAULT_BRUSH_SIZE}
        />,
        buttonBrushSizeRef.current
      );
    });
  };

  const handleSelectSketchToolBackgroundImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSelectSketchToolBackgroundColor = () => {
    setSelectedSketchTool((current) => {
      return buildSelectedSketchTool(
        current,
        "backgroundColor",
        <SketchToolColors
          keyId={"background"}
          selected={backgroundColor}
          onSelect={handleSelectBackgroundColor}
          onSaveColor={handleSaveBackgroundColor}
          savedHexColors={savedBackgroundColors}
          hexColors={BRUSH_AND_BACKGROUND_COLORS}
        />,
        buttonBackgroundColorRef.current
      );
    });
  };

  const buildSelectedSketchTool = (
    current: SelectedSketchToolType,
    type: SketchToolComponentType,
    component: React.ReactNode,
    buttonRef: HTMLButtonElement | null
  ) => {
    return {
      ...current,
      tool:
        !current.tool || current.tool.type !== type
          ? {
              type,
              component,
              centerX: buttonRef
                ? buttonRef.offsetLeft +
                  buttonRef.getBoundingClientRect().width / 2
                : undefined,
            }
          : undefined,
    };
  };

  const closeSketchTool = () => {
    setSelectedSketchTool((current) => {
      return { selected: current.selected };
    });
  };

  return (
    <div
      css={{
        display: "flex",
        margin: "1em",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {selectedSketchTool.tool && (
        <AnimatePresence>
          <motion.div
            ref={divToolRef}
            css={{
              display: "flex",
              bottom: "5em",
              zIndex: "9999",
              minWidth: "10em",
              maxWidth: "12.5em",
              padding: "0.5em",
              height: "adjust-content",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              borderRadius: "10px",
              transform: "translate(-50%)",
              left: selectedSketchTool.tool.centerX,
              backgroundColor: colors.secondaryBackground,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
          >
            {selectedSketchTool.tool.component}
          </motion.div>
        </AnimatePresence>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: colors.secondaryBackground,
          borderRadius: "10px",
          width: "fit-content",
          overflow: "hidden",
        }}
      >
        <ButtonBox
          onClick={handleSelectSketchToolUndo}
          altText="Undo"
          showTooltip={true}
        >
          <UndoIcon css={buttonCss} />
        </ButtonBox>
        <ButtonBox
          altText="Brush"
          showTooltip={true}
          selected={selectedSketchTool.selected === "brush"}
          onClick={handleSelectSketchToolBrush}
        >
          <BrushIcon css={buttonCss} />
        </ButtonBox>
        <ButtonBox
          showTooltip={true}
          altText="Eraser"
          selected={selectedSketchTool.selected === "eraser"}
          onClick={handleSelectSketchToolEraser}
        >
          <EraserIcon css={buttonCss} />
        </ButtonBox>
        <ButtonBox
          showTooltip={selectedSketchTool.tool?.type !== "brushSize"}
          altText="Size"
          ref={buttonBrushSizeRef}
          onClick={handleSelectSketchToolBrushSize}
        >
          <SpotsIcon css={buttonCss} />
        </ButtonBox>
        <ButtonBox
          showTooltip={selectedSketchTool.tool?.type !== "brushColor"}
          altText="Brush colour"
          ref={buttonBrushColorRef}
          onClick={handleSelectSketchToolBrushColor}
        >
          <div
            css={buttonCss}
            style={{
              border: "2px solid white",
              borderRadius: "2px",
              backgroundColor: selectedBrushColor,
            }}
          ></div>
        </ButtonBox>
        <ButtonBox
          showTooltip={true}
          altText="Background image"
          onClick={handleSelectSketchToolBackgroundImage}
        >
          <ImageIcon css={buttonCss} />
        </ButtonBox>
        <ButtonBox
          showTooltip={selectedSketchTool.tool?.type !== "backgroundColor"}
          altText="Background colour"
          ref={buttonBackgroundColorRef}
          onClick={handleSelectSketchToolBackgroundColor}
        >
          <BackgroundIcon css={buttonCss} />
        </ButtonBox>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        accept=".jpg, .jpeg, .png, image/jpeg, image/png"
        onChange={onFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
}

type ButtonBoxType = {
  selected?: boolean;
  altText: string;
  showTooltip: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBox = ({
  ref,
  selected,
  altText,
  showTooltip,
  children,
  ...props
}: ButtonBoxType & {
  ref?: React.RefObject<HTMLButtonElement | null>;
}) => {
  return (
    <Tooltip
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      placement="top"
      title={altText}
      zIndex={showTooltip ? 99999 : 1}
    >
      <button
        ref={ref}
        css={[
          {
            cursor: "pointer",
            position: "relative",
            padding: "1em",
            "&:hover": { backgroundColor: colors.secondaryBackgroundLighter },
            ...(selected ? { color: colors.primaryAccentColor } : {}),
          },
        ]}
        {...props}
      >
        {children}
      </button>
    </Tooltip>
  );
};
