import { ease } from "@/utils/ease";
import kebabCase from "lodash/kebabCase";
import { MotionConfig } from "motion/react";
import { RefObject } from "react";
import { useSnapshot } from "valtio";
import MessageArea from "./MessageArea";
import style from "./chat.scss?inline";
import { confState } from "./chatConf";

export default function Chat({
  chatBoxRef,
}: {
  chatBoxRef: RefObject<HTMLElement>;
}) {
  const conf = useSnapshot(confState);

  let chatBoxRootStyles = "";
  for (const [key, value] of Object.entries(conf.style)) {
    if (value) {
      chatBoxRootStyles += `--${kebabCase(key)}: ${value};`;
    }
  }

  return (
    <>
      <style>{style}</style>
      {chatBoxRootStyles && (
        <style>{`.chatBoxRoot, .chatVars {${chatBoxRootStyles}}`}</style>
      )}

      <MotionConfig transition={{ duration: 0.35, ease: ease }}>
        <MessageArea chatBoxRef={chatBoxRef} />
      </MotionConfig>
    </>
  );
}
