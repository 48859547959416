import { Reaction } from "@/types/messaging";
import { ReactionList } from "@/types/OmaNms";
import toast from "react-hot-toast";
import { getLocalUser } from "../helpers/localstorage";
import Conversation from "./conversation/Conversation";
import { isSamePhoneNumber } from "./conversation/conversationUtils/phoneNumberUtils";
import { deleteMessages } from "./deleteMessages";
import NmsMessage from "./NmsMessage";

export async function addOrRemoveEmoji(
  emoji: string,
  parentMessageId: string,
  conversation: Conversation,
  reactions?: ReactionList
) {
  const localPhone = getLocalUser();

  if (!localPhone) {
    toast.error(`Error reacting with ${emoji}`);
    return;
  }

  // Check if local user already reacted with the same emoji
  const reactionExistsForLocal = reactions
    ? Object.entries(reactions)
        .filter(([key, _]) => {
          return key === emoji;
        })
        .map(([_, messages]) => {
          return messages.find((message) =>
            isSamePhoneNumber(message.From, localPhone)
          );
        })?.[0]
    : false;

  const isCustomReaction = emoji.startsWith("http");
  /**
   * Only support remove for all, and react on existing one (add) for normal
   * We could support react on same custom, but mobile does not support it and this will create a different behavior if file coming from nms or webgw:
   * - webgw, same custom reaction url, we will add the +2 on the emoji bubble
   * - nms, url always different even if same url sent initially to network, so no +2 but a different "same" emoji displayed (we could use base64 to compare but heavy for each)
   * - let's keep it simple for now, spec also does not mention reacting on same custom
   */
  const reactionToSend: Reaction | undefined =
    reactionExistsForLocal && isCustomReaction
      ? {
          id: reactionExistsForLocal["imdn.Message-ID"],
          type: "-Custom-Reaction",
          emoji: "",
        }
      : !isCustomReaction
        ? {
            id: parentMessageId,
            type: `${reactionExistsForLocal ? "-" : "+"}Reaction`,
            emoji,
          }
        : undefined;

  if (reactionToSend) {
    if (
      !(await conversation?.sendTextMessage(
        reactionToSend.emoji ?? emoji,
        false,
        reactionToSend
      ))
    ) {
      toast.error(`Error with ${emoji}`);
      return;
    }
  }

  // Remove case
  if (reactionExistsForLocal) {
    console.log("Removing reaction ", emoji);

    if (await deleteMessages([reactionExistsForLocal["imdn.Message-ID"]])) {
      if (!(reactionExistsForLocal instanceof NmsMessage)) {
        conversation.removeMessage(NmsMessage.from(reactionExistsForLocal));
      } else {
        conversation.removeMessage(reactionExistsForLocal);
      }
    } else {
      toast.error(`Error removing reaction ${emoji}`);
    }
  }
}
