import { useAtomValue } from "jotai";
import { atoms } from "../helpers/atoms";
import WebGwContact from "../helpers/WebGwContact";
import { useChatbotInfo } from "./useChatbotInfo";

export const useMediaExtraInfo = (contact?: WebGwContact) => {
  const chatBotMediaExtraInfo = useAtomValue(
    atoms.odience.chatBotMediaExtraInfo
  );

  const mediaExtraInfo = useChatbotInfo(contact).data?.mediaExtraInfo;

  // Media extra info from odience takes prevalence over the one from chatbot common info
  return chatBotMediaExtraInfo || mediaExtraInfo;
};
