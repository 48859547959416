import { keyframes } from "@emotion/react";

export default function SvgGraphic({
  src,
  ...props
}: { src: string } & React.ComponentProps<"span">) {
  return (
    <span
      {...props}
      css={[
        {
          padding: "2em",
          pointerEvents: "none",
          userSelect: "none",
          width: "100%",
          maxWidth: "30em",
          height: "100%",
        },
        (props as any).css,
      ]}
    >
      <img
        src={src}
        css={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0 0 2px rgb(200,200,200))",
          animation: `${keyframes({
            "100%": { filter: "none" },
          })} 1s ease forwards`,
        }}
      />
    </span>
  );
}
