import { messageAreaRef } from "../messageAreaRef";
import { isOverflownY } from "./cardUtils";

/**
 * gets media scale in viewport units
 * @param opts
 * @returns width and height to set the media to
 */
function getMediaScale({
  width,
  height,
  maxVW,
  maxVH,
}: {
  width: number;
  height: number;
  maxVW: number;
  maxVH: number;
}): {
  vw: string;
  vh: string;
} {
  maxVW /= 100;
  maxVH /= 100;

  let endingWidth = window.innerWidth;
  let endingHeight = window.innerHeight;
  endingWidth = endingHeight * (width / height);
  endingHeight = endingWidth * (height / width);

  if (height < width) {
    const widthScale = window.innerWidth / endingWidth; /*  * (maxVW / 100) */
    endingWidth *= widthScale;
    endingHeight *= widthScale;
  } else {
    const heightScale =
      window.innerHeight / endingHeight; /*  * (maxVH / 100) */
    endingWidth *= heightScale;
    endingHeight *= heightScale;
  }

  if (endingWidth > window.innerWidth * maxVW) {
    const oldWidth = endingWidth;
    endingWidth = window.innerWidth * maxVW;
    endingHeight *= endingWidth / oldWidth;
  }
  if (endingHeight > window.innerHeight * maxVH) {
    const oldHeight = endingHeight;
    endingHeight = window.innerHeight * maxVH;
    endingWidth *= endingHeight / oldHeight;
  }

  const endingWidthVW = (endingWidth / window.innerWidth) * 100 + "vw";
  const endingWidthVH = (endingHeight / window.innerHeight) * 100 + "vh";

  return { vw: endingWidthVW, vh: endingWidthVH };
}

const MEDIA_MAX_VW = 85;
const MEDIA_MAX_VH = 70;

export function fullScreenMedia(
  mediaElem: HTMLImageElement | HTMLVideoElement | HTMLAudioElement,
  mediaWrapperElem: HTMLElement
) {
  if (!mediaElem || mediaElem.classList.contains("fullscreen")) return;

  mediaElem.style.borderRadius = "6px";

  const { top, left, width, height } = mediaElem.getBoundingClientRect();
  mediaElem.style.setProperty("--starting-left", `${left}px`);
  mediaElem.style.setProperty("--starting-top", `${top}px`);
  mediaElem.style.setProperty("--starting-width", `${width}px`);
  mediaElem.style.setProperty("--starting-height", `${height}px`);

  // help with animating aspect ratio
  mediaElem.style.setProperty(
    "--starting-aspect-ratio",
    mediaElem.style.aspectRatio
  );

  let naturalWidth: number;
  let naturalHeight: number;

  if (mediaElem instanceof HTMLImageElement) {
    naturalWidth = mediaElem.naturalWidth;
    naturalHeight = mediaElem.naturalHeight;
  } else if (mediaElem instanceof HTMLVideoElement) {
    naturalWidth = mediaElem.videoWidth;
    naturalHeight = mediaElem.videoHeight;
  } else {
    naturalWidth = 100;
    naturalHeight = 100;
  }

  mediaElem.style.setProperty("--natural-width", `${naturalWidth}`);
  mediaElem.style.setProperty("--natural-height", `${naturalHeight}`);
  mediaElem.style.removeProperty("max-height");

  const setEndingSizes = () => {
    // find the size the final image should be
    // scales by viewport dimensions
    const { vw, vh } = getMediaScale({
      width: naturalWidth,
      height: naturalHeight,
      maxVW: MEDIA_MAX_VW,
      maxVH: MEDIA_MAX_VH,
    });

    mediaElem.style.setProperty("--ending-width", vw);
    mediaElem.style.setProperty("--ending-height", vh);
  };
  setEndingSizes();
  window.addEventListener("resize", setEndingSizes);

  // @ts-expect-error
  mediaElem.removeResizeListener = () => {
    window.removeEventListener("resize", setEndingSizes);
  };

  // HACK js wizardry

  const wrapperParent = mediaWrapperElem.parentElement!;

  const dummy = document.createElement("div");
  dummy.style.minWidth = `${width}px`;
  dummy.style.minHeight = `${height}px`;
  dummy.setAttribute("data-place-here", "");
  mediaWrapperElem.replaceWith(dummy);

  document.body.appendChild(mediaWrapperElem);
  mediaWrapperElem.style.position = "fixed";
  mediaWrapperElem.style.zIndex = "999";

  // @ts-expect-error
  mediaWrapperElem.originalParent = wrapperParent;

  // make image fullscreen
  mediaElem.classList.add("fullscreen");
  (mediaElem as HTMLMediaElement).controls = true;
}

export function unFullscreenMedia(
  mediaElem: HTMLElement,
  mediaWrapperElem: HTMLElement,
  scrollElem: HTMLElement
) {
  // @ts-expect-error
  const wrapperParent = mediaWrapperElem.originalParent! as HTMLElement;

  const { borderRadius: outerBorderRadius } = getComputedStyle(wrapperParent);
  mediaElem.style.borderRadius = outerBorderRadius;

  // @ts-expect-error
  mediaElem.removeResizeListener?.();

  const scrollHandler = () => {
    const { top: parentTop, left: parentLeft } =
      wrapperParent.getBoundingClientRect();
    mediaElem.style.setProperty("--ending-left", `${parentLeft}px`);
    mediaElem.style.setProperty("--ending-top", `${parentTop}px`);
  };
  scrollHandler();
  scrollElem.addEventListener("scroll", scrollHandler);

  mediaElem.classList.add("from-fullscreen");

  const replaceElem = wrapperParent.querySelector("[data-place-here]")!;

  const wasOverflown = isOverflownY(
    replaceElem,
    messageAreaRef!.parentElement!
  );
  if (wasOverflown) {
    mediaElem.style.opacity = "0";
  }
  const originalControls = (mediaElem as HTMLMediaElement).controls;
  (mediaElem as HTMLMediaElement).controls = false;
  const removeFromFullscreen = () => {
    mediaElem.style.borderRadius = "";

    mediaElem.classList.remove("from-fullscreen");
    mediaElem.classList.remove("fullscreen");
    if (wasOverflown) {
      mediaElem.style.opacity = "0";
      setTimeout(() => {
        mediaElem.style.opacity = "1";
      });
    }

    replaceElem.replaceWith(mediaWrapperElem);
    mediaWrapperElem.style.removeProperty("position");
    mediaWrapperElem.style.removeProperty("z-index");
    delete (mediaWrapperElem as any).originalParent;
    // replaceWith automatically removed it from document.body

    (mediaElem as HTMLMediaElement).controls = originalControls;

    if (mediaElem.tagName === "VIDEO") {
      const video = mediaElem as HTMLVideoElement;
      if (!video.paused) {
        video.pause();
      } else {
        // Intentionally play and immediately pause the video again to trigger the onpause event to display the play button again
        void video.play();
        video.pause();
      }
    }
    scrollElem.removeEventListener("scroll", scrollHandler);
  };
  mediaElem.addEventListener("animationend", removeFromFullscreen, {
    once: true,
  });
}
