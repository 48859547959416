import { colors } from "@/styles/global.styles";
import { usePrevious } from "@/utils/hooks/usePrevious";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";
import { PrimaryButton } from "./Button";

export default function ScrollToTopButton() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const prevShowScrollToTop = usePrevious(showScrollToTop);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <PrimaryButton
      css={{
        position: "fixed",
        bottom: "min(2em, 6vw)",
        right: "min(2em, 6vw)",
        backgroundColor: colors.primaryAccentColor,
        padding: "0.5em",
        opacity: 0,
        // slide up
        animation: showScrollToTop
          ? `${keyframes({
              from: { transform: "translateY(100%)", opacity: 0 },
              to: { transform: "translateY(0)", opacity: 1 },
            })} 0.5s ease forwards`
          : prevShowScrollToTop
            ? `${keyframes({
                from: { transform: "translateY(0)", opacity: 1 },
                to: { transform: "translateY(100%)", opacity: 0 },
              })} 0.25s ease forwards`
            : undefined,
      }}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      Go To Top
    </PrimaryButton>
  );
}
