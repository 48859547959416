import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { atoms } from "../helpers/atoms";
import { loginQuery } from "../queries/login";

export function useLogin() {
  const { data: _isLoggedIn } = useQuery(loginQuery);
  const isLoggedIn = !!_isLoggedIn;
  const setIsLoggedIn = useSetAtom(atoms.provisioning.isLoggedIn);
  // We have to set the isLoggedInAtom here only if /login succeeds since we do not have any notification channel yet.

  // We can only know our registration state from the notification channel.
  // So the isLoggedInAtom will be set in useWebgwSubscription:handleClientStateNotification.
  useEffect(() => {
    setIsLoggedIn(isLoggedIn);
  }, [isLoggedIn]);
}
