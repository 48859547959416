import MuteIcon from "@/assets/mute.svg";
import { isIOS, isMobile } from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import { RefObject, useEffect, useRef, useState } from "react";
import {
  FullScreenElement,
  fullScreenPromoVideo,
} from "../helpers/EventDetailsUtils";

const PromoVideo = ({ objEvent }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRef = useRef<FullScreenElement>(null!);
  const [videoMuted, setVideoMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleVideoClickMobile = (e: React.MouseEvent) => {
    if (videoRef.current) {
      if (isIOS()) {
        setIOSFullScreen(true);
      } else {
        videoRef.current.requestFullscreen().catch((err) => {
          console.error("Error attempting to enter fullscreen mode:", err);
        });
      }

      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  /**
   * iOS uses the inline attribute for the full screen mode,
   * the pause and play here are needed for the switch
   */
  const setIOSFullScreen = (fullScreen: boolean) => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.playsInline = !fullScreen;
      videoRef.current.play();
    }
  };

  const handleVideoClickDesktop = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (videoRef.current) {
      const enteringFullScreen = !isFullScreen;
      setIsFullScreen(enteringFullScreen);

      fullScreenPromoVideo(
        videoRef.current,
        mediaRef.current,
        enteringFullScreen
      );

      videoRef.current.muted = !enteringFullScreen;
    }
  };

  const handleIOSFullScreenChanges = () => {
    /**
     * The timeout here is needed to allow the browser to set back the video element in the DOM
     */
    setTimeout(() => {
      setIOSFullScreen(false);
    }, 500);
  };

  useEffect(() => {
    if (videoRef.current && isIOS()) {
      videoRef.current.addEventListener(
        "webkitendfullscreen",
        handleIOSFullScreenChanges
      );

      return () => {
        videoRef.current?.removeEventListener(
          "webkitendfullscreen",
          handleIOSFullScreenChanges
        );
      };
    }
  }, []);

  const handleVideoEnded = () => {
    if (videoRef.current) {
      videoRef.current.loop = true;
    }
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (
      isFullScreen &&
      !mediaRef.current.contains(e.target as Node) &&
      videoRef.current
    ) {
      if (!isMobile()) {
        setIsFullScreen(false);
        fullScreenPromoVideo(videoRef.current, mediaRef.current, false);
      }

      videoRef.current.muted = true;
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handleMuteChange = () => {
        setVideoMuted(videoElement.muted);
      };
      videoElement.addEventListener("volumechange", handleMuteChange);
      setVideoMuted(videoElement.muted);

      return () => {
        videoElement.removeEventListener("volumechange", handleMuteChange);
      };
    }
  }, [videoRef]);

  useEffect(() => {
    if (isFullScreen) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isFullScreen]);

  const videoSource = objEvent.promo_video_url;

  const handleVideoClick = isMobile()
    ? handleVideoClickMobile
    : handleVideoClickDesktop;

  const [videoError, setVideoError] = useState(false);

  if (videoError) {
    return null;
  }

  return (
    <div
      css={{
        position: "relative",
        display: "inline-block",
      }}
      onClick={handleVideoClick}
      ref={mediaRef as RefObject<HTMLDivElement>}
    >
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        onEnded={handleVideoEnded}
        src={videoSource}
        css={[videoStyles, isFullScreen && fullscreenStyles]}
        onError={() => setVideoError(true)}
      ></video>
      {videoMuted && (
        <button
          css={imageWrapperStyles}
          onClick={(e) => {
            setVideoMuted(!videoMuted);
          }}
        >
          <img src={MuteIcon} alt="Mute Icon" />
        </button>
      )}
    </div>
  );
};

export default PromoVideo;

const videoStyles = css`
  position: relative;
  height: 10em;
  min-height: 10em;
  min-width: 10em;
  border-radius: 16px;
  transition: all 0.5s ease-in-out;
`;

const imageWrapperStyles = css`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 1.5em;
  height: 1.5em;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fullscreenStyles = css`
  position: fixed;
  z-index: 999;
  left: var(--ending-left);
  top: var(--ending-top);
  width: var(--ending-width);
  height: var(--ending-height);
`;
