import PolicyScreen from "@/components/shared/PolicyScreen";

const EULAText = `This Mobile Application and Web Service End User License Agreement ("Agreement") is a binding agreement between you ("End User" or "you") and SUMMIT-TECH MULTIMEDIA COMMUNICATIONS INC. ("Company"). This Agreement governs your use of the VERSE MESSAGES, (including all related documentation, the "Application"). The Application is licensed, not sold, to you.
BY CLICKING THE, “DOWNLOAD” OR "AGREE" BUTTON, AS THE CASE MAY BE AND BY USING THIS APPLICATION OR SERVICE, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL OR USE THE APPLICATION AND, IF APPLICABLE, DELETE IT FROM YOUR MOBILE DEVICE.
1.	License Grant. Subject to the terms of this Agreement, Company grants you a limited, non-exclusive, and nontransferable license to:
(a)	 download, install, access, and use the Application or Service for your use on a single mobile device or web browser owned or otherwise controlled by you ("Mobile Device") strictly in accordance with the Application's documentation; 
(b)	access, play, download, and use on such Mobile Device the Content and Services (as defined in Section 5) made available in or otherwise accessible through the Application, strictly in accordance with this Agreement and the Terms of Use applicable to such Content and Services as set forth in Section 5.
2.	License Restrictions. You shall not: 
(a)	copy the Application, except as expressly permitted by this license;
(b)	modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;
(c)	reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;
(d)	remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Application, including any copy thereof;
(e)	rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application, or any features or functionality of the Application, to any third party for any reason, including by making the Application available on a network where it is capable of being accessed by more than one device at any time; or
(f)	remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the Application; 
3.	Reservation of Rights. You acknowledge and agree that the Application or Service is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application or Service under this Agreement, or any other rights thereto other than to use the Application or Service in accordance with the license granted, and subject to all terms, conditions, and restrictions, under this Agreement. Company reserves and shall retain its entire right, title, and interest in and to the Application or Service, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.
4.	Collection and Use of Your Information. You acknowledge that when you download, install, or use the Application or Service, Company may use automatic means (including, for example, cookies and web beacons) to collect information about your Mobile Device or Web Browser and about your use of the Application or Service. You also may be required to provide certain information about yourself as a condition to downloading, installing, or using the Application/Service or certain of its features or functionality, and the Application/Service may provide you with opportunities to share information about yourself with others. All information we collect through or in connection with this Application is subject to our Privacy Policy, available at: https://www.summit-tech.ca/privacyPolicy. By downloading, installing, using, and providing information to or through this Application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
5.	Shared Content. You acknowledge when you download, install or use the Application/Service that you shall not use the Application/Service to share any content, in any form whatsoever that (i) violates this Agreement, the Privacy Policy or any other policy adopted by the Company, (ii) is unlawful, misleading, discriminatory or fraudulent, (iii) that infringes or violates someone else’s rights, including intellectual property rights and you represent that any content published, uploaded, distributed or otherwise communicated through the Application is lawfully yours and, if applicable, you have secured all rights and licenses to do so. You hereby further recognize that the Company has an irrevocable right to restrict access or remove any content that is in violation of this Agreement, without notice or delay.
6.	Permissions. You acknowledge when you download, install or use the Application/Service that you grant the Company an irrevocable non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of any content you publish, upload, distribute or otherwise communicate through the Application/Service. 
7.	Content and Services. The Application may provide you with access to Company's servers (the "Server") and products and services accessible thereon, and certain features, functionality, and content accessible on or through the Application may be hosted on the Server (collectively, "Content and Services"). Your access to and use of such Content and Services are governed by this Agreement and Privacy Policy located at https://summit-tech.ca/verse_messages/privacyPolicy , which is incorporated herein by this reference. Your access to and use of such Content and Services may require you to acknowledge your acceptance of such Privacy Policy and/or to register an account with the Company, and your failure to do so may restrict you from accessing or using certain of the Application's features and functionality. Any violation of such Privacy Policy will also be deemed a violation of this Agreement.
8.	Compliance with Law. You represent and warrant that your access or use of the Application/Service for business or commercial purposes complies with all applicable laws, rules, and regulations. You further represent that you will restrict access to your content in accordance with all applicable laws, rules, and regulations. In addition to and without limiting the requirements about who can use the Application, if you are located in a country that is subject to embargo under the laws of the United States (or under similar laws applicable to you) you may not engage in commercial activities the Application unless authorized by applicable laws. If you are on the U.S. Treasury Department’s list of Specially Designated Nationals (or an equivalent list), you may not engage in commercial or business activities on the Application (such as advertising or payments). You also may not access or use the Application if you are prohibited from receiving products, services, or software under applicable law.
9.	Geographic Restrictions. The Content and Services are based in the province of Québec in Canada and provided for access and use only by persons located in Canada or in the United States. You acknowledge that you may not be able to access all or some of the Content and Services outside of Canada and the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Content and Services from outside Canada or the United States, you are responsible for compliance with local laws.
10.	Updates. Company may from time to time in its sole discretion develop and provide Application/Service updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, "Updates"). Updates may also modify or delete in their entirety certain features and functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is connected to the internet either:
(a)	the Application will automatically download and install all available Updates; or
(b)	you may receive notice of or be prompted to download and install available Updates.
You shall promptly download and install all Updates and acknowledge and agree that the Application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.
11.	Third-Party Materials. The Application/Service may display, include, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising ("Third-Party Materials"). You acknowledge and agree that Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Company does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
12.	Term and Termination.
(a)	The term of Agreement commences when you download, access, or install the Application/Service and will continue in effect until terminated by you or Company as set forth in this Section 9.
(b)	You may terminate this Agreement by deleting the Application and all copies thereof from your Mobile Device.
(c)	Company may terminate this Agreement at any time without notice if it ceases to support the Application/Service, which Company may do in its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement. Company may also suspend or permanently disable access to your account in the event of violation of any terms of this Agreement. Company may also suspend or disable your account if you repeatedly infringe other people’s intellectual property rights or should the Company be required to do so for legal reasons.
(d)	Upon termination:
(i)	all rights granted to you under this Agreement will also terminate; and
(ii)	you must cease all use of the Application/Service and delete all copies of the Application from your Mobile Device and account.
(e)	Termination will not limit any of Company's rights or remedies at law or in equity.
13.	Disclaimer of Warranties. THE APPLICATION IS PROVIDED TO END USER "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. 
14.	Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:
(a)	PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.
(b)	DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. 
THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. 
15.	Indemnification. You agree to indemnify, defend, and hold harmless Company and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement, including but not limited to the content you submit or make available through this Application.
16.	Severability. If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.
17.	Governing Law. This Agreement is governed by and construed in accordance with the internal laws of the province of Québec without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or related to this Agreement or the Application shall be instituted exclusively before the courts of the province of Québec, in the city of Montréal. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts. 
18.	Entire Agreement. This Agreement and our Privacy Policy constitute the entire agreement between you and Company with respect to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application. 
19.	Waiver. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.`;

export default function EULA() {
  return (
    <PolicyScreen
      titleText="Application End User License Agreement"
      bodyText={EULAText}
    />
  );
}
