import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { ShowToast } from "@/utils/helpers/toastManager";
import {
  Configuration,
  TokenEndpointResponse,
  genericGrantRequest,
} from "openid-client";
import { directorUrl } from "..";
import { getConfig } from "../helpers/config";
import {
  getLocalDirectorRefreshToken,
  getLocalDirectorToken,
  getLocalOdienceUser,
  getLocalUser,
  setLocalDirectorRefreshToken,
  setLocalDirectorToken,
  setLocalOdienceUser,
} from "../helpers/localstorage";

export const DEFAULT_AVATAR = "/odience/user/user.png";

export type OdienceUser = {
  avatar: string | null;
  name: string;
  msisdn: string;
  group_id?: string;
  user_id?: string;
  roles?: OdienceUserRoles;
  email?: string;
  emails?: string[];
};

export type OdienceUserRoles = {
  super_admin: boolean;
};

export type OdienceEvent = {
  id: string;
  active: boolean;
  name: string;
  label: string;
  namespace: string;
  date: number;
  duration: number | null;
  location: string;
  brand: {
    brand_title: string;
    brand_subtitle: string;
    brand_background_color: string;
    brand_text_color: string;
    brand_image_url: string;
    brand_background_image_url: string;
    brand_logo_padding: number;
    brand_ad_image_url: string;
    brand_background_opacity: number;
  };
  stream_order?: object | null;
  chatbots: {
    id: string;
    action_image_url: string;
    display_name: string;
    bot_id: string;
    payload: string;
    chat_theme: {
      chat_background_color: string;
      top_line_color: string;
      chat_text_color: string;
      chat_text_colors: {
        self: string;
        others: string;
      };
      chat_bubble_color: {
        self: string;
        others: string;
        stroke: string;
        others_stroke: string;
      };
    };
    rich_card_theme: {
      rich_card_background_color: string;
      rich_card_description_color: string;
      rich_card_title_color: string;
      suggested_replies_theme: {
        reply_text_color: string;
        reply_background_color: string;
        reply_outline_color: string;
      };
    };
    chatbot_image_alignment: string;
    chatbot_image_style: string;
  }[];
  description: string;
  capacity: number;
  min_price: number;
  organization: string;
  organization_id: string;
  organization_image_url: string;
  ticket_url: string;
  ticket_platform: string;
  messageInterval: number;
  isRunning: boolean;
  usersConnected: number;
  category: string;
  categoryImage: string;
  imageUrl: string;
  map_image_url: string;
  promo_video_url: string;
  promo_video_aspect_ratio: string;
  is_5g: boolean;
  ai_detection: boolean;
  profanity: boolean;
  is_public: boolean;
  invitations_only: boolean;
  poll_started: boolean;
  event_started: boolean;
  live_stream_switching: boolean;
  mini_carousel_open: boolean;
  mini_carousel_orientation: string;
  silent_mode: boolean;
  picture_in_picture_mode: boolean;
  picture_in_picture_params: {
    streamId: string;
    position: string;
  };
  coordinates: {
    lat: number;
    lng: number;
  } | null;
  invitation_message: string;
  banned: boolean;
  blocked: boolean;
  opened: boolean;
  interested: boolean;
  usersInterestedCount: number;
  event_ended: boolean;
  recording_started_timestamp: number;
  invitation_accepted: boolean;
  invitation_requested: boolean;
  registered: boolean;
  complete: boolean;
  pre_access: boolean;
  settings: {
    event_feature_chat: boolean;
    message_interval: number;
    event_message_reminder: number;
    event_end_reminder_interval: number;
    time_limited_invitation: string;
    videowall_request_invite_duration: number;
    videowall_reaction_duration_interval: number;
    videowall_call_resolution: string;
    nft_bot: string;
    automatic_sms_items: string;
    maximum_stream_messages: number;
    chat_profanity: string;
  };
  has_ricoh_stream: boolean;
  owner_id: string;
  payed: boolean;
  web_allowed: number;
  app_allowed: number;
};

const waitForConfig = async () => {
  if (!isProvisioned()) return;
  let config: Awaited<ReturnType<typeof getConfig>> | undefined;
  const checkInterval = 1000; // 1 second delay between checks

  while (!config) {
    config = await getConfig();
    if (config || !isProvisioned()) {
      break;
    } else {
      console.error("Config not available, retrying...");
      await new Promise((resolve) => setTimeout(resolve, checkInterval));
    }
  }

  return config;
};

export const authenticateDirector = async (): Promise<
  OdienceUser | undefined
> => {
  const userInfoApiPath = "/api/getUserInfo";

  const fetchUserData = async (
    token: string
  ): Promise<OdienceUser | undefined> => {
    const maxRetryAttempts = 3;
    const retryDelay = 1000; // 1 second delay between retries

    for (
      let retryAttempt = 0;
      retryAttempt < maxRetryAttempts;
      retryAttempt++
    ) {
      try {
        const response = await fetch(directorUrl + userInfoApiPath, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });

        if (response.status === 401) {
          console.log("Unauthorized Token failed to fetch user data.");
        } else if (response.ok) {
          const userData = await response.json();
          if (userData.avatar === null) {
            userData.avatar = "";
          }
          setLocalOdienceUser(JSON.stringify(userData));
          return userData;
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }

      // Add a delay before the next retry
      if (retryAttempt < maxRetryAttempts - 1) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }

    return undefined;
  };

  const config = await waitForConfig();

  if (!config) {
    console.error("Undefined config");
    return undefined;
  }

  if ("application/other/director/otp" in config) {
    const otpValue = config["application/other/director/otp"];
    const localToken = getLocalDirectorToken();
    const token = localToken ? localToken : await fetchDirectorTokens(otpValue);
    return fetchUserData(token!);
  } else {
    console.log("OTP Value not found in the JSON data.");
  }
};
const fetchDirectorTokens = async (
  otpValue: string
): Promise<string | undefined> => {
  const client_id = "90a9e369-1c02-4cec-b34b-4c0685143eba";
  const client_secret = "xLeuik210XWIZRYFMqvTLWIYzEVibUTt8nzUP5bL";
  const username = (getLocalUser() ?? "").replaceAll("+", "");
  const password = otpValue;

  try {
    const config = new Configuration(
      {
        issuer: directorUrl,
        token_endpoint: directorUrl + "/oauth/token",
      },
      client_id,
      { client_secret }
    );
    const data: TokenEndpointResponse = await genericGrantRequest(
      config,
      "password",
      { username, password }
    );
    setLocalDirectorToken(data.access_token);
    setLocalDirectorRefreshToken(data.refresh_token as string);
    return data.access_token;
  } catch (error) {
    console.error("Error:", error);
    return undefined;
  }
};

export const refreshDirectorBearerToken = async (): Promise<void> => {
  const localToken = getLocalDirectorRefreshToken();
  const config = await waitForConfig();

  if (!localToken && config) {
    const otpValue = config["application/other/director/otp"];
    await fetchDirectorTokens(otpValue);
  } else {
    console.log("No refresh token or config available.");
  }
};

export const isAllowed = (event: OdienceEvent): boolean => {
  const odienceUser = getLocalOdienceUser();
  const parsedUser = JSON.parse(odienceUser!);
  if (parsedUser?.roles?.super_admin) {
    return true;
  }
  if (event.is_public === false) {
    if (event.invitation_accepted === false) {
      return false;
    }
  }
  if (event.payed === true) {
    if (event.registered === false) {
      return false;
    }
  }

  return true;
};

export const fetchEventInfo = async (
  groupId: string,
  eventId: string,
  showToast: ShowToast
): Promise<OdienceEvent | void> => {
  const apiUrl = directorUrl + "/api/" + "event/info/" + eventId;
  const maxRetryAttempts = 3;

  if (!getLocalDirectorToken()) {
    await refreshDirectorBearerToken();
  }

  for (let retryAttempt = 0; retryAttempt < maxRetryAttempts; retryAttempt++) {
    const headers = {
      Authorization: `Bearer ${getLocalDirectorToken()}`,
      Accept: "application/json",
    };

    try {
      const response = await fetch(apiUrl, { headers });

      if (response.status === 401) {
        console.log("FETCH EVENT INFO UNAUTHORIZED TOKEN");
      } else if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          try {
            const data = await response.json();
            console.log("data", data);
            if (data) {
              if (!isAllowed(data)) {
                showToast(
                  "The specified event is either private or not available to be viewed.",
                  "bottom-right",
                  "1vw"
                );
                window.location.href = "/#/odience";
              } else {
                return data;
              }
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.error("Non-JSON response received");
        }
        break;
      } else if (response.status === 404) {
        window.location.href = "/#/odience";
      } else {
        console.error("Request failed with status:", response.status);
        break;
      }
    } catch (error) {
      await refreshDirectorBearerToken();
      console.error("Error fetching data:", error);
    }
  }

  throw new Error("Failed to fetch event data");
};

export const fetchPublicEventInfo = async (
  eventId: string
): Promise<OdienceEvent[]> => {
  const headers = new Headers();
  headers.append("Accept", "application/json");

  const requestOptions: RequestInit = {
    method: "GET",
    headers: headers,
  };

  const response = await fetch(
    new URL(`/web/api/eventsList/?id=${eventId}`, directorUrl),
    requestOptions
  );

  if (response.ok) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      try {
        const data = await response.json();
        if (data) {
          return data.data as OdienceEvent[];
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Non-JSON response received");
    }
  }

  return [];
};

export const fetchEvents = async (): Promise<OdienceEvent[]> => {
  const apiUrl = directorUrl + "/api/events?per_page=9999";
  const maxRetryAttempts = 3;

  if (!getLocalDirectorToken()) {
    await refreshDirectorBearerToken();
  }

  for (let retryAttempt = 0; retryAttempt < maxRetryAttempts; retryAttempt++) {
    const headers = {
      Authorization: `Bearer ${getLocalDirectorToken()}`,
      Accept: "application/json",
    };

    try {
      const response = await fetch(apiUrl, { headers });

      if (response.status === 401) {
        console.log("FETCH EVENTS UNAUTHORIZED TOKEN");
      } else if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          try {
            const data = await response.json();
            if (data && data.events) {
              return data.events as OdienceEvent[];
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.error("Non-JSON response received");
        }
        break;
      } else {
        console.error("Request failed with status:", response.status);
        break;
      }
    } catch (error) {
      await refreshDirectorBearerToken();
      console.error("Error fetching data:", error);
    }
  }

  return [];
};

export const fetchPublicEvents = async (): Promise<OdienceEvent[]> => {
  const headers = new Headers();
  headers.append("Accept", "application/json");

  const requestOptions: RequestInit = {
    method: "GET",
    headers: headers,
  };

  const response = await fetch(
    new URL("/web/api/eventsList", directorUrl),
    requestOptions
  );

  if (response.ok) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      try {
        const data = await response.json();
        if (data) {
          return data.data as OdienceEvent[];
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Non-JSON response received");
    }
  }

  return [];
};
