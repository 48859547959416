import { convertToHex, convertToHex8, convertToRgba } from "@/utils/colors";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { ColorPicker } from "primereact/colorpicker";
import { useMemo, useState } from "react";

const StyledColorPicker = styled(ColorPicker)`
  .p-colorpicker-panel .p-colorpicker-hue {
    width: 10px !important;
  }

  .p-colorpicker-panel .p-colorpicker-color-handle {
    border-radius: 50%;
    border: 2px solid white;
    opacity: 1;
    width: 10px;
    height: 10px;
  }

  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-radius: 50%;
    border: 2px solid white;
    opacity: 1;
    margin-left: 0;
    left: 0;
    width: 10px;
    height: 10px;
  }
`;

const bubbleColorStyle = (color?: string) =>
  css({
    cursor: "pointer",
    width: "1.3em",
    height: "1.3em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(color
      ? {
          borderRadius: "50%",
          backgroundColor: color,
          boxShadow: `inset 0 0 0 0.5px ${convertToRgba("#ffffff", 0.7)}`,
        }
      : {}),
  });

const PredifinedColors = ({
  colors,
  onSelect,
  selected,
  onToggleColorPicker,
}: {
  colors: string[];
  onSelect: (color: string) => void;
  selected: string;
  onToggleColorPicker?: VoidFunction;
}) => {
  return (
    <div
      style={{
        gap: "0.5em",
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        alignItems: "center",
      }}
    >
      {onToggleColorPicker && (
        <button onClick={onToggleColorPicker} css={bubbleColorStyle()}>
          <AddCircleOutlineOutlinedIcon style={{ fontSize: "1.4em" }} />
        </button>
      )}
      {colors.map((color, index) => (
        <ColorBubble
          key={index}
          selected={selected}
          color={color}
          index={index}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};
const ColorBubble = ({
  onSelect,
  color,
  selected,
}: {
  onSelect: (color: string) => void;
  color: string;
  index: number;
  selected: string;
}) => {
  return (
    <div onClick={() => onSelect(color)} css={bubbleColorStyle(color)}>
      {selected === color && <CheckOutlinedIcon style={{ fontSize: "1em" }} />}
    </div>
  );
};

const SketchToolColors = ({
  keyId,
  selected,
  onSelect,
  hexColors,
  savedHexColors,
  onSaveColor,
}: {
  keyId: "background" | "brush";
  selected: string;
  onSelect: (color: string) => void;
  hexColors: string[];
  savedHexColors: string[];
  onSaveColor: (color: string) => void;
}) => {
  // Could be in rgba format, convert it for comparison
  const hexSelected = convertToHex8(selected);

  const convertedColors = useMemo(
    () => hexColors.map((current) => convertToHex8(current)),
    [hexColors]
  );
  const convertedSavedColors = useMemo(
    () => savedHexColors.map((current) => convertToHex8(current)),
    [savedHexColors]
  );
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [colorPickerSelectedColor, setColorPickerSelectedColor] = useState(
    // Color picker only uses hex colors
    convertToHex(selected)
  );

  const handleSelectPickerColor = (color: string) => {
    onSaveColor(color);
    onSelect(color);
  };

  const toggleColorPicker = () => {
    setShowColorPicker((previous) => !previous);
  };

  return (
    <div
      key={keyId}
      style={{
        ...(!showColorPicker
          ? {
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.5em",
            }
          : {}),
        width: "100%",
      }}
    >
      {showColorPicker ? (
        <div>
          <StyledColorPicker
            format="hex"
            defaultColor={selected.substring(1)}
            value={selected}
            onChange={(e) =>
              e.value
                ? setColorPickerSelectedColor(`#${e.value.toString()}`)
                : ""
            }
            inline
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              fontSize: "0.7em",
            }}
          >
            <div>
              HEX:{" "}
              <span
                style={{
                  paddingRight: "0.5em",
                  paddingLeft: "0.5em",
                  borderBottom: "1px solid #53585C",
                }}
              >
                {colorPickerSelectedColor}
              </span>
            </div>
            <button
              onClick={() => handleSelectPickerColor(colorPickerSelectedColor)}
              style={{
                fontSize: "0.9em",
                fontWeight: "bold",
                borderRadius: "8px",
                backgroundColor: "#53585C",
                padding: "8px",
              }}
            >
              Select
            </button>
          </div>
        </div>
      ) : (
        <>
          <PredifinedColors
            colors={convertedColors}
            selected={hexSelected}
            onSelect={onSelect}
          />
          <hr style={{ width: "100%" }} />
          <PredifinedColors
            colors={convertedSavedColors}
            selected={hexSelected}
            onSelect={onSelect}
            onToggleColorPicker={toggleColorPicker}
          />
        </>
      )}
    </div>
  );
};

export default SketchToolColors;
