import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";
export const FullScreenMap = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ControlButtons = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "10px",
  position: "absolute",
  bottom: "30px",
  top: "30px",
  right: "30px",
  width: "3em",
  height: "3em",
  backgroundColor: colors.primaryBackground,
  cursor: "pointer",
});

export const ControlButtonsSeparator = styled.div({
  backgroundColor: colors.secondaryBackground,
  height: "100%",
  width: "100%",
});

export const SelectedLocation = styled.div({
  display: "flex",
  justifyContent: "left",
  alignItems: "left",
  flexDirection: "column",
  borderRadius: "10px",
  position: "absolute",
  top: "30px",
  left: "30px",
  width: "30%",
  padding: "10px",
  backgroundColor: colors.primaryBackground,
});

export const mapStyle = {
  height: "100%",
  width: "100%",
  borderRadius: "20px",
};

export const groupedButtonStyle = css({
  height: "49%",
  color: colors.primaryTextColor,
});

export const singleButtonStyle = css({
  width: "80%",
  height: "80%",
  color: colors.primaryTextColor,
});

export const autoCompleteStyle = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  position: "relative",
  top: "30px",
  left: "30px",
  width: "30%",
  backgroundColor: colors.primaryBackground,
});
