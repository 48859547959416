import { css } from "@emotion/react";
//import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

const heightBreakpoints = [800, 860];

const hmq = heightBreakpoints.map((bp) => `@media (max-height: ${bp}px)`);

export const popupOverlayBox = css({
  width: "50vw",
  height: "90vh",
  backgroundColor: colors.primaryBackground,
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1em",
  color: "white",
  padding: "2.5vw",
});

export const actionsBar = css({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "2vw",
  zIndex: 2,
  alignItems: "center",
  [hmq[1]]: {
    margin: "1.5rem 0 0.5rem",
  },
});
