import PopupOverlay from "@/components/shared/PopupOverlay";
import { titlePopupOverlay } from "@/components/shared/SectionHeader.style";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import {
  getLocalIsFirstTimeUser,
  removeLocalIsFirstTimeUser,
} from "@/utils/helpers/localstorage";
import { Button } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const WelcomeOverlay = () => {
  const [show, setShow] = useState(!!getLocalIsFirstTimeUser());
  const navigate = useNavigate();
  const handleClose = () => {
    removeLocalIsFirstTimeUser();
    setShow(false);
  };

  const handleGoToContact = () => {
    handleClose();
    navigate(paths.contacts);
  };

  return (
    show && (
      <PopupOverlay>
        <div
          hidden={!open}
          css={{
            position: "fixed",
            maxWidth: "40vw",
            maxHeight: "50vh",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: colors.primaryBackgroundLighter,
            borderRadius: "20px",
            color: colors.primaryTextColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1.5em",
            padding: "2em",
            fontSize: "calc(0.6vw + 0.6vh)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "2em",
              right: "2em",
            }}
          >
            <img
              onClick={handleClose}
              src="/odience/event/white-close.svg"
              style={{
                width: "1.3em",
                cursor: "pointer",
              }}
            />
          </div>
          <img
            src={"assets/welcome.svg"}
            css={{
              width: "60%",
            }}
          />

          <div css={titlePopupOverlay}>Welcome to VERSE Messages</div>

          <div>
            VERSE Messages is a Rich Communication voice, video and messaing
            app.
            <br />
            Add people to begin experiencing all that VERSE Messages has to
            offer.
          </div>
          <Button
            type="primary"
            danger
            onClick={handleGoToContact}
            style={{
              fontWeight: "bold",
              padding: "2em 3em",
              fontSize: "inherit",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Add a friend
          </Button>
        </div>
      </PopupOverlay>
    )
  );
};

export default WelcomeOverlay;
