import { useEffect, useRef, useState } from "react";

/**
 * Custom hook to keep track of the previous value of a state or prop.
 *
 * @param value - The current value to track
 * @returns The previous value
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(undefined);
  useEffect(() => {
    ref.current = value;
  });
  // eslint-disable-next-line react-compiler/react-compiler
  return ref.current;
}

/**
 * Returns a reference to the previous value so that the `current` property is not accessed during render.
 *
 * This can be seen as more performant than `usePreviousState` since it doesn't use state,
 * and more performant than `usePrevious` since it doesn't access the `current` property during render and allows react-compiler to optimize the component using this.
 *
 * @param value - The value to track
 * @returns The previous value
 */
export function usePreviousRef<T>(value: T) {
  const ref = useRef<T>(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref;
}

/**
 * `usePrevious` above doesn't work with react-compiler since it accesses the `current` property during render.
 *
 * This hook works with react-compiler since it uses state.
 * In some use cases this may cause an infinite loop when rendering, that's why `usePrevious` above still exists.
 *
 * @param value - The value to track
 * @returns The previous value
 */
export function usePreviousState<T>(value: T) {
  const [current, setCurrent] = useState<T>(value);
  const [previous, setPrevious] = useState<T | undefined>(undefined);

  if (value !== current) {
    setPrevious(current);
    setCurrent(value);
  }

  return previous;
}
