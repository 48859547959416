import { removeForcedSignedOut } from "@/utils/helpers/localstorage";
import { useEffect } from "react";

import LoginPageImage from "@/assets/loginPageImage.avif";
import { colors } from "@/styles/global.styles";
import { ProvisionFirstStepCallback } from "@/types/provisioning";
import { showSessionExpiredToast } from "@/utils/helpers/loginAndCaps/session";
import { ProvisioningSteps } from "@/utils/helpers/provisionRequest";
import useProvisioningInfos from "@/utils/hooks/useProvisioningInfos";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import OptionalProvisionLogo from "../OptionalProvisionLogo";
import {
  areaCodeBox,
  areaCodeField,
  checkbox,
  checkedCSS,
  closeButton,
  inputFieldsCSS,
  mobileNumberFields,
  options,
  registerButton,
  reloginDeviceNameLabel,
  selectCSS,
  step2RegisterButton,
  termsCSS,
  text,
  textDark,
} from "../index.style";

type Props = {
  provision: ProvisionFirstStepCallback;
  next: (step: number) => void;
  onCloseProvisioning: () => void;
};

const ReloginPage = ({ provision, next, onCloseProvisioning }: Props) => {
  const {
    close,
    handleCountryChange,
    country,
    sortedCountryCodes,
    phoneNumber,
    setPhoneNumber,
    deviceName,
    setDeviceName,
    isButtonDisabled,
    handleProvision,
    fromOdiencePreview,
    checked,
    setChecked,
    unvalidPhoneNumber,
  } = useProvisioningInfos({
    provision,
    next: () => next(ProvisioningSteps.StepWaitForOtp),
    onCloseProvisioning,
    ignoreRememberMe: true,
  });

  useEffect(() => {
    showSessionExpiredToast();
    removeForcedSignedOut();
  }, []);

  return (
    <div
      css={{
        width: "80vw",
        height: "75vh",
        display: "flex",
        borderRadius: "1rem",
        color: colors.secondaryTextColor,
      }}
    >
      <IconButton onClick={close} css={closeButton}>
        <CloseIcon />
      </IconButton>
      <div css={{ width: "50%", height: "100%", padding: "1em 2.5em" }}>
        <img
          src={LoginPageImage}
          css={{
            height: "100%",
            borderRadius: "1rem",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      <div
        css={{
          width: "45%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "2em",
        }}
      >
        <div css={{ paddingBottom: "5vh" }}>
          <OptionalProvisionLogo />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            maxWidth: "30vw",
          }}
        >
          <label css={[text, textDark]} htmlFor="phoneNumber">
            Mobile Phone Number
          </label>
          <div css={[options, mobileNumberFields, { display: "flex" }]}>
            <div css={areaCodeBox}>
              <select
                id="areaCodeField"
                onChange={handleCountryChange}
                value={country}
                css={[selectCSS, areaCodeField]}
              >
                {sortedCountryCodes}
              </select>
            </div>
            <input
              id="phoneNumber"
              type="number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onWheel={(e) => e.currentTarget.blur()} // Prevent number scroll change
              placeholder="Enter phone number"
              css={[
                inputFieldsCSS,
                {
                  flexGrow: "1",
                  marginInlineStart: "1vw",
                  ...(unvalidPhoneNumber && {
                    border: `1px solid ${colors.primaryAccentColor}`,
                  }),
                },
              ]}
            />
          </div>
          <span css={[text, { fontSize: "0.75rem" }]}>
            You will receive an SMS to confirm your identity. Carrier charges
            may apply.
          </span>
          <div css={[options, mobileNumberFields, { display: "flex" }]}>
            <label
              css={[text, textDark, reloginDeviceNameLabel]}
              htmlFor="deviceName"
            >
              Device Name
            </label>
            <input
              id="deviceName"
              type="text"
              placeholder="Enter device name"
              maxLength={30}
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              css={[
                inputFieldsCSS,
                { flexGrow: "1", marginInlineStart: "1vw" },
              ]}
            />
          </div>
          <button
            type="submit"
            disabled={isButtonDisabled}
            onClick={handleProvision}
            css={[
              registerButton,
              step2RegisterButton,
              {
                fontSize: "1.2em",
                fontWeight: "bold",
                height: "55px",
                width: "100%",
              },
            ]}
          >
            Login To {fromOdiencePreview ? "Odience" : "Verse"}
          </button>
        </form>
        <div css={[checkbox, { justifyContent: "left", paddingTop: "0.5em" }]}>
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            css={checkedCSS}
          />
          <label css={termsCSS}>
            <p css={{ margin: "1em 0" }}>Remember me on this computer?</p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ReloginPage;
