import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchChatbotDirectoryCategorized } from "../chatbots";
import { useSuspenseConfig } from "./useConfig";

export function useChatbotDirectory() {
  const config = useSuspenseConfig();

  return useSuspenseQuery({
    queryKey: ["chatbotDirectory", config],
    queryFn: () => fetchChatbotDirectoryCategorized(config),
  });
}
