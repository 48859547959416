import OdienceLogo from "@/assets/OdienceLogo";
import { atoms } from "@/utils/helpers/atoms";
import { useToast } from "@/utils/helpers/toastManager";
import { useNavigationConfirmation } from "@/utils/hooks/useNavigationConfirmation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import styled from "@emotion/styled";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { getDefaultStore } from "jotai";
import React from "react";
import { NavLink } from "react-router-dom";
import VerseLogo from "../../assets/VerseLogo";
import { logoColored, mainLogo } from "../../pages/layout/Layout.style";
import { paths } from "../../routerPaths";
import { colors } from "../../styles/global.styles";
import {
  CallsNotificationBadge,
  MessagesNotificationBadge,
} from "./NotificationBadges";

type NavLinkIconProps = React.PropsWithChildren<{
  to: string;
  onClick?: (any?: any) => void;
}>;

const defaultStore = getDefaultStore();

export const discardMessageNotificationAndOverlay = () => {
  defaultStore.set(atoms.odience.chatBotMediaExtraInfo, undefined);
  defaultStore.set(atoms.messageNotification.remote, "");
  defaultStore.set(atoms.messaging.overlayRemote, "");
};

const NavLinkIcon = ({
  ref,
  to,
  children,
  onClick,
}: NavLinkIconProps & {
  ref?: React.RefObject<HTMLAnchorElement>;
}) => {
  const { dismissToast } = useToast();
  const { confirmNavigation } = useNavigationConfirmation();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    confirmNavigation(to);
    if (onClick) {
      onClick();
    }
    dismissToast?.();
  };

  return (
    <NavLink
      ref={ref}
      css={{
        position: "relative",
        textAlign: "center",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
      to={to}
      onClick={handleClick}
    >
      {children}
    </NavLink>
  );
};

const NavigationWrapper = styled.nav({
  position: "relative",
  backgroundColor: colors.secondaryBackground,
  width: "6.7%",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  minWidth: "82px",
  maxWidth: "82px",
  zIndex: 101,
});

export default function Navigation() {
  const handleGoToMessages = () => {
    setSelectedConversationId(null);
  };

  return (
    <>
      <NavigationWrapper id="navigationWrapper">
        <span
          css={{
            color: colors.primaryAccentColor,
            fontWeight: 500,
            textAlign: "center",
            paddingBottom: "4vh",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <VerseLogo
              css={[mainLogo, { minWidth: "44px", maxWidth: "44px" }]}
            />
            VERSE{" "}
            <span css={{ color: colors.primaryTextColor, lineHeight: 1 }}>
              +
            </span>
            <span
              css={{
                color: colors.primaryTextColor,
                fontSize: "14px",
              }}
            >
              ODIENCE
            </span>
          </div>
        </span>
        <NavLinkIcon to={paths.calls}>
          <CallsNotificationBadge>
            <PhoneIcon css={logoColored} />
          </CallsNotificationBadge>
        </NavLinkIcon>
        <NavLinkIcon to={paths.messages} onClick={handleGoToMessages}>
          <MessagesNotificationBadge>
            <MessageOutlinedIcon css={logoColored} />
          </MessagesNotificationBadge>
        </NavLinkIcon>
        <NavLinkIcon to={paths.contacts}>
          <PeopleAltOutlinedIcon css={logoColored} />
        </NavLinkIcon>
        <NavLinkIcon to={paths.chatbots}>
          <SmartToyOutlinedIcon css={logoColored} />
        </NavLinkIcon>
        <NavLinkIcon to={paths.odience}>
          <OdienceLogo css={logoColored} />
          {/* this second logo exists to fade the filter */}
          <OdienceLogo
            css={[
              logoColored,
              {
                position: "absolute",
                top: "0",
                left: "0",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                // filter to add color
                filter:
                  "invert(45%) sepia(37%) saturate(7485%) hue-rotate(328deg) brightness(87%) contrast(98%)",
                opacity: "0",
                ".active &, &:hover, &:focus": {
                  opacity: "1",
                },
                transition: "opacity 0.25s ease",
              },
            ]}
          />
        </NavLinkIcon>
        <NavLinkIcon to={paths.settings}>
          <SettingsOutlinedIcon css={logoColored} />
        </NavLinkIcon>
      </NavigationWrapper>
    </>
  );
}
