import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TranscriptOverlayBody = styled.div({
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
  overflowY: "auto",
});

export const TranscriptOverlayFooter = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "1vw",
  justifyContent: "end",
  width: "100%",
});

export const TranscriptlineStyling = css({
  display: "flex",
  flexDirection: "column",
  gap: "1vh",
});

export const TranscriptTitleStyling = css({
  fontWeight: "bold",
  color: colors.secondaryAccentColor,
});
