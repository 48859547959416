import { colors } from "@/styles/global.styles";
import { clearSelection } from "@/utils";
import { css } from "@emotion/react";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useQuery } from "@tanstack/react-query";
import Color from "color";
import React, {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

export type AudioPlayerAPI = {
  pause: () => void;
};

type AudioPlayerProps = {
  src: string;
  type?: string;
  onPlay?: () => void;
  onPause?: () => void;
};

function formatTime(time: number) {
  const formattedTime = new Date(time * 1000).toISOString().slice(14, 19);
  return formattedTime;
}

export default function AudioPlayer({
  src,
  type,
  onPlay,
  onPause,
  ref,
}: AudioPlayerProps & { ref?: React.Ref<AudioPlayerAPI> }) {
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, _setIsPlaying] = useState(false);
  const setIsPlaying: typeof _setIsPlaying = (val) => {
    _setIsPlaying((prev) => {
      const nextIsPlayingState = typeof val === "boolean" ? val : val(prev);

      if (nextIsPlayingState) {
        onPlay?.();
      } else {
        onPause?.();
      }
      return nextIsPlayingState;
    });
  };
  const [isPlayable, setIsPlayable] = useState(false);

  const pause = () => setIsPlaying(false);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleDurationUpdate = (totalDuration: number) => {
    setDuration(totalDuration);
    setIsPlayable(totalDuration > 0);
  };

  const handleCurrentTimeUpdate = (currentTime: number) => {
    setCurrentTime(currentTime);
  };

  useEffect(() => {
    if (!isPlaying) return;

    const handleSpaceKey = (e: KeyboardEvent) => {
      if (e.code === "Space") {
        e.preventDefault();
        e.stopPropagation();
        pause();
      }
    };

    document.addEventListener("keydown", handleSpaceKey);
    return () => {
      document.removeEventListener("keydown", handleSpaceKey);
    };
  }, [isPlaying]);

  const rootRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      pause,
    }),
    []
  );

  const {
    data: audioBlob,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["audioBlob", src],
    queryFn: async () => {
      const response = await fetch(src, { credentials: "include" });
      const blob = await response.blob();
      return blob;
    },
    staleTime: 0,
    gcTime: 1000,
    retry: false,
  });

  const formattedTime = formatTime(Math.max(0, duration - currentTime));

  return (
    <div ref={rootRef} css={audioPlayerStyle}>
      <button
        disabled={!isPlayable}
        css={playButtonStyle}
        onClick={togglePlayPause}
      >
        {isPlaying ? (
          <PauseCircleOutlineIcon css={playPauseIconStyle} />
        ) : (
            <PlayCircleOutlineIcon css={playPauseIconStyle} />
        )}
      </button>
      {!isError ? (
        <Visualizer
          isLoading={isLoading}
          audioBlob={audioBlob}
          isPlaying={isPlaying}
          onEndAudioPlayback={pause}
          onDuration={handleDurationUpdate}
          onTimeUpdate={handleCurrentTimeUpdate}
        />
      ) : (
        <FallbackAudioPlayer
          src={src}
          type={type}
          isPlaying={isPlaying}
          onEndAudioPlayback={pause}
          onDurationChange={handleDurationUpdate}
          onTimeUpdate={handleCurrentTimeUpdate}
        />
      )}
      <span css={timeStyle}>{formattedTime}</span>
    </div>
  );
}

function Visualizer({
  audioBlob,
  isPlaying,
  isLoading,
  onEndAudioPlayback,
  onDuration,
  onTimeUpdate,
}: {
  audioBlob: Blob | undefined;
  isPlaying: boolean;
  isLoading?: boolean;
  onEndAudioPlayback: () => void;
  onDuration?: (duration: number) => void;
  onTimeUpdate?: (time: number) => void;
}) {
  const recorderControls = useVoiceVisualizer({
    onEndAudioPlayback,
    warnBeforeUnload: false,
  });

  const audioTimeSeconds = Math.trunc(recorderControls.currentAudioTime);
  useLayoutEffect(() => {
    onTimeUpdate?.(audioTimeSeconds);
  }, [onTimeUpdate, audioTimeSeconds]);

  useLayoutEffect(() => {
    if (!audioBlob || recorderControls.recordedBlob) return;

    recorderControls.setPreloadedAudioBlob(audioBlob);
  }, [audioBlob]);

  useLayoutEffect(() => {
    if (!onDuration || !recorderControls.duration) return;

    onDuration(recorderControls.duration);
  }, [onDuration, recorderControls.duration]);

  useLayoutEffect(() => {
    const isVisualizerPlaying = !recorderControls.isPausedRecordedAudio;
    if (isVisualizerPlaying !== isPlaying) {
      // if they are not synced, sync the state of the visualizer with the state of the audio player
      recorderControls.togglePauseResume();
    }
  }, [isPlaying]);

  const loadFallback = !!isLoading;

  return (
    <div className="flex-1">
      {loadFallback ? <span className="w-full">Loading audio...</span> : null}
      <VoiceVisualizer
        controls={recorderControls}
        isControlPanelShown={false}
        isDefaultUIShown={false}
        isProgressIndicatorTimeShown={false}
        isProgressIndicatorTimeOnHoverShown={false}
        isProgressIndicatorShown={true}
        audioProcessingTextClassName="w-full"
        barWidth={3}
        width={"100%"}
        height={"30px"}
        mainContainerClassName={loadFallback ? "hidden" : ""}
      />
    </div>
  );
}

function FallbackAudioPlayer({
  src,
  type,
  isPlaying,
  onEndAudioPlayback,
  onDurationChange,
  onTimeUpdate,
}: {
  src: string;
  type?: string;
  isPlaying: boolean;
  onEndAudioPlayback: () => void;
  onDurationChange?: (duration: number) => void;
  onTimeUpdate?: (time: number) => void;
}) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const updateVisualProgress = (progress: number) => {
    if (!progressBarRef.current) return;

    progressBarRef.current.style.setProperty(
      "--progress",
      `${progress * 100}%`
    );
  };

  // TODO: add audio track seeking when the server can send the Accept-Ranges header
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!progressBarRef.current || !audioRef.current) return;

      const rect = progressBarRef.current.getBoundingClientRect();
      const offsetX = moveEvent.clientX - rect.left;
      const progress = Math.max(0, Math.min(offsetX / rect.width, 1));

      audioRef.current.currentTime = progress * audioRef.current.duration;
      updateVisualProgress(progress);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };

    clearSelection();
    handleMouseMove(e.nativeEvent);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp, { once: true });
  };

  useLayoutEffect(() => {
    if (!isPlaying) {
      audioRef.current?.pause();
      return;
    }

    void audioRef.current?.play();

    const updateProgressEveryFrame = () => {
      if (!audioRef.current) return;

      const progress = audioRef.current.currentTime / audioRef.current.duration;
      updateVisualProgress(progress);

      requestAnimationFrame(updateProgressEveryFrame);
    };

    updateProgressEveryFrame();
  }, [isPlaying]);

  return (
    <>
      <div
        ref={progressBarRef}
        css={progressBarWrapperStyle}
        onMouseDown={handleMouseDown}
      >
        <div css={progressBarStyle}></div>
      </div>
      <audio
        ref={audioRef}
        preload="auto"
        onEnded={onEndAudioPlayback}
        onDurationChange={(e) => onDurationChange?.(e.currentTarget.duration)}
        onTimeUpdate={(e) => onTimeUpdate?.(e.currentTarget.currentTime)}
      >
        <source src={src} type={type} />
      </audio>
    </>
  );
}

const playPauseIconStyle = css({
  height: "1.2em", width: "1.2em", color: colors.primaryTextColor
})

const audioPlayerStyle = css({
  display: "flex",
  alignItems: "center",
  backgroundColor: colors.primaryBackgroundLighter,
  borderRadius: "10px",
  padding: "10px 15px",
  width: "250px",
  color: "white !important",
  gap: "10px",
});

const playButtonStyle = css({
  width: "30px",
  minWidth: "30px",
  height: "30px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white !important",
});

const progressBarWrapperStyle = css({
  "--progress": "0%",
  flex: 1,
  height: "12px",
  borderRadius: "5px",
  position: "relative",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

const progressBarStyle = css({
  "--progress-color": new Color(colors.primaryAccentColor)
    .alpha(0.5)
    .toString(),
  "--handle-width": "12px",
  width: "100%",
  height: "2px",
  backgroundColor: colors.secondaryBackgroundLighter,
  borderRadius: "5px",
  position: "relative",
  cursor: "pointer",
  borderRight: `calc((var(--handle-width) / 2) - 0.5px) solid ${colors.secondaryBackgroundLighter}`,
  borderLeft: `calc((var(--handle-width) / 2) - 0.5px) solid var(--progress-color)`,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "var(--progress)",
    background: "var(--progress-color)",
    height: "100%",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "var(--progress)",
    width: "var(--handle-width)",
    height: "var(--handle-width)",
    backgroundColor: colors.primaryAccentColor,
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
  },
});

const timeStyle = css({
  fontSize: "14px",
  userSelect: "none",
  pointerEvents: "none",
  whiteSpace: "nowrap",
});
