import { CallInfos } from "@/types/calling";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContacts } from "@/utils/hooks/useContacts";
import DesktopNotification from "./DesktopNotification";

const CallNotification = ({ callInfos, onDesktopNotification }: { callInfos: CallInfos, onDesktopNotification: (notification: Notification) => void }) => {
	const contacts = useContacts();
	const contact = contacts?.findWithNumber(callInfos.number) ||
		WebGwContact.fromPhoneNumber(formatPhoneNumber(callInfos.number, "E123"));

	if (!contact) {
		return;
	}

	const title = `Incoming ${callInfos.isVideo ? "Video" : "Audio"
		} Call From ${contact.noNameReturnPhoneNumber()}`;

	return document.hidden ? <DesktopNotification onNotification={onDesktopNotification} title={title} /> :
		// TODO - handle in app call notifications directly here
		<></>;
};

export default CallNotification;
