const DevicesIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    css={{
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M12 21v3h9c1.6 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3H10.5c-1.6 0-3 1.3-3 3v3H9V3h13.5v18H12zM1.5 7.5C.7 7.5 0 8.2 0 9v13.5c0 .8.7 1.5 1.5 1.5H9c.8 0 1.5-.7 1.5-1.5V9c0-.8-.7-1.5-1.5-1.5H1.5zm7.5 3H1.5V21H9V10.5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#fff",
      }}
    />
  </svg>
);
export default DevicesIcon;
