import { useQuery } from "@tanstack/react-query";
import { fetchChatbotInfo } from "../chatbots";
import WebGwContact from "../helpers/WebGwContact";
import { useConfig } from "./useConfig";

export function useChatbotInfo(contact?: WebGwContact) {
  const config = useConfig();

  return useQuery({
    queryKey: ["chatbotInfo", contact?.id, config] as const,
    queryFn: async ({ queryKey: [, botIdToFetch, config] }) => {
      if (!botIdToFetch || !config) {
        return null;
      }

      try {
        return await fetchChatbotInfo(botIdToFetch, config);
      } catch (e) {
        console.error("Error when fetching chatbot info", botIdToFetch);
        console.error("Caught", e);
        return null;
      }
    },
    enabled: !!contact?.isChatbot && !!config,
    // We always want fresh info in case bot gets published/unpublished
    refetchOnMount: "always",
  });
}
