import { GoogleMap, Libraries, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useRef } from "react";

const LIBRARIES: Libraries = ["places"];
const darkMapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      { invert_lightness: "true" },
      { saturation: -100 },
      { lightness: -10 },
      { gamma: 1 },
      { hue: "#0e0e0e" },
    ],
  },
];

const EventDetailsMap = ({ objEvent }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window._env_.GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
  });

  useEffect(() => {
    if (isLoaded && objEvent.coordinates && mapRef.current) {
      mapRef.current.setCenter(objEvent.coordinates);
      markerRef.current = new google.maps.Marker({
        position: objEvent.coordinates,
        map: mapRef.current,
        icon: {
          url: "/odience/event/map_marker.png",
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 40), // Adjust anchor for correct positioning
        },
      });
    }
  }, [isLoaded, objEvent.coordinates]);

  const mapStyles: React.CSSProperties = {
    height: "100%",
    width: "100%",
    borderRadius: "30px",
  };

  if (!isLoaded) {
    return <div>Loading maps...</div>;
  }

  return (
    <div css={{ width: "100%", height: "15vh" }}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={objEvent.coordinates}
        zoom={12}
        options={{
          styles: darkMapStyles,
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      ></GoogleMap>
    </div>
  );
};

export default EventDetailsMap;
