import { ease } from "@/utils/ease";
import { animate, AnimationOptions } from "motion/react";
import React, { useEffect, useRef } from "react";

type AnimateChangeInHeightProps = {
  children: React.ReactNode;
  innerDivProps?: React.ComponentProps<"div">;
} & Omit<ResizeOptions, "containerRef"> &
  Omit<React.ComponentProps<"div">, "children">;

export function AnimateChangeInHeight({
  children,
  resizeOnce = false,
  innerDivProps,
  animationOptions,
  ...props
}: AnimateChangeInHeightProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useResize({
    containerRef: containerRef,
    animationOptions,
    resizeOnce,
  });

  return (
    <div css={{ overflow: "hidden" }} {...props} style={props.style}>
      <div ref={containerRef} {...innerDivProps}>
        {children}
      </div>
    </div>
  );
}

type ResizeOptions = {
  containerRef: React.RefObject<HTMLDivElement | null>;
  animationOptions?: AnimationOptions;
  instant?: boolean;
  resizeOnce?: boolean;
};

function useResize({
  containerRef,
  animationOptions,
  resizeOnce,
}: ResizeOptions) {
  const resizedOnceRef = useRef(false);

  // inspiration: https://github.com/motiondivision/motion/discussions/1884#discussioncomment-5861808
  useEffect(() => {
    const containerElem = containerRef.current;
    if (!containerElem) return;

    // this should never be true, but just in case
    if (resizedOnceRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const observedHeight = entries[0].contentRect.height + "px";

      // prevents the warning when animateing the minHeight and a warning says there's a problem animating from "auto"
      if (!containerElem.parentElement!.style.minHeight) {
        containerElem.parentElement!.style.minHeight =
          containerElem.parentElement!.offsetHeight + "px";
      }
      if (!containerElem.parentElement!.style.height) {
        containerElem.parentElement!.style.height =
          containerElem.parentElement!.offsetHeight + "px";
      }

      animate(
        containerElem.parentElement!,
        {
          minHeight: observedHeight,
          height: observedHeight,
        },
        animationOptions ?? {
          duration: 0.35,
          ease: ease,
        }
      );

      if (resizeOnce) {
        resizedOnceRef.current = true;
        resizeObserver.disconnect();
      }
    });

    resizeObserver.observe(containerElem);

    return () => {
      if (resizedOnceRef.current) return;
      resizeObserver.disconnect();
    };
  }, []);
}
