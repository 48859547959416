import { colors } from "@/styles/global.styles";
import { keyframes } from "@emotion/react";

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Renderable, Toast, ToastPosition, toast } from "react-hot-toast";

type ToastContextType = {
  showToast: ShowToast;
  dismissToast: (() => void) | null;
};

export type ShowToast = (
  message: ReactNode,
  position?: ToastPosition,
  fontSize?: string,
  ref?: React.RefObject<HTMLDivElement | null>,
  icon?: Renderable,
  color?: string
) => { dismiss: () => void };

type ToastMessage = {
  id: string;
  message: ReactNode;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const toasts = useRef<ToastMessage[]>([]);
  const [dismissToast, setDismissToast] = useState<(() => void) | null>(null);

  const showToast: ShowToast = useCallback(
    (message, position, fontSize, ref, icon, color) => {
      const existingToastIndex = toasts.current.findIndex(
        (t) => t.message === message
      );

      if (existingToastIndex !== -1) {
        toast.dismiss(toasts.current[existingToastIndex].id);
        toasts.current = toasts.current.filter(
          (_, index) => index !== existingToastIndex
        );
      }

      if (toasts.current.length >= 3) {
        toast.dismiss(toasts.current[0].id);
        toasts.current = toasts.current.slice(1);
      }

      const toastPosition = position || "top-center";

      const customStyle = ref?.current
        ? {
            position: "absolute" as const,
            top: `${ref.current.getBoundingClientRect().top + 10}px`,
            left: `${ref.current.getBoundingClientRect().left + ref.current.getBoundingClientRect().width / 2}px`,
            transform: "translateX(-50%)",
            zIndex: 9999,
          }
        : {};

      const toastId = toast.custom(
        (t) => (
          <div style={customStyle}>
            <MessageAnimation
              toast={t}
              fontSize={fontSize}
              message={message}
              color={color}
              icon={icon}
            />
          </div>
        ),
        {
          duration: 5000,
          position: toastPosition,
          icon: icon || undefined,
        }
      );
      toasts.current.push({ id: toastId, message });

      const dismissFunction = () => toast.dismiss(toastId);
      setDismissToast(() => dismissFunction);

      return { dismiss: dismissFunction };
    },
    [dismissToast]
  );

  return (
    <ToastContext value={{ showToast, dismissToast }}>{children}</ToastContext>
  );
};

function MessageAnimation({
  message,
  toast: t,
  fontSize,
  color,
  icon,
  position,
}: {
  message: ReactNode;
  toast: Toast;
  fontSize?: string;
  color?: string;
  icon?: Renderable;
  position?: ToastPosition;
}) {
  return (
    <div
      css={{
        color: color || colors.primaryTextColor,
        backgroundColor: "#2E3237",
        display: "flex",
        alignItems: "center",
        lineHeight: "1.3",
        boxShadow:
          "0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)",
        pointerEvents: "auto",
        padding: "8px 10px",
        borderRadius: "8px",
        justifyContent: "center",
        margin: "4px 10px",
        flex: "1 1 auto",
        whiteSpace: "nowrap",
        fontSize: fontSize || "1rem",
        flexWrap: "nowrap",
        animation: position?.endsWith("right")
          ? t.visible
            ? `${keyframes({
                "0%": { transform: "translateX(calc(100% + 16px))" },
                "100%": { transform: "translateX(0)" },
              })} 0.25s ease forwards`
            : `${keyframes({
                "0%": { transform: "translateX(0)" },
                "100%": { transform: "translateX(calc(100% + 16px))" },
              })} 0.25s ease forwards`
          : t.visible
            ? `${keyframes({
                "0%": { opacity: "0" },
                "100%": { opacity: "1" },
              })} 0.25s ease forwards`
            : `${keyframes({
                "0%": { opacity: "1" },
                "100%": { opacity: "0" },
              })} 0.25s ease forwards`,
      }}
    >
      {!!icon && (
        <span
          css={{
            marginRight: "0.5em",
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
        </span>
      )}
      <span>{message}</span>
    </div>
  );
}
