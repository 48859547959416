import { colors } from "@/styles/global.styles";
import { getLocationInfoByCoordinates } from "@/utils/map";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Autocomplete,
  GoogleMap,
  Libraries,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useRef, useState } from "react";
import BodyPortal from "./BodyPortal";
import { PrimaryButton } from "./Button";
import {
  ControlButtons,
  ControlButtonsSeparator,
  FullScreenMap,
  SelectedLocation,
  autoCompleteStyle,
  groupedButtonStyle,
  mapStyle,
  singleButtonStyle,
} from "./Map.style";
import SearchBar from "./SearchBar";
// Core library is needed to load the circle icon
const LIBRARIES: Libraries = ["places", "core"];
const MAP_DEFAULT_CENTER = { lat: 0, lng: 0 };
const MAP_CONTROLS = false;
const MAP_DEFAULT_ZOOM = 3;
const MAP_MARKER_ZOOM = 13;
const MAP_STREET_VIEW = false;
const MAP_FULLSCREEN_CONTROL = false;
const MAP_ZOOM_CONTROL = false;

export interface LatLng {
  lat: number;
  lng: number;
}

const Map = ({
  defaultLocation,
  closeMap,
  fullScreen = true,
  onLocationSelected,
  open,
}: {
  defaultLocation?: LatLng;
  closeMap?: () => void;
  fullScreen?: boolean;
  onLocationSelected?: (LatLng) => void;
  open: boolean;
}) => {
  const map = open && (
    <GoogleMapComponent
      defaultLocation={defaultLocation}
      onLocationSelected={onLocationSelected}
      closeMap={closeMap}
    />
  );

  if (fullScreen) {
    return (
      open && (
        <BodyPortal>
          <FullScreenMap
            onClick={(e) => {
              e.stopPropagation();
              closeMap?.();
            }}
          >
            <div
              css={{
                width: "90vw",
                height: "90vh",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {map}
            </div>
          </FullScreenMap>
        </BodyPortal>
      )
    );
  } else {
    return map;
  }
};

const GoogleMapComponent = ({
  defaultLocation,
  onLocationSelected,
  closeMap,
}: {
  defaultLocation?: LatLng;
  onLocationSelected?: (latLng: LatLng) => void;
  closeMap?: () => void;
}) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [myLocationIcon, setMyLocationIcon] = useState<
    google.maps.Symbol | undefined
  >(undefined);
  const [selectedMarkerPosition, setSelectedMarkerPosition] = useState<
    LatLng | undefined
  >(defaultLocation);
  const [userLocationMarkerPosition, setUserLocationMarkerPosition] = useState<
    LatLng | undefined
  >(undefined);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<
    google.maps.places.PlaceResult | undefined
  >(undefined);
  const readOnly = onLocationSelected === undefined;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: window._env_.GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
  });

  const options = {
    center: selectedMarkerPosition ?? MAP_DEFAULT_CENTER,
    mapTypeControl: MAP_CONTROLS,
    zoom: selectedMarkerPosition ? MAP_MARKER_ZOOM : MAP_DEFAULT_ZOOM,
    streetViewControl: MAP_STREET_VIEW,
    fullscreenControl: MAP_FULLSCREEN_CONTROL,
    zoomControl: MAP_ZOOM_CONTROL,
  };

  const sendLocation = () => {
    if (selectedMarkerPosition) {
      onLocationSelected?.(selectedMarkerPosition);
    }
  };

  const getUserLocation = async (callback: (LatLng) => void) => {
    if (navigator.geolocation) {
      const result = navigator.permissions
        ? await navigator.permissions.query({ name: "geolocation" })
        : { state: "prompt" };

      if (result.state === "granted" || result.state === "prompt") {
        navigator.geolocation.getCurrentPosition((position) => {
          callback({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const placeMarker = (
    position: LatLng | Partial<google.maps.LatLng> | undefined | null,
    isUserLocation: boolean = false
  ) => {
    if (position && mapRef.current) {
      const markerPosition =
        position instanceof google.maps.LatLng
          ? {
              lat: position.lat?.() ?? 0,
              lng: position.lng?.() ?? 0,
            }
          : (position as LatLng);

      if (isUserLocation) {
        setUserLocationMarkerPosition(markerPosition);
      } else {
        setSelectedMarkerPosition(markerPosition);
      }

      mapRef.current.panTo(markerPosition);
      mapRef.current.setZoom(MAP_MARKER_ZOOM);
    }
  };

  const centerMapToUserLocation = () => {
    if (mapRef.current && userLocationMarkerPosition) {
      mapRef.current.panTo(userLocationMarkerPosition);
      mapRef.current.setZoom(MAP_MARKER_ZOOM);
    }
  };

  const placeUserLocationMarker = (selectIt: boolean) => {
    getUserLocation((position: LatLng) => {
      placeMarker(position, true);

      if (selectIt) {
        onMarkerClick(position);
      }
    });
  };

  const onLoadAutoComplete = (
    autoComplete: google.maps.places.Autocomplete
  ) => {
    autoCompleteRef.current = autoComplete;
  };

  const onLoadMap = (map: google.maps.Map) => {
    mapRef.current = map;

    // The icon can only be loaded after map is loaded, otherwise error
    if (!myLocationIcon) {
      setMyLocationIcon({
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "#3359F6",
        strokeColor: "#ffffff",
        fillOpacity: 1,
        strokeWeight: 4,
        scale: 10,
      });
    }

    placeUserLocationMarker(!defaultLocation);

    if (defaultLocation) {
      onMarkerClick(defaultLocation, true);
    }
  };

  const zoomIn = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom()! + 1);
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom()! - 1);
    }
  };

  const onPlaceChanged = () => {
    if (autoCompleteRef.current) {
      const place = autoCompleteRef.current.getPlace();
      if (place.geometry) {
        setSearchQuery(place.name!);
        setSelectedAddress(place);
        placeMarker(place.geometry.location);
      }
    }
  };

  const onMarkerClick = (marker?: LatLng, force = false) => {
    // Similar to clicking on the map, mostly to handle clicking on my location since other pin is already in place if we reach this method
    onMapClick(marker, force);
  };

  const onMapClick = (position?: LatLng, force = false) => {
    if (position && mapRef.current && (!readOnly || force)) {
      placeMarker(position);

      getLocationInfoByCoordinates(mapRef.current, position, (placeResult) => {
        if (placeResult) {
          setSelectedAddress(placeResult);
        }
      });
    }
  };

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoadMap}
      mapContainerStyle={mapStyle}
      options={options}
      onClick={(e) => onMapClick(e.latLng?.toJSON())}
    >
      <ControlButtons onClick={closeMap} css={{ bottom: "initial" }}>
        <CloseIcon css={singleButtonStyle} />
      </ControlButtons>
      <ControlButtons
        onClick={centerMapToUserLocation}
        css={{ top: "initial", bottom: "10em" }}
      >
        <MyLocationIcon css={groupedButtonStyle} />
      </ControlButtons>
      <ControlButtons css={{ top: "initial", height: "6em" }}>
        <AddIcon onClick={zoomIn} css={groupedButtonStyle} />
        <ControlButtonsSeparator />
        <RemoveIcon onClick={zoomOut} css={groupedButtonStyle} />
      </ControlButtons>
      {selectedAddress && (
        <SelectedLocation>
          {!readOnly && (
            <CloseIcon
              css={{
                color: colors.primaryTextColor,
                position: "absolute",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedAddress(undefined)}
            />
          )}
          <div
            style={{
              color: colors.primaryTextColor,
              fontWeight: "bold",
            }}
          >
            {selectedAddress.name}
          </div>
          <div
            style={{
              fontSize: "0.9em",
              marginTop: "1em",
              color: colors.secondaryTextColor,
            }}
          >
            {selectedAddress.formatted_address}
          </div>
          {!readOnly && (
            <PrimaryButton
              onClick={sendLocation}
              css={{
                fontWeight: "bold",
                fontSize: "0.9em",
                height: "3em",
                marginTop: "1em",
                color: colors.primaryTextColor,
              }}
            >
              Send Location
            </PrimaryButton>
          )}
        </SelectedLocation>
      )}
      {!readOnly && !selectedAddress && (
        <Autocomplete
          onLoad={onLoadAutoComplete}
          onPlaceChanged={onPlaceChanged}
          css={autoCompleteStyle}
        >
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            css={{ margin: "1em" }}
          />
        </Autocomplete>
      )}
      {userLocationMarkerPosition && myLocationIcon && (
        <Marker
          key="myLocation"
          position={userLocationMarkerPosition as google.maps.LatLngLiteral}
          icon={myLocationIcon}
          onClick={(e) => onMarkerClick(e.latLng?.toJSON())}
        />
      )}

      {selectedMarkerPosition && (
        <Marker
          key="selectedLocation"
          position={selectedMarkerPosition}
          onClick={(e) => onMarkerClick(e.latLng?.toJSON())}
        />
      )}
    </GoogleMap>
  ) : !loadError ? (
    <div>Loading...</div>
  ) : (
    <div>Map cannot be loaded</div>
  );
};

export default Map;
