import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { AnimateChangeInHeight } from "../shared/AnimateChangeInHeight";
import { IconButton } from "../shared/Button";
import { ChatFooterRef, footerPaddingHeight } from "./ChatFooter";

export default function TypeEditOverlay({
  chatFooterRef,
}: {
  chatFooterRef: React.RefObject<ChatFooterRef | null>;
}) {
  const [reply, setReply] = useAtom(atoms.messaging.messageReply);

  return (
    <AnimateChangeInHeight
      css={{
        width: "100%",
        transform: `translateY(calc(-${footerPaddingHeight}/2))`,
      }}
    >
      {reply?.type === "Edit" && (
        <div
          css={{
            minHeight: "32px",
            maxHeight: "32px",
            padding: `0 1.5em 0 1em`,
            width: "100%",
            display: "flex",
            alignItems: "center",
            color: colors.primaryTextColor,
          }}
        >
          <p css={{ flex: "1" }}>Edit message</p>
          <IconButton
            onClick={() => {
              setReply(null);
              chatFooterRef.current?.setText("");
            }}
          >
            <CloseIcon css={{ color: colors.primaryTextColor }} />
          </IconButton>
        </div>
      )}
    </AnimateChangeInHeight>
  );
}
