import { animate, ValueAnimationTransition } from "motion/react";
import { RefObject } from "react";

export function getAnimateScrollBarProps({
  elemRef,
  maxAlpha = 1,
}: {
  elemRef?: RefObject<HTMLElement>;
  maxAlpha?: number;
} = {}) {
  const animateIn = (elem: HTMLElement, options?: ValueAnimationTransition) => {
    animate(
      elemRef ? elemRef.current : elem,
      {
        "--scrollbar-alpha": maxAlpha,
      },
      {
        duration: 0.25,
        ...options,
      }
    );
  };
  const animateOut = (
    elem: HTMLElement,
    options?: ValueAnimationTransition
  ) => {
    animate(
      elemRef ? elemRef.current : elem,
      {
        "--scrollbar-alpha": 0,
      },
      {
        delay: 0.5,
        duration: 0.25,
        ...options,
      }
    );
  };

  return {
    props: {
      onMouseEnter: (e) => animateIn(e.currentTarget),
      onMouseLeave: (e) => animateOut(e.currentTarget),
    } satisfies React.HTMLProps<HTMLElement>,
    animateIn,
    animateOut,
  };
}
