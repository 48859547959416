import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";

export const suggestionHeight = "32px";
export const suggestionGap = "0.5rem";

export const suggestionsCss = {
  main: css({
    padding: "0.75rem 0",
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: suggestionGap,
  }),
  sticky: css({
    position: "relative",
    bottom: "0",
    zIndex: "1",
  }),
  wrapper: css({
    background: "inherit",
    marginTop: "auto",
    pointerEvents: "none",
    label: "suggestions",
  }),
  button: css({
    lineHeight: "1",
    padding: "8px",
    minHeight: suggestionHeight,
    maxHeight: suggestionHeight,
    borderRadius: "8px",
    minWidth: "70px",
    color: colors.primaryTextColor,
    background: colors.primaryAccentColor,
    cursor: "pointer",
    textAlign: "center",
    userSelect: "none",
    textDecoration: "none",
    pointerEvents: "auto",

    transition: "scale 0.2s ease",
    ":active": {
      scale: "0.95",
    },
    ".rich-card-content &": {
      width: "100%",
    },
  }),
};
