import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Color from "color";
import { colors } from "../styles/global.styles";

const breakpoints = [576, 768, 992, 1200];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const NumberInput = styled.div({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "0.5em",
  marginBottom: "0",
  justifyContent: "start",
});

export const DialedNumber = styled.input({
  fontSize: "3vw",
  color: colors.primaryTextColor,
  borderBottom: `1px solid ${colors.secondaryTextColor}`,
  minWidth: "80%",
  textAlign: "center",
  [mq[2]]: {
    minWidth: "90%",
  },
  [mq[3]]: {
    minWidth: "100%",
  },
});

export const numberInputButtonCss = css({
  color: colors.secondaryTextColor,
  width: "fit-content",
  height: "fit-conent",
});

export const Keypad = styled.div({
  display: "flex",
  flexDirection: "column",
  color: colors.primaryTextColor,
  height: "100%",
  width: "90%",
  justifyContent: "space-between",
});

export const NumberRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  textAlign: "center",
  marginTop: "5%",
});

export const NumberButton = styled.button({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "5vw",
  height: "5vw",
  color: colors.primaryTextColor,
  ":hover": {
    borderRadius: "90%",
    background: colors.secondaryBackground,
  },
  ":disabled": {
    color: colors.secondaryTextColor,
    pointerEvents: "none",
  },
});

export const NumericEntry = styled.div({
  fontSize: "clamp(1.5em, 2.6vw, 2.75em)",
  lineHeight: "3vw",
});

export const AlphabetEntry = styled.div({
  fontSize: "clamp(0.8em, 1vw, 2em)",
  lineHeight: "2vw",
  color: colors.secondaryTextColor,
});

export const CallOptions = css({
  color: "#FFFFFF",
  ":disabled": {
    cursor: "default",
  },
});

export const CallButtons = css({
  backgroundColor: colors.secondaryAccentColor,
  borderRadius: "100%",
  height: "5vw",
  width: "5vw",
  maxHeight: "3.75em",
  maxWidth: "3.75em",
  minHeight: "0.5em",
  minWidth: "0.5em",
  padding: "20%",
  "button:disabled &": {
    backgroundColor: new Color(colors.secondaryAccentColor)
      .alpha(0.5)
      .toString(),
  },
});

export const CallOptionsButton = css({
  borderRadius: "100%",
  height: "2.2vw",
  width: "2.2vw",
  maxHeight: "1.3em",
  maxWidth: "1.3em",
  minHeight: "0.6em",
  minWidth: "0.6em",
});

export const CallOptionsActiveButton = css({
  color: colors.primaryAccentColor,
});

export const DropDownContainer = styled.div({
  zIndex: 5,
  bottom: "100%",
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  whiteSpace: "nowrap",
  marginLeft: "-7vw",
  alignItems: "baseline",
  backgroundColor: "#000E1A",
  color: "#FFFFFF",
  position: "absolute",
  borderRadius: "10px 10px 0px 0px",
  borderColor: "#8C8F92",
  borderWidth: "thin",
});

export const DeleteDropDownContainer = styled.div({
  zIndex: 5,
  bottom: "100%",
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  whiteSpace: "nowrap",
  alignItems: "baseline",
  backgroundColor: "#000E1A",
  color: "#FFFFFF",
  position: "absolute",
  borderRadius: "10px 10px 0px 0px",
  borderColor: "#8C8F92",
  borderWidth: "thin",
});

export const DropDownButton = styled.button({
  height: "4vh",
  display: "flex",
  paddingTop: "1vh",
  borderColor: "#8C8F92",
  borderWidth: "thin",
  width: "100%",
});
