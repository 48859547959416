import PolicyScreen from "@/components/shared/PolicyScreen";

const policyText = `Last Updated: 2022-03-29

VERSE Messages application or service (the “Platform”) is operated by SUMMIT-TECH MULTIMEDIA COMMUNICATIONS INC. Throughout this privacy policy (the “Privacy Policy”), the terms “Summit”, “we”, “us” and “our” refer to SUMMIT-TECH MULTIMEDIA COMMUNICATIONS INC. Summit is the controller of your personal information, and is responsible for the collection, processing, and disclosure of your personal information as described in this Privacy Policy, unless expressly specified otherwise. If you have any questions about this Privacy Policy or want to exercise your rights hereunder, please email us at rcs@summit-tech.ca or write to our Privacy Officer at the address hereinbelow.

Summit is committed to protecting and respecting your privacy, and will only use any personal information submitted in conformity with all applicable laws and regulations, and in accordance with this Privacy Policy.

We reserve the right to modify this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on our Website and Platform. If we make material changes to this Privacy Policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.

Our postal address is:

Summit-Tech Multimedia Communications Inc.
201-9203 boul. Saint-Laurent 
Montréal (Québec) 
H2N1N2 Canada
rcs@summit-tech.ca 
	
1.	Processing activities covered

This Privacy Policy applies to the following processing activities:

-	Visiting our Website and Platform and accessing our Website and Platform which display or link through to this Privacy Policy;

-	Registering as a member of the Website and the Platform or otherwise complete a user profile or other forms via Summit’s Website and Platform;

-	Using the Website and the Platform which include real-time social networking activities;

-	Communicating with us to report a problem or to submit queries, concerns or comments regarding Summit’s Website and Platform and/or any contents therein; 

-	Receiving email communications from us; and

-	Registering for and playing in one or more of the episodes available within the game.


2.	Personal information that we collect

We only collect the personal information that is necessary to carry out our business, provide the service(s) you requested and to keep you informed of the latest news about our services. 

Subject to certain exceptions and limitations, personal information is any information that directly or indirectly identifies, describes, relates to, is capable of being associated with, or can reasonably link to a particular consumer or household. 

The personal information that we collect directly from you may include the following:

-	Personal identifiers, which may include, without limitation, email address, name, telephone number;

-	Internet or other electronic network activity information, which may include, without limitation, browsing history, search history, and information regarding interaction with an internet website, application, or advertisement; 

-	Usage of our Website or Platform, including chat transcripts, logs and/or games history; and;

-	Inferences we make, which may include your likelihood of retention.


3.	Personal information of children

You must be at least 18 years old to create any account on the Website and Platform and to sign up to our newsletter. We do not knowingly collect any personal information from children under the age of 18. Those under the age of 18 should not use the Website and Platform or the contents therein or provide us with any personal information. We encourage parents and legal guardians to monitor their children’s Internet usage, and if you have reasons to believe that a child under the age of 18 has provided personal information to us, please email us at rcs@summit-tech.ca .


4.	Device and usage data

We use common information-gathering tools, such as log files, cookies, web beacons, and similar technologies to automatically collect information, which may contain personal information, from your computer or mobile device as you navigate our Website and Platform or interact with emails we have sent to you.

As is true of most websites, we gather certain information automatically via log files. This collected information may include your Internet Protocol (IP) address (or proxy server), device and application identification numbers, your location, your browser type, your Internet service provider and/or mobile carrier, the pages and files you viewed, your searches, your operating system and system configuration information, and date/time stamps associated with your usage.

This information is used to comply with data contracts, treaties, national laws, and regulations, analyze overall trends to help us provide and improve our Website and Platform and the contents therein, and to guarantee their security and continued proper functioning. We also collect IP addresses from users when they log into the services as part of the Website and Platform’s security features.

We use cookies and similar technologies such as web beacons, tags, and Javascript alone or in conjunction with cookies to compile information about usage of our Website and Platform and the contents therein, and interaction with emails from us.

When you visit our Website and Platform, our servers or an authorized third party may place a cookie on your browser, which can collect information, including personal information, about your online activities over time and across different sites. Cookies help us ensure our Website and Platform are not misused by bots and prevent account login information sharing.

We use both session-based and persistent cookies. Session cookies exist only during one session and disappear from your computer or device when you close your browser software or turn off your computer. Persistent cookies remain on your computer or device after you close your browser or turn off your computer or device. Most web and mobile device browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set by using the opt-out tool at www.optout.networkadvertising.org.  You can also learn more about cookies by visiting www.allaboutcookies.org which includes additional useful information on cookies and how to block cookies using different types of browsers or mobile device. Please note, however, that by blocking or deleting cookies used by us, you may not be able to take full advantage of the Website and Platform and the contents therein.




5.	Purposes for which we process personal information and the legal basis on which we rely

We collect and process your personal information for the purposes and on the legal basis identified below:

-	Providing our services and performing contracts: We use your personal information to enable us to operate and administer our Website and Platform and the contents therein, and to perform any contract with you to provide you with content you access and service you request according to any applicable terms of service, including chat communications and other means of direct communications between our users;

-	Managing user registrations: We will process your personal information for the purpose of identifying you and by managing your user account for the purpose of performing the contract with you according to any applicable terms of service, and relevant national laws and regulations;

-	Handling contacts and user support requests: If you contact us by any available means, we will process your personal information to the extent it is necessary to fulfill your request and communicate with you;

-	Developing and improving our Website and Platform and the contents therein: We will process your personal information to analyze trends and track your usage of our Website and Platform and their contents, and interactions with emails to the extent this is necessary for us to develop and improve our Website and Platform and their contents, and to provide our users with more relevant content;  

-	Sending communications: We will process your personal information to send you the latest updates about us, including information about our service, to the extent you have provided your prior separate consent;

-	Complying with legal obligations: We will process your personal information when cooperating with public and government authorities, courts, or regulators in accordance with our legal obligations under applicable laws to the extent this requires the processing or disclosure of personal information to protect our rights, and is necessary for our legitimate interests to protect against misuse or abuse of data hosted on our Website and Platform, our data, our Website and Platform, to protect personal property or safety, to pursue remedies available to us and limit our damages, to comply with judicial proceedings, court order, or legal process, and/or to respond to lawful requests.

Where we need to collect and process personal information by law, or under a contract we have entered into with you and you fail to provide that required personal information when requested, we may not be able to perform the contract.

Where your personal information is anonymized or de-identified, we do not require a legal basis to use it as the information will no longer constitute personal information under the applicable data protection law. Our use of anonymized or de-identified data is not subject to this Privacy Policy.


6.	Service Providers

Your personal information may be collected, transferred to, and stored by us, SMS service provider, or Amazon Web Services (AWS) on their servers located in the United States of America, North Virginia or in Canada, European Union, United Kingdom, whichever is closer to your physical location. We will use reasonable means to ensure that personal information is protected regardless of where your personal information may be located.

We may transfer personal information to non-affiliated service providers in providing the services, such as IT and system administration and hosting, research and analytics, marketing, account management, technical operations, credit card processing and email or SMS service providers to send out emails or SMS on our behalf, pursuant to agreements that prohibit the service providers from using or disclosing the personal information other than for the purpose of processing and which impose security safeguards appropriate to the sensitivity of the information. Our contracted service providers whose names, addresses and contact information are reproduced below.

Service Providers	Contacts Information	Purposes
Amazon Web Services
Hosting and cloud server	Amazon Web Services Head Quarters
410 Terry Ave N
Seattle, WA
United States
	Hosting cloud company on which the platform is hosted

We may disclose aggregate statistics about our users in order to describe our business to prospective partners, advertisers, sponsors and other reputable third parties and for other lawful purposes, but these statistics will include no personally identifiable information. We may disclose your personal information to any of our affiliates, or to our agents or contractors who assist us in providing our services, processing transactions, receiving and sending communications, updating marketing lists, analyzing data, providing IT and other support services, or in other tasks, from time to time. Our agents and contractors will only use your information to the extent necessary to perform their functions. In the event of a sale of all or substantially all of our business or another similar transaction, we may transfer or disclose your personal information to a purchaser or prospective purchaser, who may collect, use and disclose such information for the purposes of evaluating the proposed transaction or operating and managing the affairs of the acquired business, or for other purposes identified in this Privacy Policy.

We reserve the right to disclose your personal information as required by law, and as necessary to protect the property, interests and rights of Summit.

In individual cases, we may also share personal information with professional advisers acting as processors or joint controllers including lawyers, bankers, auditors, and insurers based in countries in which we operate, who provide consultancy, banking, legal, insurance, and accounting services.

Any personal information or other information you choose to submit in during events, live streams, forums, blogs, or chat rooms whether they are public or private, on our Website and Platform may be read, collected, and/or used by others who visit these events, live streams, forums, blogs or chat rooms, depending on your account settings.

Our Website and Platform and their contents may contain links to websites operated by third parties. These websites may collect your personal information. This Privacy Policy does not apply to the practices of third parties and we do not assume any responsibility for their practices, and by using our Website and Platform and their contents and/or accessing a third party’s website from our Website and Platform, you release us of any responsibility for, the actions, practices and omissions of third parties. Please check the privacy policy at each website you visit.


7.	Retention of personal information

We will keep your personal information only as long as is reasonably necessary for the time with the original purpose of collection or such longer period as may be required by applicable consistent law. We determine the appropriate retention period for personal information on the basis of the amount, nature, and sensitivity of your personal information, the potential risk of harm from unauthorized use or disclosure, and whether we can achieve the purposes of the processing through other means.


8.	Your access and control over your personal information

You may exercise any of the rights described below by sending an email to rcs@summit-tech.ca or by using the contact information indicated in this section. Please note that we may ask you to verify your identity before taking further action on your request. The verification methods we use may include requests to disclose your first name, last name, email address, location information, security question, account activity and other information. We will process your request within 30 days of receipt.  

a) 	Managing your information

You may access and update some of your information by accessing your account with us, as applicable. You are responsible for keeping your personal information up-to-date. You can also ask us to correct inaccurate or incomplete personal information concerning you that you cannot update yourself within your account.

b) 	Access to your information

You have the right to request information about the personal information we hold on you at any time. You can contact us at rcs@summit-tech.ca and we will provide you with your personal information via email. If you require additional copies, we may need to charge a reasonable fee.

If you are a Californian resident, once per calendar year, you may request that we provide a list of companies to which we disclose your personal information for business purposes, and a list of the categories of such personal information that we share.  You may request further information about our compliance with this law by emailing rcs@summit-tech.ca. Please include “California Privacy Rights Request” in the first line of the description and your California mailing or street address. Please note that we are only required to respond to one request per customer each year, and we are not required to respond to requests made by means other than through this e-mail address nor requests made by non-California US residents. 

c)	 Right to erasure

If you no longer want us to use your information to provide you with our latest news or any of our services, you can request that we erase your personal information and close your account(s).

Please note, however, that we may retain some of your personal information as necessary for our legitimate interests, such as fraud detection and enhancing security. We may also retain and use your personal information to the extent necessary to comply with our legal obligations, such as keeping information for tax, legal reporting and auditing obligations, as well as professional obligations. Information you have shared with others (e.g. comments, community postings) may continue to be publicly visible. Additionally, some copies of your information (e.g. log records) may remain in our database, but are disassociated from personal identifiers. Residual copies may also be kept in our backup systems. If we have shared your personal information with service providers, we will let them know about the erasure where possible.

d) 	Right to object to direct marketing

If we rely on your consent as our legal basis for processing your personal information, you have the right to withdraw that consent at any time. If you have already given your consent but you prefer not to receive promotional information from us, you may unsubscribe by using the unsubscribe link situated at the bottom of any promotional message you receive from us, or email us at rcs@summit-tech.ca . 

e) 	Restriction of processing

You can ask us to block or suppress the processing of your personal information in certain circumstances such as where you contest the accuracy of that personal information or you object to us processing it and we have no legitimate grounds that override yours. Such request alone does not stop us from storing your personal information.

If you are a Californian resident, you have the right to opt-out of the sale of your personal information. Under the California Consumer Privacy Act (the “CCPA”), the “sale” of personal information broadly includes any communication of personal information to any business that is deemed to be a “third party” (as defined in the CCPA) for monetary or other valuable consideration, subject to certain exceptions and limitations.  Summit does not sell personal information. However, if you would like to submit such a request, please send your request to rcs@summit-tech.ca 

f) 	Right to portability

Whenever we process your personal information, by automated means based on your consent or based on an agreement, you have the right to get a copy of your personal information transferred to you or to another party. This only includes the personal information you have provided to us.

g) 	Automated decision-making

You have the right to elect not to be subject to a decision based solely on automated processing, including profiling ("Automated Decision-Making"). Our Website and Platform in relation to an account’s suspension or ban are reviewed by humans.   

h)	Consent withdrawal

To the extent we base the collection, processing, and sharing of your personal information on your consent, you have the right to withdraw your consent at any time, without affecting the lawfulness of the processing based on such consent before its withdrawal.

i) 	Right to non-discrimination

Pursuant to the CCPA, we will not discriminate against Californian residents for exercising any of their rights under the CCPA. Unless permitted by the CCPA, we will not deny you services, charge you different prices or rates for services, or imposing penalties, provide you a different level or quality of services, or suggest that you may receive a different price or rate for services or a different level or quality of services for your exercise of your CCPA rights.

j) 	Authorized agent

If you are a Californian resident, you may use an authorized agent to exercise certain privacy-related requests and rights on your behalf.  The authorized agent’s exercise or request on your behalf sent by mail shall include a power of attorney or a notarized statement properly signed by you appointing such authorized agent for such purpose.  If your authorized agent submits the exercise or request online, a copy of the power of attorney or the original notarized statement shall be sent to us by mail.

k)	Questions or complaints

If you have any queries or would like to make any complaint in relation to this Privacy Policy and our privacy practices, you may write to our Privacy Officer at rcs@summit-tech.ca or at the following address:

Attn: Privacy Officer
Summit-Tech Multimedia Communications Inc.
201-9203 boul. Saint-Laurent 
Montréal (Québec) 
H2N1N2 Canada
rcs@summit-tech.ca 

9.	Personal information security

We take reasonable precautions to ensure the protection of personal information collected, used or disclosed by us using appropriate physical, organizational and technological measures. We use industry-standard encryption technologies to receive sensitive data submitted by you on our Website and Platform.

In case of a personal data breach that is likely to result in high risk to your rights and freedoms, we will you and the proper authority within 72 hours of becoming aware of the breach and without notify undue delay.

While we follow generally accepted standards to protect personal information, no method of storage or transmission is 100% secure. You are solely responsible for protecting your password, limiting access to your devices, and signing out of the Website and Platform after your sessions. If you have any questions about the security of our Website and Platform, please contact us using the contact information in the “Questions or complaints” section above.`;

export default function PrivacyPolicy() {
  return <PolicyScreen titleText="Privacy Policy" bodyText={policyText} />;
}
