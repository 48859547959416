import { fetchChatbotDirectoryCategorized } from "../chatbots";
import { getConfig } from "../helpers/config";
import { queryClient } from "../queryClient";

export async function getChatbotDirectory() {
  const config = await getConfig();
  return queryClient.fetchQuery({
    queryKey: ["chatbotDirectory", config],
    queryFn: () => fetchChatbotDirectoryCategorized(config),
  });
}
