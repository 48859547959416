import { NumberWithType } from "@/utils/helpers/WebGwContact";
import { useEffect, useRef, useState } from "react";
import {
  DropdownMenu,
  dropdownItem,
  primaryText,
  secondaryText,
} from "./Dropdown.style";

type DropdownProps = {
  options: NumberWithType[];
  onSelect: (selected: NumberWithType) => void;
  placeholder?: React.ReactNode;
  icon?: React.ReactNode;
};

const ContactInfoDropdown = ({
  options,
  onSelect,
  placeholder,
  icon,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hasMultipleOptions = options.length > 1;

  return (
    <div
      css={{ position: "relative", display: "inline-block" }}
      ref={dropdownRef}
    >
      <button
        css={{ lineHeight: "0" }}
        onClick={
          hasMultipleOptions ? toggleDropdown : () => onSelect(options[0])
        }
      >
        {placeholder}
      </button>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <button
              css={dropdownItem}
              key={index}
              onClick={() => {
                setIsOpen(false);
                onSelect(option);
              }}
            >
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {icon}
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "0.75vw",
                  }}
                >
                  <div css={primaryText}>{option[0]}</div>
                  <div css={secondaryText}>{option[1]}</div>
                </div>
              </div>
            </button>
          ))}
        </DropdownMenu>
      )}
    </div>
  );
};

export default ContactInfoDropdown;
