import OdienceProfileAvatar from "@/components/odience/components/OdienceProfileAvatar";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import {
  removeEmail,
  removeInvite,
  removeInvitedEventId,
  removeLastEventId,
  removeLastGroupId,
} from "@/utils/helpers/sessionStorage";
import { useOdienceOrganization } from "@/utils/hooks/useOdienceOrganization";
import { css } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

type EventListHeaderProps = {
  handleGetAppModal: () => void;
  odienceAvatar: string;
  handleOdienceAvatarError: () => void;
  handleOpenEditProfile: () => void;
};

const BackToOnboarding = () => {
  const handleRemoveInviteStorageItems = () => {
    removeEmail();
    removeInvite();
    removeInvitedEventId();
    removeLastEventId();
    removeLastGroupId();
  };

  return (
    <div css={backToOnboardingStyles}>
      <Link
        to={paths.onboarding}
        css={linkStyles}
        onClick={handleRemoveInviteStorageItems}
      >
        <ExpandMoreIcon css={expandMoreIconStyles} />
        <div>Verse Messages</div>
      </Link>
    </div>
  );
};

const LoginButton = () => (
  <div>
    <Link css={loginButtonStyles} to={paths.provisioningFromOdience}>
      Log in
    </Link>
  </div>
);

const HeaderTitle = ({ show }: { show: boolean }) => (
  <div css={headerTitleStyles}>{show ? "ODIENCE" : ""}</div>
);

const GetAppButton = ({
  handleGetAppModal,
}: {
  handleGetAppModal: () => void;
}) => (
  <button onClick={handleGetAppModal} css={getAppButtonStyles}>
    <img
      src="/odience/web_client/Qr-code.svg"
      alt="QR Code"
      css={qrCodeImageStyles}
    />
    <div>Get the app</div>
  </button>
);

const EventListHeader = ({
  handleGetAppModal,
  odienceAvatar,
  handleOdienceAvatarError,
  handleOpenEditProfile,
}: EventListHeaderProps) => {
  const { embeddedMode, organizationId } = useOdienceOrganization();
  return (
    <div>
      {!isProvisioned() && !embeddedMode && <BackToOnboarding />}

      <div css={containerStyles}>
        <HeaderTitle show={!embeddedMode || !organizationId} />

        <div css={rightSectionStyles}>
          <GetAppButton handleGetAppModal={handleGetAppModal} />
          {!isProvisioned() ? (
            <LoginButton />
          ) : (
            <OdienceProfileAvatar
              {...{
                odienceAvatar,
                handleOdienceAvatarError,
                handleOpenEditProfile,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventListHeader;

const backToOnboardingStyles = css({
  color: colors.primaryTextColor,
  paddingBottom: "2.25em",
});

const linkStyles = css({
  display: "flex",
  gap: "0.5vw",
  flexDirection: "row",
  alignItems: "center",
});

const expandMoreIconStyles = css({
  transform: "rotate(90deg)",
  width: "1.25em",
  height: "1.25em",
  fontWeight: "bold",
});

const loginButtonStyles = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  padding: "2vh 2vw",
  color: colors.primaryTextColor,
  fontWeight: "bold",
  backgroundColor: colors.primaryAccentColor,
  fontSize: "1.125rem",
});

const headerTitleStyles = css({
  color: colors.primaryTextColor,
  fontWeight: "lighter",
  textAlign: "center",
  letterSpacing: "1.875rem",
  fontSize: "1.875rem",
});

export const getAppButtonStyles = css({
  display: "flex",
  backgroundColor: "black",
  color: "white",
  gap: "0.5em",
  border: "3px solid #313131",
  borderRadius: "10px",
  fontWeight: "bold",
  textDecoration: "none",
  padding: "2vh 1vw",
});

export const qrCodeImageStyles = css({
  height: "1.5em",
});

const containerStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const rightSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "1vw",
  marginRight: "2em",
});
