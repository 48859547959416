import {
  DeleteOverlayBodyCSS,
  DeleteOverlayButtonCSS,
  MainPopupOverlayCSS,
} from "@/pages/conversationsScreen/ConversationScreen.style";
import { colors } from "@/styles/global.styles";
import {
  TranscriptOverlayBody,
  TranscriptOverlayFooter,
  TranscriptTitleStyling,
  TranscriptlineStyling,
} from "./CallTranscriptOverlay.style";
import PopupOverlay from "./PopupOverlay";
type CallTranscriptOverlayProps = {
  togglePopup: () => void;
  remote?: string;
  transcript: string;
  handleAction: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  primaryButtonText?: string;
};

interface TranscriptSegment {
  elapsedStart: number;
  elapsedEnd: number;
  initialTime: number;
  transcriptLines?: TranscriptLine[];
}
interface TranscriptLine {
  speakerId: string;
  type: number;
  text: string;
}

const CallTranscriptOverlay = ({
  togglePopup,
  remote,
  transcript,
  handleAction,
  primaryButtonText,
}: CallTranscriptOverlayProps) => {
  const transformConversationData = (data: string) => {
    const transcript: { callSummary; transcript: TranscriptSegment[] } =
      JSON.parse(data);
    const { callSummary, transcript: segments } = transcript;
    const transformedTranscript: {
      callSummary;
      transcript: TranscriptSegment[];
    } = {
      callSummary,
      transcript: [],
    };
    if (typeof transformedTranscript.callSummary !== "string")
      transformedTranscript.callSummary =
        transformedTranscript.callSummary.summary;
    for (const segment of segments) {
      if (segment.elapsedStart) {
        transformedTranscript.transcript.push({
          elapsedEnd: segment.elapsedEnd ?? 0,
          elapsedStart: segment.elapsedStart,
          initialTime: 0,
          transcriptLines: [],
        });
      }
      if (segment.transcriptLines) {
        for (const line of segment.transcriptLines) {
          const { speakerId, type, text } = line;
          // We use == instead of === in case 0 comes as an integer value.
          const speaker = speakerId == "0" ? "Me" : remote!;
          if (text) {
            const currentSegment =
              transformedTranscript.transcript[
                transformedTranscript.transcript.length - 1
              ];
            if (currentSegment && currentSegment.transcriptLines) {
              currentSegment.transcriptLines.push({
                speakerId: speaker,
                type,
                text,
              });
            }
          }
        }
      }
    }

    return transformedTranscript;
  };

  const transformedTranscript = transformConversationData(transcript);

  const transcriptLines = transformedTranscript.transcript.map(
    (segment, index) => {
      const elapsedStartSeconds = segment.elapsedStart
        ? segment.elapsedStart / 1000
        : 0;
      const elapsedEndSeconds = segment.elapsedEnd
        ? segment.elapsedEnd / 1000
        : 0;

      const initialTime = new Date(segment.initialTime);

      const startTime = new Date(
        initialTime.getTime() + elapsedStartSeconds * 1000
      )
        .toISOString()
        .substr(11, 8);
      const endTime =
        elapsedEndSeconds !== 0
          ? new Date(initialTime.getTime() + elapsedEndSeconds * 1000)
              .toISOString()
              .substr(11, 8)
          : "end";

      return (
        <div key={index}>
          <div
            css={[
              TranscriptlineStyling,
              {
                paddingBottom: "1vh",
                color: colors.secondaryAccentColor,
              },
            ]}
          >
            {startTime} - {endTime}
          </div>
          {segment.transcriptLines!.map(({ speakerId, text }, lineIndex) => (
            <div key={lineIndex} css={TranscriptlineStyling}>
              <div css={{ paddingBottom: "1vh" }}>
                <span css={{ fontWeight: "bold" }}>{speakerId}:</span> {text}
              </div>
            </div>
          ))}
        </div>
      );
    }
  );
  return (
    <PopupOverlay>
      <div css={MainPopupOverlayCSS}>
        <div css={DeleteOverlayBodyCSS}>
          <h3
            css={{
              color: colors.secondaryAccentColor,
              margin: "1em 0",
              fontWeight: "bold",
            }}
          >
            Call Transcript From Call With {remote}
          </h3>
        </div>

        <TranscriptOverlayBody>
          <div
            css={[
              TranscriptlineStyling,
              {
                paddingBottom: "2vh",
              },
            ]}
          >
            <div css={TranscriptTitleStyling}>Call Summary:</div>
            <div>{transformedTranscript.callSummary}</div>
          </div>
          <span
            css={[
              TranscriptTitleStyling,
              {
                paddingBottom: "2vh",
              },
            ]}
          >
            Transcript:
          </span>
          {transcriptLines}
        </TranscriptOverlayBody>

        <TranscriptOverlayFooter>
          <button
            css={[
              DeleteOverlayButtonCSS,
              {
                backgroundColor: colors.secondaryAccentColor,
                color: colors.primaryTextColor,
              },
            ]}
            onClick={handleAction}
          >
            {primaryButtonText ? primaryButtonText : "Close"}
          </button>
        </TranscriptOverlayFooter>
      </div>
    </PopupOverlay>
  );
};

export default CallTranscriptOverlay;
