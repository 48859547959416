import WebGwContact from "../helpers/WebGwContact";
import { usePrevious } from "./usePrevious";

export function useContactEdited(
  contact: WebGwContact | null
): WebGwContact | null {
  const prevContact = usePrevious(contact);

  if (prevContact && contact) {
    const keys1 = Object.keys(prevContact).sort();
    const keys2 = Object.keys(contact).sort();

    const values1 = Object.values(prevContact).sort();
    const values2 = Object.values(contact).sort();

    if (
      JSON.stringify(keys1) !== JSON.stringify(keys2) ||
      JSON.stringify(values1) !== JSON.stringify(values2)
    ) {
      return contact;
    }
  }

  return null;
}
