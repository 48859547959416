import { colors } from "@/styles/global.styles";
import { isMobile } from "@/utils/helpers/Utils";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { css, keyframes } from "@emotion/react";

//STREAM CENTER STYLES

export const longPressAnimStyle = css`
  position: fixed;
  width: 1.4em;
  height: 1.4em;
  cursor: pointer;
  display: none;
  border-radius: 50%;
  transform: scale(2);
  background-color: rgba(255, 255, 255, 0.1);
`;

export const longPressAnimVisibleStyle = css`
  display: block;
`;

export const customCursorStyle = css`
  cursor: pointer;
`;

export const streamErrorDivStyle = css({
  color: colors.primaryTextColor,
  backgroundColor: "#141414",
  fontSize: "1rem",
  height: isMobile() ? "100vh" : "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  zIndex: 100,
  position: isMobile() ? "absolute" : "relative",
  "@media (orientation: landscape)": {
    position: !isProvisioned() && isMobile() ? "fixed" : "relative",
    top: 0,
  },
});

export const streamErrorButtonStyle = css({
  backgroundColor: colors.primaryAccentColor,
  color: colors.primaryTextColor,
  marginTop: "1em",
  padding: "0.5em",
  borderRadius: "50px",
  width: "auto",
  border: "none",
});

export const startStreamDivStyle = css({
  userSelect: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: isMobile() ? "absolute" : "relative",
  backgroundColor: "#141414",
  borderRadius: "10px",
  overflow: "hidden",
  height: isMobile() ? "100vh" : "100%",
  width: "100%",
  zIndex: "100",
  "@media (orientation: landscape)": {
    position: !isProvisioned() && isMobile() ? "fixed" : "relative",
  },
});

export const startStreamButtonStyle = css({
  margin: "1em auto",
  width: "13em",
  height: "7em",
  cursor: "pointer",
});

export const fullScreenDivStyle = css({
  boxSizing: "border-box",
  userSelect: "none",
  padding: "0.5em 0.5em 0",
  opacity: 1,
  display: "flex",
  gap: "1em",
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 9999,
  alignItems: "center",
  transition: "all 0.2s ease",
});

export const fullScreenButtonStyle = css({
  marginLeft: "auto",
  height: "2em",
  width: "2em",
  cursor: "pointer",
  userSelect: "none",
  transition: "all 0.2s ease",
  top: "2em",
  right: "2em",
  display: "none",
});

export const activeStreamContainerStyle = (addRadius: boolean) =>
  css({
    userSelect: "none",
    height: "100%",
    width: "100%",
    borderRadius: isMobile() || !addRadius ? undefined : "1rem",
    objectFit: "cover",
  });

//MESSAGE CONTAINER STYLES

export const messageCardStyle = css({
  display: "flex",
  justifyContent: "center",
  height: "fit-content",
  padding: isMobile() ? "0.7em 0.8em" : "1.4em 0.7em",
  borderRadius: "1em",
  alignItems: "center",
  width: isMobile() ? "fit-content" : "100%",
  maxWidth: isMobile() ? "90%" : "100%",
  gap: "1.4em",
  backgroundColor: isMobile() ? "rgb(28, 28, 28)" : "#0d0d0d",
  boxSizing: "border-box",
  fontSize: isMobile() ? "0.8rem" : "1rem",
});

export const messageAvatarStyle = css({
  borderRadius: "50%",
  overflow: "hidden",
  width: "2.5em",
  height: "2.5em",
  display: "flex",
  justifyContent: "center",
});

export const messageRowStyle = css({
  fontSize: "0.75rem",
  color: colors.primaryTextColor,
  display: "flex",
  gap: "0.33em",
  opacity: 0.5,
  alignItems: "center",
});

export const messageUserNameStyle = css({
  fontWeight: "bold",
  width: "fit-content",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const messageContentStyle = css({
  width: isMobile() ? undefined : "17vw",
  maxWidth: "17em",
  color: colors.primaryTextColor,
  fontSize: isMobile() ? "0.8rem" : "1rem",
  overflowWrap: "anywhere",
});

// KEY FRAMES

export const slideFromLeft = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const slideFromRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const scaleAnimation = keyframes`  
0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
  }
`;

export const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-3rem);
    opacity: 0;
  }
`;

export const reactionAvatarAnimation = keyframes`
  0% {
    transform: translateY(5rem);
    opacity: 1;
  }
  100% {
    transform: translateY(2rem);
    opacity: 0;
  }
}`;

export const reactionTotalAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(1rem, -5rem);
  }
}`;

export const reactionAnimation = keyframes`
  to {
    transform: translateY(-60rem);
    opacity: 0;
  }
}`;

export const leftRight = keyframes`
  to {
    transform: translateX(5rem);
  }
}`;

export const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const promptStyle = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(26, 32, 38);
  padding: 1em 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  animation: ${slideUp} 0.5s ease;
  height: 24%;
`;
