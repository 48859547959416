import BodyPortal from "@/components/shared/BodyPortal";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { AnimatePresence } from "motion/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { deleteConversation } from "../database";
import Conversation from "../messaging/conversation/Conversation";

export const useDeleteConversationModal = (
  conversation: Conversation,
  conversationTitle: string,
  onAction?: VoidFunction,
  hideOnAction = false
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteConversation = async (
    event: React.MouseEvent,
    conversation: Conversation
  ) => {
    const toastId = `deleteConversation-${conversation.id}`;
    toast.loading(`Deleting conversation with ${conversation.getName()}`, {
      id: toastId,
    });

    const result = await deleteConversation(conversation);

    if (result) {
      toast.success(`Conversation deleted successfully`, {
        id: toastId,
      });
    } else {
      toast.error("Error deleting the conversation, please try again later.", {
        id: toastId,
      });
    }

    event?.stopPropagation();
  };

  const show = () => {
    setIsModalOpen(true);
  };

  const hide = () => {
    setIsModalOpen(false);
  };

  const modal = (
    <AnimatePresence>
      {isModalOpen && (
        <BodyPortal>
          <ConfirmationPopup
            togglePopup={handleCancel}
            title="Delete Conversation"
            confirmationMessage={
              <p>
                Are you sure you want to delete this conversation with{" "}
                <code
                  css={{
                    background: "rgb(0,0,0,0.25)",
                    borderRadius: "4px",
                    padding: "2px",
                    textWrap: "nowrap",
                  }}
                >
                  {conversationTitle}
                </code>
                ?
              </p>
            }
            handleAction={(event) => {
              onAction?.();

              void handleDeleteConversation(event, conversation);
              if (hideOnAction) {
                hide();
              }
            }}
          />
        </BodyPortal>
      )}
    </AnimatePresence>
  );

  return { modal, show, hide };
};
