import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import TZEF from "../../../assets/onboardingScreen/botImages/2084.png";
import gpt from "../../../assets/onboardingScreen/botImages/gpt.png";
import lightControls from "../../../assets/onboardingScreen/botImages/lightControls.png";
import nezumi from "../../../assets/onboardingScreen/botImages/nezumi.png";
import pollbotCreator from "../../../assets/onboardingScreen/botImages/pollbotCreator.png";
import shopping from "../../../assets/onboardingScreen/botImages/shopping.png";

type Slide = {
  imgProps: { alt: string };

  src: string;
  title: string;
  description: string;
};

const slides: Slide[][] = [
  [
    {
      imgProps: { alt: "Nezumi" },
      src: nezumi,
      title: "Nezumi",
      description:
        "Embark on a captivating game designed to ensure quality family time. Join our charming mouse hero as they navigate through a whimsical world, solving puzzles and overcoming challenges.",
    },
    {
      imgProps: { alt: "2084" },
      src: TZEF,
      title: "2084",
      description:
        "Navigate through the intricacies of this steampunk universe. Make decisions that shape your destiny and unravel the mysteries of a world where gears turn and steam hisses at every twist and turn.",
    },
  ],
  [
    {
      imgProps: { alt: "Light Controls" },
      src: lightControls,
      title: "Smart Lights",
      description:
        "A new interactive hub for your smart lighting. No more apps. Control your home lighting with simple voice or text commands. Adjust brightness, color & more for a truly simple and colourful experience.",
    },
    {
      imgProps: { alt: "Poll Bot Creator" },
      src: pollbotCreator,
      title: "Poll Bot Creator",
      description:
        "Quickly set up and customize polls with a few easy steps. Create polls for every type of event in minutes!",
    },
  ],
  [
    {
      imgProps: { alt: "1ere Avenue Shopping" },
      src: shopping,
      title: "Shopping",
      description:
        "Easily integrate your existing e-commerce platform into a shopping experience complete with an Ai shopping assistant, cart purchases and saves - transforming your platform into virtual commerce (vCommerce).",
    },
    {
      imgProps: { alt: "GPT University" },
      src: gpt,
      title: "GPT University",
      description:
        "Think you’re smarter than chat GPT? Challenge yourself and while learning something new.",
    },
  ],
];

function ChatbotCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(2);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      setPrevSlide(currentSlide);
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [currentSlide, isPaused]);

  const handleIndicatorClick = (index: number) => {
    if (currentSlide !== index) {
      setPrevSlide(currentSlide);
      setCurrentSlide(index);
    }
  };

  return (
    <div
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <CarouselWrapper>
        {slides.map((slide, index) => (
          <CarouselSlide
            key={index}
            active={index === currentSlide}
            direction={index === prevSlide ? "out" : "in"}
            zIndex={index === prevSlide ? 2 : 1}
          >
            <SlideContent>
              {slide.map((item, idx) => (
                <SlideItem key={idx}>
                  <div>
                    <SlideImage src={item.src} alt={item.imgProps.alt} />
                  </div>
                  <h4
                    css={{
                      marginTop: "0.75em",
                    }}
                    className="text-lg font-bold md:text-2xl"
                  >
                    {item.title}
                  </h4>
                  <p
                    css={{
                      lineHeight: 1.2,
                    }}
                    className="text-base text-onboardingSecondaryText md:text-lg"
                  >
                    {item.description}
                  </p>
                </SlideItem>
              ))}
            </SlideContent>
          </CarouselSlide>
        ))}
        <IndicatorWrapper>
          {slides.map((_, index) => (
            <Indicator
              key={index}
              isActive={index === currentSlide}
              onClick={() => handleIndicatorClick(index)}
            />
          ))}
        </IndicatorWrapper>
      </CarouselWrapper>
    </div>
  );
}

export default ChatbotCarousel;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    left: 100%;
  }
  to {
    transform: translateX(0);
    left: 0;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
    left: 0;
  }
  to {
    transform: translateX(-100%);
    left: -100%;
  }
`;
export const CarouselWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 32.5em;
  max-height: 90vh;
  width: 100%;

  @media (max-width: 767px) {
    height: 50em;
    max-height: 120vh;
  }
  @media (min-width: 1280px) {
    height: 35em;
  }
`;

export const CarouselSlide = styled.div<{
  active: boolean;
  direction: string;
  zIndex: number;
}>`
  position: absolute;
  top: 0;
  left: ${(props) => (props.active ? "0" : "100%")};
  width: 100%;
  transition: left 1s ease-in-out;
  transition: opacity 1.5s ease-in-out;
  opacity: ${(props) => (props.active ? "1" : "0")};
  animation: ${(props) => (props.active ? slideIn : slideOut)} 1s forwards;
  z-index: ${({ zIndex }) => zIndex};
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`;

export const Indicator = styled.div<{ isActive: boolean }>`
  width: 1em;
  height: 1em;
  margin: 0 8px;
  border: 2px solid grey;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? "grey" : "transparent")};
  transition: background-color 0.3s ease;
`;

export const SlideContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding: 1em;

  @media (min-width: 1280px) {
    gap: 1em;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0.5em;
  }
`;

export const SlideItem = styled.div`
  flex: 1;
  max-width: 50%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SlideImage = styled.img`
  height: auto;
  width: 100%;
`;
