import ContactModal from "@/components/shared/ContactModal";
import SvgGraphic from "@/components/shared/SvgGraphic";
import { createContact } from "@/utils/contacts/index";
import { Button } from "antd";
import { useState } from "react";
import { useProxy } from "valtio/utils";
import "../../components/contactListScreen/NewContactForm.css";
import SectionHeader from "../../components/shared/SectionHeader";
import {
  contactState,
  resetContactState,
} from "../../utils/contacts/contactState";
import { Box } from "../layout/Layout.style";
import { Container, Text } from "./EmptyContactListScreen.style";

export const NoContactsScreen = ({}: {}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contact = useProxy(contactState);

  const showModal = async () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    createContact(contact);
  };

  const handleCancel = () => {
    resetContactState();
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <Text>
          Add contacts to begin to experience all that VERSE Messages has to
          offer
        </Text>
      </div>

      <div>
        <Button type="primary" danger onClick={showModal}>
          Create New Contact
        </Button>

        <ContactModal
          open={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
    </>
  );
};

const EmptyContactListScreen = () => {
  return (
    <>
      <Box>
        <SectionHeader pageName="Contacts" />
        <Container>
          <NoContactsScreen />
        </Container>
      </Box>
      <Box>
        <SvgGraphic src="/assets/Contacts_Graphic.svg" />
      </Box>
    </>
  );
};

export default EmptyContactListScreen;
