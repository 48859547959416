import { IconButton } from "@/components/shared/Button";
import { atoms } from "@/utils/helpers/atoms";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import CloseIcon from "@mui/icons-material/Close";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CircularProgress from "@mui/material/CircularProgress";
import { useAtomValue } from "jotai";
import { useLayoutEffect, useMemo, useRef } from "react";
import { colors } from "../../styles/global.styles";

export default function NewConversationScreenHeader({
  contacts,
  onRemoveContact,
  setSearchQuery,
  readOnly = false,
  onShowContactPicker,
  onAddNumber,
  showLoadingPhoneIcon,
  closeCreateGroupChat,
  defaultValue,
}: {
  contacts?: WebGwContact[];
  onRemoveContact: (
    contactId: string,
    currentAddContactInputValue?: string
  ) => void;
  setSearchQuery: (string) => void;
  readOnly?: boolean;
  onShowContactPicker: (show: boolean) => void;
  onAddNumber?: (number: string) => void;
  showLoadingPhoneIcon?: boolean;
  closeCreateGroupChat?: () => void;
  defaultValue?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const contactLengthRef = useRef(contacts?.length || 0);
  const reply = useAtomValue(atoms.messaging.messageReply);
  const numberEnteredHandler = async (phoneNumber: string) => {
    if (!phoneNumber) {
      return;
    }

    onAddNumber?.(phoneNumber);
  };

  useLayoutEffect(() => {
    if (contacts && inputRef.current) {
      inputRef.current.focus();

      // Clear whenever a contact was added
      if (contactLengthRef.current < contacts.length) {
        inputRef.current.value = "";
      }

      contactLengthRef.current = contacts.length;
    }
  }, [contacts?.length]);

  const conversationUpgradingToGroupChat = useAtomValue(
    atoms.messaging.conversationUpgradingToGroupChat
  );
  const firstContactIsConversing = useMemo(() => {
    const firstContact = contacts?.at(0);
    if (!firstContact) return false;

    const firstContactUpgrading =
      conversationUpgradingToGroupChat?.participants[0];
    if (!firstContactUpgrading) return false;

    return (
      firstContactUpgrading.id === firstContact.id &&
      isSamePhoneNumber(
        firstContactUpgrading.getMainPhoneNumber(),
        firstContact.getMainPhoneNumber()
      )
    );
  }, [conversationUpgradingToGroupChat]);

  return (
    <div
      css={{
        paddingTop: "1em",
        width: "calc(100% - 3em)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: `2px solid ${colors.tertiaryTextColor}`,
        opacity: reply !== null ? 0.3 : 1,
      }}
    >
      <label
        css={{
          color: colors.secondaryTextColor,
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "3em",
          paddingRight: "1em",
          userSelect: "none",
        }}
      >
        <b>To:</b>
        {contacts?.map((contact, idx) => (
          <div
            key={contact.id}
            css={{
              marginLeft: "0.5em",
              backgroundColor: colors.secondaryBackground,
              color: colors.primaryTextColor,
              borderRadius: "5px",
              padding: "0.3em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              css={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "9em",
                overflow: "hidden",
              }}
            >
              {" " + contact.noNameReturnPhoneNumber()}
            </div>
            {(idx === 0 && firstContactIsConversing) || readOnly ? null : (
              <button
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CloseIcon
                  css={{
                    color: colors.primaryTextColor,
                    width: "1em",
                    fontSize: "1em",
                  }}
                  onClick={() =>
                    onRemoveContact(contact.id, inputRef.current?.value)
                  }
                />
              </button>
            )}
          </div>
        ))}
        {!readOnly && (
          <>
            <input
              ref={inputRef}
              type="text"
              placeholder="Type a name or phone number"
              css={{
                "::placeholder": {
                  fontSize: "0.85em",
                  color: colors.secondaryTextColor,
                },
                margin: "0",
                paddingLeft: "1em",
                backgroundColor: "transparent",
                color: colors.primaryTextColor,
                width: "100%",
              }}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                const input = e.target as HTMLInputElement;
                if (e.key === "Enter") {
                  numberEnteredHandler(input.value);
                } else if (e.key === "Backspace" && input.value === "") {
                  if (contacts && contacts.length > 0) {
                    onRemoveContact(contacts[contacts.length - 1].id);
                  }
                }
              }}
              defaultValue={defaultValue}
            />
            {showLoadingPhoneIcon && (
              <div css={{ width: "3em" }}>
                <CircularProgress color="error" size={"1.5em"} />
              </div>
            )}
          </>
        )}
      </label>
      <div css={{ display: "flex", alignItems: "center", gap: "0.25em" }}>
        <IconButton
          onClick={() => onShowContactPicker(true)}
          css={{ color: colors.primaryAccentColor }}
          disabled={readOnly}
        >
          <PeopleAltIcon />
        </IconButton>
        {closeCreateGroupChat && (
          <IconButton
            onClick={closeCreateGroupChat}
            css={{ color: colors.primaryTextColor }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
