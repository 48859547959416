import { useContacts } from "@/utils/hooks/useContacts";
import ContactListScreen from "./ContactListScreen";
import EmptyContactListScreen from "./EmptyContactListScreen";

export default function ContactListScreenWrapper() {
  // We want to display the empty contact list component if only chatbots
  const contacts = useContacts()?.filter((contact) => !contact.isChatbot);

  if (!contacts || contacts.length === 0) {
    return <EmptyContactListScreen />;
  }
  return <ContactListScreen />;
}
