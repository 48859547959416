import { queryClient } from "@/utils/queryClient";
import { nanoid } from "nanoid";
import { proxy, ref } from "valtio";
import vCard from "vcf";
import {
  convertToCRLF,
  extractMultipartBoundary,
  parseMultipartMixed,
} from "..";
import { ChatMessage } from "../../components/chatScreen/chat/typings";
import { ChatbotPayload } from "../../components/chatScreen/chat/typings/gsma";
import { ImdnInfo, ImdnList } from "../../types/OmaNms";
import WebGwContact from "../helpers/WebGwContact";
import { replaceEmojis } from "../helpers/emoji";
import { generateMapUrl } from "../map";
import NmsMessage from "./NmsMessage";

type handleNmsPayloadPart = (
  messageProxy: Partial<ChatMessage>,
  payloadPart: NmsMessage["payloadParts"][number]
) => void;

const handleTextMessage: handleNmsPayloadPart = (message, payloadPart) => {
  if (payloadPart.textContent == null) {
    throw new Error("Invalid bot text message payload");
  }

  message.textMessage = payloadPart.textContent;
};

const handleBotSuggestions: handleNmsPayloadPart = (message, payloadPart) => {
  if (!payloadPart.textContent) {
    throw new Error("Invalid bot suggestion payload");
  }

  message.suggestedChipList = JSON.parse(payloadPart.textContent);
};

const handleBotResponse: handleNmsPayloadPart = (message, payloadPart) => {
  if (!payloadPart.textContent) {
    throw new Error("Invalid bot response payload");
  }

  try {
    const responseJson = JSON.parse(payloadPart.textContent) as ChatbotPayload;

    if (responseJson?.response) {
      message.textMessage =
        responseJson.response.reply?.displayText ??
        responseJson.response.action?.displayText;
    }
  } catch (e) {
    console.debug("Error parsing JSON", payloadPart);
    message.textMessage = payloadPart.textContent;
  }

  if (!message.textMessage) {
    throw new Error("Invalid response to chatbot suggestion");
  }
};

const handleBotMessage: handleNmsPayloadPart = (message, payloadPart) => {
  if (!payloadPart.textContent) {
    throw new Error("Invalid bot message payload");
  }

  message.richcardMessage = JSON.parse(payloadPart.textContent);
};

const handleImage: handleNmsPayloadPart = (message, payloadPart) => {
  const thumbnailOrRegularMedia = payloadPart.contentDisposition?.startsWith(
    "icon"
  )
    ? ({
        thumbnailUrl: payloadPart.href,
        thumbnailContentType: payloadPart.contentType,
        thumbnailFileSize: payloadPart.size!,
      } as any) // the rest of the media properties should be set later in a different payload part
    : {
        mediaUrl: payloadPart.href,
        height: "TALL_HEIGHT",
        mediaContentType: payloadPart.contentType,
        mediaFileSize: payloadPart.size!,
        // TODO contentDescription
        // contentDescription: "TODO",
      };

  if (message.richcardMessage?.message?.generalPurposeCard?.content?.media) {
    Object.assign(
      message.richcardMessage.message.generalPurposeCard.content.media,
      thumbnailOrRegularMedia
    );
  }

  message.richcardMessage ??= {
    message: {
      generalPurposeCard: {
        layout: {
          cardOrientation: "VERTICAL",
        },
        content: {
          media: thumbnailOrRegularMedia,
        },
      },
    },
  };
};

const handleApplication: handleNmsPayloadPart = (message, payloadPart) => {
  if (payloadPart.href) {
    const media = {
      mediaUrl: payloadPart.href,
      height: "TALL_HEIGHT" as const,
      mediaContentType: payloadPart.contentType,
      mediaFileSize: payloadPart.size!,
      contentDescription: payloadPart.textContent,
    };

    if (message.richcardMessage?.message?.generalPurposeCard?.content?.media) {
      Object.assign(
        message.richcardMessage.message.generalPurposeCard.content.media,
        media
      );
    }

    message.richcardMessage ??= {
      message: {
        generalPurposeCard: {
          layout: {
            cardOrientation: "VERTICAL",
          },
          content: {
            media: media,
          },
        },
      },
    };
  }
};

const handlePushLocation: handleNmsPayloadPart = (message, payloadPart) => {
  if (!payloadPart.textContent) {
    return;
  }

  const position = payloadPart.textContent.match(/<gml:pos>(.*)<\/gml:pos>/);

  const latLng =
    position && position.length == 2
      ? position[1]?.split(" ").map((coordinate) => {
          return parseFloat(coordinate);
        })
      : undefined;

  if (latLng && latLng.length == 2) {
    const coordinates = { lat: latLng[0], lng: latLng[1] };

    const media = {
      mediaUrl: generateMapUrl(coordinates),
      height: "TALL_HEIGHT" as const,
      mediaContentType: payloadPart.contentType,
      mediaFileSize: payloadPart.size!,
      contentDescription: JSON.stringify(coordinates),
    };

    if (message.richcardMessage?.message?.generalPurposeCard?.content?.media) {
      Object.assign(
        message.richcardMessage.message.generalPurposeCard.content.media,
        media
      );
    }

    message.richcardMessage ??= {
      message: {
        generalPurposeCard: {
          layout: {
            cardOrientation: "VERTICAL",
          },
          content: {
            media: media,
          },
        },
      },
    };
  }
};

const handleAudioFile: handleNmsPayloadPart = (message, payloadPart) => {
  const media = {
    mediaUrl: payloadPart.href,
    height: "SHORT_HEIGHT",
    mediaContentType: payloadPart.contentType,
    mediaFileSize: payloadPart.size!,
  } as any;

  message.richcardMessage ??= {
    message: {
      generalPurposeCard: {
        layout: {
          cardOrientation: "VERTICAL",
        },
        content: {
          media: media,
        },
      },
    },
  };
};

const handleVideoFile: handleNmsPayloadPart = (message, payloadPart) => {
  const media = {
    mediaUrl: payloadPart.href,
    mediaContentType: payloadPart.contentType,
    mediaFileSize: payloadPart.size!,
  } as any;

  message.richcardMessage = {
    message: {
      generalPurposeCard: {
        layout: {
          cardOrientation: "VERTICAL",
        },
        content: {
          media: {
            ...message.richcardMessage?.message.generalPurposeCard?.content
              .media,
            ...media,
          },
        },
      },
    },
  };
};

const handleVCard: handleNmsPayloadPart = (message, payload) => {
  if (!payload.href || message.contactCard) {
    return;
  }

  (async () => {
    const vcard = await queryClient.fetchQuery({
      queryKey: [payload.href],
      queryFn: async () => {
        try {
          const res = await fetch(payload.href!, {
            credentials: "include",
          });

          if (!res.ok) {
            return null;
          }

          const vcardText = convertToCRLF(await res.text());
          return new vCard().parse(vcardText);
        } catch (e) {
          console.error("unable to fetch vcard", e);
        }
        return null;
      },
      staleTime: Infinity,
    });

    if (!vcard) {
      message.contactCard!.error = ref(new Boolean(true));
      return;
    }

    const numberOrNumbers = vcard.get("tel");
    // find preferred number or use the first one
    const number = (
      Array.isArray(numberOrNumbers)
        ? numberOrNumbers.find((prop) => prop.is("pref")) || numberOrNumbers[0]
        : numberOrNumbers
    )?.valueOf();

    const fullNames = vcard.get("fn");
    const fullName = (fullNames?.[0] ?? fullNames).valueOf();

    const photoProperty = vcard.get("photo") as vCard.Property | undefined;
    const photoEncoding = (photoProperty as any)?.encoding as
      | string
      | undefined;
    let photoUrl = photoProperty?.valueOf();
    if (photoEncoding?.toLowerCase() === "base64") {
      const photoType = ((photoProperty as any)?.type as string) || "png";
      photoUrl = `data:image/${photoType};base64,${photoUrl}`;
    }

    message.contactCard!.webGwContact = ref(
      WebGwContact.fromAttributes({
        id: nanoid(),
        name: fullName,
        phone: [[number, ""]],
        photo: photoUrl,
      })
    );
    message.contactCard!.vcard = ref(vcard);
    message.contactCard!.loading = ref(new Boolean(false));
  })();

  message.contactCard ??= proxy({});

  // Only set if no boolean for loading
  if (!message.contactCard.loading) {
    message.contactCard.loading = ref(new Boolean(true));
  }
};

const handleUnimplemented: handleNmsPayloadPart = (message) => {
  const stylesheetContent = `
  message {
    color: grey;
    font-style: italic;
  }
`;
  const dataURI = `data:text/css;charset=utf-8,${encodeURIComponent(
    stylesheetContent
  )}`;
  message.richcardMessage ??= {
    message: {
      generalPurposeCard: {
        layout: {
          cardOrientation: "HORIZONTAL",
          imageAlignment: "LEFT",
          style: dataURI,
        },
        content: {
          title: "Message Type Not Supported",
        },
      },
    },
  };
};

const handlePrefixTypes = {
  audio: handleAudioFile,
  video: handleVideoFile,
} as const satisfies Record<string, handleNmsPayloadPart>;

const handleMessageTypes = {
  "text/plain": handleTextMessage,
  "application/vnd.gsma.botsuggestion.v1.0+json": handleBotSuggestions,
  "application/vnd.gsma.botsuggestion.response.v1.0+json": handleBotResponse,
  "application/vnd.gsma.botmessage.v1.0+json": handleBotMessage,
  image: handleImage,
  "application/vnd.gsma.rcs-ft-http+xml": handleImage,
  "application/vnd.gsma.rcspushlocation+xml": handlePushLocation,
  "multipart/mixed": () => {}, // TODO
  "application/xml": () => {}, // Do nothing
  "text/x-vcard": handleVCard,
  "text/vcard": handleVCard,
  application: handleApplication,
  unimplemented: handleUnimplemented,
} as const satisfies Record<string, handleNmsPayloadPart>;

export default function parseNmsToChatMessage(
  nmsMsgSnap: NmsMessage,
  fallbackToGenericFile?: boolean
): ChatMessage | null {
  if (!nmsMsgSnap.Direction) {
    throw new Error("Undefined message direction");
  }

  const message: Partial<ChatMessage> = {
    msgId: nmsMsgSnap["imdn.Message-ID"],
    originalMsgId: nmsMsgSnap.getOriginalMessageId(),
    time: nmsMsgSnap.Date,
    referenceId: nmsMsgSnap["Reference-ID"],
    referenceType: nmsMsgSnap["Reference-Type"],
    deleted: nmsMsgSnap.deleted,
    direction: nmsMsgSnap.Direction,
  };

  const payloadPartsCopy = nmsMsgSnap.payloadParts.map((part) => ({ ...part }));
  for (let i = 0; i < payloadPartsCopy.length; i++) {
    const payloadPart = payloadPartsCopy[i];

    const contentType = extractMimeType(
      payloadPart.contentType
    ) as keyof typeof handleMessageTypes;

    // special case where the multipart data needs to be parsed and unwrapped into new payload parts
    if (contentType === "multipart/mixed") {
      if (!payloadPart.textContent) {
        console.error(
          "No text content in multipart/mixed content type",
          payloadPart
        );
        continue;
      }

      const boundary = extractMultipartBoundary(payloadPart.contentType);
      if (!boundary) {
        console.error(
          "Could not extract boundary from multipart/mixed content type",
          payloadPart
        );
        continue;
      }

      parseMultipartMixed(payloadPart.textContent, boundary, payloadPartsCopy);
      continue;
    }

    const [type] = contentType.split("/");
    let handler = handlePrefixTypes[type as keyof typeof handlePrefixTypes] as
      | handleNmsPayloadPart
      | undefined;

    if (!handler) {
      handler = handleMessageTypes[contentType] as
        | handleNmsPayloadPart
        | undefined;
    }

    // Handle special cases for images files and generic files including text files
    if (
      fallbackToGenericFile ||
      !handler ||
      (contentType === "text/plain" && nmsMsgSnap.FileName)
    ) {
      if (
        fallbackToGenericFile ||
        contentType.startsWith("application") ||
        (contentType.startsWith("text") && !payloadPart.textContent)
      ) {
        payloadPart.textContent = nmsMsgSnap.FileName;
        handler = handleMessageTypes.application;
      } else if (contentType.startsWith("image")) {
        handler = handleMessageTypes.image;
      } else {
        console.error(
          `Unimplemented content type '${payloadPart.contentType}' => '${contentType}'`
        );
        handler = handleMessageTypes.unimplemented;
      }
    }

    handler(message, payloadPart);
  }

  if (
    !message.textMessage &&
    !message.deleted &&
    !message.richcardMessage &&
    !message.contactCard
  ) {
    const contentType =
      typeof nmsMsgSnap["Content-Type"] === "string"
        ? nmsMsgSnap["Content-Type"]
        : nmsMsgSnap["Content-Type"]?.[0];
    if (
      message.referenceType === "Delete" ||
      message.referenceType === "Recall" ||
      contentType?.startsWith("application/conference-info") ||
      contentType?.startsWith("application/xml")
    ) {
      return null;
    }

    console.error("Error with", nmsMsgSnap, message);
    throw new Error("Invalid message payload. No text or richcard");
  }

  if (nmsMsgSnap.Direction === "Out") {
    if (nmsMsgSnap.isDisplayedNotificationSent) {
      message.status = "read";
    } else {
      const imdns = extractImdns(nmsMsgSnap.imdns);
      if (imdns) {
        if (imdns.has("Displayed")) {
          message.status = "read";
        } else if (imdns.has("Delivered")) {
          message.status = "delivered";
        } else if (imdns.has("stored")) {
          message.status = "sent";
        } else if (imdns.has("Failed")) {
          message.status = "failed";
        } else {
          message.status = "sending";
        }
      } else {
        message.status = "sending";
      }
    }
  }

  replaceWithEmojis(message);

  return message as ChatMessage;
}

function replaceWithEmojis(message: Partial<ChatMessage>) {
  if (message.textMessage) {
    [message.textMessage] = replaceEmojis(message.textMessage);
  }

  if (message.richcardMessage?.message.generalPurposeCard?.content.title) {
    [message.richcardMessage.message.generalPurposeCard.content.title] =
      replaceEmojis(
        message.richcardMessage.message.generalPurposeCard.content.title
      );
  }

  if (
    message.richcardMessage?.message.generalPurposeCard?.content.description
  ) {
    [message.richcardMessage.message.generalPurposeCard.content.description] =
      replaceEmojis(
        message.richcardMessage.message.generalPurposeCard.content.description
      );
  }

  for (const suggestion of message.richcardMessage?.message.generalPurposeCard
    ?.content.suggestions || []) {
    if ("action" in suggestion) {
      [suggestion.action.displayText] = replaceEmojis(
        suggestion.action.displayText
      );
    } else {
      [suggestion.reply.displayText] = replaceEmojis(
        suggestion.reply.displayText
      );
    }
  }
}

function extractMimeType(s: string) {
  return s.split(";")[0];
}

function extractImdns(imdns?: ImdnList) {
  if (!imdns?.imdn) return;

  const imdnSet = new Set<ImdnInfo["type"]>();
  for (const imdn of imdns.imdn) {
    for (const info of imdn.imdnInfo) {
      imdnSet.add(info.type);
    }
  }
  return imdnSet;
}

export function getThumbnailImageFromNmsMessage(
  nmsMessage: NmsMessage | undefined,
  nmsMessageProxy: NmsMessage | undefined,
  fetchFromBase64 = false
) {
  if (!nmsMessage || !nmsMessageProxy) return;

  for (let i = 0; i < nmsMessage.payloadParts.length; i++) {
    const payloadPart = nmsMessage.payloadParts[i];
    if (payloadPart.contentDisposition?.startsWith("icon")) {
      if (!payloadPart.href) continue;

      if (fetchFromBase64 && !payloadPart.href.startsWith("data")) {
        void (async () => {
          const imageData = await queryClient.fetchQuery({
            queryKey: [payloadPart.href!],
            queryFn: async () => {
              try {
                const res = await fetch(payloadPart.href!, {
                  credentials: "include",
                });
                const imageBase64 = await res.text();
                return `data:${payloadPart.contentType};base64,${imageBase64}`;
              } catch (e) {
                console.error("unable to fetch thumbnail url", e);
              }
              return null;
            },
            staleTime: 1000 * 10,
            gcTime: 1000 * 10,
          });
          if (imageData) {
            // update the proxy so the thumbnail will rerender.
            nmsMessageProxy.payloadParts[i].href = imageData;
          }
        })();
      }

      return {
        thumbnailUrl: payloadPart.href,
        thumbnailContentType: payloadPart.contentType,
        thumbnailFileSize: payloadPart.size!,
        contentDisposition: payloadPart.contentDisposition ?? "",
      };
    }
  }
}
