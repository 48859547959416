import { SerializedStyles } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

type OtpInputProps = {
  amount: number;
  autoFocus?: boolean;
  handleOutputString: (output: string) => void;
  inputProps: {
    className: string;
    css: SerializedStyles;
    placeholder: string;
  };
  inputRegExp: RegExp;
};

const OtpInput = ({
  amount,
  autoFocus,
  handleOutputString,
  inputProps,
  inputRegExp,
}: OtpInputProps) => {
  const [inputValues, setInputValues] = useState<string[]>(
    Array(amount).fill("")
  );

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    const handleBackspace = (e: KeyboardEvent) => {
      if (e.key === "Backspace") {
        e.preventDefault();
        const currentIndex = inputRefs.current.findIndex(
          (input) => input === document.activeElement
        );
        if (currentIndex !== -1) {
          const newInputValues = [...inputValues];
          const currentInputValue = newInputValues[currentIndex];
          if (currentInputValue.length > 0) {
            newInputValues[currentIndex] = currentInputValue.slice(0, -1);
          } else if (currentIndex > 0) {
            newInputValues[currentIndex - 1] = "";
            inputRefs.current[currentIndex - 1]?.focus();
          }
          setInputValues(newInputValues);
          handleOutputString(newInputValues.join(""));
        }
      }
    };

    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      if (e.clipboardData) {
        const pastedText = e.clipboardData.getData("text/plain");
        if (pastedText.length === amount) {
          const newInputValues = pastedText.split("");
          setInputValues(newInputValues);
          handleOutputString(newInputValues.join(""));
          inputRefs.current[0]?.focus();
        }
      }
    };

    window.addEventListener("keydown", handleBackspace);
    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("keydown", handleBackspace);
      window.removeEventListener("paste", handlePaste);
    };
  }, [inputValues, inputRefs, handleOutputString, amount]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const inputValue = e.target.value;

    if (inputRegExp.test(inputValue)) {
      const newInputValues = [...inputValues];
      newInputValues[index] = inputValue;
      setInputValues(newInputValues);
      handleOutputString(newInputValues.join(""));

      if (index < amount - 1 && inputValue.length === 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        gap: "1vw",
        marginTop: "1vh",
      }}
    >
      {inputValues.map((value, index) => (
        <input
          key={index}
          type="text"
          autoFocus={autoFocus && index === 0}
          value={value}
          onChange={(e) => handleInputChange(e, index)}
          ref={(input) => {
            inputRefs.current[index] = input;
          }}
          {...inputProps}
        />
      ))}
    </div>
  );
};

export default OtpInput;
