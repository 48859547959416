import type { FFmpeg as _FFmpeg } from "@ffmpeg/ffmpeg";
import type { toBlobURL as _toBlobURL } from "@ffmpeg/util";
import ffmpegCoreJsUrl from "../../../node_modules/@ffmpeg/core/dist/esm/ffmpeg-core.js?url";
import ffmpegCoreWasmUrl from "../../../node_modules/@ffmpeg/core/dist/esm/ffmpeg-core.wasm?url";
import { blobToUint8Array } from "../messaging/conversation/conversationUtils/";

let coreURL: string;
let wasmURL: string;
let FFmpeg: typeof _FFmpeg;
let toBlobURL: typeof _toBlobURL;

async function loadFFmpegResources() {
  let FFmpegPromise: Promise<typeof _FFmpeg>;
  if (!FFmpeg) {
    FFmpegPromise = import("@ffmpeg/ffmpeg").then((f) => f.FFmpeg);
  }

  toBlobURL ??= (await import("@ffmpeg/util")).toBlobURL;

  if (!coreURL || !wasmURL) {
    [coreURL, wasmURL] = await Promise.all([
      toBlobURL(ffmpegCoreJsUrl, "text/javascript"),
      toBlobURL(ffmpegCoreWasmUrl, "application/wasm"),
    ]);
  }

  FFmpeg ??= await FFmpegPromise!;
}

export async function loadFFmpeg() {
  try {
    await loadFFmpegResources();

    const ffmpeg = new FFmpeg();
    await ffmpeg.load({
      coreURL,
      wasmURL,
    });
    return ffmpeg;
  } catch (e) {
    console.error("Unable to load FFmpeg", e);
  }
}

const input = "input.weba";
const output = "output.mp3";
export async function convertWebMAudioToMP3(ffmpeg: _FFmpeg, audio: Blob) {
  await ffmpeg.writeFile(input, await blobToUint8Array(audio));
  await ffmpeg.exec(["-i", input, output]);

  const deleteInputPromise = ffmpeg.deleteFile(input);

  const fileData = await ffmpeg.readFile(output);

  await ffmpeg.deleteFile(output);
  await deleteInputPromise;

  return new Blob([fileData], { type: "audio/mp3" });
}
