import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const TextButton = styled.button<{ active: boolean }>(({ active }) => ({
  transition: "color 0.2s",
  ":disabled": {
    color: colors.tertiaryTextColor,
    cursor: "default",
  },
  ...(active && {
    color: colors.primaryAccentColor,
  }),
}));
