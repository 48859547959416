import type { CSSObject } from "@emotion/react";

type ToolTipProps = React.HTMLAttributes<HTMLDivElement> & {
  tooltipContent?: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  css?: CSSObject;
};
export default function ToolTip({
  children,
  tooltipContent,
  color,
  backgroundColor,
  ...props
}: ToolTipProps) {
  return (
    <div
      {...props}
      css={{
        zIndex: "1000",
        position: "relative",
        ":hover > div": {
          opacity: "1",
          transitionDelay: "0.5s", // hovered transition is 0.5s
          pointerEvents: "auto",
        },
      }}
    >
      <div
        css={{
          userSelect: "none",
          position: "absolute",
          top: "-0.5em",
          left: "50%",
          width: "100%",
          height: "100%",
          opacity: "0",
          transition: "opacity 0.25s ease",
          transitionDelay: "0", // default transition is 0s
          pointerEvents: "none",
        }}
      >
        <div
          css={{
            position: "fixed", // fixed position with absolute parent to ignore css overflow: hidden of the container
            borderRadius: "6px",
            backgroundColor,
            color,
            padding: "0.5em",
            transform: "translate(-50%, -100%)",
            "::after": {
              content: "''",
              zIndex: "-1",
              position: "absolute",
              bottom: "0",
              left: "50%",
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderTop: `6px solid ${backgroundColor}`,
              display: "inline-block",
              transform: "translate(-50%, 100%)",
            },
          }}
        >
          {tooltipContent}
        </div>
      </div>
      {children}
    </div>
  );
}
