import { colors, hexToRgb } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useToast } from "@/utils/helpers/toastManager";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { displayChatScreenOverlay } from "@/utils/messaging/conversation/conversationUtils";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { slideFromLeft, slideFromRight } from "../EventStream.style";

type StreamServicesProps = {
  event: OdienceEvent;
  isPanelOpen: boolean;
  profileComplete: boolean;
  handleActionToParticipate: () => void;
  toggleStreamServicesPanel: () => void;
};

const StreamServices = ({
  event,
  isPanelOpen,
  profileComplete,
  handleActionToParticipate,
  toggleStreamServicesPanel,
}: StreamServicesProps) => {
  const [panelBackgroundImage, setPanelBackgroundImage] = useState(
    event.brand.brand_background_image_url
  );
  const [panelBackgroundColor, setPanelBackgroundColor] = useState(
    event.brand.brand_background_color
  );
  const [panelBackgroundOpacity, setPanelBackgroundOpacity] = useState(
    event.brand.brand_background_opacity
  );

  const { showToast } = useToast();

  useEffect(() => {
    setPanelBackgroundImage(event.brand.brand_background_image_url);
    setPanelBackgroundOpacity(event.brand.brand_background_opacity);
    setPanelBackgroundColor(event.brand.brand_background_color);
  }, [
    event.brand.brand_background_opacity,
    event.brand.brand_background_image_url,
    event.brand.brand_background_color,
  ]);

  const chatbotListMapped = event?.chatbots.map((objChatbot) => {
    const customName =
      profileComplete || !isProvisioned()
        ? objChatbot.display_name
        : "Enter Display Name to participate";
    const chatbotId = objChatbot.bot_id;

    const handleShowChatOverlay = async () => {
      const contact = WebGwContact.fromChatbotInfo(objChatbot);
      const phoneNumber = contact.getMainPhoneNumber();

      const { conversation } = Conversation.getOrCreate({
        phoneNumber,
        contactToLinkIfCreate: contact,
      });

      displayChatScreenOverlay(phoneNumber);
      await conversation.startChatbotConversation();
    };

    const handleOnChatbotClick = async () => {
      if (isProvisioned()) {
        if (objChatbot.payload !== "client_qr_scan") {
          if (!profileComplete) {
            handleActionToParticipate();
          } else {
            toggleStreamServicesPanel();
            await handleShowChatOverlay();
          }
        } else {
          showToast(
            "Long press on the QR Codes in-stream to view the product catalog",
            "top-center"
          );
        }
      } else {
        showToast("Log in to use In-Stream Services", "top-center");
      }
    };

    if (chatbotId !== undefined || chatbotId !== "undefined") {
      return (
        <button
          key={objChatbot.id}
          onClick={handleOnChatbotClick}
          css={chatbotButtonStyle(event)}
        >
          <div css={chatbotImageStyle}>
            <img
              src={objChatbot.action_image_url}
              alt={`Open ${objChatbot.display_name} chatbot`}
            />
          </div>
          <div>{customName}</div>
        </button>
      );
    }

    return null;
  });

  return (
    <div
      css={[
        panelStyle(event, isPanelOpen),
        {
          background: panelBackgroundImage
            ? `linear-gradient(rgba(${hexToRgb(panelBackgroundColor)?.join(",")}, ${
                1 - (panelBackgroundOpacity ?? 1)
              }), rgba(${hexToRgb(panelBackgroundColor)?.join(",")}, ${
                1 - (panelBackgroundOpacity ?? 1)
              })), url(${panelBackgroundImage})`
            : panelBackgroundColor,
          backgroundRepeat: panelBackgroundImage ? "no-repeat" : "initial",
          backgroundSize: panelBackgroundImage ? "cover" : "initial",
          backgroundPosition: panelBackgroundImage ? "center" : "initial",
        },
      ]}
    >
      <div css={headerStyle(event)}>
        <div css={brandInfoStyle(event)}>
          <div css={brandTitleStyle}>{event?.brand?.brand_title}</div>
          <div css={brandSubtitleStyle}>{event?.brand?.brand_subtitle}</div>
        </div>
        <button onClick={toggleStreamServicesPanel} css={buttonStyle}>
          <img
            src="/odience/event/white-close.svg"
            alt="Close Stream Services Panel"
            css={css`
              height: 1.1em;
              width: 1.1em;
            `}
          />
        </button>
      </div>
      <div css={ServicesListStyle(event)}>
        {chatbotListMapped}
        {event?.brand?.brand_ad_image_url && (
          <div css={adImageStyle}>
            <img
              src={event.brand.brand_ad_image_url}
              alt="Event Brand Image"
              css={css`
                width: 100%;
                height: auto;
                object-fit: cover;
              `}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StreamServices;

const panelStyle = (event, isPanelOpen) => css`
  background-color: ${event?.brand?.brand_background_color ?? "#141414"};
  visibility: ${isPanelOpen ? "visible" : "hidden"};
  top: 0;
  position: absolute;
  right: 0;
  z-index: 10;
  overflow: hidden;
  flex-direction: column;
  border-radius: 10px;
  gap: 0.3em;
  width: 100%;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  animation: 0.3s forwards linear
    ${isPanelOpen ? slideFromRight : slideFromLeft};
  display: flex;
  padding-bottom: 1.4em;
  color: ${colors.primaryTextColor};
`;

const headerStyle = (event) => css`
  background-color: ${event.brand.brand_background_image_url
    ? undefined
    : (event?.brand?.brand_background_color ?? "#222222")};
  width: 90%;
  padding: 1.4em;
  display: flex;
  align-items: center;
  height: 5.6em;
  box-sizing: border-box;
`;

const brandInfoStyle = (event) => css`
  color: ${event?.brand?.brand_text_color ?? colors.primaryTextColor};
  margin-top: 0.6em;
  display: flex;
  flex-direction: column;
`;

const brandTitleStyle = css`
  font-weight: bold;
  font-size: 1.4rem;
`;

const brandSubtitleStyle = css`
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.6em;
`;

const buttonStyle = css`
  margin-left: auto;
  cursor: pointer;
  user-select: none;
`;

const ServicesListStyle = (event) => css`
  box-sizing: border-box;
  max-width: 27em;
  width: 27.5vw;
  min-width: 15em;
  display: flex;
  padding: 1.4em;
  overflow-y: auto;
  flex-direction: column;
  scrollbar-width: none;
  &::-webkit-scrollbar-track {
    background-color: ${event?.brand?.brand_background_color};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${event?.brand?.brand_background_color};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar {
    border-radius: 10px;
    display: none;
    width: 0.3em;
    scrollbar-color: #1c1c1c;
    scrollbar-width: thin;
  }
`;

const adImageStyle = css`
  margin-top: 1.4em;
  border-radius: 10px;
  padding-top: 1.4em;
`;

const chatbotButtonStyle = (event) => css`
  color: ${event?.brand?.brand_text_color ?? colors.primaryTextColor};
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  align-items: center;
  margin: 0.6em 0;
  font-style: italic;
  font-size: 1.1em;
`;

const chatbotImageStyle = css`
  margin-right: 0.6em;
  width: 3em;
  img {
    width: 100%;
  }
`;
