import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

/* the hr styling is already overwritten globally */
export const VerticalRule = styled.hr({
  height: "60%",
  width: "1px",
  margin: "0 0.5em",
  backgroundColor: colors.tertiaryTextColor,
});
