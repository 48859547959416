import BlockedScreenGraphic from "@/assets/settingsScreen/No_Blocked _Graphic.svg";
import BlockedContactCard from "@/components/contactListScreen/BlockedContactCard";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { ListContainer } from "@/components/shared/ListItem.style";
import SvgGraphic from "@/components/shared/SvgGraphic";
import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import {
  fetchBlockedContactsList,
  unblockContact,
} from "@/utils/helpers/fetchContacts";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useToast } from "@/utils/helpers/toastManager";
import { useContacts } from "@/utils/hooks/useContacts";
import InfoIcon from "@mui/icons-material/Info";
import { useAtom } from "jotai";
import { AnimatePresence } from "motion/react";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
const BlockedList = () => {
  const [data, setData] = useAtom(atoms.contacts.blockedList);
  const [webgwContacts, setWebgwContacts] = useState<WebGwContact[]>([]);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<WebGwContact | null>(
    null
  );
  const { showToast } = useToast();

  const contacts = useContacts();

  useEffect(() => {
    fetchBlockedContactsList(setData);
  }, [setData]);

  useEffect(() => {
    if (!contacts) return;
    const webgwContacts2: WebGwContact[] | undefined = data?.map(
      (phoneNumber) => {
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber, "E123");

        return (
          contacts?.findWithNumber(formattedPhoneNumber) ||
          WebGwContact.fromPhoneNumber(formattedPhoneNumber)!
        );
      }
    );
    if (webgwContacts2) {
      setWebgwContacts(webgwContacts2);
    }
  }, [contacts, data]);

  const toggleBlockModal = (contact?: WebGwContact) => {
    if (contact) setSelectedContact(contact);
    console.log("selected contact", selectedContact);
    setIsBlockModalOpen(!isBlockModalOpen);
  };

  const handleUnblockOk = async () => {
    if (!selectedContact) return;
    const mainPhoneNumber = await formatPhoneNumber(
      selectedContact.getMainPhoneNumber(),
      "SIP"
    );
    const success = await unblockContact(mainPhoneNumber);
    if (success) {
      showToast("Contact has been unblocked!", "bottom-right");
      setWebgwContacts(
        webgwContacts.filter((inCon) => inCon?.id !== selectedContact.id)
      );
    } else {
      toast.error("Error unblocking the contact.", {
        icon: <InfoIcon />,
        duration: 5000,
        position: "bottom-right",
        style: { backgroundColor: "#2E3237", color: colors.primaryAccentColor },
      });
    }
    setIsBlockModalOpen(false);
  };

  return (
    <>
      <div
        css={{
          color: colors.primaryTextColor,
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <div css={{ fontSize: "1.75em", marginBottom: "4vh" }}>
          Blocked Numbers
        </div>
        {webgwContacts && webgwContacts.length > 0 ? (
          <ListContainer>
            {webgwContacts?.map((contact: WebGwContact) => {
              if (contact?.isChatbot) return null;

              return (
                <Fragment key={contact.id}>
                  <BlockedContactCard
                    contact={contact}
                    toggleBlockModal={toggleBlockModal}
                  />
                </Fragment>
              );
            })}
          </ListContainer>
        ) : (
          <div css={{ height: "100%", width: "100%" }}>
            <span>You have no blocked numbers. Hooray!</span>
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                height: "70vh",
                alignItems: "center",
              }}
            >
              <SvgGraphic src={BlockedScreenGraphic} />
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isBlockModalOpen && (
          <ConfirmationPopup
            title="Unblock Contact"
            togglePopup={() => toggleBlockModal()}
            confirmationMessage="Are you sure you want to unblock this contact?"
            handleAction={handleUnblockOk}
            primaryButtonText="Unblock"
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default BlockedList;
