import { useEffect, useRef, useState } from "react";
import { sleep } from "..";
import { setCalls } from "../calls/callState";
import { insertNewCallsIntoRecentCallsMap } from "../calls/callUtils";
import { getContactsAsync, setLoadedContacts } from "../contacts/contactUtils";
import {
  deleteDbs,
  getStoredCalls,
  updateStoredCalls,
  updateStoredConversations,
} from "../database";
import WebGwContact, { WebGwContactList } from "../helpers/WebGwContact";
import {
  getLocalMessagesCursor,
  removeLocalMessagesCursor,
  setLocalMessagesCursor,
} from "../helpers/localstorage";
import type NmsMessage from "../messaging/NmsMessage";
import { conversationsState } from "../messaging/conversation/ConversationState";
import {
  insertNewNmsMessagesIntoConversation,
  setConversationMessageAsSent,
} from "../messaging/conversation/conversationUtils";
import { updateConversationInDatabase } from "../messaging/conversation/conversationUtils/updateConversationInDatabase";
import { fetchMessages } from "../messaging/fetchMessages";
import { getChatbotDirectory } from "../queries/chatbots";

async function fetchNmsMessages(
  cursor?: string | null,
  messages: NmsMessage[][] = [[], [], []]
): Promise<{ cursor: string; messages: NmsMessage[][] } | null> {
  console.info(
    "Fetching messages from NMS, currently have",
    messages.reduce((s, e) => s + e.length, 0),
    "messages"
  );

  const messagesObj = await fetchMessages(cursor).catch((err) => {
    console.error("Failed to sync NMS messages", err);
    return null;
  });

  if (!messagesObj) {
    console.warn("NMS returned an invalid answer");
    return null;
  }

  const { messages: newMessages, creationCursor } = messagesObj;

  console.info(
    "Loaded",
    newMessages.length,
    "messages from NMS, continue loading..."
  );

  if (newMessages.length === 0) {
    console.log(
      "Done loading all messages from NMS, returning",
      messages.reduce((s, e) => s + e.length, 0),
      "messages"
    );
    return { cursor: creationCursor, messages };
  }

  // process new messages
  const arrMessages = newMessages.reduce(
    (acc: NmsMessage[][], e: NmsMessage) => {
      const [callHistory, others, conferenceInfo] = acc;

      if (e["Content-Type"] === "application/conference-info+xml") {
        const toUpdateIndex = conferenceInfo.findIndex(
          (c) => c["Contribution-ID"] === e["Contribution-ID"]
        );

        const updatedConferenceInfo = [...conferenceInfo];
        if (toUpdateIndex !== -1) {
          updatedConferenceInfo[toUpdateIndex] = e; //  replace existing entry
        } else {
          updatedConferenceInfo.push(e); //add new entry
        }

        return [callHistory, others, updatedConferenceInfo];
      } else if (e["Message-Context"] === "message/callhistory") {
        return [[...callHistory, e], others, conferenceInfo];
      } else {
        return [callHistory, [...others, e], conferenceInfo];
      }
    },
    messages
  );

  return fetchNmsMessages(creationCursor, arrMessages);
}

export function useInitNmsMessages(handleSyncData: boolean): boolean {
  const initialized = useRef(false);
  const [isSynced, setIsSynced] = useState(false);

  useEffect(() => {
    async function syncData(): Promise<boolean> {
      try {
        const msgCount = await syncStoredNmsInfo();
        if (msgCount !== 0) {
          await markEmptyImdnMessagesAsSent();
        }
        setIsSynced(true);
        return true;
      } catch (e) {
        console.error("useInitNmsMessages: error:", e);
        if (e instanceof TypeError) {
          // Retry logic for handling stored data conflicts
          await deleteDbs();
          await sleep(2000);
          return syncData();
        } else {
          throw new Error("Syncing data failed");
        }
      }
    }

    if (handleSyncData) {
      if (initialized.current) return;

      initialized.current = true;
      console.log("NmsDatabase: loading messages from network message store");

      syncData().catch((e) => {
        console.error("Background sync failed:", e);
      });
    }
  }, [handleSyncData]);

  return isSynced;
}

async function markEmptyImdnMessagesAsSent() {
  for (const conversation of conversationsState.conversations.values()) {
    const messages = conversation
      .getMessages()
      .filter((message) => message.imdns?.imdn?.length === 0);
    let updateDatabase = false;

    for (const message of messages) {
      updateDatabase = true;
      console.log("Marking message ", message["imdn.Message-ID"], " as sent");

      if (message.Direction === "Out") {
        setConversationMessageAsSent(
          message["imdn.Message-ID"],
          message.To,
          message.Date,
          false
        );
      }
    }

    if (updateDatabase) {
      await updateConversationInDatabase(conversation.id);
    }
  }
}

async function syncStoredNmsInfo() {
  try {
    console.info("Loading info from NMS");
    const contactsPromise = getContactsAsync();
    const chatbotDirectoryPromise = getChatbotDirectory();

    let contacts: WebGwContactList | null = null;
    try {
      contacts = (await contactsPromise) || null;
    } catch (e) {
      console.warn("contactsPromise failed", e);
    }

    if (contacts === null) {
      contacts = new WebGwContactList();
    }

    try {
      const chatbots = (await chatbotDirectoryPromise)[0];
      if (chatbots) {
        for (const cb of chatbots) {
          contacts.push(WebGwContact.fromChatbotInfo(cb));
        }
      }
      // updates react-query state. This makes refreshing on the contacts page possible
      setLoadedContacts(contacts);
    } catch (e) {
      console.warn("chatbotDirectoryPromise failed", e);
    }

    console.info(
      "Loaded",
      contacts.length,
      "contacts from chatbots & contacts"
    );

    const [messages, calls] = await Promise.all([
      fetchNmsMessages(getLocalMessagesCursor()),
      getStoredCalls(),
    ]);

    const syncedMessages = messages?.messages;
    const cursorMessages = messages?.cursor || "";
    const total = syncedMessages?.reduce((s, e) => s + e.length, 0);
    console.log("messages fetched in total -> ", total);

    if (syncedMessages && total) {
      const newCalls = new Map(calls);
      await insertNewNmsMessagesIntoConversation(
        [...syncedMessages[2], ...syncedMessages[1]],
        contacts
      );
      insertNewCallsIntoRecentCallsMap(syncedMessages[0], contacts, newCalls);
      setCalls(newCalls);
      await updateStoredCalls(newCalls);
      await updateStoredConversations(conversationsState.conversations);
      setLocalMessagesCursor(cursorMessages);
      return syncedMessages.length;
    }

    return 0;
  } catch (error) {
    removeLocalMessagesCursor();
    throw error;
  }
}
