import VerseLogo from "@/assets/VerseLogo";
import { PolicyLinks } from "@/components/shared/PolicyScreen";
import { colors } from "@/styles/global.styles";
import { verseEnv, versionVerseWebGw, versionVerseWebUi } from "@/utils";
import { icon, text, textBody, textDark } from "../provisioning/index.style";

const AboutPage = () => {
  return (
    <div
      css={{
        color: colors.primaryTextColor,
        display: "flex",
        flexDirection: "column",
        width: "90%",
      }}
    >
      <div css={{ fontSize: "1.75em", marginBottom: "2vh" }}>About</div>
      <div
        css={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <VerseLogo css={icon} />
        <h2 css={text}>VERSE Messages</h2>

        <div
          css={[
            textBody,
            {
              fontSize: "0.85em",
            },
          ]}
        >
          <h3
            css={[
              text,
              { textAlign: "center", margin: "1em", fontWeight: "bold" },
            ]}
          >
            Enjoy a Rich Communication Experience
          </h3>
          <p
            css={[
              textDark,
              { textAlign: "center", margin: "2em", marginBottom: "0em" },
            ]}
          >
            © Summit Tech 2023
          </p>
          <p css={[textDark, { textAlign: "center" }]}>All rights reserved</p>

          <br />

          <PolicyLinks />
        </div>
      </div>
      <div
        style={{
          fontSize: "0.8em",
          color: colors.secondaryTextColorLighter,
          textAlign: "center",
        }}
      >
        {verseEnv !== "prod" && (
          <>
            <p>
              <u>webgw-ui</u>
              <br />
              <b>{versionVerseWebUi}</b>
            </p>
            <p style={{ marginTop: "1em" }}>
              <u>webgw-api</u>
              <br />
              <b>{versionVerseWebGw}</b>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AboutPage;
