import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const Root = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "calc(100% - 3em)",
  height: "2.5em",
  backgroundColor: colors.secondaryBackground,
  borderRadius: "10px",
});

export const icon = css({
  height: "100%",
  marginLeft: "0.5em",
  color: colors.secondaryTextColor,
  display: "flex",
  alignItems: "center",
});
