import Infinity from "@/assets/infinity.svg";
import WhiteQR from "@/assets/qr-white.svg";
import RestreamIcon from "@/assets/restream.svg";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useToast } from "@/utils/helpers/toastManager";
import { isMobile } from "@/utils/helpers/Utils";
import { keyframes } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import GetAppModal from "../../../components/GetAppModal";
import {
  JoinButtonContainer,
  JoinButtonStyled,
  PulseStyle,
  StreamButtonStyle,
  UpcomingEventContainer,
  UpcomingEventStyle,
} from "../EventDetailsPage.style";
import {
  getEventEndDate,
  handleRequestToJoinEvent,
} from "../helpers/EventDetailsUtils";
import GuestListPopup from "./GuestListPopup";

type Props = {
  objEvent: any;
};

const JoinButton = ({ objEvent }: Props) => {
  const { showToast } = useToast();
  const [isAppModalOpen, setAppModalOpen] = useState(false);
  const setShowPrivateEventPopup = useSetAtom(atoms.odience.privateEventPopup);
  const [showRequestToJoinPopup, setShowRequestToJoinPopup] = useState(false);
  const navigate = useNavigate();

  const streamAvailable = ["live", "re-stream"].includes(objEvent.label);
  const requestToJoin = objEvent.invitations_only;
  const invitationRequested = objEvent.invitation_requested;
  const invitationAccepted = objEvent.invitation_accepted;

  const navigateToStream = () => {
    const streamPath = generatePath(
      isProvisioned() ? paths.stream : paths.previewOdienceStream,
      { eventId: objEvent.id }
    );
    setShowPrivateEventPopup(false);
    void navigate(streamPath);
  };

  const handleClickJoinButton = () => {
    if (requestToJoin) {
      if (!invitationRequested && !invitationAccepted) {
        setShowRequestToJoinPopup(true);
      } else if (invitationRequested && !invitationAccepted) {
        showToast("Your Guest List request is pending", "top-center", "1vw");
      } else if (invitationAccepted) {
        navigateToStream();
      }
    } else {
      navigateToStream();
    }
  };

  const pulsate = keyframes`
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(0.8); opacity: 0.8; }
    100% { transform: scale(1); opacity: 0; }
  `;

  const renderJoinButton = () => {
    const commonStyles = {
      backgroundColor:
        objEvent.label === "re-stream"
          ? colors.primaryBackground
          : colors.primaryAccentColor,
      border: objEvent.label === "re-stream" ? "2px solid white" : "none",
    };

    return (
      <JoinButtonStyled
        css={objEvent.web_allowed === 0 ? { ...commonStyles } : commonStyles}
        onClick={
          objEvent.web_allowed === 0
            ? () => setAppModalOpen(true)
            : handleClickJoinButton
        }
      >
        <div
          css={[
            StreamButtonStyle,
            {
              width: "8em",
            },
          ]}
        >
          <div
            css={[
              PulseStyle,
              {
                right: getPulsePosition(),
                animation: `${pulsate} 2s infinite`,
              },
            ]}
          />
          <span
            css={{
              fontSize: objEvent.web_allowed === 0 ? "0.75em" : "1em",
              fontWeight: "bold",
            }}
          >
            {objEvent.web_allowed === 0
              ? "Get the app to join this event"
              : objEvent.label === "re-stream"
                ? "Restream"
                : "Live"}
          </span>

          {requestToJoin &&
          !invitationAccepted &&
          objEvent.web_allowed === 1 ? (
            <img
              src={"/odience/event/lockIcon.svg"}
              alt="Restream Icon"
              css={{ height: "1em", width: "1em" }}
            />
          ) : objEvent.web_allowed === 0 ? (
            <img src={WhiteQR} alt="QR Code" />
          ) : objEvent.label === "live" ? (
            <ExpandMoreIcon
              css={{ height: "2em", width: "2em", transform: "rotate(270deg)" }}
            />
          ) : (
            <img src={RestreamIcon} alt="Restream Icon" />
          )}
        </div>
      </JoinButtonStyled>
    );
  };

  const getPulsePosition = () => {
    if (isMobile() && objEvent.web_allowed === 0) return "8.5%";
    if (objEvent.web_allowed === 0) return "4.6%";
    if (isMobile() && requestToJoin && !invitationAccepted) return "7.5%";
    if (requestToJoin && !invitationAccepted) return "5%";
    if (objEvent.label === "re-stream" && isMobile()) return "8%";
    if (objEvent.label === "re-stream") return "2.85%";
    if (objEvent.label === "live" && isMobile()) return "13%";
    if (objEvent.label === "live") return "8%";
  };

  const renderUpcomingEvent = () => {
    const dateObj = new Date(objEvent.date * 1000);

    return (
      <UpcomingEventContainer>
        <div css={[UpcomingEventStyle, { backgroundColor: "#333333" }]}>
          <span css={{ fontSize: "1.4em", textTransform: "uppercase" }}>
            {dateObj.toLocaleString("default", { month: "short" })}
          </span>
          <span css={{ fontSize: "3em" }}>{dateObj.getDate()}</span>
          <span css={{ color: colors.secondaryTextColor, fontSize: "1.5em" }}>
            {dateObj.getFullYear()}
          </span>
        </div>
        <div css={[UpcomingEventStyle, { border: "3px solid #333333" }]}>
          <span css={{ fontSize: "2.5em" }}>
            {
              dateObj
                .toLocaleString("default", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
                .split(" ")[0]
            }
          </span>
          <span css={{ fontSize: "1.5em", color: colors.secondaryTextColor }}>
            {
              dateObj
                .toLocaleString("en-US", { hour: "numeric", hour12: true })
                .split(" ")[1]
            }
          </span>
        </div>
      </UpcomingEventContainer>
    );
  };

  return (
    <>
      {streamAvailable && (
        <JoinButtonContainer>
          <StreamTimeDetails objEvent={objEvent} />
          {renderJoinButton()}
        </JoinButtonContainer>
      )}
      {objEvent.label === "upcoming" && renderUpcomingEvent()}
      <GetAppModal
        show={isAppModalOpen}
        onCloseModal={() => setAppModalOpen(false)}
        objEvent={null}
      />
      {showRequestToJoinPopup && (
        <GuestListPopup
          objEvent={objEvent}
          handleRequestToJoinEvent={handleRequestToJoinEvent}
          handleShowRequestToJoinPopup={setShowRequestToJoinPopup}
        />
      )}
    </>
  );
};

const StreamTimeDetails = ({ objEvent }: { objEvent: any }) => {
  const endDate = getEventEndDate(objEvent.date, objEvent.duration);

  const dateObj = new Date(objEvent.date * 1000);
  const formattedDate = dateObj.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  if (objEvent.label === "re-stream") {
    return <div>WAS LIVE {formattedDate}</div>;
  }
  if (objEvent.label === "live") {
    return (
      <div css={{ display: "flex", gap: "1vw", alignItems: "center" }}>
        STARTED @ {formattedTime} <span>•</span>
        {objEvent.duration === -1 ? (
          <img src={Infinity} css={{ height: "1em" }} />
        ) : (
          endDate.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        )}
      </div>
    );
  }
  return (
    <div>
      {formattedDate} •{" "}
      {objEvent.duration === -1
        ? "Unlimited"
        : endDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })}
    </div>
  );
};

export default JoinButton;
