import voiceAIBip from "@/assets/sounds/VoiceAIBip.mp3";
import Webrtc, { CALL_STATE } from "../webrtc/webrtc";
import TranscriptSender from "./transcriptSender";
import VoiceBot, { VOICE_BOT_STATE } from "./voicebot";

export default class VoiceBotManager {
  private transcriptSender: TranscriptSender;
  //private liveVoiceBot: VoiceBot | undefined;
  private callVoiceBots: Map<string, VoiceBot>;
  private onTranscriptReceived!: any;
  private webrtc: Webrtc;
  private bipAudioTimer?: NodeJS.Timeout;
  private static readonly BIP_AUDIO_DELAY_INIT_MS = 3000;
  private static readonly BIP_AUDIO_DELAY_LOOP_MS =
    VoiceBotManager.BIP_AUDIO_DELAY_INIT_MS * 10;
  private currentAudioNode?: AudioNode;
  private currentAudioContext?: AudioContext;
  private bipAudioBuffer?: AudioBuffer;
  constructor(transcriptSender: TranscriptSender, webrtc: Webrtc) {
    this.transcriptSender = transcriptSender;
    this.callVoiceBots = new Map();
    this.webrtc = webrtc;
    this.fetchBipAudio();
  }

  private async fetchBipAudio() {
    const response = await fetch(voiceAIBip);
    const arrayBuffer = await response.arrayBuffer();
    this.bipAudioBuffer = await new AudioContext().decodeAudioData(arrayBuffer);
  }

  public setCurrentMediaStream(
    audioContext: AudioContext,
    audioNode: AudioNode
  ) {
    this.currentAudioNode = audioNode;
    this.currentAudioContext = audioContext;
  }

  public startLiveRecording() {
    const liveVoiceBot = this.callVoiceBots.get(this.webrtc.getCallId());
    const res = liveVoiceBot?.startLiveRecording();

    if (res) {
      this.playBipAudio(true, true);
    }

    return res;
  }

  public playBipAudio(play: boolean, init?: boolean) {
    if (play) {
      this.bipAudioTimer = setTimeout(
        () => {
          // This can only be played once, hence we need to set it every time
          const bipAudioSource = new AudioBufferSourceNode(
            this.currentAudioContext!,
            { buffer: this.bipAudioBuffer }
          );

          // Set bip audio to current audio stream + local speaker (destination)
          bipAudioSource.connect(this.currentAudioNode!);
          bipAudioSource.connect(this.currentAudioContext!.destination);
          bipAudioSource.start();

          // We loop the bip first after 3s, then in loop after 30s
          this.playBipAudio(true, false);
        },
        init
          ? VoiceBotManager.BIP_AUDIO_DELAY_INIT_MS
          : VoiceBotManager.BIP_AUDIO_DELAY_LOOP_MS
      );
    } else {
      clearTimeout(this.bipAudioTimer);
    }
  }

  public stopLiveRecording() {
    this.playBipAudio(false);
    const liveVoiceBot = this.callVoiceBots.get(this.webrtc.getCallId());
    return liveVoiceBot?.stopLiveRecording();
  }

  public startCallTranscription(callId: string) {
    const callVoiceBot = this.callVoiceBots.get(callId);
    if (callVoiceBot) {
      return callVoiceBot?.startRecording() ?? false;
    }
    return false;
  }

  public stopCallTranscription(callId: string) {
    const callVoiceBot = this.callVoiceBots.get(callId);
    if (callVoiceBot) {
      callVoiceBot?.stopRecording();
    }
  }

  public createCallVoiceBot(
    microphoneAudioStream: any,
    IncomingAudioStream: any,
    callId: string,
    remote?: string
  ) {
    let callVoiceBot = this.callVoiceBots.get(callId);
    if (callVoiceBot) {
      callVoiceBot.stopRecording();
      callVoiceBot = undefined;
    }

    callVoiceBot = new VoiceBot({
      incomingAudioStream: IncomingAudioStream,
      microphoneAudioStream: microphoneAudioStream,
      type: "call",
      callId,
      remote,
    });

    callVoiceBot.setOnVoiceBotStateChange(
      this.onVoiceBotStateChange.bind(this)
    );

    this.callVoiceBots.set(callId, callVoiceBot);
  }

  public setTranscriptCallback(transcriptCallback: any): any {
    this.onTranscriptReceived = transcriptCallback;
    const liveVoiceBot = this.callVoiceBots.get(this.webrtc.getCallId());
    if (liveVoiceBot) {
      liveVoiceBot.setTranscriptCallback(this.onTranscriptReceived);
    }
  }

  public callEnded(callId: string) {
    this.prepareAndUploadToNms(
      callId,
      VOICE_BOT_STATE.recordingCallTranscriptDone
    );
  }

  private onVoiceBotStateChange(
    callId: string,
    voiceBotState: VOICE_BOT_STATE
  ) {
    console.log(
      "Recorder: voiceBotState ",
      voiceBotState,
      ", callState=",
      this.webrtc.getCallState()
    );
    if (
      (this.webrtc.getCallState() === CALL_STATE.NoCall &&
        callId === this.webrtc.getCallId()) ||
      this.webrtc.getCallId() != callId
    ) {
      this.prepareAndUploadToNms(callId, voiceBotState);
    }
  }

  private prepareAndUploadToNms(
    callId: string,
    voiceBotState: VOICE_BOT_STATE
  ) {
    console.log(`prepareAndUploadToNms: voiceBotState ${voiceBotState}`);
    const callVoiceBot = this.callVoiceBots.get(callId);
    if (
      voiceBotState === VOICE_BOT_STATE.recordingCallTranscriptDone &&
      //   callState === CALL_STATE.NoCall &&
      callVoiceBot?.isTranscriptValid()
    ) {
      this.transcriptSender.addVoiceBotCallTranscript(
        callId,
        callVoiceBot?.getFormattedTranscript()
      );
      callVoiceBot?.clearVoicebot();
    } else callVoiceBot?.requestCallSummary();
  }
}
