const OdienceLogo = (props: React.ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Odience-logo_nav"
    x={0}
    y={0}
    viewBox="0 0 30 30"
    {...props}
  >
    <style>{".st0{fill:#8c8f92}"}</style>
    <g id="Odience-logo-Black-Square">
      <path
        d="M8.914 17.819c-.4-.9-.6-1.8-.6-2.8v-.2c.1-3.6 3-6.4 6.6-6.5v-8.3c-8.3.2-14.9 6.9-14.9 15 0 8.3 6.7 14.9 15 14.9 3.6 0 6.9-1.3 9.5-3.4-5.6-.2-13.4-2.4-15.6-8.7z"
        className="st0"
      />
      <g transform="translate(25.014 13.119)">
        <defs>
          <path
            id="SVGID_1_"
            d="M5 1.9c0-8.3-6.7-15-15-15h-.2v8.3h.2a6.7 6.7 0 0 1 0 13.4c-2.7 0-5-1.6-6.1-3.9 2.2 6.2 10 8.6 15.6 8.8C2.9 10.7 5 6.6 5 1.9z"
          />
        </defs>
        <clipPath id="SVGID_00000011736169235523628770000006091070241131424178_">
          <use
            xlinkHref="#SVGID_1_"
            style={{
              overflow: "visible",
            }}
          />
        </clipPath>
        <g
          id="Group_3_00000096758098404600227110000014968331712585173948_"
          style={{
            clipPath:
              "url(#SVGID_00000011736169235523628770000006091070241131424178_)",
          }}
        >
          <linearGradient
            id="Rectangle_2_00000173856518510941823490000011241041799955519882_"
            x1={263.887}
            x2={263.851}
            y1={-57.208}
            y2={-57.306}
            gradientTransform="matrix(118.743 0 0 -126.503 -31339.393 -7239.378)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0}
              style={{
                stopColor: "#8c8f92",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#8c8f92",
                stopOpacity: 0,
              }}
            />
          </linearGradient>
          <path
            id="Rectangle_2_00000162336597579018762060000007826988277402493088_"
            d="M-23.5-19.1h36v38.4h-36z"
            style={{
              fill: "url(#Rectangle_2_00000173856518510941823490000011241041799955519882_)",
            }}
          />
        </g>
      </g>
      <path
        d="m15.814 7.319-.6 1h-.5l-.6-1v-6.3l.7-1h.2l.8 1z"
        className="st0"
      />
    </g>
  </svg>
);
export default OdienceLogo;
