import {
  SketchBackgroundType,
  SketchDrawingType,
  SketchIdType,
  SketchImageType,
  SketchUndoType,
} from "@/utils/helpers/notificationChannel";
import { fabric } from "fabric";
import { SketchOptionsType } from ".";
import "../../libs/fabric/5.3.0/EraserBrush";

import { useEffect, useImperativeHandle, useLayoutEffect, useRef } from "react";
import { SketchRefType } from ".";

const CANVAS_IMAGE_DEFAULT_CONTENT_TYPE = "image/png";

type SketchProps = {
  sketchDrawing?: SketchDrawingType;
  sketchUndo?: SketchUndoType;
  sketchBackground?: SketchBackgroundType;
  sketchImage?: SketchImageType;
  onDraw: (sketch: {
    drawing: Omit<SketchDrawingType["drawing"], keyof SketchIdType>;
  }) => void;
  onBackgroundColorChange: (color: string) => void;
  options: SketchOptionsType;
  canDraw: boolean;
  onSketchChange: VoidFunction;
};

type SketchHistoryType =
  | {
      type: "backgroundColor";
      value: string;
      incoming: boolean;
    }
  | {
      type: "draw" | "backgroundImage" | "erase";
      value: fabric.Object;
      incoming: boolean;
    };

const addIncomingInfo = (object: fabric.Object, incoming: boolean) => {
  Object.assign(object, { incoming });
};

const Sketch = ({
  ref,
  sketchDrawing,
  sketchUndo,
  sketchBackground,
  sketchImage,
  onDraw,
  onBackgroundColorChange,
  options,
  canDraw,
  onSketchChange,
}: SketchProps & {
  ref?: React.RefObject<SketchRefType | null>;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fabricRef = useRef<fabric.Canvas | null>(null);
  const sketchBrushColorRef = useRef(options.defaultBrushColor);
  const sketchEraserSelectedRef = useRef(false);
  const historyRef = useRef<SketchHistoryType[]>([]);
  const isUndoEraseInProgress = useRef(false);
  const isIncomingErasing = useRef<undefined | boolean>(undefined);
  useImperativeHandle(ref, () => ({
    setBrush: () => {
      if (!fabricRef.current) {
        return;
      }
      sketchEraserSelectedRef.current = false;
      const currentWidth = fabricRef.current.freeDrawingBrush.width;
      fabricRef.current.freeDrawingBrush = new fabric.PencilBrush(
        fabricRef.current
      );
      fabricRef.current.freeDrawingBrush.color = sketchBrushColorRef.current;
      fabricRef.current.freeDrawingBrush.width = currentWidth;
    },
    setBrushSize: (size: number) => {
      if (!fabricRef.current || !fabricRef.current.freeDrawingBrush) {
        return;
      }

      fabricRef.current.freeDrawingBrush.width = size;
    },
    setBrushColor: (color: string) => {
      if (!fabricRef.current || !fabricRef.current.freeDrawingBrush) {
        return;
      }

      sketchBrushColorRef.current = color;
      fabricRef.current.freeDrawingBrush.color = sketchBrushColorRef.current;
    },
    setEraser: () => {
      if (!fabricRef.current) {
        return;
      }
      sketchEraserSelectedRef.current = true;
      const currentWidth = fabricRef.current.freeDrawingBrush.width;
      // @ts-expect-error
      fabricRef.current.freeDrawingBrush = new fabric.EraserBrush(
        fabricRef.current
      );
      fabricRef.current.freeDrawingBrush.width = currentWidth;
    },
    setBackgroundColor: (sketchBackgroundColor: SketchBackgroundType) => {
      setBackground(sketchBackgroundColor, false);
    },
    setBackgroundImage: async (
      sketchImage: SketchImageType,
      onBackgroundImageChange
    ) => {
      setImage(sketchImage, false, onBackgroundImageChange);
    },
    undo: (undo: SketchUndoType) => {
      undoLast(undo, false);
    },
    toBase64: (format: string) => {
      if (!fabricRef.current) {
        return;
      }

      return fabricRef.current.toDataURL({
        format,
        quality: 1,
        multiplier: 1,
      });
    },
  }));

  useLayoutEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const getCanvasSquaredDimension = () => {
      return window.innerHeight * 0.7;
    };

    const handleResize = () => {
      if (!fabricRef.current) {
        return;
      }

      const dimension = getCanvasSquaredDimension();
      fabricRef.current.setDimensions({
        width: dimension,
        height: dimension,
      });

      fabricRef.current.calcOffset();
    };

    const dimension = getCanvasSquaredDimension();

    const sketchOptions = {
      width: dimension,
      height: dimension,
      backgroundColor: options.defaultBackgroundColor,
    };

    fabricRef.current = new fabric.Canvas(canvasRef.current, sketchOptions);
    fabricRef.current.isDrawingMode = true;
    fabricRef.current.freeDrawingBrush = new fabric.PencilBrush(
      fabricRef.current
    );
    fabricRef.current.freeDrawingBrush.color = sketchBrushColorRef.current;
    fabricRef.current.freeDrawingBrush.width = options.defaultBrushSize;
    fabricRef.current.renderAll();

    fabricRef.current.on("path:created", onPathCreated);
    fabricRef.current.on("object:added", onObjectAdded);
    fabricRef.current.on("erasing:end", onErase);

    if (fabricRef.current.backgroundColor) {
      onBackgroundColorChange(fabricRef.current.backgroundColor.toString());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      if (fabricRef.current) {
        fabricRef.current.off("path:created", onPathCreated);
        fabricRef.current.off("object:added", onObjectAdded);
        fabricRef.current.off("erasing:end", onErase);
        fabricRef.current.dispose();
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!canDraw) {
      return;
    }

    drawOrEraseIncoming(sketchDrawing);
  }, [sketchDrawing, canDraw]);

  useEffect(() => {
    if (!canDraw) {
      return;
    }

    undoLast(sketchUndo, true);
  }, [sketchUndo, canDraw]);

  useEffect(() => {
    if (!canDraw) {
      return;
    }

    setBackground(sketchBackground, true);
  }, [sketchBackground, canDraw]);

  useEffect(() => {
    if (!canDraw) {
      return;
    }

    setImage(sketchImage, true);
  }, [sketchImage, canDraw]);

  /**
   * This will be triggered when:
   * - local draw line
   * - remote draw line
   */
  const onObjectAdded = (e) => {
    saveToHistory({
      type: "draw",
      target: { incoming: e.target.incoming, value: e.target },
    });
  };

  /**
   * This will triggered when:
   * - local erase
   * - local undo erase
   * - remote erase
   * - remote undo erase
   */
  const onErase = (e) => {
    // When this erase is from an undo, we dont want to save it again in the history
    if (isUndoEraseInProgress.current) {
      return;
    }

    saveToHistory({
      type: "erase",
      target: { incoming: !!isIncomingErasing.current, value: e.path },
    });
  };

  /**
   * This will triggered when:
   * - local draw line
   * - local erase (after onErase)
   * - local undo erase (after onErase)
   * - remote erase (after onErase)
   * - remote undo erase (after onErase)
   */
  const onPathCreated = async (event) => {
    if (!fabricRef.current) {
      return;
    }

    // For erase undo we dont want to dispatch any drawing, switch back the flag in the end
    if (isUndoEraseInProgress.current) {
      isUndoEraseInProgress.current = false;
      return;
    }

    // Similarily for incoming erase we dont want to dispatch back any drawing
    if (isIncomingErasing.current) {
      isIncomingErasing.current = undefined;
      return;
    }

    const path = event.path;

    const canvasWidth = fabricRef.current.getWidth();
    const canvasHeight = fabricRef.current.getHeight();

    const points = path.path.map((path: [string, ...number[]]) => {
      const points = path.slice(1) as number[];

      // Format is [0] = x, [1] = y and for curving [2] = x and [3] = y
      for (let i = 0; i < points.length; i++) {
        points[i] /= i % 2 === 0 ? canvasWidth : canvasHeight;
      }

      return points;
    });

    const sketchDrawing = {
      drawing: {
        width: String(fabricRef.current.freeDrawingBrush.width),
        // Add a random color in case of erase otherwise mobile wont parse it, in the end it will just use the erase flag below
        color: sketchEraserSelectedRef.current ? "#ffffff" : path.stroke,
        points: points.flat(),
        ...(sketchEraserSelectedRef.current ? { erase: "1" } : {}),
      },
    };

    onDraw(sketchDrawing);
  };

  const saveToHistory = (event) => {
    if (!fabricRef.current) {
      return;
    }

    historyRef.current.push({
      type: event.type,
      value: event.target.value,
      incoming: !!event.target.incoming,
    });

    onSketchChange();
  };

  const setImage = async (
    sketchImage?: SketchImageType,
    incoming?: boolean,
    onBackgroundImageChange?: (base64: string) => void
  ) => {
    const fabricInstance = fabricRef.current;
    if (!sketchImage || !fabricInstance) {
      return;
    }

    fabric.Image.fromURL(
      sketchImage.image.base64.startsWith("data")
        ? sketchImage.image.base64
        : `data:${CANVAS_IMAGE_DEFAULT_CONTENT_TYPE};base64,${sketchImage.image.base64}`,
      function (image) {
        const canvasWidth = fabricInstance.getWidth();
        const canvasHeight = fabricInstance.getHeight();
        const imgWidth = image.width!;
        const imgHeight = image.height!;

        // Calculate aspect ratios
        const widthScale = canvasWidth / imgWidth;
        const heightScale = canvasHeight / imgHeight;

        const scaleFactor = Math.min(widthScale, heightScale);

        image.scale(scaleFactor);

        // Center the image
        image.set({
          left: (canvasWidth - imgWidth * scaleFactor) / 2,
          top: (canvasHeight - imgHeight * scaleFactor) / 2,
          // @ts-expect-error
          erasable: false,
        });

        addIncomingInfo(image, !!incoming);

        fabricInstance.backgroundImage = image;
        fabricInstance.renderAll();

        // Manually save to history as fabric does not do it for background image change
        saveToHistory({
          type: "backgroundImage",
          target: { incoming, value: image },
        });
        if (onBackgroundImageChange)
          onBackgroundImageChange(
            // Use low compression, network does not support big files
            image.toDataURL({
              format: "jpeg",
              quality: 0.3,
            })
          );
      }
    );
  };

  const setBackground = (
    sketchBackground?: SketchBackgroundType,
    incoming?: boolean
  ) => {
    if (!sketchBackground || !fabricRef.current) {
      return;
    }
    fabricRef.current.backgroundColor = sketchBackground.background_color.color;

    onBackgroundColorChange(fabricRef.current.backgroundColor);
    fabricRef.current.renderAll();

    // Manually save to history as fabric does not do it for background color change
    saveToHistory({
      type: "backgroundColor",
      target: { incoming, value: fabricRef.current.backgroundColor },
    });
  };

  const undoLast = async (sketchUndo?: SketchUndoType, incoming?: boolean) => {
    if (!sketchUndo || !fabricRef.current) {
      return;
    }

    const idx = historyRef.current.findLastIndex((v) => v.incoming == incoming);
    if (idx == -1) return;
    const previous = historyRef.current[idx];
    historyRef.current.splice(idx, 1);

    switch (previous.type) {
      case "draw":
        fabricRef.current.remove(previous.value);
        break;
      case "erase":
        isUndoEraseInProgress.current = true;
        erase(
          !!incoming,
          true,
          // TODO - looks like undoing an erase leaves a small line not erased, add a pixel to the width
          previous.value.strokeWidth! + 1,
          // @ts-expect-error
          previous.value.path
        );
        break;
      case "backgroundColor": {
        const c = historyRef.current.findLast(
          (v) => v.type == "backgroundColor"
        );
        fabricRef.current.backgroundColor =
          c?.value || options.defaultBackgroundColor;
        onBackgroundColorChange(fabricRef.current.backgroundColor);
        fabricRef.current.renderAll();
        break;
      }
      case "backgroundImage": {
        const i = historyRef.current.findLast(
          (v) => v.type == "backgroundImage"
        );
        // @ts-expect-error
        fabricRef.current.backgroundImage = i?.value || undefined;
        fabricRef.current.renderAll();
        break;
      }
    }
  };

  const drawOrEraseIncoming = (sketchDrawing?: SketchDrawingType) => {
    if (!sketchDrawing || !fabricRef.current) {
      return;
    }

    const canvasWidth = fabricRef.current.getWidth();
    const canvasHeight = fabricRef.current.getHeight();

    const points = sketchDrawing.drawing.points;

    let pathString =
      "M " + points[0] * canvasWidth + " " + points[1] * canvasHeight;

    for (let i = 2; i < points.length; i += 2) {
      const x = points[i] * canvasWidth;
      const y = points[i + 1] * canvasHeight;
      pathString += `L ${x} ${y}`;
    }

    const width = parseInt(sketchDrawing.drawing.width);
    const path = new fabric.Path(pathString, {
      stroke: sketchDrawing.drawing.color,
      strokeWidth: width,
      fill: "",
      selectable: false,
      strokeLineJoin: "round",
      strokeLineCap: "round",
    });

    addIncomingInfo(path, true);

    if (sketchDrawing.drawing.erase) {
      // @ts-expect-error
      erase(true, false, width, path.path);
    } else {
      fabricRef.current.add(path);
    }
  };

  /**
   * Erase manually using the EraserBrush for incoming erase and undos, using directly the brush events
   */
  const erase = (incoming: boolean, undo: boolean, width: number, path: []) => {
    isIncomingErasing.current = incoming;
    // @ts-expect-error
    const eraser = new fabric.EraserBrush(fabricRef.current);
    eraser.width = width;
    eraser.inverted = undo ? true : false;

    const points: { x: number; y: number }[] = [];
    path.forEach((path: [string, ...number[]]) => {
      const current = path.slice(1) as number[];

      points.push({ x: current[0], y: current[1] });

      if (current.length === 4) {
        points.push({ x: current[2], y: current[4] });
      }
    });

    for (const [index, point] of points.entries()) {
      if (index === 0) {
        eraser.onMouseDown(point, { e: new MouseEvent("mousedown") });
      } else {
        eraser.onMouseMove(point, { e: new MouseEvent("mousemove") });
      }
    }

    eraser.onMouseUp({ e: new MouseEvent("mouseup") });
  };

  // The div wrapper is needed here because the DOM is dynamically changed by fabric and the parent component needs to keep same number of child in case of any parent number of elements update - typically when going from ongoing to ended - (this component will always return one element, the div)
  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default Sketch;
