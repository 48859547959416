import CalendarIcon from "@/assets/calendar.svg";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { domainUrl } from "@/utils";
import { isMobile } from "@/utils/helpers/Utils";
import { useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import AddToCalendarButtonTooltip, {
  CalendarEvent,
  formatEventDate,
  generateIcsCalendarFile,
} from "../helpers/EventDetailsUtils";

const AddToCalendar = ({ objEvent }) => {
  const [showAddToCalendar, setShowAddToCalendar] = useState(false);
  const location = useLocation();
  const { eventId } = useParams();
  const currentPath = location.pathname;
  const previewOdiencePath = paths.previewOdience;

  const eventName = objEvent?.name;
  const organizationName = objEvent?.organization;
  const eventLink = `https://director.${domainUrl}/o/${eventId}`;
  const eventDate = formatEventDate(objEvent?.date);
  const message = `Please Join ${eventName}'s event on ${eventDate} hosted by ${organizationName}. \nVirtualized: ${eventLink}`;

  const AddToCalendarRef = useRef(null);

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(AddToCalendarRef, () => {
    setShowAddToCalendar(false);
  });

  const handleCalendarClick = () => {
    if (isMobile()) {
      const icsUrl = generateIcsCalendarFile(OdienceEvent);
      const link = document.createElement("a");
      link.href = icsUrl;
      link.download = `${OdienceEvent.title}.ics`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setShowAddToCalendar(!showAddToCalendar);
    }
  };

  const OdienceEvent: CalendarEvent = {
    title: eventName,
    description: message,
    startDate: new Date(objEvent?.date * 1000),
    durationInSeconds: objEvent.duration,
    location: objEvent.location,
  };

  const calendarOptions = (
    <div
      ref={AddToCalendarRef}
      css={{
        borderRadius: "1rem",
        height: "5vh",
        justifyContent: "center",
        display: "flex",
        gap: "2rem",
        alignitems: "center",
        zIndex: 777,
        position: "relative",
        top: "15vh",

        visibility: showAddToCalendar ? "visible" : "hidden",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        flexDirection: isMobile() ? "column" : "row",
        bottom:
          currentPath.includes(previewOdiencePath) && isMobile()
            ? "45vh"
            : undefined,
      }}
    >
      <AddToCalendarButtonTooltip calendarEvent={OdienceEvent} />
    </div>
  );

  return (
    <div>
      <button
        onClick={() => handleCalendarClick()}
        css={{
          display: "flex",
          alignItems: "center",
          position: "fixed",
          gap: "0.5em",
          backgroundColor: colors.primaryBackground,
          color: colors.primaryTextColor,
          padding: "1em",
          borderRadius: "80px",
          border: "2px solid rgba(255, 255, 255, 0.15)",
          zIndex: 999,
        }}
      >
        <img
          src={CalendarIcon}
          alt="Add To Calendar"
          css={{ height: "1.6em", width: "1.6em" }}
        />
      </button>
      {calendarOptions}
    </div>
  );
};
export default AddToCalendar;
