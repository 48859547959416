import { css } from "@emotion/react";
import { colors } from "../../styles/global.styles";

export const name = css({
  width: "100%",
  color: colors.primaryTextColor,
  whiteSpace: "nowrap",
  fontWeight: "400",
  fontSize: "1.2em",
});

export const logo = css({
  minWidth: "2em",
  maxWidth: "2em",
  minHeight: "2em",
  maxHeight: "2em",
  justifyContent: "right",
  display: "flex",
  color: colors.primaryAccentColor,
});
