import { ease } from "@/utils/ease";
import mergeRefs2 from "@/utils/helpers/mergeRefs2";
import { css } from "@emotion/react";
import { AnimatePresence, MotionConfig, animate, motion } from "motion/react";
import React, { useLayoutEffect, useRef } from "react";

type SlideOnTopProps = {
  open: boolean;
  /** how many of this component are stacked on each other (spaces them out) */
  layer: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  lazyRenderChildren?: boolean;
  onAnimationComplete?: () => void;
  children: React.ReactNode;
};

export default function SlideOnTop({
  ref,
  open,
  layer,
  onClick,
  children,
  onAnimationComplete,
}: SlideOnTopProps & {
  ref?: React.RefObject<HTMLDivElement | null>;
}) {
  layer = Math.max(Math.min(layer, 6), 1);

  const padding = `${layer}em`;
  const paddingPixels = layer * 16;

  const contentRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  useLayoutEffect(() => {
    if (!contentRef.current) return;

    animate(
      contentRef.current,
      {
        x: open
          ? padding
          : `${contentRef.current.offsetWidth + paddingPixels + boxShadowWidth}px`,
      },
      {
        duration: initialRender.current ? 0 : open ? 0.4 : 0.25,
        ease: ease,
        onComplete: () => {
          queueMicrotask(() => {
            initialRender.current = false;
            if (!open && contentRef.current) {
              contentRef.current.style.transform = `translateX(calc(100% + ${padding} + ${boxShadowWidth}px))`;
            }
          });
          onAnimationComplete?.();
        },
      }
    );
  }, [open]);

  return (
    <AnimatePresence initial={false}>
      <MotionConfig transition={{ duration: open ? 0.4 : 0.25, ease: ease }}>
        <motion.div
          css={backgroundCss}
          animate={{ opacity: open ? 1 : 0 }}
        ></motion.div>
        <div
          ref={mergeRefs2(() => [ref, contentRef])}
          onClick={(e) => {
            if (onClick && e.currentTarget.contains(e.target as Node)) {
              onClick(e);
            }
          }}
          css={contentCss}
          style={{
            width: `calc(100% - ${padding})`,
          }}
        >
          {children}
        </div>
      </MotionConfig>
    </AnimatePresence>
  );
}

const backgroundCss = css({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  zIndex: "2",
  borderRadius: "20px",
  background: "rgb(0,0,0,0.25)",
  pointerEvents: "none",
});

const boxShadowWidth = 8;

const contentCss = css({
  position: "absolute",
  height: "100%",
  top: "0",
  left: "0",
  zIndex: "2",
  "& > *": {
    boxShadow: `0 0 ${boxShadowWidth}px 0px rgb(0,0,0,0.5)`,
  },
});
