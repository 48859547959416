import bezier from "bezier-easing";
import {
  DependencyList,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useDraggable } from "react-use-draggable-scroll";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { transformScrollHorizontal } from "..";

export function useScrollIntoView(
  {
    getElem,
    scrollOptions,
    instant,
  }: {
    getElem: () => HTMLElement | null;
    instant?: boolean;
    scrollOptions?: Parameters<typeof scrollIntoView>[1];
  },
  deps?: DependencyList
) {
  const firstRunRef = useRef(true);
  useLayoutEffect(() => {
    const elem = getElem();
    if (!elem) return;

    // same as elemRef.current.scrollIntoView but with custom ease
    scrollIntoView(elem, {
      block: "center",
      inline: "center",
      duration: 350,
      ease: bezier(0.25, 0.1, 0.25, 1),
      ...scrollOptions,
      ...((instant || firstRunRef.current) && {
        duration: 0,
      }),
    } as Parameters<typeof scrollIntoView>[1]);
    firstRunRef.current = false;

    const scrollOnResize = () => {
      elem.scrollIntoView({
        block: "center",
        inline: "center",
      });
    };
    window.addEventListener("resize", scrollOnResize);
    return () => {
      window.removeEventListener("resize", scrollOnResize);
    };
  }, [getElem, ...(deps || [])]);
}

const scrollThreshold = 80; // px

export function useDraggableAndScrollableWithFocusedElements(
  draggableRef: RefObject<HTMLElement>,
  moveFocused: (n: 1 | -1 | 0) => void
) {
  useDraggableWithFocusedElements(draggableRef, moveFocused);
  useScrollableWithFocusedElements(draggableRef, moveFocused);
}

function useDraggableWithFocusedElements(
  draggableRef: RefObject<HTMLElement>,
  moveFocused: (n: 1 | -1 | 0) => void
) {
  const {
    events: { onMouseDown: onMouseDown1 },
  } = useDraggable(draggableRef, {
    applyRubberBandEffect: false,
    decayRate: 0,
  });

  const mouseDownX = useRef<number | undefined | null>(undefined);

  useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      // eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
      document.addEventListener(
        "mouseup",
        // eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
        (e) => {
          if (!mouseDownX.current) return;

          const diff = mouseDownX.current - e.clientX;
          mouseDownX.current = null;

          // can only move by one at a time.
          // to move multiple (moveFocused(2)), the width of a focused element needs to be added as a parameter
          if (diff > scrollThreshold) {
            moveFocused(1);
          } else if (diff < -scrollThreshold) {
            moveFocused(-1);
          } else {
            moveFocused(0);
          }
        },
        { once: true }
      );

      mouseDownX.current = e.clientX;
      onMouseDown1(e as any);
    };

    draggableRef.current?.addEventListener("mousedown", onMouseDown);

    return () => {
      draggableRef.current?.removeEventListener("mousedown", onMouseDown);
    };
  }, [draggableRef]);
}

function useScrollableWithFocusedElements(
  ref: RefObject<HTMLElement>,
  moveFocused: (n: 1 | -1 | 0) => void
) {
  const internalState = useRef({
    dx: 0,
    moveFocusedTimeout: undefined as number | undefined,
    doScroll: true,
  });

  useEffect(() => {
    if (!ref.current) return;

    const wheelHandler = (e: WheelEvent) => {
      e.preventDefault();

      const s = internalState.current;

      if (!s.doScroll) {
        return;
      }

      const elem = e.currentTarget as HTMLElement;
      elem.style.scrollBehavior = "smooth";
      // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
      setTimeout(() => {
        elem.style?.removeProperty("scroll-behavior");
      });

      s.dx += transformScrollHorizontal(e);

      const moveFocusedAnimationTime = 150;

      if (s.dx > scrollThreshold) {
        s.dx = 0;
        s.doScroll = false;
        moveFocused(1);
        clearTimeout(s.moveFocusedTimeout);
        s.moveFocusedTimeout = +setTimeout(() => {
          s.doScroll = true;
        }, moveFocusedAnimationTime);
      } else if (s.dx < -scrollThreshold) {
        s.dx = 0;
        s.doScroll = false;
        moveFocused(-1);
        clearTimeout(s.moveFocusedTimeout);
        s.moveFocusedTimeout = +setTimeout(() => {
          s.doScroll = true;
        }, moveFocusedAnimationTime);
      }

      clearTimeout(s.moveFocusedTimeout);
      s.moveFocusedTimeout = +setTimeout(() => {
        s.dx = 0;
        s.doScroll = false;
        moveFocused(0);
        clearTimeout(s.moveFocusedTimeout);
        s.moveFocusedTimeout = +setTimeout(() => {
          s.doScroll = true;
        }, moveFocusedAnimationTime);
      }, 500);
    };

    ref.current.addEventListener("wheel", wheelHandler, {
      passive: false,
    });

    return () => {
      ref.current?.removeEventListener("wheel", wheelHandler);
    };
  }, [ref.current]);
}
