import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const Container = styled.div({
  width: "90%",
  height: "85%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const Text = styled.p({
  color: colors.primaryTextColor,
});
