import WebGwContact from "@/utils/helpers/WebGwContact";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import ContactForm from "../contactListScreen/ContactForm";

export default function ContactModal({
  open,
  handleOk,
  handleCancel,
  numberToCall,
  webgwContact,
  title = "New Contact",
}: {
  open: boolean;
  handleOk: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  numberToCall?: string;
  webgwContact?: WebGwContact | null;
  title?: string;
}) {
  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <>
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button type="primary" danger onClick={handleOk}>
            Save
          </Button>
        </>
      }
      css={{ zIndex: "9999", display: "flex", justifyContent: "center" }}
    >
      <ContactForm
        webgwContact={webgwContact}
        numberInputfromKeypad={numberToCall}
      />
    </Modal>
  );
}
