import { odiencePathIndicatorKey } from "@/routerPaths";
import { useSearchParams } from "react-router-dom";
import VerseLogo from "../../assets/provisioning/Verse_Logo.svg";
import { OdienceLogo, verseLogo } from "./index.style";

const OptionalProvisionLogo = () => {
  const [params] = useSearchParams();
  const fromOdiencePreview = params.has(odiencePathIndicatorKey);

  return fromOdiencePreview ? (
    <div css={{ display: "flex", gap: "2vw", textAlign: "center" }}>
      <img
        src="/odience/odience_logo_red.png"
        css={{ height: "55px", scale: "1.2" }}
        alt="Odience Logo"
      />

      <div css={OdienceLogo}>ODIENCE</div>
    </div>
  ) : (
    <img id="verseLogo" css={verseLogo} src={VerseLogo} alt="Verse" />
  );
};

export default OptionalProvisionLogo;
