import { preloadImage } from "@/utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import styled from "@emotion/styled";
import { CSSProperties, useEffect, useReducer } from "react";
import { colors } from "../../styles/global.styles";

export const AvatarImg = styled.img({
  borderRadius: "100%",
  objectFit: "cover",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
});

export const avatarSize = "2em";
export const AvatarBackground = styled.div({
  backgroundColor: colors.tertiaryBackground,
  borderRadius: "50%",
  color: colors.primaryAccentColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  fontSize: "1.25em",
  textTransform: "uppercase",
  overflow: "hidden",
  minWidth: avatarSize,
  minHeight: avatarSize,
  width: avatarSize,
  height: avatarSize,
});

// @ts-expect-error
AvatarBackground.defaultProps ??= {};
// @ts-expect-error
AvatarBackground.defaultProps.onMouseDown = (e) => {
  e.preventDefault();
};

export function DefaultAvatar(props: React.ComponentProps<"svg">) {
  const svgProps = { ...props, style: undefined };

  return (
    <AvatarBackground
      css={{ padding: "0", width: "unset", height: "unset" }}
      className={props.className}
      style={props.style}
    >
      <svg viewBox="0 0 35 35" {...svgProps}>
        <path
          fill="currentcolor"
          d="M17.5 7a6.252 6.252 0 0 1 5.3 3.06 5.82 5.82 0 0 1 .824 3.065A6.125 6.125 0 1 1 17.5 7Zm0 24.5a14.014 14.014 0 0 1-10.745-5.04 17.415 17.415 0 0 1 21.49 0A14.014 14.014 0 0 1 17.5 31.5Z"
        />
      </svg>
    </AvatarBackground>
  );
}

function DefaultChatbotAvatar(props: React.ComponentProps<"svg">) {
  const svgProps = { ...props, style: undefined };

  return (
    <AvatarBackground
      css={{ padding: "0", width: "unset", height: "unset" }}
      className={props.className}
      style={props.style}
    >
      <svg viewBox="0 0 35 35" {...svgProps}>
        <path
          id="Path_278"
          d="M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5C35,7.8,27.2,0,17.5,0z"
        />
        <g id="Chatbot_Default" transform="translate(6 5)">
          <path
            id="Path_518"
            fill="currentcolor"
            d="M11.4,6.2c-6.2,0-11.3,5-11.4,11.2c0,0-0.4,3.5,2.9,3.5h17.2c0,0,2.8,0.3,2.8-3.5
		C22.8,11.2,17.7,6.1,11.4,6.2 M16.2,19.8H7.1c-1.7,0.2-3.3-1-3.5-2.8c0-0.3,0-0.6,0-0.9c0.3-6.8,7.9-6.6,7.9-6.6
		c7.9,0,7.9,6.5,7.9,6.5C19.3,19.8,16.2,19.8,16.2,19.8"
          />
          <path
            id="Path_519"
            fill="currentcolor"
            d="M7.9,15.8c0,0.8-0.7,1.5-1.5,1.5c0,0-0.1,0-0.1,0c-0.9,0-1.6-0.7-1.6-1.5 c0.1-0.9,0.8-1.5,1.7-1.5C7.2,14.3,7.9,14.9,7.9,15.8C7.9,15.8,7.9,15.8,7.9,15.8"
          />
          <path
            id="Path_520"
            fill="currentcolor"
            d="M18.1,15.8c0,0.8-0.7,1.5-1.5,1.5c0,0-0.1,0-0.1,0c-0.9,0-1.6-0.7-1.6-1.5 c0-0.8,0.7-1.5,1.5-1.5c0,0,0.1,0,0.1,0C17.3,14.2,18,14.9,18.1,15.8C18.1,15.7,18.1,15.8,18.1,15.8"
          />
          <path
            id="Path_521"
            fill="currentcolor"
            d="M13.3,1.9c0,1-0.9,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9S10.4,0,11.4,0C12.5,0,13.3,0.9,13.3,1.9"
          />
          <rect
            id="Rectangle_2384"
            x="10.7"
            y="3"
            fill="currentcolor"
            width="1.5"
            height="2.3"
          />
        </g>
      </svg>
    </AvatarBackground>
  );
}

function DefaultFrontRowAvatar(props: React.ComponentProps<"svg">) {
  const svgProps = { ...props, style: undefined };

  return (
    <AvatarBackground
      css={{ padding: "0", width: "unset", height: "unset" }}
      className={props.className}
      style={props.style}
    >
      <svg viewBox="-10 -10 50 50" {...svgProps}>
        <g
          id="Front_Row_Icon"
          data-name="Front Row Icon"
          transform="translate(-10 -10)"
        >
          <g id="Front_Row" data-name="Front Row" transform="translate(10 10)">
            <g
              id="front_row-2"
              data-name="front row"
              transform="translate(0 3)"
            >
              <path
                id="Union_1"
                data-name="Union 1"
                d="M27.644,23.854H14.718V22.307c0-1.149,1.317-1.894,2.421-2.318a12.479,12.479,0,0,1,4.039-.773,12.479,12.479,0,0,1,4.042.774c1.1.424,2.421,1.169,2.421,2.318v1.546ZM21.178,17.67a3.167,3.167,0,0,1-3.231-3.092,3.235,3.235,0,0,1,6.463,0A3.167,3.167,0,0,1,21.178,17.67Z"
                transform="translate(-6.131 -5.124)"
                fill="#dc3851"
              />
              <path
                id="Rectangle_2840"
                data-name="Rectangle 2840"
                d="M2-2.5H23A4.505,4.505,0,0,1,27.5,2V16.4A4.505,4.505,0,0,1,23,20.9H2a4.505,4.505,0,0,1-4.5-4.5V2A4.505,4.505,0,0,1,2-2.5ZM23,17.9a1.5,1.5,0,0,0,1.5-1.5V2A1.5,1.5,0,0,0,23,.5H2A1.5,1.5,0,0,0,.5,2V16.4A1.5,1.5,0,0,0,2,17.9Z"
                transform="translate(2.5 2.5)"
                fill="#dc3851"
              />
            </g>
            <rect
              id="Rectangle_3458"
              data-name="Rectangle 3458"
              width="30"
              height="30"
              fill="none"
            />
          </g>
        </g>
      </svg>
    </AvatarBackground>
  );
}

export function DefaultGroupChatAvatar(props: React.ComponentProps<"svg">) {
  const svgProps = { ...props, style: undefined };

  return (
    <AvatarBackground
      css={{ padding: "0", width: "unset", height: "unset" }}
      className={props.className}
      style={props.style}
    >
      <svg viewBox="0 0 150 150" {...svgProps}>
        <path
          fill="#000E1A"
          d="M75,0C33.6,0,0,33.6,0,75s33.6,75,75,75s75-33.6,75-75l0,0C150,33.6,116.4,0,75,0z"
        />
        <path
          fill="#DC3851"
          d="M102.1,47.5c8,0,14.5,6.5,14.5,14.5s-6.5,14.5-14.5,14.5S87.6,70,87.6,62
		C87.6,54.1,94,47.6,102.1,47.5C102,47.5,102,47.5,102.1,47.5z M57.1,35.7c9.8,0,17.7,7.9,17.7,17.7s-7.9,17.7-17.7,17.7
		s-17.7-7.9-17.7-17.7C39.4,43.6,47.3,35.7,57.1,35.7z M57.1,104.2v28.1c-18-5.7-32.3-19.4-38.6-37.2c7.9-8.4,27.5-12.7,38.6-12.7
		c4.8,0.1,9.6,0.7,14.3,1.7C59,90.6,57.1,99.2,57.1,104.2L57.1,104.2z M75,135c-2,0-4-0.1-5.9-0.3v-30.5c0-10.7,22.1-16,33-16
		c8,0,21.9,2.9,28.8,8.6C121.9,119.8,99.7,135,75,135L75,135z"
        />
      </svg>
    </AvatarBackground>
  );
}

export const AvatarWithFallback = ({
  fallback = <DefaultAvatar />,
  fallbackWhileLoading = false,
  ...props
}: {
  fallback?: React.ReactElement;
  fallbackWhileLoading?: boolean;
} & React.ComponentProps<"img">) => {
  const [loadErr, setLoadErr] = useReducer(() => true, false);
  const [loaded, setLoaded] = useReducer(() => true, false);

  useEffect(() => {
    preloadImage(props.src!).then(setLoaded);
  }, []);

  if ((fallbackWhileLoading && !loaded) || loadErr) {
    return fallback;
  }

  return (
    <AvatarBackground>
      <AvatarImg
        {...props}
        onError={(e) => {
          setLoadErr();
          props.onError?.(e);
        }}
      />
    </AvatarBackground>
  );
};

function AvatarBackgroundWithFallback({
  photo,
  fallback,
  style,
}: {
  photo: string;
  fallback?: React.ReactElement;
  style: CSSProperties | undefined;
}) {
  return (
    <AvatarBackground css={style as any}>
      <AvatarWithFallback src={photo} fallback={fallback} style={style} />
    </AvatarBackground>
  );
}

function AvatarGroupChat({
  photo,
  style,
}: {
  photo: string | undefined;
  style: CSSProperties | undefined;
}) {
  return photo ? (
    <AvatarBackgroundWithFallback photo={photo} style={style} />
  ) : (
    <DefaultGroupChatAvatar css={style as any} />
  );
}

function AvatarContact({
  contact,
  style,
}: {
  contact: WebGwContact;
  style: CSSProperties | undefined;
}) {
  // No photo for front row, return directly the default avatar
  if (contact.isOdienceFrontRow()) {
    return <DefaultFrontRowAvatar style={style} />;
  }

  const fallback = contact.isChatbot ? (
    <DefaultChatbotAvatar style={style} />
  ) : (
    <DefaultAvatar style={style} />
  );

  if (contact.photo) {
    return (
      <AvatarBackgroundWithFallback
        photo={contact.photo}
        fallback={fallback}
        style={style}
      />
    );
  } else if (contact.initials) {
    return (
      <AvatarBackground style={style}>{contact.initials}</AvatarBackground>
    );
  }

  return fallback;
}

function AvatarPhoto({
  photo,
  style,
}: {
  photo: string | undefined;
  style: CSSProperties | undefined;
}) {
  return photo ? (
    <AvatarBackgroundWithFallback
      photo={photo}
      fallback={<DefaultAvatar css={style as any} />}
      style={style}
    />
  ) : (
    <DefaultAvatar css={style as any} />
  );
}

export function Avatar({
  contact,
  isGroupChat,
  photo,
  style,
}: {
  contact?: WebGwContact;
  isGroupChat?: boolean;
  photo?: string;
  style?: CSSProperties;
}) {
  if (isGroupChat) {
    return <AvatarGroupChat photo={photo} style={style} />;
  } else if (contact) {
    return <AvatarContact contact={contact} style={style} />;
  }
  return <AvatarPhoto photo={photo} style={style} />;
}
