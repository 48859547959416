import styled from "@emotion/styled";

export const CarouselButton = styled.button<
  { left: true; right?: false | never } | { right: true; left?: false | never }
>(({ left, right }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: left ? "0" : undefined,
  right: right ? "0" : undefined,
  height: "100%",
  width: "2rem",
  opacity: "0",
  ".carouselBox:hover &": {
    opacity: "1",
  },
  ":disabled": {
    opacity: "0", // ! hover/disabled opacity ordering matters
    pointerEvents: "none",
  },
  transition: `opacity 0.4s ease`,
  "::after": {
    content: "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "0.9em",
    height: "0.9em",
    border: "0.3em solid",
    borderColor: "white transparent transparent white",
    transform: `translate(calc(-50% + ${left ? "" : "-"}30%), -50%) rotate(${
      left ? "-45" : "135"
    }deg)`,
    filter: "drop-shadow(0 0 2px rgba(255,255,255,50%))",
  },
}));
