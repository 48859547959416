import { atoms } from "@/utils/helpers/atoms";
import {
  getLocalAccessToken,
  getLocalUser,
} from "@/utils/helpers/localstorage";
import { getLastEventId, getLastGroupId } from "@/utils/helpers/sessionStorage";
import { useAtomValue } from "jotai";
import Lottie from "lottie-react";
import { Navigate, generatePath, useSearchParams } from "react-router-dom";
import loadingAnimation from "../../../assets/lottie/LoadingLine.json";
import {
  defaultPage,
  odiencePathIndicatorKey,
  paths,
} from "../../../routerPaths";
import { NotificationChannelManager } from "../../../utils/helpers/notificationChannel";
import { Body, text, title } from "../index.style";
const Step4 = () => {
  const provisionSuccess = useAtomValue(atoms.provisioning.result);

  const [params] = useSearchParams();
  const fromOdiencePreview = params.has(odiencePathIndicatorKey);
  const odienceEventId = localStorage.getItem("odienceEventIdKey");
  const privateOdienceEventFromInviteGroupId = getLastGroupId();
  const privateOdienceEventFromInviteEventId = getLastEventId();
  const uri = localStorage.getItem("uriBeforeRelogin");
  if (uri) {
    localStorage.removeItem("uriBeforeRelogin");
    const accessToken = getLocalAccessToken(),
      user = getLocalUser();
    console.log(accessToken + "=" + user);
    if (accessToken && user) {
      const notificationChannelManager = new NotificationChannelManager();
      console.log("Subscribing to WebGW");
      notificationChannelManager.subscribe(accessToken, user, "");
    }
  }
  const page = uri ?? defaultPage;

  const navigatePath =
    fromOdiencePreview &&
    privateOdienceEventFromInviteGroupId &&
    privateOdienceEventFromInviteEventId
      ? generatePath(paths.details, {
          groupId: privateOdienceEventFromInviteGroupId,
          eventId: privateOdienceEventFromInviteEventId,
        })
      : fromOdiencePreview && odienceEventId
        ? generatePath(paths.stream, {
            eventId: odienceEventId,
          })
        : fromOdiencePreview
          ? paths.odience
          : page;

  return (
    <Body>
      <div css={{ padding: "0 25px" }}>
        <div css={title}>
          <h2
            css={[
              text,
              { fontSize: "1.75em", fontWeight: "400", marginBottom: "2em" },
            ]}
          >
            Thank You For Using{" "}
            {fromOdiencePreview ? "Odience" : "Verse Messages"}
          </h2>
        </div>
        <div
          css={[
            text,
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <span>Your registration is being processed.</span>{" "}
          <Lottie
            id="loadingAnimation"
            css={{
              height: "20vh",
              position: "relative",
              bottom: "95px",
              left: "10px",
              marginBottom: "-118px",
            }}
            animationData={loadingAnimation}
            loop={true}
          />
        </div>
        {provisionSuccess && <Navigate replace to={navigatePath} />}
      </div>
    </Body>
  );
};

export default Step4;
