import animationData from "@/assets/lottie/wave.json";
import animationData2 from "@/assets/lottie/wave2.json";
import animationData3 from "@/assets/lottie/wave3.json";
import { colors } from "@/styles/global.styles";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import AnimatedTextContainer from "../shared/AnimatedTextContainer";
import "./VoiceBotOverlay.css";

type VoiceBotProps = {
  transcribedText: string;
  voiceBotActive: boolean;
  handleVoiceBotClicked: () => void;
};

const VoiceBotOverlay = ({
  transcribedText,
  voiceBotActive,
  handleVoiceBotClicked,
}: VoiceBotProps) => {
  const [dots, setDots] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const element = containerRef.current;
    if (element) {
      element.scrollTo({
        top: element.scrollHeight - element.clientHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setDots((dots) => {
        switch (dots) {
          case "":
            return ".";
          case ".":
            return "..";
          case "..":
            return "...";
          default:
            return "";
        }
      });
    }, 500);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    console.log("Recorder: scrolling to top");
    setTimeout(() => scrollToBottom(), 50);
  }, [transcribedText]);

  return (
    <div className="voice-bot-overlay">
      <button
        onClick={handleVoiceBotClicked}
        css={{
          color: colors.primaryTextColor,
          position: "relative",
          top: "1vh",
          right: "1vw",
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        close
      </button>
      <div className="voice-bot-text">
        <div className="voice-bot-text-sudb-div">
          <div className="lottie-anim">
            <Lottie
              animationData={animationData3}
              autoPlay
              loop
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              height={60}
            />
          </div>
          <div className="lottie-anim">
            <Lottie
              animationData={animationData2}
              autoPlay
              loop
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              height={60}
            />
          </div>
          <div className="lottie-anim">
            <Lottie
              animationData={animationData}
              autoPlay
              loop
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              height={60}
            />
          </div>
        </div>
        <div className="powered-by">
          powered by <span>Summit IMS RCS Cloud</span>
        </div>
        <div ref={containerRef} id="text-container" className="text-container">
          {transcribedText.length === 0 && (
            <div id="loading-div" className="loading-div-parent">
              <div className="loading-div">
                transcription service active{dots}
              </div>
            </div>
          )}
          <AnimatedTextContainer
            text={transcribedText}
            voiceBotActive={voiceBotActive}
          />
        </div>
      </div>
    </div>
  );
};

export default VoiceBotOverlay;
