import { derive } from "derive-valtio";
import { proxy } from "valtio";
import { devtools, proxyMap } from "valtio/utils";
import type Call from "./Call";

declare module "valtio" {
  function useSnapshot<T extends object>(p: T): T;
}

export type CallId = string;
export type CallMap = Map<CallId, Call>;

export const callsState = proxy({
  calls: proxyMap(new Map()) as CallMap,
  mapVersion: 0,
});

if (import.meta.env.DEV && "__REDUX_DEVTOOLS_EXTENSION__" in window) {
  const debugCallState = derive({
    ...Object.assign(
      // @ts-expect-error
      ...Object.keys(callsState).map((key) => ({
        [key]: (get) => get(callsState)[key],
      }))
    ),
    calls: (get) => Object.fromEntries(get(callsState).calls),
  });

  devtools(debugCallState);
}

export function setCalls(newCalls: typeof callsState.calls) {
  // callsState.calls = proxyMap(new Map(newCalls));
  for (const [id, c] of newCalls.entries()) {
    // ? not sure why cloning the messages is necessary to reflect an update
    callsState.calls.set(id, c);
  }
  callsState.mapVersion++;
}
