import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorIcon from "@mui/icons-material/Error";
import { motion } from "motion/react";

export default function FileDropOverlay({
  acceptedFile,
}: {
  acceptedFile: boolean;
}) {
  return (
    <>
      <motion.div
        css={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          zIndex: "2",
          backgroundColor: "rgb(0,0,0,0.3)",
          backdropFilter: "blur(2px)",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25, ease: ease }}
      ></motion.div>
      <motion.div
        css={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: "3",
          borderRadius: "20px",
          border: `2px dashed ${acceptedFile ? colors.primaryTextColor : colors.primaryAccentColor}`,
          color: acceptedFile
            ? colors.primaryTextColor
            : colors.primaryAccentColor,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2em",
          textAlign: "center",
          justifyContent: "center",
          textShadow: "0 0 2px black",
          fontSize: "1.5em",
          fontWeight: "bold",
          filter: "drop-shadow(0 0 4px rgb(0,0,0,0.75))",
          width: "100%",
          height: "100%",
        }}
        initial={{ x: "-50%", y: "-50%", scale: 1, opacity: 0 }}
        animate={{ scale: 0.975, opacity: 1 }}
        exit={{ scale: 1, opacity: 0 }}
        transition={{ duration: 0.25, ease: ease }}
      >
        {acceptedFile ? (
          <>
            <CloudUploadIcon css={{ width: "1.5em", height: "1.5em" }} />
            Drop files here
          </>
        ) : (
          <>
            <ErrorIcon css={{ width: "1.5em", height: "1.5em" }} />
            This conversation does not accept file transfers
          </>
        )}
      </motion.div>
    </>
  );
}
