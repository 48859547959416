import WebGwContact from "@/utils/helpers/WebGwContact";
import { PlusOutlined } from "@ant-design/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import { ChangeEvent, useMemo } from "react";
import { useSnapshot } from "valtio";
import { contactState } from "../../utils/contacts/contactState";
import { generateRandomString } from "../../utils/helpers/Utils";
import { DefaultAvatar } from "../shared/Avatar";
import {
  AddButton,
  DeleteButton,
  DividerLine,
  DividerSpace,
  InputField,
  InputRow,
  NameInput,
  Root,
  SectionHeader,
  SectionTitle,
  SelectOptions,
  Space,
} from "./ContactForm.style";

// Constants for dropdown labels
const phoneLabels = [
  { value: "", label: "No Label" },
  { value: "; mobile", label: "Mobile" },
  { value: "; home", label: "Home" },
  { value: "; work", label: "Work" },
];

const emailLabels = [
  { value: "", label: "No Label" },
  { value: "; home", label: "Home" },
  { value: "; work", label: "Work" },
];

const addressLabels = [
  { value: "", label: "No Label" },
  { value: "; home", label: "Home" },
  { value: "; work", label: "Work" },
];

const relatedLabels = [
  { value: "", label: "No Label" },
  { value: "; Mother", label: "Mother" },
  { value: "; Father", label: "Father" },
  { value: "; Sibling", label: "Sibling" },
  { value: "; Family", label: "Family" },
  { value: "; Friend", label: "Friend" },
  { value: "; Other", label: "Other" },
];

type Props = {
  webgwContact?: WebGwContact | null | undefined;
  numberInputfromKeypad?: string;
};

const ContactForm = ({ webgwContact, numberInputfromKeypad }: Props) => {
  const formState = contactState;
  const contact = useSnapshot(formState);
  const populateFormStateFromContact = (webgwContact: WebGwContact) => {
    const {
      id,
      company,
      name,
      note,
      url,
      userAvatar,
      birthday,
      ...contactData
    } = webgwContact;

    // Initialize base fields
    // eslint-disable-next-line react-compiler/react-compiler
    formState.id = id || "";
    formState.company = company || "";
    formState.firstName = name?.split(" ")[0] || "";
    formState.lastName = name?.split(" ")[1] || "";
    formState.notes = note || "";
    formState.website = url || "";
    formState.userAvatar = undefined;
    formState.birthday = birthday || "";

    let clearDefaultPhoneNumber = true;
    let clearDefaultEmail = true;
    let clearDefaultAddress = true;
    let clearDefaultRelated = true;

    // Iterate over remaining contact data keys
    for (const [contactKey, contactVal] of Object.entries(contactData)) {
      if (!Array.isArray(contactVal)) continue;

      if (contactKey.startsWith("phone")) {
        for (const phoneEntry of contactVal) {
          if (
            !formState.phoneNumber.some((e) => e.number === phoneEntry[0]) &&
            phoneEntry[0]
          ) {
            if (clearDefaultPhoneNumber) {
              formState.phoneNumber = [];
              clearDefaultPhoneNumber = false;
            }
            formState.phoneNumber.unshift({
              number: phoneEntry[0],
              type: contactKey.substring("phone".length),
              id: phoneEntry[1] || generateRandomString(6),
              caps: !!phoneEntry[2],
            });
          }
        }
      } else if (contactKey.startsWith("email")) {
        for (const emailEntry of contactVal) {
          if (
            !formState.email.some((e) => e.email === emailEntry[0]) &&
            emailEntry[0]
          ) {
            if (clearDefaultEmail) {
              formState.email = [];
              clearDefaultEmail = false;
            }
            formState.email.unshift({
              email: emailEntry[0],
              type: contactKey.substring("email".length),
              id: emailEntry[1] || generateRandomString(6),
            });
          }
        }
      } else if (contactKey.startsWith("address")) {
        for (const addressEntry of contactVal) {
          if (
            Array.isArray(addressEntry) &&
            addressEntry[1] &&
            !formState.address.some((e) => e.street1 === addressEntry[1])
          ) {
            if (clearDefaultAddress) {
              formState.address = [];
              clearDefaultAddress = false;
            }

            formState.address.unshift({
              type: addressEntry[0],
              street1: addressEntry[1] || "",
              street2: addressEntry[2] || "",
              city: addressEntry[3] || "",
              province: addressEntry[4] || "",
              postalCode: addressEntry[5] || "",
              country: addressEntry[6] || "",
              id: addressEntry[7] || generateRandomString(6),
            });
          }
        }
      } else if (contactKey.startsWith("person")) {
        for (const relatedEntry of contactVal) {
          if (
            !formState.related.some((e) => e.name === relatedEntry[0]) &&
            relatedEntry[0]
          ) {
            if (clearDefaultRelated) {
              formState.related = [];
              clearDefaultRelated = false;
            }
            formState.related.unshift({
              name: relatedEntry[0],
              type: contactKey.substring("person".length),
              id: relatedEntry[1] || generateRandomString(6),
            });
          }
        }
      }
    }
  };
  //TO DO CHANGE FUNCTION TO HANDLE MULTIPLE NUMBERS
  const addPhoneNumberFromKeypad = (numberInputfromKeypad: string) => {
    if (!numberInputfromKeypad) return;

    formState.phoneNumber = [
      {
        number: numberInputfromKeypad,
        type: "",
        id: generateRandomString(6),
        caps: false,
      },
    ];
  };

  useMemo(() => {
    if (webgwContact) {
      populateFormStateFromContact(webgwContact);
    }
    if (numberInputfromKeypad) {
      addPhoneNumberFromKeypad(numberInputfromKeypad);
    }
  }, [webgwContact, numberInputfromKeypad]);

  const onChangeDate: DatePickerProps["onChange"] = (
    date,
    dateString: string | string[]
  ) => {
    if (typeof dateString === "string") {
      formState.birthday = dateString;
    } else {
      console.warn("Unexpected dateString format:", dateString);
      formState.birthday = "";
    }
  };

  const handleFieldChange =
    (field: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index?: number) => {
      if (index !== undefined) {
        formState[field][index] = {
          ...formState[field][index],
          [e.target.name]: e.target.value,
        };
      } else {
        formState[field] = e.target.value;
      }
    };

  const handleAddField = (field: string, defaultValue: any) => {
    formState[field].push({ ...defaultValue, id: generateRandomString(6) });
  };

  const handleRemoveField = (field: string, index: number) => {
    formState[field].splice(index, 1);
  };

  const renderFieldList = (
    field: string,
    labels: { value: string; label: string }[],
    inputPlaceholder: string
  ) =>
    contact[field]?.map((item: any, index: number) => (
      <div
        key={item.id}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "1em",
          width: "95%",
        }}
      >
        <SelectOptions
          name="type"
          onChange={(e) => handleFieldChange(field)(e, index)}
          defaultValue={item.type}
        >
          {labels.map((label) => (
            <option key={label.value} value={label.value}>
              {label.label}
            </option>
          ))}
        </SelectOptions>
        <InputField
          placeholder={inputPlaceholder}
          name={
            field === "phoneNumber"
              ? "number"
              : field === "email"
                ? "email"
                : "name"
          }
          onChange={(e) => handleFieldChange(field)(e, index)}
          defaultValue={item.number || item.email || item.name || ""}
        />
        {contact[field].length > 1 && (
          <DeleteButton onClick={() => handleRemoveField(field, index)}>
            <DeleteIcon />
          </DeleteButton>
        )}
      </div>
    ));

  const renderAddressList = () =>
    contact.address.map((item: any, index: number) => (
      <div
        key={item.id}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <InputRow>
          <SelectOptions
            name="type"
            onChange={(e) => handleFieldChange("address")(e, index)}
            defaultValue={item.type}
          >
            {addressLabels.map((label) => (
              <option key={label.value} value={label.value}>
                {label.label}
              </option>
            ))}
          </SelectOptions>
          <InputField
            placeholder="Street"
            name="street1"
            onChange={(e) => handleFieldChange("address")(e, index)}
            defaultValue={item.street1}
          />
          {contact.address.length > 1 && (
            <DeleteButton onClick={() => handleRemoveField("address", index)}>
              <DeleteIcon />
            </DeleteButton>
          )}
        </InputRow>
        <InputRow>
          <InputField
            placeholder="City"
            name="city"
            onChange={(e) => handleFieldChange("address")(e, index)}
            defaultValue={item.city}
          />
          <InputField
            placeholder="Province"
            name="province"
            onChange={(e) => handleFieldChange("address")(e, index)}
            defaultValue={item.province}
          />
        </InputRow>
        <InputRow>
          <InputField
            placeholder="Postal Code"
            name="postalCode"
            onChange={(e) => handleFieldChange("address")(e, index)}
            defaultValue={item.postalCode}
          />
          <InputField
            placeholder="Country"
            name="country"
            onChange={(e) => handleFieldChange("address")(e, index)}
            defaultValue={item.country}
          />
        </InputRow>
      </div>
    ));

  return (
    <Root>
      <div css={{ display: "flex", justifyContent: "center" }}>
        {!contact.userAvatar && (
          <DefaultAvatar
            css={{
              minWidth: "3em",
              width: "8vw",
              maxWidth: "6em",
              height: "auto",
            }}
          />
        )}
      </div>

      <Section title="Name">
        <InputRow>
          <NameInput
            placeholder="First Name"
            defaultValue={formState.firstName}
            onChange={handleFieldChange("firstName")}
          />
          <Space />
          <NameInput
            placeholder="Last Name"
            defaultValue={formState.lastName}
            onChange={handleFieldChange("lastName")}
          />
        </InputRow>
      </Section>
      <DividerLine />
      <Section title="Company">
        <InputRow>
          <NameInput
            placeholder="Company Name"
            defaultValue={formState.company}
            onChange={handleFieldChange("compnay")}
          />
        </InputRow>
      </Section>
      <DividerLine />
      <Section
        title="Phone"
        // TODO: Add functionality to add phone numbers
        // actionButton={
        //   <AddButton
        //     onClick={() =>
        //       handleAddField("phoneNumber", {
        //         number: "",
        //         type: "",
        //       })
        //     }
        //   >
        //     <PlusOutlined /> Add
        //   </AddButton>
        // }
      >
        {renderFieldList("phoneNumber", phoneLabels, "Phone Number")}
      </Section>
      <DividerLine />
      <Section
        title="Email"
        actionButton={
          <AddButton
            onClick={() =>
              handleAddField("email", {
                email: "",
                type: "",
              })
            }
          >
            <PlusOutlined /> Add
          </AddButton>
        }
      >
        {renderFieldList("email", emailLabels, "Email")}
      </Section>
      <DividerLine />
      <Section title="Website">
        <InputRow>
          <NameInput
            placeholder="URL"
            defaultValue={formState.website}
            onChange={handleFieldChange("website")}
          />
        </InputRow>
      </Section>
      <DividerLine />
      <Section title="Birthday">
        <DatePicker
          onChange={onChangeDate}
          defaultValue={formState.birthday ? dayjs(formState.birthday) : null}
          style={{ height: "4em", textAlign: "center" }}
        />
      </Section>
      <DividerLine />
      <Section
        title="Address"
        actionButton={
          <AddButton
            onClick={() =>
              handleAddField("address", {
                type: "",
                street1: "",
                city: "",
                province: "",
                postalCode: "",
                country: "",
              })
            }
          >
            <PlusOutlined /> Add
          </AddButton>
        }
      >
        {renderAddressList()}
      </Section>
      <DividerLine />
      <Section
        title="Related Person"
        actionButton={
          <AddButton onClick={() => handleAddField("related", { name: "" })}>
            <PlusOutlined /> Add
          </AddButton>
        }
      >
        {renderFieldList("related", relatedLabels, "Name")}
      </Section>
      <DividerLine />
      <Section title="Notes">
        <InputRow>
          <NameInput
            placeholder="Notes"
            defaultValue={formState.notes}
            onChange={handleFieldChange("notes")}
          />
        </InputRow>
      </Section>
    </Root>
  );
};

const Section = ({
  title,
  children,
  actionButton,
}: {
  title: string;
  children: React.ReactNode;
  actionButton?: React.ReactNode;
}) => (
  <div css={{ width: "100%" }}>
    <SectionHeader>
      <SectionTitle>{title}</SectionTitle>
      {actionButton}
    </SectionHeader>
    <DividerSpace />
    {children}
    <DividerSpace />
  </div>
);

export default ContactForm;
