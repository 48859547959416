import ContactModal from "@/components/shared/ContactModal";
import { ThreeLinesLoaderBox } from "@/components/shared/Loaders/ThreeLines";
import SvgGraphic from "@/components/shared/SvgGraphic";
import { createContact } from "@/utils/contacts/index";
import { atoms } from "@/utils/helpers/atoms";
import { checkPhoneNumberCaps } from "@/utils/helpers/loginAndCaps/capabilities";
import { useContacts } from "@/utils/hooks/useContacts";
import { useContactsChanged } from "@/utils/hooks/useContactsChanged";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAtom, useAtomValue } from "jotai";
import { Suspense, useEffect, useState } from "react";
import ContactFilters from "../../components/contactListScreen/ContactFilters";
import ContactsList from "../../components/contactListScreen/ContactsList";
import "../../components/contactListScreen/NewContactForm.css";
import SearchBar from "../../components/shared/SearchBar";
import SectionHeader from "../../components/shared/SectionHeader";
import {
  contactState,
  resetContactState,
} from "../../utils/contacts/contactState";
import UserContactInfoScreen from "../contactInfoScreen/UserContactInfoScreen";
import { Box } from "../layout/Layout.style";
import { Container } from "./ContactListScreen.style";

const ContactListScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayContact, setDisplayContact] = useAtom(
    atoms.contacts.displayContact
  );
  const [resetCounter, setResetCounter] = useState(0);

  const contactListFiltered = useAtomValue(atoms.contacts.filteredContacts);
  const [searchQuery, setSearchQuery] = useState("");
  const savedContacts = useContacts();
  const contactsChanged = useContactsChanged(savedContacts!);

  useEffect(() => {
    if (contactsChanged) {
      setDisplayContact(contactsChanged);
    }
  }, [savedContacts]);

  const showModal = () => {
    resetContactState();
    setResetCounter((prev) => prev + 1); // Increment counter to force remount
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    if (contactState.phoneNumber[0].number) {
      for (const number of contactState.phoneNumber) {
        await checkPhoneNumberCaps(number.number);
      }
    }

    await createContact(contactState);
    resetContactState();
  };

  const handleCancel = () => {
    resetContactState();
    setIsModalOpen(false);
  };

  return (
    <>
      <Box>
        <SectionHeader
          pageName="Contacts"
          iconButton={<PersonAddIcon />}
          onClick={showModal}
        />

        <ContactModal
          open={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          webgwContact={null}
          key={resetCounter}
        />

        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Container>
          <Suspense fallback={<ThreeLinesLoaderBox />}>
            {contactListFiltered && (
              <div
                css={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <ContactFilters />
                <ContactsList
                  searchQuery={searchQuery}
                  disableNoPhoneContacts={false}
                />
              </div>
            )}
          </Suspense>
        </Container>
      </Box>

      {displayContact ? (
        <UserContactInfoScreen webgwContact={displayContact} />
      ) : (
        <Box>
          <SvgGraphic src="/assets/Contacts_Graphic.svg" />
        </Box>
      )}
    </>
  );
};

export default ContactListScreen;
