import { colors } from "@/styles/global.styles";
import { isMobile, openOdienceDownloadAppWebPage } from "@/utils/helpers/Utils";
import { atoms } from "@/utils/helpers/atoms";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { css } from "@emotion/react";
import { useAtom } from "jotai";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { floatAnimation } from "../EventStream.style";
import { WEB_CLIENT_REACTIONS_IMAGES } from "../helpers/EventStreamUtils";

type StreamMessageInputProps = {
  profileComplete: boolean;
  selectedStream: string;
  handleActionToParticipate: () => void;
  sendReaction: (key: string) => void;
  sendMessage: () => void;
  enabled: boolean;
};

const StreamMessageInput = ({
  profileComplete,
  selectedStream,
  handleActionToParticipate,
  sendReaction,
  sendMessage,
  enabled,
}: StreamMessageInputProps) => {
  const [emoteDivOpen, setEmoteDivOpen] = useState(false);
  const [selectedEmote, _setSelectedEmote] = useState("heart");
  const [animate, setAnimate] = useState<number>();
  const emoteDivOptions = useRef<HTMLDivElement>(null);
  const [messageToSend, setMessageToSend] = useAtom(
    atoms.odience.messageToSend
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageToSend(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const toggleEmoteOptions = () => {
    setEmoteDivOpen(!emoteDivOpen);
  };

  const handleAnimateSelectedReaction = (index: number) => {
    setAnimate(index);

    setTimeout(() => {
      setAnimate(undefined);
    }, 1000);
  };

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(emoteDivOptions, () => setEmoteDivOpen(false));

  return !profileComplete || !isProvisioned() ? (
    <div
      css={participationButtonStyle}
      onClick={() =>
        isMobile()
          ? openOdienceDownloadAppWebPage()
          : handleActionToParticipate()
      }
    >
      {isMobile()
        ? "Get The App "
        : isProvisioned()
          ? "Enter Display Name "
          : "Log in "}
      To Participate
    </div>
  ) : enabled ? (
    <div css={sendMessageDivStyle}>
      <button onClick={toggleEmoteOptions} css={emoteButtonStyle}>
        <div css={emoteHoverStyle}>
          <div key={selectedEmote} css={emoteImageStyle}>
            <img
              src={WEB_CLIENT_REACTIONS_IMAGES[selectedEmote]}
              alt={`${selectedEmote} emote`}
              css={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </div>
        <img src="/odience/web_client/Plus.svg" alt="" css={plusImageStyle} />
      </button>
      <div ref={emoteDivOptions} css={emoteDivOptionsStyle(emoteDivOpen)}>
        {Object.keys(WEB_CLIENT_REACTIONS_IMAGES).map((key, index) => (
          <button
            key={key}
            onClick={() => [
              sendReaction(key),
              handleAnimateSelectedReaction(index),
            ]}
            css={emoteButtonInnerStyle}
          >
            <img
              src={WEB_CLIENT_REACTIONS_IMAGES[key]}
              alt={`${WEB_CLIENT_REACTIONS_IMAGES[key]}`}
              css={[
                emoteImagePositionStyle,
                {
                  opacity: animate === index ? 0.8 : 1,
                  animation:
                    animate === index
                      ? `${floatAnimation} 1s forwards`
                      : "none",
                },
              ]}
            />
            <img src={WEB_CLIENT_REACTIONS_IMAGES[key]} alt="" />
          </button>
        ))}
      </div>
      <div css={messageInputStyle}>
        <input
          type="text"
          value={messageToSend}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Post to stream..."
          css={inputStyle}
        />
      </div>
      <button onClick={sendMessage} css={sendButtonStyle}>
        <img
          src="/odience/event/send.svg"
          alt="Send Message"
          css={sendButtonImageStyle}
        />
      </button>
    </div>
  ) : (
    <div
      css={participationButtonStyle}
      style={{
        cursor: "not-allowed",
        backgroundColor: colors.tertiaryBackground,
      }}
    >
      The stream chat has been disabled by the moderator
    </div>
  );
};

export default StreamMessageInput;

const participationButtonStyle = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  padding: "2vh 1vw",
  color: colors.primaryTextColor,
  backgroundColor: isProvisioned()
    ? colors.tertiaryBackground
    : colors.primaryAccentColor,
  fontSize: "0.75rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  marginRight: "2em",
  cursor: "pointer",
  position: "relative",
  left: isMobile() ? "50%" : undefined,
  transform: isMobile() ? "translateX(-50%)" : undefined,
  height: isMobile() ? "2em" : undefined,
  marginTop: isMobile() ? "0.3em" : undefined,
  textAlign: "center",
});

const sendMessageDivStyle = css({
  display: "flex",
  gap: "1.25em",
  justifyContent: "center",
  alignItems: "center",
  width: "85%",
  marginBottom: "0.625em",
  height: "6.25em",
});

const emoteButtonStyle = css({
  width: "2em",
  height: "2em",
  userSelect: "none",
});

const emoteHoverStyle = css({
  "&:hover": {
    transform: "scale(0.95)",
  },
});

const emoteImageStyle = css({
  width: "2em",
  height: "2em",
  opacity: 0.9,
  "&:hover": {
    opacity: 1,
  },
});

const plusImageStyle = css({
  width: "0.6em",
  height: "0.6em",
  position: "relative",
  top: "-2em",
  left: "2em",
});

const emoteDivOptionsStyle = (emoteDivOpen: boolean) =>
  css({
    display: emoteDivOpen ? "flex" : "none",
    opacity: emoteDivOpen ? 1 : 0,
    padding: "1.25em",
    alignContent: "flex-start",
    flexWrap: "wrap",
    gap: "2em",
    justifyContent: "center",
    alignItems: "center",
    width: "18em",
    height: "fit-content",
    borderRadius: "10px",
    zIndex: 103,
    position: "absolute",
    bottom: 0,
    left: "-15em",
    backgroundColor: "#171717",
    transition: "all 0.2s ease",
    pointerEvents: emoteDivOpen ? "auto" : "none",
  });

const emoteButtonInnerStyle = css({
  width: "2em",
  height: "2em",
  opacity: 0.9,
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    opacity: 1,
  },
});

const emoteImagePositionStyle = css({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
});

const messageInputStyle = css({
  backgroundColor: "#080808",
  borderRadius: "6px",
  width: "23em",
  height: "3.75em",
  padding: "0.33em",
});

const inputStyle = css({
  width: "100%",
  height: "100%",
  fontSize: "0.9em",
  background: "none",
  border: "none",
  outlineWidth: 0,
  color: colors.primaryTextColor,
});

const sendButtonStyle = css({
  cursor: "pointer",
  userSelect: "none",
  "&:hover": {
    opacity: 0.5,
    transform: "scale(0.95)",
  },
});

const sendButtonImageStyle = css({
  width: "2em",
});
