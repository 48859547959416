import { useAtom, useAtomValue } from "jotai";
import { atoms } from "../helpers/atoms";

export function useSketch(): UseSketch {
  const [outgoingSketchInfos, setOutgoingSketchInfos] = useAtom(
    atoms.sketch.outgoingSketchInfos
  );
  const incomingSketchInfos = useAtomValue(atoms.sketch.incomingSketchInfos);

  const sendSketchInvite = (phoneNumber: string) => {
    if (!canAcceptOrSendSketch()) {
      return;
    }

    setOutgoingSketchInfos({
      number: phoneNumber,
      startFullScreen: true,
      id: 1,
    });
  };

  const canAcceptOrSendSketch = () => {
    return !outgoingSketchInfos && !incomingSketchInfos;
  };

  return {
    sendSketchInvite,
    canAcceptOrSendSketch: canAcceptOrSendSketch(),
  };
}

type UseSketch = {
  sendSketchInvite: (number: string) => void;
  canAcceptOrSendSketch: boolean;
};
