import { css } from "@emotion/react";

import { colors } from "../../styles/global.styles";

export const transcriptButtonCss = css({
  width: "2em",
  height: "100%",
  opacity: "0.85",
  "&:hover": {
    opacity: 1,
  },
});

export const callTypeIconCss = (enableHover = true) =>
  css({
    display: "flex",
    gap: "2vw",
    color: colors.secondaryTextColor,
    "&:hover, &:focus": enableHover && {
      color: colors.primaryAccentColor,
    },
  });

export const iconFillCss = css({
  "&:hover, &:focus": {
    "& svg path": {
      color: colors.primaryAccentColor,
      fill: colors.primaryAccentColor,
      backgroundColor: colors.primaryAccentColor,
    },
  },
});

export const contact = css({
  color: colors.primaryTextColor,
});

export const callInfo = css({
  color: colors.secondaryTextColor,
});

export const timeStamp = css({
  width: "5vw",
  color: colors.secondaryTextColor,
});
