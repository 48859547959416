import { useEffect, useRef } from "react";
import { setCalls } from "../calls/callState";
import { getStoredCalls } from "../database";

export function useInitCalls() {
  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    void (async () => {
      try {
        const storedCalls = await getStoredCalls();
        if (storedCalls) {
          setCalls(storedCalls);
        }
      } catch (e) {
        console.error("Error initializing calls", e);
      }
    })();
  }, []);
}
