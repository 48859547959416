import { atoms } from "@/utils/helpers/atoms";
import {
  defaultChatbotImgUrl,
  fac,
  getAvgColorOptions,
} from "@/utils/helpers/chatbots";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useChatbotDirectory } from "@/utils/hooks/useChatbotDirectory";
import { css, keyframes } from "@emotion/react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  Fragment,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Box } from "../../pages/layout/Layout.style";
import { colors, scrollbarWidth } from "../../styles/global.styles";
import { ChatbotInfo } from "../../utils/chatbots";
import { usePrevious } from "../../utils/hooks/usePrevious";
import {
  useDraggableAndScrollableWithFocusedElements,
  useScrollIntoView,
} from "../../utils/hooks/useScrollIntoView";
import { CarouselButton } from "../shared/CarouselButton";
import { TextButton } from "../shared/TextButton";
import { VerticalRule } from "../shared/VerticalRule";

const cardGapSm = "0.5rem";
const cardGapMd = "1rem";
export const cardCount = 3;

const categorizedBotsStyle = {
  listContainer: css({
    marginTop: "1em",
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: "10rem",
    "--card-gap": cardGapMd,
    "@media (max-width: 1200px)": {
      "--card-gap": cardGapSm,
    },
    gap: "var(--card-gap)",
    overflowX: "scroll",

    userSelect: "none",
    // disable scrollbar
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
      width: "0",
      height: "0",
    },
  }),
};

export default function CategorizedBots() {
  const [bots, botCategories] = useChatbotDirectory().data;

  const [activeCategory, _setActiveCategory] =
    useState<keyof typeof botCategories>("Featured");
  const lastActiveCategory = usePrevious(activeCategory);
  const categoryChanged = activeCategory !== lastActiveCategory;

  const activeBots = useMemo(
    () => botCategories[activeCategory].filter((bot) => !bot || !bot.odience),
    [activeCategory, botCategories]
  );

  const [forceRerenderKey, refocus] = useReducer((prev) => prev + 1, 0);
  const [_focusedIdx, moveFocused] = useReducer(
    (prev: number, val: (1 | -1 | 0) | { set: number }) => {
      if (!bots) return prev;

      if (val === 0) {
        refocus();
        return prev;
      }

      if (typeof val === "object") {
        return val.set * cardCount;
      }

      const res = prev + val * cardCount;
      if (res < 0 || res > activeBots.length - 1) {
        refocus();
        return prev;
      }
      return res;
    },
    0
  );
  // this is outside of the useReducer because it shouldn't update the previous (_focusedIdx) value
  const focusedIdx =
    // make sure there are always a cardCount number of cards in focus
    _focusedIdx + cardCount - 1 > activeBots.length - 1
      ? _focusedIdx - cardCount + (activeBots.length % cardCount)
      : _focusedIdx;
  const incrementFocusedIdx = useCallback(() => moveFocused(1), []);
  const decrementFocusedIdx = useCallback(() => moveFocused(-1), []);

  const isMin = focusedIdx === 0;
  const isMax = activeBots.length - 1 - focusedIdx < cardCount;

  const setActiveCategory = (ac: Parameters<typeof _setActiveCategory>[0]) => {
    moveFocused({
      set: 0,
    });
    _setActiveCategory(ac);
  };

  const draggableRef = useRef<HTMLDivElement>(null!);
  useDraggableAndScrollableWithFocusedElements(draggableRef, moveFocused);

  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  useScrollIntoView(
    {
      getElem: () => cardRefs.current[focusedIdx + Math.trunc(cardCount / 2)],
      instant: categoryChanged,
    },
    [focusedIdx, forceRerenderKey]
  );

  if (!bots || !activeBots.length) return null;

  return (
    <Box
      css={{
        backgroundColor: colors.fourthBackground,
        width: `calc((100% + (8px + ${scrollbarWidth}) * 2) * 0.9)`,
        padding: "1em 0",
        position: "relative",
        minHeight: "clamp(14.5rem, 14vw, 18rem)",
        "& .carouselButton:disabled": {
          opacity: "0 !important",
        },
      }}
      className="carouselBox"
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          height: "1.5rem",
          color: colors.secondaryTextColor,
        }}
      >
        {(
          Object.entries(botCategories) as [
            keyof typeof botCategories,
            (ChatbotInfo | null)[],
          ][]
        ).map(([category, botsOfCategory], idx) => (
          <Fragment key={idx}>
            {idx !== 0 && <VerticalRule />}
            <TextButton
              active={activeCategory === category}
              disabled={!botsOfCategory.length}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </TextButton>
          </Fragment>
        ))}
      </div>
      <div css={categorizedBotsStyle.listContainer} ref={draggableRef}>
        {activeBots.map((bot, idx) => (
          <BotCard
            key={bot?.id || idx}
            ref={(card) => {
              cardRefs.current[idx] = card;
            }}
            focused={idx - focusedIdx < cardCount && idx - focusedIdx >= 0}
            bot={bot}
          />
        ))}
      </div>
      <CarouselButton
        disabled={isMin}
        left
        css={{
          height: "66%",
          transform: "translateY(-36%)",
          width: "8%",
        }}
        onClick={decrementFocusedIdx}
        className="carouselButton"
      />
      <CarouselButton
        disabled={isMax}
        right
        css={{
          height: "66%",
          transform: "translateY(-36%)",
          width: "8%",
        }}
        onClick={incrementFocusedIdx}
        className="carouselButton"
      />
    </Box>
  );
}

const buttonSpace = "7%";
const botCardStyle = {
  main: css({
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    minHeight: "10rem",
    "--width": `calc(((100% - var(--card-gap) * ${
      cardCount - 1
    }) / ${cardCount}) - ${buttonSpace})`,
    minWidth: "var(--width)",
    maxWidth: "var(--width)",
    fontSize: "0.8em",
    textAlign: "center",
    color: colors.primaryTextColor,
    background: colors.secondaryBackground,
    borderRadius: "8px",
    overflow: "hidden",
    transition: `opacity 0.4s ease`,
    // these margins don't matter much. The card's position is controlled by ``useScrollIntoView`` above
    ":first-of-type": {
      // same as bottom padding
      marginLeft: "1em",
    },
    ":last-of-type": {
      marginRight: "var(--width)",
    },
  }),
  image: css({
    objectFit: "cover",
    minHeight: "100%",
    maxHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    userDrag: "none",
    WebkitUserDrag: "none",
  }),
};

const BotCard = ({
  ref,
  bot,
  focused,
}: { bot: ChatbotInfo | null; focused: boolean } & {
  ref?: React.Ref<HTMLDivElement | null>;
}) => {
  const setSelectedChatbot = useSetAtom(atoms.chatbot.selectedChatbot);

  const defaultChatbotImgBackgroundColor = useAtomValue(
    atoms.chatbot.defaultChatbotImgBackgroundColor
  );

  const [crossOrigin, removeCrossOrigin] = useReducer(() => false, true);
  const [imgLoaded, setImgLoaded] = useReducer(() => true, false);
  const [imgError, setImgError] = useReducer(() => true, false);

  return (
    <div
      ref={ref}
      style={{
        visibility: !bot ? "hidden" : undefined,
        opacity: focused ? "1" : "0.25",
      }}
      css={botCardStyle.main}
      onClick={
        bot && focused
          ? () => {
              const chatbotContact = WebGwContact.fromChatbotInfo(bot);
              setSelectedChatbot(chatbotContact);
            }
          : undefined
      }
    >
      <span
        css={{
          height: "clamp(55%, 8vw, 75%)",
          minWidth: "100%",
          maxWidth: "100%",
          userSelect: "none",
          backgroundColor: defaultChatbotImgBackgroundColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {(!imgLoaded || imgError) && (
          <img
            src={defaultChatbotImgUrl}
            css={[
              botCardStyle.image,
              {
                width: "75%",
                minWidth: "75%",
                objectFit: "contain",
                opacity: "0.25",
              },
              !imgError && {
                animation: `${keyframes({
                  "0%": { opacity: "0.25" },
                  "50%": { opacity: "0.60" },
                  "100%": { opacity: "0.25" },
                })} 1s ease-in-out infinite`,
              },
            ]}
          ></img>
        )}
        {bot && !imgError && (
          <img
            src={bot.icon}
            crossOrigin={crossOrigin ? "anonymous" : undefined}
            style={{
              opacity: imgLoaded ? "1" : "0",
              display: imgLoaded ? "block" : "none",
            }}
            css={botCardStyle.image}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onError={() => {
              if (crossOrigin) {
                // try loading without crossorigin before setting the error flag to true
                removeCrossOrigin();
              } else {
                setImgError();
              }
            }}
            onLoad={(e) => {
              if (crossOrigin) {
                const avgColor = fac.getColor(
                  e.currentTarget,
                  getAvgColorOptions
                );
                e.currentTarget.style.backgroundColor = avgColor.rgb;
              }

              setImgLoaded();
            }}
          />
        )}
      </span>
      {bot && (
        <div css={{ margin: "auto 0.5em", overflowWrap: "break-word" }}>
          {bot.name}
        </div>
      )}
    </div>
  );
};
