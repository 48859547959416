import VerseLogo from "@/assets/VerseLogo";
import { NoContactsScreen } from "@/pages/contactListScreen/EmptyContactListScreen";
import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContacts } from "@/utils/hooks/useContacts";
import CloseIcon from "@mui/icons-material/Close";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useMemo, useRef, useState } from "react";
import ContactsList from "../contactListScreen/ContactsList";
import { AnimateChangeInHeight } from "./AnimateChangeInHeight";
import { DefaultAvatar } from "./Avatar";
import { IconButton } from "./Button";
import Checkbox from "./Checkbox";
import { actionsBar, popupOverlayBox } from "./ContactPicker.style";
import { ThreeLinesLoaderBox } from "./Loaders/ThreeLines";
import PopupOverlay from "./PopupOverlay";
import SearchBar from "./SearchBar";
import { titlePopupOverlay } from "./SectionHeader.style";

const ContactPicker = ({
  from,
  preSelectedContacts,
  onSelectedContacts,
  onClose,
}: {
  from: "Conversation" | "Keypad";
  preSelectedContacts: WebGwContact[];
  onSelectedContacts: (contact: WebGwContact[]) => void;
  onClose: () => void;
}) => {
  const selectedContactsDivRef = useRef<HTMLDivElement>(null);
  const setContactListFilter = useSetAtom(atoms.contacts.contactFilter);

  const [searchQuery, setSearchQuery] = useState("");
  const contactListFiltered = useAtomValue(atoms.contacts.filteredContacts);
  const [selected, setSelected] = useState(false);
  const contacts = useContacts();
  const availableContacts = useMemo(
    () => contacts?.filter((contact) => contact && !contact.isChatbot),
    [contacts]
  );

  const handleContactFilter = async () => {
    setSelected(!selected);
    if (!selected) {
      await setContactListFilter("verse");
    } else {
      setContactListFilter("all");
    }
  };

  const onSubmit = () => {
    onClose();
    onSelectedContacts(preSelectedContactsState);
    setPreSelectedContactsState([]);
  };

  const [preSelectedContactsState, setPreSelectedContactsState] = useState(
    preSelectedContacts || ([] as WebGwContact[])
  );

  const handleOnSelectContact = (contact: WebGwContact) => {
    if (from === "Keypad") {
      setPreSelectedContactsState([contact]);
    } else {
      setPreSelectedContactsState((current) => [...current, contact]);
      scrollToBottom();
    }
  };

  const handleOnUnSelectContact = (contact: WebGwContact) => {
    if (from === "Keypad") {
      setPreSelectedContactsState([]);
    } else {
      setPreSelectedContactsState(
        preSelectedContactsState.filter((current) => {
          return current.id !== contact.id;
        })
      );
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (selectedContactsDivRef.current) {
        selectedContactsDivRef.current.scrollTop =
          selectedContactsDivRef.current.scrollHeight;
      }
    });
  };

  const hasContacts = availableContacts && availableContacts.length > 0;

  const contactUpgradingToGroupChat = useAtomValue(
    atoms.messaging.conversationUpgradingToGroupChat
  )?.participants[0];

  return (
    <PopupOverlay>
      <div css={popupOverlayBox}>
        <div
          css={{
            top: "2vh",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            zIndex: 999,
            alignItems: "center",
          }}
        >
          <div css={titlePopupOverlay}>
            {hasContacts && from === "Keypad"
              ? "Select Contact"
              : hasContacts
                ? "Select Contacts"
                : "Add Contacts"}
          </div>
          <IconButton onClick={() => onClose()}>
            <CloseIcon
              css={{
                color: colors.primaryTextColor,
                height: "1.3em",
                width: "1.3em",
              }}
            />
          </IconButton>
        </div>

        {hasContacts && (
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            css={{
              width: "100%",
            }}
          />
        )}

        <AnimateChangeInHeight
          css={{
            backgroundColor: "#21272D",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          {preSelectedContactsState.length > 0 && from === "Conversation" && (
            <div
              css={{
                width: "100%",
                padding: "1vw",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                ref={selectedContactsDivRef}
                css={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  maxHeight: "6em",
                  gap: "0.5em",
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                    width: "4px",
                    height: "4px",
                  },
                  boxShadow: `0 0 8px 8px #21272D`,
                }}
              >
                {preSelectedContactsState.map((contact) => {
                  return (
                    <div
                      key={contact.id}
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        backgroundColor: "#2E3237",
                        borderRadius: "10px",
                        padding: "0.5em 0.5em",
                        minHeight: "3em",
                        flexShrink: 0,
                        ...(contact.isVerse && {
                          "& .verse-logo, &:hover .close-icon": {
                            display: "initial",
                          },
                          "&:hover .verse-logo, & .close-icon": {
                            display: "none",
                          },
                        }),
                      }}
                      onClick={(e) => {
                        if (e.ctrlKey) {
                          e.stopPropagation();
                          console.log(contact);
                        }
                      }}
                    >
                      <div
                        css={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          paddingRight: "0.5em",
                        }}
                      >
                        <DefaultAvatar />
                      </div>

                      <div
                        css={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "center",
                          paddingRight: "0.5em",
                        }}
                      >
                        <div
                          css={{
                            color: colors.primaryTextColor,
                            display: "flex",
                            flexDirection: "row",
                            textWrap: "nowrap",
                          }}
                        >
                          {contact.noNameReturnPhoneNumber()}
                        </div>
                        <div
                          css={{
                            color: colors.secondaryTextColor,
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "0.75rem",
                            minHeight: "18px",
                          }}
                        >
                          {(contact.phone ||
                            contact.getMainPhoneNumberWithCaps() ||
                            contact.getMainPhoneNumber()) &&
                            formatPhoneNumber(
                              contact.getMainPhoneNumberWithCaps() ||
                                contact.getMainPhoneNumber() ||
                                contact.phone![0][0],
                              "E164"
                            )}
                        </div>
                      </div>

                      {/* disable the close button if the contact is upgrading to group chat */}
                      {contactUpgradingToGroupChat?.id !== contact.id && (
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "column",
                            width: "1.25em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => handleOnUnSelectContact(contact)}
                            css={{
                              ":hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            className="close-icon"
                          >
                            <CloseIcon />
                          </IconButton>

                          {contact.isVerse && (
                            <VerseLogo className="verse-logo" />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </AnimateChangeInHeight>

        {contactListFiltered && hasContacts ? (
          <div
            css={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexGrow: "1",
              minHeight: "0",
            }}
          >
            <Suspense fallback={<ThreeLinesLoaderBox />}>
              <div
                css={{
                  maxHeight: "92%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <ContactsList
                  searchQuery={searchQuery}
                  ignoreContacts={
                    contactUpgradingToGroupChat
                      ? [contactUpgradingToGroupChat]
                      : undefined
                  }
                  isContactPicker={true}
                  preSelectedContacts={preSelectedContactsState}
                  onSelectContact={handleOnSelectContact}
                  onUnSelectContact={handleOnUnSelectContact}
                />
              </div>
            </Suspense>
          </div>
        ) : (
          <div
            css={{
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <NoContactsScreen />
          </div>
        )}
        {hasContacts && (
          <div css={actionsBar}>
            <div>
              {contactListFiltered && hasContacts && (
                <div
                  css={{
                    display: "flex",
                    height: "1rem",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    textWrap: "nowrap",
                    flexDirection: "row",
                    gap: "0.5vw",
                    width: "100%",
                    paddingLeft: "1vw",
                  }}
                >
                  <Checkbox
                    checkboxId="ContactPickerFilter"
                    checked={selected}
                    onChange={handleContactFilter}
                  />{" "}
                  Verse contacts only
                </div>
              )}
            </div>
            <div css={{ display: "flex", gap: "1em" }}>
              <button
                css={{
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "10px",
                  fontSize: "1rem",
                  padding: "1vh 1vw",
                  width: "7vw",
                  height: "6vh",
                  minWidth: "5em",
                  minHeight: "3em",
                  backgroundColor: colors.secondaryBackground,
                  color: colors.primaryTextColor,
                }}
                onClick={() => onClose()}
              >
                Cancel
              </button>
              <button
                css={{
                  textDecoration: "none",
                  border: "none",
                  borderRadius: "10px",
                  fontSize: "1rem",
                  padding: "1vh 1vw",
                  width: "7vw",
                  height: "6vh",
                  minWidth: "5em",
                  minHeight: "3em",
                  backgroundColor:
                    preSelectedContactsState.length > 0
                      ? colors.primaryAccentColor
                      : colors.primaryAccentColorDarker,
                  color: colors.primaryTextColor,
                }}
                disabled={preSelectedContactsState.length === 0}
                onClick={onSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </div>
    </PopupOverlay>
  );
};

export default ContactPicker;
