import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContactEdited } from "@/utils/hooks/useContactEdited";
import { useContacts } from "@/utils/hooks/useContacts";
import { useContactsChanged } from "@/utils/hooks/useContactsChanged";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import VerseLogo from "../../assets/VerseLogo";
import { name } from "../../components/contactListScreen/ContactCard.style";
import { colors } from "../../styles/global.styles";
import WebGwContact, { NumberWithType } from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import { ListItem } from "../shared/ListItem.style";
import { logo } from "./ContactCard.style";

type Props = React.ComponentProps<"li"> & {
  contact: WebGwContact;
  searchIndices?: NonNullable<
    ReturnType<(typeof WebGwContact)["prototype"]["filterContact"]>
  >[1];
  searchQueryLength: number;
  disabled?: boolean;
};

function ContactCard({
  contact: receivedContact,
  searchIndices,
  searchQueryLength,
  disabled,
  ...liProps
}: Props) {
  const contacts = useContacts();
  const contact =
    contacts?.find((pContact) => pContact.id === receivedContact.id) ??
    receivedContact;
  const [phoneNumbers, setPhoneNumbers] = useState<
    undefined | NumberWithType[]
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [mainPhoneNumber, setMainPhoneNumber] = useState("");
  const contactsChanged = useContactsChanged(contacts!);
  const editedContact = useContactEdited(contact);

  useEffect(() => {
    //TODO EXPORT DUPLICATE FETCHDATA FUNCTION TO BE REUSED WHERE NEEDED
    const fetchData = async () => {
      try {
        const resolvedPhoneNumbers =
          await contact.getAllPhoneNumbersWithTypesAndCaps();
        setPhoneNumbers(resolvedPhoneNumbers);
        if (resolvedPhoneNumbers && resolvedPhoneNumbers.length > 0) {
          setMainPhoneNumber(resolvedPhoneNumbers[0][0]);
        } else {
          const mainNumber = contact.getMainPhoneNumber();
          if (mainNumber) {
            setMainPhoneNumber(mainNumber);
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching phone numbers:", error);
      }
    };
    setLoading(true);
    const fetchPromise = fetchData();
    fetchPromise.finally(() => setLoading(false));
  }, [editedContact, contactsChanged]);

  const numberToUse = mainPhoneNumber || contact.getMainPhoneNumber();

  const mainPhoneNumberFormatted = searchQueryLength
    ? numberToUse
    : formatPhoneNumber(numberToUse, "E123");

  const contactName = contact.noNameReturnPhoneNumber(
    mainPhoneNumberFormatted || numberToUse
  );

  if (!phoneNumbers) return null;

  return (
    <ListItem {...liProps} style={{ ...(disabled && { cursor: "default" }) }}>
      <Avatar contact={contact} />
      <div>
        <div css={name}>{contactName}</div>
        <div
          css={[
            name,
            {
              color: colors.secondaryTextColor,
              marginTop: "0.25em",
              fontSize: "0.8em",
            },
          ]}
        >
          {mainPhoneNumberFormatted}
        </div>
      </div>

      <div
        css={{
          width: "100%",
          height: "2em",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div css={logo}>
          {phoneNumbers.length > 0 ? (
            <VerseLogo />
          ) : loading ? (
            <CircularProgress color="error" css={logo} />
          ) : null}
        </div>
      </div>
    </ListItem>
  );
}

export default ContactCard;
