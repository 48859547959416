import { Body, registerButton } from "../index.style";

import DeviceManagement from "@/pages/settingsScreen/DeviceManagement";
import { atoms } from "@/utils/helpers/atoms";
import { fetchAllDevices, logoutEvent } from "@/utils/helpers/removeDevice";
import { useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";

type Props = {
  devices: any;
  deviceLimit: any;
  next: (next?: any) => void;
  cancel: () => void;
};

const StepRemoveDevice = ({ devices, next, deviceLimit, cancel }: Props) => {
  const [data, setData] = useAtom(atoms.provisioning.devices);
  const setMaxDevices = useSetAtom(atoms.provisioning.maxDevices);

  useEffect(() => {
    if (devices?.length === undefined) {
      fetchAllDevices(setData, setMaxDevices);
    } else if (devices) {
      setData(devices);
    }
  }, [setData, devices]);

  return (
    <>
      <Body css={{ paddingTop: "2vh" }}>
        <DeviceManagement
          devices={data}
          next={next}
          deviceLimit={deviceLimit}
        />
        <button
          onClick={logoutEvent}
          css={[registerButton, { marginTop: "5vh", width: "90%" }]}
        >
          Cancel Registration
        </button>
      </Body>
    </>
  );
};

export default StepRemoveDevice;
