const VerseLogo = (props: React.ComponentProps<"svg">) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 126 126"
    style={
      {
        //   enableBackground: "new 0 0 126 126",
      }
    }
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st1{fill:url(#Verse_logo-2_00000103983219387728944640000013208622913847238027_)}"
      }
    </style>
    <path
      id="Icon_Frame"
      style={{
        fill: "none",
      }}
      d="M0 0h126v126H0z"
    />
    <linearGradient
      id="Verse_logo-2_00000177486266000868002650000013201334222935282360_"
      gradientUnits="userSpaceOnUse"
      x1={5.955}
      y1={120.893}
      x2={6.505}
      y2={120.114}
      gradientTransform="matrix(117.3392 0 0 -91.5773 -663.229 11085.633)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#e56d72",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#dc3a52",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#dc3851",
        }}
      />
    </linearGradient>
    <path
      id="Verse_logo-2"
      style={{
        fill: "url(#Verse_logo-2_00000177486266000868002650000013201334222935282360_)",
      }}
      d="M121.3 42.1c-.7-14.3-12.8-25.4-27.1-24.7-6 .3-11.8 2.6-16.2 6.7l-.9.9-14.4 14.3L48.3 25l-.9-.9c-10.6-9.6-27-8.8-36.7 1.8-8.7 9.5-9 24-.8 33.9l.2.3c.6.9 1.2 1.7 1.9 2.5l32.5 38c8.6 10 23.6 11.2 33.6 2.6.9-.8 1.8-1.7 2.6-2.6l32.5-38c.7-.8 1.3-1.7 1.9-2.5l.2-.3c4.2-5 6.3-11.3 6-17.7m-10 14.2c-.5.5-.9 1-1.4 1.4-8 8-21 8-29 0L66.6 43.4l.1-.1-.1-.1L81 28.8l.7-.7c8.1-7.4 20.6-7 28.3.7 7.4 7.4 8 19.3 1.3 27.5"
    />
  </svg>
);

export default VerseLogo;
