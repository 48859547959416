import { getStoredConversations } from "@/utils/database";
import { useEffect, useRef } from "react";
import { conversationsState, setConversations } from "./ConversationState";
import { failPreviousOngoingMessages } from "./conversationUtils";

export function useInitConversations() {
  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    void (async () => {
      conversationsState.isLoading = true;
      try {
        const storedConversations = await getStoredConversations();
        if (storedConversations) {
          setConversations(storedConversations);

          // Some previous messages may still be in progress state (like sending a file and closing the browser), fail them
          await failPreviousOngoingMessages();
        }
      } catch (e) {
        console.error("Error initializing conversations", e);
      }
      conversationsState.isLoading = false;
    })();
  }, []);
}
