import BackToEvents from "@/assets/Exit.svg";
import GetAppModal from "@/components/odience/components/GetAppModal";
import OdienceProfileAvatar from "@/components/odience/components/OdienceProfileAvatar";
import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useToast } from "@/utils/helpers/toastManager";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { useAtomValue } from "jotai";
import { useState } from "react";
import {
  getAppButtonStyles,
  qrCodeImageStyles,
} from "../../eventList/components/EventListHeader";

type EventStreamHeaderProps = {
  odienceAvatar: string;
  handleOdienceAvatarError: () => void;
  handleOpenEditProfile: () => void;
  event: OdienceEvent;
  selectedStreamId: string;
  is360Stream: boolean;
  availableEventStreams: any[];
  onNavigateBack: () => void;
  resetOdienceCallStates: () => void;
  callActive: boolean;
};

const EventStreamHeader = ({
  odienceAvatar,
  handleOdienceAvatarError,
  handleOpenEditProfile,
  event,
  selectedStreamId,
  is360Stream,
  availableEventStreams,
  onNavigateBack,
  resetOdienceCallStates,
  callActive,
}: EventStreamHeaderProps) => {
  const [isAppModalOpen, setAppModalOpen] = useState(false);
  const { dismissToast } = useToast();
  const isVideoWallCall = useAtomValue(atoms.odience.isVideoWallCall);

  const handleVideoWallActive = (event: PopStateEvent) => {
    event.preventDefault();
    const confirmLeave = window.confirm(
      `Are you sure you want to go back? You are currently on ${isVideoWallCall ? "the video wall" : "front row"}.`
    );

    if (confirmLeave) {
      resetOdienceCallStates();
      return true;
    } else {
      return false;
    }
  };

  const backToEvents = (
    <button
      onClick={() => {
        if (callActive) {
          const proceed = handleVideoWallActive(new PopStateEvent("popstate"));
          if (!proceed) return;
        }
        dismissToast?.();
        onNavigateBack();
      }}
    >
      <img
        src={BackToEvents}
        alt="Back to event list"
        css={{ height: "2em", width: "2em" }}
      />
    </button>
  );

  const eventImage = (
    <div>
      <img
        src={event.imageUrl || event.organization_image_url}
        alt=""
        css={{ borderRadius: "10px", height: "4.3em", width: "4.3em" }}
      />
    </div>
  );

  const eventTitle = (
    <div css={{ fontSize: "1.5rem", textTransform: "uppercase" }}>
      {event.name ?? "Unavailable"}
    </div>
  );

  return (
    <div
      css={{
        color: colors.primaryTextColor,
        borderRadius: "20px",
        width: "100%",
        height: "7em",
        backgroundColor: "#1c1c1c",
        display: "flex",
        gap: "2em",
        flexShrink: 0,
        paddingLeft: "4em",
        alignItems: "center",
        transition: "all .2s ease",
      }}
    >
      {backToEvents}

      {eventImage}
      <div css={{ display: "flex", gap: "1vh", flexDirection: "column" }}>
        {eventTitle}

        <StreamTitle
          streamId={selectedStreamId}
          streams={availableEventStreams}
          is360Stream={is360Stream}
        />
      </div>
      <div
        css={{ position: "absolute", right: "5%", display: "flex", gap: "1vw" }}
      >
        <GetAppButton handleGetAppModal={() => setAppModalOpen(true)} />

        {isProvisioned() && (
          <OdienceProfileAvatar
            {...{
              odienceAvatar,
              handleOdienceAvatarError,
              handleOpenEditProfile,
            }}
          />
        )}
      </div>
      <GetAppModal
        show={isAppModalOpen}
        onCloseModal={() => setAppModalOpen(false)}
        objEvent={event}
      />
    </div>
  );
};

const GetAppButton = ({
  handleGetAppModal,
}: {
  handleGetAppModal: () => void;
}) => (
  <button onClick={handleGetAppModal} css={getAppButtonStyles}>
    <img
      src="/odience/web_client/Qr-code.svg"
      alt="QR Code"
      css={qrCodeImageStyles}
    />
    <div>Get the app</div>
  </button>
);

const StreamTitle = ({
  streamId,
  streams,
  is360Stream,
}: {
  streamId: string;
  streams: any[];
  is360Stream: boolean;
}) => {
  const stream = streams.find((stream) => {
    return stream.id === streamId;
  });

  const streamName =
    stream && stream.info.name + " " + stream.info.urls[0].resolution;
  const is360StreamImage = is360Stream
    ? "odience/event/stream/360Icon.svg"
    : "odience/event/stream/Planar.svg";

  return (
    stream && (
      <div
        key={`${stream.streamName}`}
        css={{
          fontSize: "1rem",
          display: "flex",
          gap: "0.5em",
          alignItems: "center",
        }}
      >
        <img
          src={is360StreamImage}
          alt={is360Stream ? "360 Stream" : "2D Stream"}
        />
        <span>{streamName}</span>
      </div>
    )
  );
};

export default EventStreamHeader;
