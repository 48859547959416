import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { atoms } from "../helpers/atoms";

export const useNavigationConfirmation = () => {
  const callActive = useAtomValue(atoms.calling.callActive);
  const [isVideoWallCall, setIsVideoWallCall] = useAtom(
    atoms.odience.isVideoWallCall
  );
  const [isFrontRowCall, setIsFrontRowCall] = useAtom(
    atoms.odience.isFrontRowCall
  );
  const setFeaturedCaller = useSetAtom(atoms.odience.featuredCaller);
  const setMutedByModerator = useSetAtom(atoms.odience.mutedByModerator);
  const setStreamVolume = useSetAtom(atoms.odience.streamVolume);
  const navigate = useNavigate();

  const confirmNavigation = (newPath: string) => {
    if (callActive && (isVideoWallCall || isFrontRowCall)) {
      const confirmLeave = window.confirm(
        `Are you sure you want to navigate away? You are currently on ${isVideoWallCall ? "the video wall" : "front row"}.`
      );

      if (confirmLeave) {
        setIsVideoWallCall(false);
        setIsFrontRowCall(false);
        setFeaturedCaller(false);
        setMutedByModerator(false);
        setStreamVolume(100);
        void navigate(newPath);
      }
    } else {
      void navigate(newPath);
    }
  };

  return { confirmNavigation };
};
