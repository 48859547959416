import {
  emojiToHTML,
  frequentlyUsed,
  searchIndex,
} from "@/utils/helpers/emoji";
import { PlusIcon } from "@heroicons/react/24/solid";

type ReactionPickerProps = {
  handleEmojiClick: (emoji: string) => void;
  expandToAll: () => void;
};

export default function ReactionPicker({
  ref,
  ...props
}: ReactionPickerProps & {
  ref?: React.RefObject<HTMLDivElement | null>;
}) {
  const frequentlyUsedEmojis = frequentlyUsed
    .get({ maxFrequentRows: 1, perLine: 6 })
    .values()
    .take(6)
    .map((emoji) => {
      const emojiData = searchIndex.get(emoji);
      return {
        html: emojiToHTML(emojiData),
        native: emojiData.skins[0].native,
      };
    });

  return (
    <div
      ref={ref}
      className="flex gap-0.5 rounded-xl bg-secondaryBackground p-1.5 text-xl text-primaryTextColor [&>*:hover]:scale-110 [&>*]:transition-transform"
    >
      {frequentlyUsedEmojis
        .map((emoji) => (
          <button
            key={emoji.native}
            className="box-content flex h-[24px] w-[24px] items-center justify-center rounded-full p-1 hover:bg-secondaryBackgroundLighter [&>*]:!h-full [&>*]:!w-full"
            onClick={() => {
              props.handleEmojiClick(emoji.native);
            }}
            dangerouslySetInnerHTML={{ __html: emoji.html }}
          ></button>
        ))
        .toArray()}
      <button
        onClick={props.expandToAll}
        className="box-content flex h-[24px] w-[24px] items-center justify-center rounded-full p-1 hover:bg-secondaryBackgroundLighter [&>*]:!h-full [&>*]:!w-full"
      >
        <PlusIcon width={24} height={24} />
      </button>
    </div>
  );
}
