import React from "react";

// Had to make a custom component for the Planar icon so we can manipulate the fill color
const Planar = (props: React.ComponentProps<"svg">) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path fill="none" d="M0 0h24v24H0Z" data-name="Path 2685" />
    <path
      d="M16.066 6.972v10.154H2.843V6.972h13.223m1.322-2.538H1.52A1.3 1.3 0 0 0 .198 5.703v12.692a1.3 1.3 0 0 0 1.322 1.269h15.868a1.3 1.3 0 0 0 1.322-1.269v-4.442l5.288 5.081V5.069l-5.289 5.077V5.703a1.3 1.3 0 0 0-1.321-1.269Z"
      data-name="Path 2686"
    />
  </svg>
);

export default Planar;
