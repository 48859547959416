import { colors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import { getLocalUser } from "@/utils/helpers/localstorage";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useContacts } from "@/utils/hooks/useContacts";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import parseNmsToChatMessage from "@/utils/messaging/parseNmsToChatMessage";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Color from "color";
import { useAtom } from "jotai";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useMemo, useState } from "react";
import { AnimateChangeInHeight } from "../shared/AnimateChangeInHeight";
import ContactCard from "./chat/messages/ContactCard";
import RichcardMessage from "./chat/messages/RichcardMessage";
import TextMessage from "./chat/messages/TextMessage";
import { ChatFooterRef } from "./ChatFooter";

const TypeReplyOverlay = ({
  isOnOverlay,
  chatFooterRef,
}: {
  isOnOverlay?: boolean;
  chatFooterRef: React.RefObject<ChatFooterRef | null>;
}) => {
  const [fallbackToGenericFile, setFallbackToGenericFile] = useState(false);
  const contacts = useContacts();
  const [reply, setReply] = useAtom(atoms.messaging.messageReply);

  useEffect(() => {
    if (!reply) return;
    setFallbackToGenericFile(false);

    chatFooterRef.current?.focus();
    if (reply && reply.type === "Edit") {
      const s = reply.message?.getText();
      if (s) chatFooterRef.current?.setText(s);
    }
  }, [reply]);

  const replyingTo = useMemo(() => {
    const from = reply?.message?.From;
    if (!from) return null;

    const contact =
      contacts?.findWithNumber(from) || WebGwContact.fromPhoneNumber(from);

    const localUser = getLocalUser();

    if (isSamePhoneNumber(localUser, contact?.getMainPhoneNumber())) {
      return "yourself";
    }

    return contact?.noNameReturnPhoneNumber();
  }, [reply]);

  const message = useMemo(
    () =>
      reply?.message
        ? parseNmsToChatMessage(reply.message, fallbackToGenericFile)
        : undefined,
    [reply?.message, fallbackToGenericFile]
  );

  let MessageComponent:
    | typeof TextMessage
    | typeof RichcardMessage
    | typeof ContactCard
    | undefined;
  if (message) {
    if (message.textMessage) {
      MessageComponent = TextMessage;
    } else if (message.richcardMessage) {
      MessageComponent = RichcardMessage;
    } else if (message.contactCard) {
      MessageComponent = ContactCard;
    }
  }

  const handleFallbackToGenericFile = () => {
    setFallbackToGenericFile(true);
  };

  const bgColor = new Color(colors.secondaryBackground).darken(0.15).toString();

  return (
    <AnimateChangeInHeight
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: isOnOverlay ? "100%" : "calc(100% - 1em * 2)",
        borderRadius: "20px 20px 0px 0px",
        // 10px to cover the border radius gap in between this and the message box
        boxShadow: `0 10px 0 0 ${bgColor}`,
        transform: "translateY(1px)",
        backgroundColor: bgColor,
        "& > div": {
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <AnimatePresence mode="popLayout">
        {reply?.message != null &&
          reply?.type != "Edit" &&
          message &&
          MessageComponent &&
          replyingTo && (
            <motion.div
              key={replyingTo}
              className="chatVars"
              dark-theme="true"
              css={{
                padding: "1em",
                width: "100%",
                // 10px to cover the border radius gap in between this and the message box
                paddingBottom: "calc(1em + 10px)",
                display: "flex",
                justifyContent: "center",
                gap: "0.5vw",
                flexDirection: "column",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div
                css={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Replying to {replyingTo}:</div>
                <IconButton onClick={() => setReply(null)}>
                  <CloseIcon css={{ color: colors.primaryTextColor }} />
                </IconButton>
              </div>

              <MessageComponent
                message={message}
                removeTopMargin
                isLastMessage={false}
                roundBorderTop
                roundBorderBottom
                direction={reply.message.Direction!}
                onFallbackToGenericFile={handleFallbackToGenericFile}
                showReplyMessage={false}
              />
            </motion.div>
          )}
      </AnimatePresence>
    </AnimateChangeInHeight>
  );
};

export default TypeReplyOverlay;
