import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "./Button";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <PrimaryButton
      onClick={(e) => {
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          navigate(paths.root);
        }
      }}
      css={{
        position: "absolute",
        top: "min(2em, 6vw)",
        left: "min(2em, 6vw)",
        textDecoration: "none",
        color: colors.primaryTextColor,
        padding: "0.5em",
      }}
    >
      Back
    </PrimaryButton>
  );
}
