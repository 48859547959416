import { colors, scrollbarWidth } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import { useChatbotDirectory } from "@/utils/hooks/useChatbotDirectory";
import { useAtom } from "jotai";
import { filterItem, FilterType } from "../../utils";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import HighlightedText from "../shared/HighlightedText";
import {
  editListItemStyle,
  ListContainer,
  ListItem,
} from "../shared/ListItem.style";

type Props = {
  searchQuery: string;
};

export default function ChatBotList({ searchQuery }: Props) {
  const [selectedChatbot, setSelectedChatbot] = useAtom(
    atoms.chatbot.selectedChatbot
  );
  const [bots] = useChatbotDirectory().data;

  return (
    <div css={{ width: `calc((100% + (8px + ${scrollbarWidth}) * 2) * 0.9)` }}>
      {bots && (
        <ListContainer
          css={{
            height: "fit-content",
            marginTop: "0.5em",
            width: "100%",
            overflowY: "hidden",
          }}
        >
          {bots.reduce((acc: React.ReactNode[], bot) => {
            if (bot.odience) {
              return acc;
            }

            const filtered = filterItem(
              bot,
              searchQuery.toLocaleLowerCase(),
              FilterType.PARTIAL,
              "name"
            );

            if (!filtered) return acc;

            const [botInfo, { nameIndex }] = filtered;

            const openChatbotMessages = () => {
              const chatbotContact = WebGwContact.fromChatbotInfo(bot);
              setSelectedChatbot(chatbotContact);
            };

            const contact = WebGwContact.fromChatbotInfo(botInfo);

            acc.push(
              <div
                css={[
                  editListItemStyle,
                  {
                    backgroundColor: selectedChatbot
                      ? contact.id === selectedChatbot.id
                        ? colors.secondaryBackground
                        : "inherit"
                      : "inherit",
                  },
                ]}
                key={bot.id}
              >
                <ListItem onClick={openChatbotMessages}>
                  <Avatar contact={contact} />
                  <HighlightedText
                    text={botInfo.name}
                    highlightIndex={nameIndex}
                    searchQueryLength={searchQuery.length}
                  />
                </ListItem>
              </div>
            );

            return acc;
          }, [])}
        </ListContainer>
      )}
    </div>
  );
}
