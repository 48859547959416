import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = styled.div({
  width: "90%",
  height: "85%",
});

export const SettingTitles = css({
  color: colors.secondaryTextColor,
  height: "5vh",
  display: "flex",
  alignItems: "center",
  fontSize: "0.75em",
});

export const DeviceCard = css({
  display: "flex",
  flexDirection: "row",
  "&:hover, &:focus": {
    color: colors.primaryAccentColor,
    fill: colors.secondaryTextColor,
    backgroundColor: colors.secondaryBackground,
  },
  transition: "color 0.25s ease",
  borderRadius: "10px",
  height: "8vh",
  alignItems: "center",
});

export const DividerLine = styled.div({
  width: "95%",
  borderBottom: "solid 1px",
  borderColor: colors.secondaryTextColor,
  display: "flex",
  alignItems: "center",
  opacity: "0.2",
});

export const DividerSpace = styled.div({
  height: "2vh",
});

export const DeviceCategoryTitles = css({
  fontSize: "0.8em",
  color: colors.secondaryTextColor,
  fontWeight: "bold",
  paddingLeft: "1em",
});

export const AvatarCSS = css({
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  overflow: "hidden",
});
