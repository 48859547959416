import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";

export const msgCss = {
  root: css({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "0 0.5em",
  }),
  message: css({
    width: "auto",
    overflowWrap: "break-word",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  }),
  in: css({
    alignItems: "start",
  }),
  lastIn: css({
    paddingBottom: "0.5em",
  }),
  out: css({
    alignItems: "end",
  }),
  richCardMediaStandalone: css({
    width: "320px",
  }),
};

export const textMessageCss = css({
  background: "var(--msg-background)",
  textShadow: "0 0 2px rgba(0, 0, 0, 0.5)",
  padding: "0.6em 0.75em",
  maxWidth: "min(64vw, var(--MAX_STANDALONE_CARD_WIDTH))",
  whiteSpace: "pre-wrap",
  ".In &": {
    background: "var(--msg-background-darker)",
  },
});

export const carouselTolerancePx = 20;

export const cardContentCss = css({
  background: "var(--msg-background)",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  ":hover": {
    willChange: "max-height",
  },
});

export const mainContentCss = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  padding: "0 0.75em",
});

export const cardCarouselCss = css({
  width: "100%",
  maxWidth: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  display: "flex",
  flexWrap: "nowrap",
  gap: "0.5rem",
  // maxHeight: "var(--MAX_STANDALONE_CARD_HEIGHT)",
  "--scrollbar-alpha": "0",
  "::-webkit-scrollbar": {
    height: "6px",
  },
  "::-webkit-scrollbar-track": {
    margin: `0 calc(var(--scrollbar-offset,0) * -1)`,
  },
});

export const msgContentCss = css({
  width: "fit-content",
  lineHeight: "1",
  marginTop: "0.25em",
  transition:
    "background var(--t), border-radius var(--t), margin-top var(--t)",
});

const shrinkableTextCss = css({
  // multiline ellipsis setup
  display: "-webkit-inline-box",
  WebkitBoxOrient: "vertical",

  "& a": {
    color: colors.primaryAccentColor,
    ":visited": {
      color: colors.primaryAccentColorDarker,
    },
  },
});

const shrinkTextCss = css({
  cursor: "pointer",
  // enable multiline ellipsis
  textOverflow: "ellipsis",
  overflow: "hidden",
  WebkitLineClamp: "2",
});

export const titleCss = {
  main: css([
    {
      whiteSpace: "pre-wrap",
      maxWidth: "100%",
      height: "auto",
      lineHeight: "1.25",
      // ! using margin instead of padding since the next line can still be seen when using the multiline ellipsis css
      marginTop: "0.5em",
      marginBottom: "0.5em",
    },
    shrinkableTextCss,
  ]),
  shrink: shrinkTextCss,
};

export const descriptionCss = {
  main: css([
    {
      whiteSpace: "pre-wrap",
      lineHeight: "1.25",
    },
    shrinkableTextCss,
  ]),
  noTitle: css({
    marginTop: "0.5em",
  }),
  noSuggestions: css({
    marginBottom: "0.5em",
  }),
  shrink: css([
    shrinkTextCss,
    {
      WebkitLineClamp: "3",
    },
  ]),
};

export const roundBorderCss = {
  init: css({
    borderRadius: "6px",
  }),
  topLeft: css({
    borderTopLeftRadius: "12px",
  }),
  bottomLeft: css({
    borderBottomLeftRadius: "12px",
  }),
  topRight: css({
    borderTopRightRadius: "12px",
  }),
  bottomRight: css({
    borderBottomRightRadius: "12px",
  }),
  topSpacing: css({
    marginTop: "0.5rem",
  }),
};
