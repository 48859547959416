import WebGwContact, { WebGwContactList } from "../helpers/WebGwContact";
import { usePrevious } from "./usePrevious";

export function useContactsChanged(
  savedContacts: WebGwContactList
): WebGwContact | null {
  const prevContacts = usePrevious(savedContacts);

  for (const contact of savedContacts) {
    const prevContact = prevContacts?.find((prev) => prev.id === contact.id);

    if (!prevContact) {
      return contact;
    }
  }

  return null;
}
