import {
  ChatMessagePayload,
  Reply,
  SendMessageResult,
} from "@/types/messaging";
import toast from "react-hot-toast";
import { baseNMSUrl, baseWebGwUrl } from "..";
import { SuggestionResponse } from "../../components/chatScreen/chat/typings";
import { ChatbotPayload } from "../../components/chatScreen/chat/typings/gsma";
import { ImdnInfo } from "../../types/OmaNms";
import { getConfig } from "../helpers/config";
import { formatPhoneNumber } from "../helpers/formatPhoneNumber";
import { getLocalAccessToken, getLocalUser } from "../helpers/localstorage";
import {
  pauseNmsNotifications,
  resumeNmsNotifications,
} from "../helpers/nmsWebsocket";
import {
  getNmsLoggedIn,
  removeNmsLoggedIn,
  setNmsLoggedIn as setNmsLoggedInKey,
} from "../helpers/sessionStorage";
import { handleReloginMechanic } from "../hooks/useWebgwSubscription";
import { generateLocationPayload } from "../map";
import {
  REGEX_CONTRIBUTION_ID,
  cleanPhoneNumber,
  getTextAfterLastSlash,
  isPhoneNumberAGroupContributionId,
} from "./conversation/conversationUtils/phoneNumberUtils";

export async function nmsLogin(encodedPhoneNumber: string) {
  const config = await getConfig();
  if (!config) {
    console.error("Undefined config");
    return;
  }

  console.debug("Unauthorized. Logging into nms with config", config);
  // nms login
  const loginRes = await fetch(
    new URL(`/nms/v1/store/${encodedPhoneNumber}/login`, baseNMSUrl),
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: config["application/3gpp_ims/ext/gsma/username"],
        password: config["application/3gpp_ims/ext/gsma/userpwd"],
      }),
    }
  );
  console.log("login:", loginRes.ok ? await loginRes.json() : loginRes.ok);
  return loginRes;
}

export async function nmsLogout(encodedPhoneNumber: string): Promise<boolean> {
  try {
    const logoutRes = await fetch(
      new URL(`/nms/v1/store/${encodedPhoneNumber}/logout`, baseNMSUrl),
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        referrerPolicy: "origin-when-cross-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    );

    if (logoutRes.ok) {
      setNmsLoggedIn(false);
    }

    return logoutRes.ok;
  } catch (error) {
    console.error("Error during logout:", error);
    return false;
  }
}

export const isNmsLoggedIn = () => !!getNmsLoggedIn();
export const setNmsLoggedIn = (loggedIn: boolean) => {
  if (loggedIn) setNmsLoggedInKey("1");
  else removeNmsLoggedIn();
};
type OneToOneRes = {
  resourceReference: {
    resourceURL: string; // "https://sbc.erl.rcs.st:8443/chat/v1/+15145550080/oneToOne/sip:+15145550081@erl.rcs.st/adhoc/messages/zukloejoh3mu5gqw"
  };
};

export async function sendDelete(type: string, to: string) {
  const user = getLocalUser();
  if (!user) {
    console.error("No user logged in");
    return;
  }
  const accessToken = getLocalAccessToken();
  if (!accessToken) {
    console.error("No access token");
    return;
  }
  const config = await getConfig();
  if (!config) {
    console.error("No config");
    return;
  }
  const res = await fetch(
    new URL(
      `/chat/v1/${cleanPhoneNumber(user)}/${type}/${await formatPhoneNumber(
        to,
        "SIP"
      )}?access_token=${accessToken}`,
      baseWebGwUrl
    ),
    {
      method: "DELETE",
      cache: "no-store",
    }
  );

  if (!res.ok) {
    console.error("Failed to delete", type, res);
    return false;
  }

  return true;
}

type SendMessageType = {
  chatMessage: ChatMessagePayload;
  to: string;
  isGroupChat?: boolean;
  groupChatSubject?: string;
  groupChatIconUrl?: string;
  conferenceUri?: string;
  reply?: Reply;
};

export async function sendConversationMessage({
  chatMessage,
  to,
  isGroupChat = false,
  groupChatSubject = "",
  groupChatIconUrl = "",
  conferenceUri = "",
  reply,
}: SendMessageType) {
  try {
    /**
     * Under slow connectivity, nms events can come too fast before callers have time to save message in database.
     * Pause nms notifications here, and re-enable them at the end of the process
     */
    pauseNmsNotifications();

    const messageRes = await sendMessage({
      chatMessage,
      to,
      isGroupChat,
      groupChatSubject,
      groupChatIconUrl,
      conferenceUri,
      reply,
    });

    if (!messageRes) {
      return;
    }
    const resourceUrl = ((await messageRes.json()) as OneToOneRes)
      .resourceReference.resourceURL;

    return {
      messageId: getTextAfterLastSlash(resourceUrl),
      contributionId: getContributionIdFromMessageResourceUrl(
        resourceUrl,
        false
      ),
    };
  } finally {
    // Queue the resume allowing it to be run out of the flow, when sync code from callers finishes (leaving time for the database insertion);
    queueMicrotask(() => {
      resumeNmsNotifications();
    });
  }
}

export async function sendMessage({
  chatMessage,
  to,
  isGroupChat = false,
  groupChatSubject = "",
  groupChatIconUrl = "",
  conferenceUri = "",
  reply,
}: SendMessageType) {
  const user = getLocalUser();
  if (!user) {
    console.error("No user logged in");
    return;
  }
  const accessToken = getLocalAccessToken();
  if (!accessToken) {
    console.error("No access token");
    return;
  }
  const config = await getConfig();
  if (!config) {
    console.error("No config");
    return;
  }
  let errorMessage: string | undefined;
  try {
    const res = await fetch(
      new URL(
        `/chat/v1/${cleanPhoneNumber(user)}/oneToOne/${
          isGroupChat ? to : await formatPhoneNumber(to, "SIP")
        }/adhoc/messages?access_token=${accessToken}`,
        baseWebGwUrl
      ),
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          ...(isGroupChat && { subject: groupChatSubject }),
          ...(isGroupChat && groupChatIconUrl && { icon: groupChatIconUrl }),
          ...(!!(isGroupChat && conferenceUri) && {
            confuri: `<${conferenceUri}>`,
          }),
          ...(reply && {
            "rcs.Reference-ID": String(reply.id),
            "rcs.Reference-Type": String(reply.type),
          }),
        },
        body: JSON.stringify(chatMessage),
      }
    );

    if (!res.ok) {
      if (res?.status === 403 || res?.status === 500) {
        console.log("Lost connectivity, reconnecting...");
        void handleReloginMechanic(false);
      }
      errorMessage = "Failed to send message. Status: " + res.status;
    } else {
      console.log("SENT MESSAGE RESULT", res);
      return res;
    }
  } catch (e: any) {
    errorMessage = "Error while sending message" + e;
  }

  toast.error(errorMessage);
  console.error(errorMessage);
}

export async function sendTextMessage(
  message: string,
  to: string,
  bot = false,
  isGroupChat = false,
  groupChatSubject = "",
  groupChatIconUrl = "",
  conferenceUri = "",
  reply?: Reply
): Promise<SendMessageResult | undefined> {
  return await sendConversationMessage({
    chatMessage: {
      chatMessage: {
        text: message,
        contentType: bot
          ? "application/vnd.gsma.botsuggestion.response.v1.0+json"
          : "text/plain;charset=UTF-8",
        reportRequest: ["sent", "Delivered", "Displayed"],
        referenceId: reply?.id,
        referenceType: reply?.type,
      },
    },
    to,
    isGroupChat,
    groupChatSubject,
    groupChatIconUrl,
    conferenceUri,
    reply,
  });
}

export async function sendFile(
  xmlPayload: string,
  to: string,
  isGroupChat = false,
  groupChatSubject = "",
  groupChatIconUrl = "",
  conferenceUri = "",
  reply?: Reply
): Promise<SendMessageResult | undefined> {
  return await sendConversationMessage({
    chatMessage: {
      chatMessage: {
        text: xmlPayload,
        contentType: "application/vnd.gsma.rcs-ft-http+xml",
        reportRequest: ["sent", "Delivered", "Displayed"],
        referenceId: reply?.id,
        referenceType: reply?.type,
      },
    },
    to,
    isGroupChat,
    groupChatSubject,
    groupChatIconUrl,
    conferenceUri,
    reply,
  });
}

export async function sendLocation(
  to: string,
  latitude: number,
  longitude: number,
  isGroupChat: boolean = false,
  groupChatSubject = "",
  groupChatIconUrl = "",
  conferenceUri = "",
  reply?: Reply
): Promise<SendMessageResult | undefined> {
  return await sendConversationMessage({
    chatMessage: {
      chatMessage: {
        text: generateLocationPayload(to, latitude, longitude),
        contentType: "application/vnd.gsma.rcspushlocation+xml",
        reportRequest: ["sent", "Delivered", "Displayed"],
        referenceId: reply?.id,
        referenceType: reply?.type,
      },
    },
    to,
    isGroupChat,
    groupChatSubject,
    groupChatIconUrl,
    conferenceUri,
    reply,
  });
}

const clearTypingAfterMs = 3000;
const typingMap = new Map<string, { number: number; conferenceUri?: string }>();

window.addEventListener("beforeunload", () => {
  for (const to of typingMap.keys()) {
    void sendIsTyping(false, to, isPhoneNumberAGroupContributionId(to));
  }
});

function setRemoveIsTypingAfterTimeout(to: string, conferenceUri?: string) {
  clearTimeout(typingMap.get(to)?.number);

  const t = +setTimeout(() => {
    sendIsTyping(
      false,
      to,
      isPhoneNumberAGroupContributionId(to),
      conferenceUri
    );
  }, clearTypingAfterMs);

  typingMap.set(to, { number: t, conferenceUri });
}

export async function sendIsTyping(
  typing: boolean,
  to: string,
  isGroupChat: boolean = false,
  conferenceUri = ""
) {
  let p: Promise<unknown> | undefined;

  if (typing) {
    const sent =
      typingMap.has(to) ||
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      !!(p = sendMessage({
        chatMessage: {
          isComposing: {
            state: "active",
            contenttype: "",
            refresh: 60,
          },
        },
        to,
        isGroupChat,
        conferenceUri,
      }));
    if (sent) {
      setRemoveIsTypingAfterTimeout(to, conferenceUri);
    }
  } else {
    const sent =
      !typingMap.has(to) ||
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      !!(p = sendMessage({
        chatMessage: {
          isComposing: {
            state: "inactive",
            contenttype: "",
            refresh: 60,
          },
        },
        to,
        isGroupChat,
        conferenceUri,
      }));
    if (sent) {
      typingMap.delete(to);
    }
  }

  await p;
}

export async function sendMessageStatus(
  sender: string,
  messageId: string,
  status: ImdnInfo["type"]
) {
  const phoneNumber = getLocalUser();
  if (!phoneNumber) {
    console.error("No phone number provided");
    return;
  }
  const accessToken = getLocalAccessToken();
  if (!accessToken) {
    console.error("No access token");
    return;
  }
  const config = await getConfig();
  if (!config) {
    console.error("No config");
    return;
  }
  const res = await fetch(
    new URL(
      `/chat/v1/${phoneNumber}/oneToOne/${await formatPhoneNumber(
        sender,
        "SIP"
      )}/adhoc/messages/${messageId}/status?access_token=${accessToken}`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        messageStatusReport: {
          status: status,
        },
      }),
    }
  ).catch((e) => {
    console.error(e);
    return null;
  });
  return res?.ok ?? false;
}

export function getTextFromSuggestionResponse(obj: SuggestionResponse) {
  if ("reply" in obj.response) {
    return obj.response.reply.displayText;
  } else {
    return obj.response.action.displayText;
  }
}

export function getTextFromRichCard(obj: ChatbotPayload) {
  if (obj.message) {
    if (obj.message.generalPurposeCard) {
      return (
        obj.message.generalPurposeCard.content.title ||
        obj.message.generalPurposeCard.content.description
      );
    } else {
      return (
        obj.message.generalPurposeCardCarousel.content[0].title ||
        obj.message.generalPurposeCardCarousel.content[0].description
      );
    }
  }
  return "<richcard>";
}

export async function addPayloadPartToNmsObject(
  objectId: string,
  payload: any
) {
  const phoneNumber = getLocalUser();
  if (!phoneNumber) {
    console.error("No phone number provided");
    return;
  }
  const encodedPhoneNumber = encodeURIComponent("tel:" + phoneNumber);
  const res = await fetch(
    new URL(
      `/nms/v1/store/${encodedPhoneNumber}/objects/${objectId}/payloadParts`,
      baseNMSUrl
    ),
    {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  ).catch((e) => {
    console.error(e);
    return null;
  });
  return res?.ok ?? false;
}

export async function getMaxFileSizeAllowed() {
  const config = await getConfig();

  if (!config) {
    console.error("null config!");
    return 10000;
  }

  return parseInt(config["application/messaging/filetransfer/maxsizefiletr"]);
}

export function getContributionIdFromMessageResourceUrl(
  resourceUrl: string,
  isIncoming: boolean
): string | undefined {
  const start = isIncoming ? "v1" : "oneToOne";
  const end = isIncoming ? "oneToOne" : "adhoc";

  return resourceUrl
    .match(new RegExp(start + "/" + REGEX_CONTRIBUTION_ID + "/" + end))
    ?.at(1);
}

export function getRemoteInfosFromImdnResourceUrl(resourceUrl: string) {
  const contributionId = resourceUrl
    .match(new RegExp("groupchat/" + REGEX_CONTRIBUTION_ID + "/adhoc"))
    ?.at(1);

  const fromPhoneNumber = resourceUrl
    .match(new RegExp("v1/(.*)/" + (contributionId ? "groupchat" : "oneToOne")))
    ?.at(1);

  if (!fromPhoneNumber) {
    return;
  }

  return {
    contributionId,
    phoneNumber: cleanPhoneNumber(fromPhoneNumber),
    messageId: getTextAfterLastSlash(resourceUrl),
  };
}
