import ReconnectingLottie from "@/assets/lottie/Reconnecting.json";
import Lottie from "lottie-react";

const ReconnectingAnimationLottie = () => {
  return (
    <Lottie
      css={{ height: "5vh" }}
      animationData={ReconnectingLottie}
      loop={true}
    />
  );
};

export default ReconnectingAnimationLottie;
