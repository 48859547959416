import { colors } from "@/styles/global.styles";
import { isMobile } from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import { useState } from "react";
import { scaleAnimation } from "../EventStream.style";

type StreamInvitePopupProps = {};

const StreamInvitePopup = ({}: StreamInvitePopupProps) => {
  const [showInvitePopup, setShowInvitePopup] = useState(false);

  //TODO Implement invite accept and decline logic here
  const declineInvite = () => {
    setShowInvitePopup(false);
  };

  const acceptInvite = () => {};

  return (
    !isMobile() &&
    showInvitePopup && (
      <div
        css={{
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "25em",
          gap: "0.6em",
          height: "6em",
          borderRadius: "10px",
          background: "rgba(30, 30, 30, 0.9)",
          transition: "all 0.2s ease",
          color: colors.primaryTextColor,
        }}
      >
        <img
          src="/odience/web_client/Star_1.svg"
          alt=""
          css={{
            position: "absolute",
            userSelect: "none",
            width: "3.75em",
            height: "3.75em",
            top: "-4.4em",
            left: "2em",
            animation: `${scaleAnimation} 1s ease-in-out infinite alternate`,
          }}
        />
        <img
          src="/odience/web_client/Star_3.svg"
          alt=""
          css={{ width: "2em", height: "2em", top: "6em", left: "2.5em" }}
        />
        <div className="inviteModalIcon">
          <img
            src="/odience/presentor_menu_icons/icon_front_row.svg"
            alt=""
            css={{ width: "2em", height: "2em" }}
          />
        </div>
        <div
          css={{
            width: "10em",
            display: "flex",
            flexDirection: "column",
            gap: "0.3em",
          }}
        >
          <div css={{ fontWeight: "bold" }}>Front Row Invitation</div>
          <div css={{ fontSize: "0.75rem", opacity: 0.5 }}>
            Move to the Front Row for a VIP experience!
          </div>
        </div>
        <button onClick={declineInvite} css={inviteButtonStyle}>
          <img
            src="/odience/event/white-close.svg"
            alt="Decline Invite"
            css={{ width: "1.25em", height: "1.25em" }}
          />
        </button>
        <button
          onClick={acceptInvite}
          css={[
            inviteButtonStyle,
            { backgroundColor: colors.secondaryAccentColor, border: "none" },
          ]}
        >
          <img
            src="/odience/event/white-check.svg"
            alt="Accept Invite"
            css={{ width: "1.25em", height: "1.25em" }}
          />
        </button>
      </div>
    )
  );
};

export default StreamInvitePopup;

const inviteButtonStyle = css({
  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  borderRadius: "10px",
  boxSizing: "border-box",
  justifyContent: "center",
  alignItems: "center",
  width: "3.75em",
  height: "3.75em",
  border: "0.3rem solid rgba(255, 255, 255, 0.3)",
});
