import { colors } from "@/styles/global.styles";
import { convertDateToHumanReadable } from "@/utils/helpers/time";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { formatFileSizeToHumanReadable } from "@/utils/messaging/conversation/conversationUtils";
import NmsMessage from "@/utils/messaging/NmsMessage";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const MessageDetails = ({
  conversation,
  message,
  onClose,
}: {
  conversation: Conversation;
  message: NmsMessage;
  onClose: VoidFunction;
}) => {
  const maxHistory = 4;
  const history = [...message.history, message];
  const [showAllHistory, setShowAllHistory] = useState(
    message.history.length < maxHistory
  );

  if (history.length > 1) {
    // History only contains raw messages, convert them to nms prototype (except latest one which is already good - current nms message)
    for (let i = 0; i < history.length - 1; i++) {
      history[i] = NmsMessage.from(message.history[i]);
    }
  }

  const getMessageContent = (message: NmsMessage) => {
    return message.getText() || message.FileName || "Media";
  };

  return (
    <>
      <div css={mainCss}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ textAlign: "start", fontSize: "1.3em" }}>
            Message Details
          </div>
          <button style={{ textAlign: "end" }} onClick={onClose}>
            <CloseIcon style={{ fontSize: "2em" }} />
          </button>
        </div>
        <div css={detailsCss}>
          <div css={dividerCss} />
          <div css={sectionCss}>
            <div css={twoSectionCss}>
              <h3>ORIGINAL MESSAGE</h3>
              <h3>TIME {message.Direction === "Out" ? "SENT" : "RECEIVED"}</h3>
            </div>
            <div css={twoSectionCss}>
              <div>{getMessageContent(history[0])}</div>
              <div>{convertDateToHumanReadable(history[0].Date)}</div>
            </div>
          </div>
          {history.length > 1 && (
            <div css={sectionCss}>
              <h3>EDIT HISTORY</h3>
              <div>
                {/* Display history except original message (already above) + current message (latest edit)*/}
                {history
                  .slice(1, showAllHistory ? history.length : maxHistory)
                  .map((history) => {
                    return (
                      <div key={history["imdn.Message-ID"]} css={twoSectionCss}>
                        <div>{getMessageContent(history)}</div>
                        <div>{convertDateToHumanReadable(history.Date)}</div>
                      </div>
                    );
                  })}

                {!showAllHistory && history.length > maxHistory && (
                  <div css={twoSectionCss}>
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowAllHistory(true)}
                    >
                      View All
                    </h3>
                  </div>
                )}
              </div>
            </div>
          )}
          <div css={sectionCss}>
            <div css={twoSectionCss}>
              <h3>{message.Direction === "Out" ? "TO" : "FROM"}</h3>
              <h3>CONVERSATION TYPE</h3>
            </div>
            <div css={twoSectionCss}>
              <div>{conversation.getName(true)}</div>
              <div>
                {conversation.getIsGroupChat()
                  ? "Group chat"
                  : "One to one chat"}
              </div>
            </div>
          </div>
          <div css={sectionCss}>
            <div css={twoSectionCss}>
              <h3>SIZE</h3>
              <h3>TYPE</h3>
            </div>
            <div css={twoSectionCss}>
              <div>
                {formatFileSizeToHumanReadable(
                  message.payloadParts.find(
                    (payload) => payload.contentDisposition === "file"
                  )?.size || message.getText()?.length
                )}
              </div>
              <div>{message.getText() ? "Text" : "Media"}</div>
            </div>
          </div>
          <div css={sectionCss}>
            <div css={twoSectionCss}>
              <h3>MESSAGE SOURCE</h3>
              <h3>UUID</h3>
            </div>
            <div css={twoSectionCss}>
              <div>-</div>
              <div>{message["imdn.Message-ID"]}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageDetails;

const mainCss = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "65%",
  height: "fit-content",
  flexDirection: "column",
  borderRadius: "20px",
  backgroundColor: colors.primaryBackground,
  padding: "2em",
  maxHeight: "65%",
});

const detailsCss = css({
  marginTop: "1em",
  width: "100%",
  fontSize: "0.9em",
  position: "relative",
  overflow: "hidden",
  overflowY: "auto",
});

const sectionCss = css({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "0.5em",
  "& > h3": {
    fontSize: "0.7em",
    color: colors.secondaryTextColor,
  },
});

const twoSectionCss = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "5px",
  "& > h3": {
    fontSize: "0.7em",
    color: colors.secondaryTextColor,
  },
  "& > div:nth-of-type(1)": {
    width: "45%",
    maxHeight: "6vh",
    overflow: "auto",
    wordWrap: "break-word",
  },
  "& > div:nth-of-type(2)": {
    width: "45%",
  },
  "& > h3:nth-of-type(1)": {
    width: "45%",
  },
  "& > h3:nth-of-type(2)": {
    width: "45%",
  },
});

const dividerCss = css({
  position: "absolute",
  width: "1px",
  backgroundColor: colors.secondaryTextColor,
  height: "100%",
  left: "50%",
  transform: "translate(-50%)",
});
