import { isOverflownX } from "@/components/chatScreen/chat/util/cardUtils";
import { MotionValue, useMotionValueEvent, useSpring } from "motion/react";
import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";

export default function useDragScrollInertia(
  scrollElem: RefObject<HTMLElement>,
  isDraggingRef: RefObject<boolean>,
  tolerance: number,
  isLastMessage: boolean,
  options: {
    direction?: "x" | "y";
    onDragStart?: (e: MouseEvent) => void;
    onDragEnd?: (e: MouseEvent) => void;
  } = {}
) {
  options.direction ||= "x";

  const maxScrollAbsoluteRef = useRef(0);

  const defaultDuration = 300;
  const slowDuration = 1500;
  const scrollDurationRef = useRef(defaultDuration);
  const scroll: MotionValue<number> = useSpring(0, {
    get duration() {
      return scrollDurationRef.current;
    },
    bounce: 0,
  });

  let moving = false;

  const moveToBound = (updatePosition = false, jump = false) => {
    if (moving) return;
    moving = true;
    setTimeout(() => {
      moving = false;
    });

    if (updatePosition) {
      scroll.updateAndNotify(scrollElem.current.scrollLeft, false);
    }
    const currentScroll = scroll.get();
    const maxScrollAbsolute = maxScrollAbsoluteRef.current;
    const maxScroll = maxScrollAbsolute - tolerance;
    if (currentScroll < tolerance) {
      if (jump) scroll.jump(tolerance);
      else scroll.set(tolerance);
    } else if (currentScroll > maxScroll) {
      if (jump) scroll.jump(maxScroll);
      else scroll.set(maxScroll);
    }
  };
  const jumpToBound = useCallback(() => moveToBound(true, true), []);

  const t = useRef<number>(undefined);

  useMotionValueEvent(scroll, "change", (newScroll) => {
    const maxScrollAbsolute = maxScrollAbsoluteRef.current;
    if (newScroll < 0) {
      scroll.jump(0);
    } else if (newScroll > maxScrollAbsolute) {
      scroll.jump(maxScrollAbsolute);
    }

    const maxScroll = maxScrollAbsolute - tolerance;
    const prevScroll = scroll.getPrevious() || 0;
    if (
      (newScroll < tolerance && newScroll < prevScroll) ||
      (newScroll > maxScroll && newScroll > prevScroll)
    ) {
      // lower move speed when out of bounds
      scrollDurationRef.current = slowDuration;
    } else {
      scrollDurationRef.current = defaultDuration;
    }

    if (scroll.getVelocity() === 0 && !isDraggingRef.current) {
      moveToBound();
    }
    clearTimeout(t.current);
    t.current = +setTimeout(() => {
      if (scroll.getVelocity() === 0 && !isDraggingRef.current) {
        moveToBound();
      }
    }, 500);

    if (!scrollElem.current) return;

    if (options.direction === "x") {
      scrollElem.current.scrollLeft = newScroll;
    } else {
      scrollElem.current.scrollTop = newScroll;
    }
  });

  const startX = useRef(0);
  const startScrollLeft = useRef(0);

  const isMouseWheelMovingRef = useRef({
    value: 0,
    timeout: undefined as number | undefined,
  });

  const paddingAdded = useRef(false);
  const doesOverflow = useRef(false);

  useLayoutEffect(() => {
    // this is in a microtask to ensure that the padding properties are reset (by the cleanup function) before being set again (in this hook)

    const setPadding = () => {
      if (!scrollElem.current || !scrollElem.current.lastElementChild) return;

      if (paddingAdded.current) return;
      paddingAdded.current = true;

      doesOverflow.current = isOverflownX(
        scrollElem.current.lastElementChild,
        scrollElem.current
      );
      if (!doesOverflow.current) return;

      // this isn't really in the right place for this since it doesn't relate to this hook's purpose
      scrollElem.current.style.paddingBottom = "6px";
      if (isLastMessage) scrollElem.current.style.marginBottom = "-6px";

      // add padding to the sides for an elastic effect if the content overflows
      if (options.direction === "x") {
        scrollElem.current.style.paddingLeft = `${
          (parseFloat(scrollElem.current.style.paddingLeft) || 0) + tolerance
        }px`;
        scrollElem.current.style.paddingRight = `${
          (parseFloat(scrollElem.current.style.paddingRight) || 0) + tolerance
        }px`;
      } else {
        scrollElem.current.style.paddingTop = `${
          (parseFloat(scrollElem.current.style.paddingTop) || 0) + tolerance
        }px`;
        scrollElem.current.style.paddingBottom = `${
          (parseFloat(scrollElem.current.style.paddingBottom) || 0) + tolerance
        }px`;
      }

      maxScrollAbsoluteRef.current =
        scrollElem.current.scrollWidth - scrollElem.current.clientWidth;
      scroll.jump(tolerance);
    };

    const reset = () => {
      if (!scrollElem.current) return;

      scrollElem.current.style.paddingBottom = "";
      if (isLastMessage) scrollElem.current.style.marginBottom = "";
      if (options.direction === "x") {
        scrollElem.current.style.paddingLeft = "";
        scrollElem.current.style.paddingRight = "";
      } else {
        scrollElem.current.style.paddingTop = "";
      }

      paddingAdded.current = false;
    };

    setPadding();

    const resetAndSetPadding = () => {
      reset();
      setPadding();

      moveToBound(true);
    };
    window.addEventListener("resize", resetAndSetPadding);

    return () => {
      reset();
      window.removeEventListener("resize", resetAndSetPadding);
    };
  }, [scrollElem.current, isLastMessage]);

  useEffect(() => {
    if (!scrollElem.current) return;

    // add event listeners

    const onMouseMove = (e: MouseEvent) => {
      e.stopPropagation();

      if (!doesOverflow.current) return;

      // stop dragging if the mouse is released or leaves the window
      if (e.buttons === 0 || e.clientX < 0 || e.clientX > window.innerWidth) {
        stopDragging(e);
        return;
      }

      const deltaX = e.clientX - startX.current;
      // start dragging if the mouse is moved more than 10px from the start and is not already dragging
      if (
        Math.abs(deltaX) <= 10 &&
        !scrollElem.current.classList.contains("dragging")
      )
        return;

      // drag
      scroll.set(startScrollLeft.current - deltaX);

      isDraggingRef.current = true;
      if (scrollElem.current.classList.contains("dragging")) {
        options.onDragStart?.(e);
      } else {
        scrollElem.current.classList.add("dragging");
      }
    };

    const stopDragging = (e: MouseEvent) => {
      e?.stopPropagation();

      moveToBound(true);

      document.removeEventListener("mousemove", onMouseMove);
      // this is in a setTimeout to put it at the end of the event queue
      // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
      setTimeout(() => {
        isDraggingRef.current = false;
      });
      scrollElem.current.classList.remove("dragging");
    };

    const onWheelHandler = (e: WheelEvent) => {
      if (!doesOverflow.current || !e.shiftKey) return;

      // reset the scrollX velocity if the user scrolls (or switches scrolling directions - this is where the problem arises)
      // while the carousel scroll is still being animated
      if (Math.round(Math.abs(scroll.getVelocity())) > 0) {
        scroll.jump(scroll.get());
      }

      const value = e.deltaX > 0 ? 1 : -1; // 1 for right, -1 for left
      isMouseWheelMovingRef.current.value = value;
      clearTimeout(isMouseWheelMovingRef.current.timeout);
      isMouseWheelMovingRef.current.timeout = +setTimeout(() => {
        isMouseWheelMovingRef.current.value = 0;

        if (scroll.getVelocity() === 0 && !isDraggingRef.current) {
          moveToBound(true);
        }
      }, 100);
    };

    const onMouseDownHandler = (e: MouseEvent) => {
      e.stopPropagation();
      // When on a text block, prevent text selection over the full dom which causes issue with the scroll
      e.preventDefault();

      if (!doesOverflow.current) return;

      startX.current = e.clientX;
      startScrollLeft.current = scrollElem.current.scrollLeft;

      // eslint-disable-next-line @eslint-react/web-api/no-leaked-event-listener
      document.addEventListener("mousemove", onMouseMove);
    };

    const onMouseUpHandler = (e: MouseEvent) => {
      stopDragging(e);

      options.onDragEnd?.(e);
    };

    scrollElem.current.addEventListener("wheel", onWheelHandler);
    scrollElem.current.addEventListener("mousedown", onMouseDownHandler);
    scrollElem.current.addEventListener("mouseup", onMouseUpHandler);

    return () => {
      if (!scrollElem.current) return;
      scrollElem.current.removeEventListener("wheel", onWheelHandler);
      scrollElem.current.removeEventListener("mousedown", onMouseDownHandler);
      scrollElem.current.removeEventListener("mouseup", onMouseUpHandler);
    };
  }, [scrollElem.current]);

  return { jumpToBound };
}
