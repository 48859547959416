import { colors } from "@/styles/global.styles";
import AddCircle from "@mui/icons-material/AddCircle";
import { Box } from "../layout/Layout.style";

const ListExistingGroupChats = ({
  onStartGroupConversation,
}: {
  onStartGroupConversation: () => void;
}) => {
  return (
    <Box
      style={{
        width: "100%",
        backgroundColor: colors.secondaryBackground,
        flexDirection: "row",
        justifyContent: "left",
        padding: "1em",
        color: colors.primaryTextColor,
        cursor: "pointer",
      }}
      onClick={onStartGroupConversation}
    >
      <AddCircle
        css={{
          color: colors.primaryAccentColor,
          marginLeft: "0.5em",
          marginRight: "0.5em",
        }}
      />{" "}
      <div>Start new group chat</div>
      <div style={{ flex: "1", textAlign: "right", marginRight: "0.5em" }}>
        &#62;
      </div>
    </Box>
  );
};

export default ListExistingGroupChats;
