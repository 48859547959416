import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const RootWrapper = styled.div({
  height: "100vh",
  width: "100vw",
  padding: "5vh 3vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "400px",
  minWidth: "500px",
});

export const Root = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "1vw",
});

export const Box = styled.div({
  overflow: "hidden",
  backgroundColor: colors.primaryBackground,
  width: "45%",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  paddingTop: "3%",
  paddingBottom: "3%",
  alignItems: "center",
});

export const mainLogo = css({
  height: "auto",
  width: "3.9vw",
  maxWidth: "50px",
  overflow: "visible",
});

export const logo = css({
  height: "auto",
  width: "3vw",
  maxWidth: "35px",
});

export const logoColored = css([
  logo,
  {
    color: colors.secondaryTextColor,
    ".active &": {
      color: colors.primaryAccentColor,
    },
    "&:hover, &:focus, .navLink:hover &, .navLink:focus &, .navLink .logoWrapper:hover &, .navLink .logoWrapper:focus &":
      {
        color: colors.primaryAccentColor,
      },
    transition: "color 0.25s ease",
    minWidth: "32px",
    maxWidth: "32px",
  },
]);
