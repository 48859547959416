import { atoms } from "@/utils/helpers/atoms";
import { css } from "@emotion/react";
import { useAtom } from "jotai";
import { useCallback } from "react";

const WebEventsOnlyInput = () => {
  const [isChecked, setChecked] = useAtom(atoms.odience.webOnlyEvents);

  const toggleChecked = useCallback(() => {
    setChecked((prev) => !prev);
  }, [setChecked]);

  return (
    <div css={container}>
      <input
        id="webEventsCheckbox"
        name="webEventsCheckbox"
        type="checkbox"
        checked={isChecked}
        onChange={toggleChecked}
        css={checkboxStyle}
      />
      <label htmlFor="webEventsCheckbox" css={labelStyle} />
      <div css={textStyle}>Available on web</div>
    </div>
  );
};

export default WebEventsOnlyInput;

const container = css`
  display: flex;
  gap: 1vw;
  align-items: center;
`;

const checkboxStyle = css`
  display: none;
`;

const labelStyle = css`
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1.5rem 1.5rem;
  cursor: pointer;
  background-image: url("/odience/web_client/Checkbox_Unchecked.svg");
  background-repeat: no-repeat;

  input[type="checkbox"]:checked + & {
    background-image: url("/odience/web_client/Checkbox_Checked.svg");
  }
`;

const textStyle = css`
  margin-left: 0.75rem;
  color: white;
  font-size: 1.2rem;
`;
