import { useNProgress } from "@tanem/react-nprogress";
import { useMemo } from "react";
import { useNavigation } from "react-router-dom";
import { colors } from "../../styles/global.styles";

const transitionThresholdMs = 250;

export default function NavigationProgress() {
  const navigation = useNavigation();

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: navigation.state === "loading",
  });

  const startTime = useMemo(() => performance.now(), []);

  return (
    <div
      css={{
        background: `linear-gradient(${colors.primaryAccentColor}, ${colors.primaryAccentColor})`,
        position: "fixed",
        height: "2px",
        top: "0",
        transition: `width ${animationDuration}ms ease`,
      }}
      style={{
        left: "0",
        width: `calc(100vw * ${progress})`,
        ...(isFinished && {
          width: "0",
          left: "unset",
          right: "0",
          // animate out if it took over the limit to load
          transition:
            !isFinished && performance.now() - startTime > transitionThresholdMs
              ? `width 500ms ease`
              : undefined,
        }),
      }}
    ></div>
  );
}
