import heifConverterWorker from "@/utils/messaging/conversation/conversationUtils/heicConvert.worker?worker&url";
import { milliseconds } from "date-fns/milliseconds";
import type {
  HeicConvertOptions,
  HeicConvertResult,
} from "./heicConvert.worker";

const maxWorkers = Math.min(navigator.hardwareConcurrency, 4);
let workerCount = 0;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function heicConvert(
  options: HeicConvertOptions,
  signal?: AbortSignal
) {
  while (workerCount >= maxWorkers) {
    await sleep(100);
  }

  const worker = new Worker(heifConverterWorker, { type: "module" });
  workerCount++;

  worker.postMessage(options, { transfer: [options.buffer] });

  return new Promise<HeicConvertResult>((resolve, reject) => {
    const t = setTimeout(
      () => {
        reject({
          error: "Timeout",
        });
        worker.terminate();
        workerCount--;
      },
      milliseconds({ minutes: 1 })
    );

    if (signal) {
      const handleAbort = () => {
        clearTimeout(t);
        reject(signal.reason);
        worker.terminate();
        workerCount--;
      };
      if (signal.aborted) {
        handleAbort();
        return;
      }
      signal.onabort = handleAbort;
    }

    worker.onmessage = (e) => {
      clearTimeout(t);

      resolve(e.data);
      worker.terminate();
      workerCount--;
    };
  });
}
