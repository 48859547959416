
import { paths } from "@/routerPaths";
import { atoms } from "@/utils/helpers/atoms";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { displayChatScreenOverlay } from "@/utils/messaging/conversation/conversationUtils";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CallNotification from "./CallNotification";
import MessageNotification from "./MessageNotification";

/**
 * This component manages the chat and call notifications
 */
const Notifications = () => {
  const desktopNotification = useRef<Notification | undefined>(undefined);

  const handleDesktopNotification = (notification: Notification) => {
    desktopNotification.current?.close();
    desktopNotification.current = notification;
  }

  return <>
    <Messages onDesktopNotification={handleDesktopNotification} />
    <Calls onDesktopNotification={handleDesktopNotification} />
  </>;
};

const Messages = ({ onDesktopNotification }: { onDesktopNotification: (notification: Notification) => void }) => {

  const [remoteNotification, setRemoteNotification] = useAtom(
    atoms.messageNotification.remote
  );

  const overlayRemote = useAtomValue(
    atoms.messaging.overlayRemote
  );

  const [conversationNotification, setConversationNotification] = useState<Conversation | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(paths.messages)) {
      handleCloseNotification();
    }
  }, [location]);

  const handleOpenConversation = () => {
    displayChatScreenOverlay(remoteNotification);
    handleCloseNotification();
  }

  const handleCloseNotification = () => {
    setConversationNotification(undefined);
    setRemoteNotification("");
  }

  useEffect(() => {
    const conversation = remoteNotification ? Conversation.getOrCreate({ phoneNumber: remoteNotification }).conversation : null;

    if (!conversation) {
      handleCloseNotification();
      return;
    }

    // Only display notification if overlay is not already being shown for same number or tab not active
    if (!isSamePhoneNumber(remoteNotification, overlayRemote) || document.hidden) {
      setConversationNotification(conversation);
    }
    // Otherwise reset to allow useEffect trigger next time if same remote
    else {
      handleCloseNotification();
    }

  }, [remoteNotification]);

  return conversationNotification && <MessageNotification onDesktopNotification={onDesktopNotification} conversation={conversationNotification} onOpenConversation={handleOpenConversation} onClose={handleCloseNotification} />;
}

const Calls = ({ onDesktopNotification }: { onDesktopNotification: (notification: Notification) => void }) => {
  const incomingCallInfos = useAtomValue(atoms.calling.incomingCallInfos);

  return incomingCallInfos && <CallNotification onDesktopNotification={onDesktopNotification} callInfos={incomingCallInfos} />;
};

export default Notifications;
