import PopupOverlay from "@/components/shared/PopupOverlay";
import {
  isMac,
  isMobile,
  verseAndroidDesktopUrl,
  verseIOSDesktopUrl,
  verseMacDesktopUrl,
} from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import appleIosBadgeIcon from "../../../assets/app-store.svg";
import appleMacOsBadgeIcon from "../../../assets/Apple-macos-badge.svg";
import DevicesIcon from "../../../assets/DevicesIcon";
import googleBadgeIcon from "../../../assets/play-store.svg";
import IosQRcode from "../../../assets/verse_ios_qr.jpg";
import GoogleQRCode from "../../../assets/verse_play_qr.jpg";

export default function Overlay({
  toggleOverlay,
}: {
  toggleOverlay: () => void;
}) {
  const overlayRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(overlayRef as React.RefObject<HTMLElement>, () => {
    toggleOverlay();
  });

  return (
    <PopupOverlay>
      <div
        ref={overlayRef}
        id="getTheAppPopUpContainer"
        className={isMobile() ? "mobilePopUp" : "desktopPopUp"}
        css={{
          width: "fit-content",
          height: "fit-content",
          backgroundColor: "#1A2026",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: "white",
          padding: "2.5vw",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h3 css={{ margin: "1em 0", fontWeight: "bold", fontSize: "1.4rem" }}>
            Download the app
          </h3>
          <button
            css={{
              background: "none",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleOverlay}
          >
            <CloseIcon />
          </button>
        </div>

        <div
          css={{
            width: "100%",
            alignItems: "center",
            gap: "2vh",
          }}
        >
          <div
            css={{
              width: "75%",
              display: "flex",
              flexDirection: "row",
              gap: "1vh",
              alignItems: "center",
            }}
          >
            <div>
              <DevicesIcon css={{ height: "2em", opacity: "0.5" }} />
            </div>

            <div>
              Get Verse for
              {isMac() ? " mobile, tablet or mac " : " mobile or tablet "}
              from the app stores
            </div>
          </div>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "1vh",
          }}
        >
          {isMac() && (
            <div>
              <a
                href={verseMacDesktopUrl}
                target="_blank"
                css={{ display: "inline-block", marginRight: "1em" }}
                rel="noreferrer"
              >
                <img src={appleMacOsBadgeIcon} css={BadgeStyles} />
              </a>
            </div>
          )}
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "1vh",
              alignItems: "center",
            }}
          >
            <a
              href={verseIOSDesktopUrl}
              target="_blank"
              css={{ marginRight: "1em" }}
              rel="noreferrer"
            >
              <img src={appleIosBadgeIcon} css={BadgeStyles} />
            </a>
            <a
              href={verseIOSDesktopUrl}
              target="_blank"
              css={{ marginRight: "1em" }}
              rel="noreferrer"
            >
              <img
                src={IosQRcode}
                alt="Verse iOS QR code"
                className="getTheAppQRCode"
                css={QRStyles}
              ></img>
            </a>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "1vh",
              alignItems: "center",
            }}
          >
            <a href={verseAndroidDesktopUrl} target="_blank" rel="noreferrer">
              <img src={googleBadgeIcon} css={BadgeStyles} />
            </a>
            <a href={verseAndroidDesktopUrl} target="_blank" rel="noreferrer">
              <img
                src={GoogleQRCode}
                alt="Verse Google play QR code"
                css={QRStyles}
              ></img>
            </a>
          </div>
        </div>
      </div>
    </PopupOverlay>
  );
}

const BadgeStyles = css({
  height: "4em",
  maxWidth: "100%",
});

const QRStyles = css({
  height: "8em",
  width: "8em",
});
