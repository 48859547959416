import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchChatbotBanners } from "../chatbots";
import { useSuspenseConfig } from "./useConfig";

export function useChatbotBanners() {
  const config = useSuspenseConfig();

  return useSuspenseQuery({
    queryKey: ["chatbotBanners", config],
    queryFn: () => fetchChatbotBanners(config),
  });
}
