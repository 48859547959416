import cloneDeep from "lodash/cloneDeep";
import { proxy } from "valtio";
import { generateRandomString } from "../helpers/Utils";
import WebGwContact from "../helpers/WebGwContact";

export type Phone = {
  number: string;
  type: string;
  id: string;
  caps: boolean;
};

export type Email = {
  email: string;
  type: string;
  id: string;
};

export type RelatedPerson = {
  name: string;
  type: string;
  id: string;
};

export type Address = {
  type: string;
  street1: string;
  street2: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  id: string;
};

const defaultContactState = {
  id: "",
  contacts: undefined as WebGwContact[] | undefined,
  firstName: "",
  lastName: "",
  phoneNumber: [
    { number: "", type: "", id: generateRandomString(6), caps: false },
  ] as Phone[],
  related: [
    { name: "", type: "", id: generateRandomString(6) },
  ] as RelatedPerson[],
  company: "",
  email: [{ email: "", type: "", id: generateRandomString(6) }] as Email[],
  birthday: "",
  notes: "",
  website: "",
  address: [
    {
      type: "",
      street1: "",
      street2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      id: generateRandomString(6),
    },
  ] as Address[],
  userAvatar: undefined,
};

export const contactState = proxy(cloneDeep(defaultContactState));

export function resetContactState() {
  const resetContactState = cloneDeep(defaultContactState);
  for (const key of Object.keys(resetContactState)) {
    contactState[key] = resetContactState[key];
  }
}
