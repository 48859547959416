import {
  SketchBackgroundType,
  SketchImageType,
  SketchUndoType,
} from "@/utils/helpers/notificationChannel";

export const SKETCH_EXPORT_IMAGE_TYPE = "png";
export const SKETCH_EXPORT_FILENAME = "sketch.svg";

export type SketchOptionsType = {
  defaultBrushColor: string;
  defaultBrushSize: number;
  defaultBackgroundColor: string;
};

export type SketchRefType = {
  setBrush: VoidFunction;
  setBrushSize: (size: number) => void;
  setBrushColor: (color: string) => void;
  setBackgroundColor: (sketchBackgroundColor: SketchBackgroundType) => void;
  setBackgroundImage: (
    sketchImage: SketchImageType,
    callback?: (blob: string) => void
  ) => void;
  setEraser: VoidFunction;
  undo: (undo: SketchUndoType) => void;
  toBase64: (format: string) => string | undefined;
};
