import type { Config } from "tailwindcss";
import { colors } from "./src/styles/global.styles";

export default {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      colors: {
        ...colors,
      },
      backgroundColor: {
        ...colors,
      },
      borderColor: {
        ...colors,
      },
    },
  },
  plugins: [],
} satisfies Config;
