import { DeviceIdentityData } from "@/types/common";
import { baseWebGwUrl } from "../../utils";
import { handleLoggedOut } from "../hooks/useWebgwSubscription";
import { nmsLogout } from "../messaging";
import {
  getDeviceName,
  getLocalAccessToken,
  getLocalUser,
  setLoggedOutFromUser,
} from "./localstorage";
import { generateDeviceId } from "./loginAndCaps/publishCaps";

type SetData = (data: DeviceIdentityData[]) => void;
type SetMaxDevices = (data: string) => void;

export const fetchAllDevices = async (
  setData: SetData,
  setMaxDevices: SetMaxDevices
) => {
  const accessToken = getLocalAccessToken();
  const currentTs = Date.now();
  const url = `${baseWebGwUrl}/client/v1/provisionedDevices?access_token=${accessToken}&_t=${currentTs}&resFormat=json`;
  try {
    const res = await fetch(url);
    const data = await res.json();
    console.log("registered devices", data);
    setData(data.notificationList.loginResultNotification.result.DeviceList);
    setMaxDevices(
      data.notificationList.loginResultNotification.result.MaxDevice
    );
  } catch (error) {
    console.error("Error fetching devices:", error);
  }
};

export const removeDevice = (
  devices: DeviceIdentityData[],
  deviceIdForRemoval: string,
  setData: SetData,
  setMaxDevices: SetMaxDevices,
  next?: (next?: any) => void
) => {
  const accessToken = getLocalAccessToken();
  const currentTs = Date.now();
  const removeDeviceURL = `${baseWebGwUrl}/client/v1/provisionedDevices/${deviceIdForRemoval}?access_token=${accessToken}&_t=${currentTs}&resFormat=json`;
  console.log(`Calling DELETE on deviceid: ${deviceIdForRemoval}`);
  fetch(removeDeviceURL, {
    method: "DELETE",
  }).then((res) => {
    console.log("show deleted device", deviceIdForRemoval);
    if (!devices || devices?.length === 0) {
      fetchAllDevices(setData, setMaxDevices);
    } else {
      if (next) {
        next();
      } else {
        fetchAllDevices(setData, setMaxDevices);
      }
    }
  });
};

const removeOwnDevice = async () => {
  const user = getLocalUser();
  const deviceName = getDeviceName();
  const deviceId = generateDeviceId(user!, deviceName!);
  const accessToken = getLocalAccessToken();
  const removeDeviceUrl = `${baseWebGwUrl}/client/v1/provisionedDevices/${deviceId}?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`;
  return fetch(removeDeviceUrl, {
    method: "DELETE",
  });
};

export const logoutEvent = async () => {
  try {
    const phoneNumber = getLocalUser();
    const accessToken = getLocalAccessToken();
    if (!phoneNumber || !accessToken) {
      throw new Error("User or access token not found");
    }
    setLoggedOutFromUser(true);
    const encodedPhoneNumber = encodeURIComponent("tel:" + phoneNumber);
    const removeDeviceResult = await removeOwnDevice();
    console.log("Removing self device from ACS:", removeDeviceResult);
    const result = await nmsLogout(encodedPhoneNumber);
    console.log("Logging out from NMS:", result);
  } catch (error) {
    console.error("Logout failed:", error);
  }

  // The logout feature should never be a blocker for the user, directly handle it here no matter if exception and without waiting for the server confirmation, which could take some time (or simply never come).
  handleLoggedOut(false);
};

export const loggedOutByAnotherDevice = (
  devices: any[],
  key: string,
  value: string
) => {
  const foundObject = devices.find((device) => device[key] === value);

  return foundObject ? foundObject : null;
};
