import { queryClient } from "@/utils/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/utils";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import ActiveTabPopup from "./components/navigation/ActiveTabPopup";
import { router } from "./router";
import { routerOdience } from "./routerOdience";
import { colors } from "./styles/global.styles";
import {
  getImpi,
  initReloadOnAccessTokenChange,
} from "./utils/helpers/localstorage";
import { ToastProvider } from "./utils/helpers/toastManager";
import { useIsTabActive } from "./utils/hooks/useIsTabActive";

initReloadOnAccessTokenChange();

function HydrateAtoms() {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return null;
}

export default function App() {
  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <HydrateAtoms />
        <Main />
        <Toaster
          containerStyle={{ zIndex: 99999999 }}
          toastOptions={{
            style: {
              backgroundColor: colors.secondaryBackground,
              color: colors.primaryTextColor,
            },
          }}
        />
      </QueryClientProvider>
    </ToastProvider>
  );
}

function Main() {
  const [isTabActive, setCurrentTabActive] = useIsTabActive();

  if (isTabActive || !getImpi()) {
    const ComponentToRender =
      window.location.hostname === window._env_.REACT_APP_DOMAIN_SECONDARY
        ? routerOdience
        : router;

    return <RouterProvider router={ComponentToRender} />;
  }

  return <ActiveTabPopup setCurrentTabActive={setCurrentTabActive} />;
}
