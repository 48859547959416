import { colors } from "@/styles/global.styles";
import styled from "@emotion/styled";
import { Slider } from "antd";
import { useState } from "react";

const StyledSlider = styled(Slider)`
  .ant-slider-handle:hover::after,
  .ant-slider-handle:focus::after {
    width: 8px;
    height: 8px;
    inset-inline-start: 0;
    inset-block-start: 0;
  }

  .ant-slider-handle::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 8px;
    height: 8px;
    background-color: ${colors.primaryAccentColor};
    border-radius: 50%;
    cursor: pointer;
    transition: unset;
    box-shadow: unset !important;
  }

  .ant-slider-track,
  .ant-slider-rail {
    height: 2px !important;
  }
`;

const SketchToolBrushSize = ({
  selected,
  onSelect,
  min,
}: {
  selected: number;
  onSelect: (size: number) => void;
  min: number;
}) => {
  const [value, setValue] = useState(selected);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "nowrap",
      }}
    >
      <StyledSlider
        min={min}
        defaultValue={selected}
        onChangeComplete={onSelect}
        onChange={setValue}
        style={{ margin: "0.5em", width: "75%" }}
        styles={{
          track: {
            height: "5px",
            background: colors.primaryAccentColor,
          },
          rail: {
            backgroundColor: "#707070",
          },
        }}
      />
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "50%",
          width: `${value / 6}px`,
          height: `${value / 6}px`,
        }}
      />
    </div>
  );
};

export default SketchToolBrushSize;
