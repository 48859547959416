
import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "../shared/Avatar";

const MessageInAppNotification = ({ contact, title, message, onClose, onReply }: { contact: WebGwContact, title: string, message?: string, onClose: VoidFunction, onReply: VoidFunction }) => {
	return <div css={{
		position: "fixed",
		top: "10%",
		left: "80%",
		transform: "translateX(-50%)",
		backgroundColor: colors.secondaryBackground,
		color: colors.primaryTextColor,
		padding: "1em",
		width: "fit-content",
		textAlign: "center",
		zIndex: "9999",
		borderRadius: "10px",
		boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
		fontSize: "0.8rem",
	}}
	>    <div
		css={{
			display: "flex",
			flexDirection: "row",
			gap: "2vw",
			fontSize: "1.2em",
			alignItems: "center",
			justifyContent: "center",
		}}
	>
			<Avatar contact={contact} />

			<div css={{ display: "flex", flexDirection: "column", maxWidth: "10vw", maxHeight: "10vh" }}>
				<p style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{title}</p>
				<p css={[{ textOverflow: "ellipsis", overflow: "hidden", "-webkit-line-clamp": "3", "-webkit-box-orient": "vertical", display: "-webkit-box" }]}>{message}</p>
			</div>

			<div css={{ display: "flex", flexDirection: "column" }}>
				<button onClick={onClose}>
					<CloseIcon />
				</button>
				<button
					css={{ color: "red", marginTop: "0.7em" }}
					onClick={onReply}
				>
					Reply
				</button>
			</div>
		</div>
	</div>;
}

export default MessageInAppNotification;