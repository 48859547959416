import Conversation from "@/utils/messaging/conversation/Conversation";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { buildNotificationBody, buildNotificationTitle } from ".";
import DesktopNotification from "./DesktopNotification";
import MessageInAppNotification from "./MessageInAppNotification";

const MessageNotification = ({ conversation, onOpenConversation, onClose, onDesktopNotification }: { conversation: Conversation, onOpenConversation: (conversation: Conversation) => void, onClose: VoidFunction, onDesktopNotification: (notification: Notification) => void }) => {
	const conversationLiveUpdate = useSnapshot(conversation);
	const timeoutDuration = Number(window._env_.NOTIFICATION_TIMEOUT) || 5000;
	const lastMessage = conversationLiveUpdate.getLastMessage();
	const contact = lastMessage?.contact() ?? conversationLiveUpdate.participants[0];
	const from = conversationLiveUpdate.getIsGroupChat()
		? contact.noNameReturnPhoneNumber()
		: undefined;
	const message = buildNotificationBody(lastMessage, from);
	const title = buildNotificationTitle(conversationLiveUpdate, !document.hidden);

	const handleReply = () => {
		onClose();
		onOpenConversation(conversation);
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			onClose();
		}, timeoutDuration);

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [from, message, title]);

	return document.hidden ? <DesktopNotification onNotification={onDesktopNotification} onClose={onClose} title={title} message={message} /> :
		<MessageInAppNotification contact={contact} title={title} message={message} onClose={onClose} onReply={handleReply} />;
};

export default MessageNotification;
